import styled, { css } from 'styled-components';
import { baseColors, typography } from '../../constants';

export const StyledSectionTime = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 8px;
    gap: 10px;
    border-radius: 4px;
    background: ${baseColors.primary()};
    & span {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #ffffff;
    }
`;

export const StyledTimer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
`;

const textEllipsis = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledTextValue = styled.p`
    ${typography.text.M};
    ${textEllipsis};
`;

export const StyledTextKey = styled.p`
    ${typography.text.M};
    ${textEllipsis};
    opacity: 0.72;
`;
