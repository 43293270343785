import { Form, Field } from 'react-final-form';
import { Input, Button } from '@/components';
import { StyledFormContainer, StyledFieldsContainer } from '../../styled';
import { Textarea } from '@/components/Input/Textarea/Textarea';
import { useContext, useState } from 'react';
import { apiClient } from '@/api/apiClient';
import { TOAST_TYPE, ToastContext } from '@/components/Toast/ToastContext';

export const WriteToUs = () => {
    const {toast} = useContext(ToastContext)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');
    const onClick = async () => {
        try {
            const res = await apiClient.post('/api/crm/feedback/', {
                    email,
                    name,
                    text
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            toast({title: "The form has been submitted"})
        } catch(e) {
            console.log(e)
            toast({title: "Failed to submit form", type: TOAST_TYPE.ERROR})
        }
        
    }
    const isDisabled = email === "" || name === "" || text === "";
    return <StyledFormContainer>
        <StyledFieldsContainer>
            <Input
                id="name"
                name="name"
                type="text"
                label="Your name"
                onChange={setName}
            />
            <Input
                id="email"
                name="email"
                type="email"
                label="Your email"
                onChange={setEmail}
            />
            <Textarea
                id="text"
                name="text"
                label="Text"
                onChange={setText}
            />
        </StyledFieldsContainer>
        <Button onClick={onClick} disabled={isDisabled}>Send to us</Button>
    </StyledFormContainer>
};
