import { memo } from 'react';
import { TextItem, OkIcon, FailedIcon } from '@/components';
import { IHealthChecks } from '@/types';
import { baseColors } from '@/constants';
import {
    StyledHealthChecks,
    StyledTitle,
    StyledContent,
    StyledDescription,
    StyledCheckContainer,
    StyledEmptyContainer,
} from './styled';

interface IProps {
    checks?: IHealthChecks[];
}

export const HealthChecks = memo(({ checks }: IProps) => {
    return (
        <StyledHealthChecks>
            <StyledTitle>Healthchecks</StyledTitle>

            {checks && checks.length ? (
                <StyledContent>
                    {checks.map((ch) => (
                        <StyledCheckContainer>
                            {ch.result === 'OK' ? (
                                <OkIcon fill={baseColors.ok()} size={24} />
                            ) : (
                                <FailedIcon fill={baseColors.error()} />
                            )}
                            <TextItem
                                widthKey={160}
                                keySize="M"
                                valueSize="M"
                                valueText={ch.description}
                                keyText={ch.name}
                                direction="column"
                            />
                        </StyledCheckContainer>
                    ))}
                </StyledContent>
            ) : (
                <StyledEmptyContainer>
                    <StyledDescription>
                        This tab usually contains checks to verify if system is
                        working.
                        <br />
                        There are no healthchecks for that particular system
                    </StyledDescription>
                </StyledEmptyContainer>
            )}
        </StyledHealthChecks>
    );
});
