import { useState, useEffect, useMemo, SetStateAction, Dispatch } from 'react';
import { LoaderDots } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { StyledPageLoaderContainer } from '@/pages/styled';
import {
    StyledMentor,
    StyledMentorMessage,
    StyledMentorMessageFooter,
    StyledMentorCharacter,
} from '../../styled';
import quote_left from '../../assets/quote_left_img.svg';
import character_image from '../../assets/character.png';
import { congratulationTextExample } from './constants';

interface IProps {
    messageFetching: boolean;
    message?: string;
    speedOfMesage?: number;
}

export const Mentor = ({
    messageFetching,
    message,
    speedOfMesage = 40,
}: IProps) => {
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [congratulationText, setCongratulationText] = useState<string>('');

    const { tasks } = useAppSelector((store) => store.TaskSlice);
    const { highlights } = useAppSelector((state) => state.FlagsSlice);

    const isDone = useMemo<boolean>(() => {
        const tasksIsDone = tasks?.map((t) => t.is_completed);
        const flagsIsDone = highlights?.not_found.length === 0;
        if (tasksIsDone && !tasksIsDone.includes(false) && flagsIsDone) {
            return true;
        }
        return false;
    }, [highlights, tasks]);

    useEffect(() => {
        const handleSetMessage = (
            msg: string,
            currMsg: string,
            setMsg: Dispatch<SetStateAction<string>>
        ) => {
            const char = msg.charAt(currMsg.length);
            setMsg(currMsg + char);
        };

        const intervalIsDoneMessage = setInterval(() => {
            if (isDone) {
                handleSetMessage(
                    congratulationTextExample,
                    congratulationText,
                    setCongratulationText
                );
                if (
                    congratulationText.length >=
                    congratulationTextExample.length
                ) {
                    clearInterval(intervalIsDoneMessage);
                }
                return;
            }
            clearInterval(intervalIsDoneMessage);
        }, speedOfMesage);

        const interval = setInterval(() => {
            if (message) {
                if (isDone) {
                    if (
                        congratulationText.length >=
                        congratulationTextExample.length
                    ) {
                        handleSetMessage(
                            message,
                            currentMessage,
                            setCurrentMessage
                        );
                        if (currentMessage?.length >= message?.length) {
                            clearInterval(interval);
                        }
                    }
                    return;
                }
                handleSetMessage(message, currentMessage, setCurrentMessage);
                if (currentMessage?.length >= message?.length) {
                    clearInterval(interval);
                }
            }
        }, speedOfMesage);

        return () => {
            clearInterval(interval);
            clearInterval(intervalIsDoneMessage);
        };
    }, [
        message,
        speedOfMesage,
        currentMessage,
        isDone,
        congratulationText,
        setCurrentMessage,
        setCongratulationText,
    ]);

    return (
        <StyledMentor>
            <StyledMentorMessage>
                {messageFetching ? (
                    <StyledPageLoaderContainer
                        style={{ justifyContent: 'flex-start' }}
                    >
                        <LoaderDots />
                    </StyledPageLoaderContainer>
                ) : (
                    <>
                        {isDone && <p>{congratulationText}</p>}
                        <p className={isDone ? 'secondMessage' : undefined}>
                            {currentMessage}
                        </p>
                    </>
                )}
            </StyledMentorMessage>
            <StyledMentorMessageFooter>
                <img src={quote_left} alt="" />
                <div className="middle_image"></div>
            </StyledMentorMessageFooter>
            <StyledMentorCharacter>
                <img
                    src={character_image}
                    alt="character"
                    className="imageCharacter"
                />
                <div className="textContainer">
                    <p className="name">Cowboy Luke</p>
                    <p className="jobTitle">Result Oriented CISO</p>
                </div>
            </StyledMentorCharacter>
        </StyledMentor>
    );
};
