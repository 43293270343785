import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
    text-align: center;
    width: 180px;
`;

export const StyledStreak = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledStreakContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2px;
    align-items: center;
`;

export const StyledStreakText = styled.span<{ $active: boolean }>`
    ${typography.text.XS};
    color: ${({ $active }) => baseColors.white($active ? 1 : 0.25)};
`;

const generateLines = ({
    $active,
    $position,
}: {
    $active: boolean;
    $position: 'first' | 'middle' | 'last' | 'lastMiddle';
}) => {
    const baseLineStyle = css`
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        min-width: 4px;
    `;
    return css`
        &::before {
            ${baseLineStyle};
            background: ${$active
                ? baseColors.primary($position === 'first' ? 0 : 1)
                : baseColors.white($position === 'first' ? 0 : 0.25)};
        }
        &::after {
            ${baseLineStyle};
            background: ${$active
                ? $position === 'lastMiddle'
                    ? baseColors.white(0.25)
                    : baseColors.primary($position === 'last' ? 0 : 1)
                : baseColors.white($position === 'last' ? 0 : 0.25)};
        }
    `;
};

export const StyledPointContainer = styled.div<{
    $active: boolean;
    $position: 'first' | 'middle' | 'last' | 'lastMiddle';
}>`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 0;
    ${generateLines};
`;

const generateStylePoint = ({ $active }: { $active: boolean }) => {
    return css`
        background: ${$active ? baseColors.primary() : baseColors.white(0.25)};
    `;
};

export const StyledPoint = styled.div<{
    $active: boolean;
}>`
    width: 8px;
    height: 8px;
    min-width: 8px;
    min-height: 8px;
    max-width: 8px;
    max-height: 8px;
    border-radius: 24px;
    ${generateStylePoint}
`;
