import { Field, Form } from 'react-final-form';
import { Button, Input, IconButton, CloseIcon } from '@/components';
import { changeUserNameThunk } from '@/store/reducers/ProfileSlice/AsyncThunk';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { StyledForm, StyledHeader } from '../styled';

interface IProps {
    onClose?: () => void;
}

interface IValue {
    username?: string;
}

export const ChangeUsername = ({ onClose }: IProps) => {
    const dispatch = useAppDispatch();
    const { profile, isLoadingProfile } = useAppSelector(
        (state) => state.ProfileSlice
    );
    const initialValues: IValue = {
        username: profile?.user,
    };

    const submitHandler = async (args: IValue) => {
        if (!!args.username) {
            const resp = await dispatch(changeUserNameThunk(args.username));
            if (resp.meta.requestStatus === 'fulfilled' && onClose) onClose();
        }
    };

    return (
        <>
            <StyledHeader>
                <span>Change username</span>
                <IconButton
                    icon={<CloseIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose && onClose();
                    }}
                />
            </StyledHeader>
            <Form onSubmit={submitHandler} initialValues={initialValues}>
                {({ handleSubmit, pristine, values: { username } }) => {
                    const isDisabled =
                        pristine ||
                        !username ||
                        username === profile?.user ||
                        isLoadingProfile;
                    return (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field name="username">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            label="Name"
                                            value={value}
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            </Field>
                            <Button type="submit" disabled={isDisabled}>
                                Change
                            </Button>
                        </StyledForm>
                    );
                }}
            </Form>
        </>
    );
};
