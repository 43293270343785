import { nanoid } from 'nanoid';
import { IWorkshopLaunchedLab, IWorkshop } from '@/types';
import {
    IconButton,
    LinkIcon,
    TextItem,
    CopyItem,
    NoData,
    AddIcon,
    LoaderDots,
} from '@/components';
import { headers } from './constants';
import {
    StyledTable,
    StyledRow,
    StyledContainer,
    StyledAction,
    StyledCell,
    StyledHeader,
} from './styled';
import { useEffect, useState } from 'react';
import { apiClient } from '@/api/apiClient';
import { StyledPageLoaderContainer } from '@/pages/styled';

interface IProps {
    // workshopLabs?: IWorkshopLaunchedLab[];
    workshopId: number | string;
}

export const LabList = ({ workshopId }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [workshopLabs, setWorkshopLabs] = useState<
        IWorkshopLaunchedLab[] | undefined
    >(undefined);

    useEffect(() => {
        const getLabs = async (id: number | string) => {
            setFetching(true);
            try {
                const response = await apiClient.get(`/api/v1/workshops/${id}`);
                const data = (await response.data) as IWorkshop;
                await setWorkshopLabs(data.labs);
                await setFetching(false);
            } catch (err) {
                console.log(err);
            }
        };
        if (isOpened) getLabs(workshopId);
    }, [isOpened, workshopId]);

    const renderContent = () => {
        if (workshopLabs && workshopLabs.length) {
            return (
                <>
                    {workshopLabs?.map((w, idx) => {
                        const { uuid, student_name, attempts, points } = w;
                        const labLink = `${window.location.origin}/workshoplab/${uuid}/`;
                        return (
                            <StyledRow index={idx + 1} key={uuid}>
                                <StyledContainer>
                                    <StyledCell
                                        style={{ width: headers[0].width }}
                                    >
                                        <span>{student_name}</span>
                                    </StyledCell>
                                    <StyledCell
                                        style={{ width: headers[1].width }}
                                    >
                                        <span>{attempts}</span>
                                    </StyledCell>
                                    <StyledCell
                                        style={{ width: headers[2].width }}
                                    >
                                        <span>{points}</span>
                                    </StyledCell>
                                    <StyledCell
                                    // style={{ width: headers[3].width }}
                                    >
                                        <CopyItem
                                            valueText={labLink}
                                            size="M"
                                        />
                                    </StyledCell>
                                </StyledContainer>
                            </StyledRow>
                        );
                    })}
                </>
            );
        }
        return <NoData title="No data" style={{ padding: '64px 0' }} />;
    };

    return (
        <StyledTable>
            <StyledHeader
                $isOpened={isOpened}
                onClick={(e) => {
                    e.preventDefault();
                    setIsOpened(!isOpened);
                }}
            >
                <span>Labs</span>
                <AddIcon />
            </StyledHeader>
            {isOpened ? (
                <>
                    <StyledRow index={0}>
                        <StyledContainer>
                            {headers.map((h) => (
                                <StyledCell
                                    key={nanoid()}
                                    style={{ width: `${h.width}px` }}
                                >
                                    <span className="cellHeader">
                                        {h.title}
                                    </span>
                                </StyledCell>
                            ))}
                        </StyledContainer>
                    </StyledRow>
                    {fetching ? (
                        <StyledPageLoaderContainer style={{ padding: '8px 0' }}>
                            <LoaderDots />
                        </StyledPageLoaderContainer>
                    ) : (
                        renderContent()
                    )}
                </>
            ) : null}
        </StyledTable>
    );
};
