import styled from "styled-components";

export const StyledVolume = styled.div`
  height: 20px;
  padding: 2px;
  display: flex;
  gap: 2px;
  justify-content: space-between;
`;

export const StyledItem = styled.div<{ bgColor: string }>`
  height: 16px;
  width: 4px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 4px;
`;
