import { Icon } from "../Icon";

export const IconWarning = () => {
  return (
    <Icon
      content={
        <>
          <path
            d="M14.7243 2.61594C13.6039 0.46135 10.5214 0.461355 9.40102 2.61594L1.34239 18.1133C0.303882 20.1104 1.75304 22.4974 4.00404 22.4974H20.1213C22.3723 22.4974 23.8215 20.1104 22.783 18.1133L14.7243 2.61594Z"
            fill="white"
          />
          <path
            d="M13.6456 13.5563C13.6144 14.0848 13.1768 14.4974 12.6474 14.4974H11.4772C10.9479 14.4974 10.5102 14.0848 10.479 13.5563L10.1248 7.5563C10.0909 6.98185 10.5476 6.49738 11.1231 6.49738H13.0015C13.577 6.49738 14.0337 6.98185 13.9998 7.5563L13.6456 13.5563Z"
            fill="#DE621C"
          />
          <path
            d="M14.0625 18.4974C14.0625 19.6019 13.1671 20.4974 12.0625 20.4974C10.9579 20.4974 10.0625 19.6019 10.0625 18.4974C10.0625 17.3928 10.9579 16.4974 12.0625 16.4974C13.1671 16.4974 14.0625 17.3928 14.0625 18.4974Z"
            fill="#DE621C"
          />
        </>
      }
    />
  );
};
