import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const TrashIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3H10C10.5523 3 11 2.55228 11 2C11 1.44772 10.5523 1 10 1H6Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 5C1 4.44772 1.44772 4 2 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H13V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V6H2C1.44772 6 1 5.55228 1 5ZM5 6H11V13H5V6Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H15C15.5523 4 16 3.55228 16 3C16 2.44772 15.5523 2 15 2H9Z"
                            fill={fill}
                        />
                        <path
                            d="M11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12V16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16V12Z"
                            fill={fill}
                        />
                        <path
                            d="M15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 7C2 7.55228 2.44772 8 3 8H5V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6H3C2.44772 6 2 6.44772 2 7ZM7 20V8H17V20H7Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
