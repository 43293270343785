import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledCard = styled.div`
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    background: ${baseColors.levelThree()};
    border-radius: 8px;
    border: 1px solid ${baseColors.white(0.1)};
`;

export const StyledContent = styled.div`
    box-sizing: border-box;
    padding: 16px 120px 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledHeader = styled.div``;

export const StyledSubheader = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledSubheaderItem = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const StyledTitle = styled.span`
    ${typography.title.L};
`;

export const StyledIsPremium = styled.span`
    ${typography.title.XS};
    color: ${baseColors.warning()};
    text-transform: uppercase;
    letter-spacing: 0.17rem;
`;

export const StyledSmallText = styled.span`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

export const StyledStatContainer = styled.div`
    display: flex;
    gap: 32px;
    box-sizing: border-box;
    padding: 16px 32px 16px 0px;
`;

export const StyledStats = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
