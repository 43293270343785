import { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Button } from '@/components';
import { ITask, ILab } from '@/types';
import { NoData, LoaderDots } from '@/components';
import { baseColors } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    openHintThunk,
    getHintThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { StyledContainer, StyledFooter } from './styled';

interface IProps {
    taskId?: ITask['id'];
    uuid?: ILab['uuid'];
}

export const Content = ({ taskId, uuid }: IProps) => {
    const dispatch = useAppDispatch();

    const { hint, messageAboutEmptyHint, hintState, openHintState } =
        useAppSelector((state) => state.TaskSlice);

    useEffect(() => {
        if (taskId && uuid) {
            dispatch(getHintThunk({ taskId, uuid }));
        }
    }, [taskId, uuid, dispatch]);

    const isNoData =
        (!hint || !hint.length) &&
        (!messageAboutEmptyHint || !messageAboutEmptyHint?.length);

    const isFetching = hintState.fetching || openHintState.fetching;

    const renderContent = () => {
        if (isFetching) {
            return (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (isNoData) {
            return (
                <NoData
                    style={{ paddingTop: '32px', paddingBottom: '48px' }}
                    title="No hints"
                    text="In real life there will be no such button"
                    buttons={[
                        {
                            title: 'Get hint',
                            action: () => {
                                if (taskId && uuid)
                                    dispatch(openHintThunk({ taskId, uuid }));
                            },
                        },
                    ]}
                />
            );
        }
        return (
            <>
                {hint?.map((h, idx) => {
                    return <p key={nanoid()}>{`${idx}. ${h.text}`}</p>;
                })}
                {messageAboutEmptyHint && (
                    <p style={{ color: baseColors.critical() }}>
                        {messageAboutEmptyHint}
                    </p>
                )}
            </>
        );
    };
    return (
        <>
            <StyledContainer>{renderContent()}</StyledContainer>
            {!isNoData && (
                <StyledFooter>
                    <Button
                        disabled={isFetching}
                        onClick={() => {
                            if (taskId && uuid) {
                                dispatch(openHintThunk({ taskId, uuid }));
                            }
                        }}
                    >
                        Get New Hint
                    </Button>
                </StyledFooter>
            )}
        </>
    );
};
