import { useState } from 'react';
import { IconButton, AddIcon, AddWorkshop, PopUp } from '@/components';

export const Actions = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <PopUp opened={isOpen}>
                <AddWorkshop onExpandModal={setIsOpen} />
            </PopUp>
            <IconButton
                icon={<AddIcon />}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                }}
            />
        </>
    );
};
