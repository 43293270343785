import { IStep, IAnchors } from './types';

export const steps: IStep[] = [
    {
        step: 1,
        text: [
            {
                text: 'We deploy a',
                type: 'simple',
            },
            {
                text: ' live',
                type: 'accent',
            },
            {
                text: ' and',
                type: 'simple',
            },
            {
                text: ' vulnerable',
                type: 'accent',
            },
            {
                text: ' system',
                type: 'simple',
            },
        ],
    },
    {
        step: 2,
        text: [
            {
                text: 'You have',
                type: 'simple',
            },
            
            {
                text: ' access',
                type: 'accent',
            },
            {
                text: ' to',
                type: 'simple',
            },
            {
                text: ' SIEM deployed for you ',
                type: 'accent',
            },
        ],
    },
    {
        step: 3,
        text: [
            {
                text: 'You discover ',
                type: 'accent',
            },
            {
                text: ' system and',
                type: 'simple',
            },
            {
                text: ' interact',
                type: 'accent',
            },
            {
                text: ' with it',
                type: 'simple',
            },


        ],
    },
    {
        step: 4,
        text: [
            {
                text: 'You ',
                type: 'accent',
            },
            {
                text: ' have to ',
                type: 'simple',
            },
            {
                text: ' harden ',
                type: 'accent',
            },
            {
                text: ' the system',
                type: 'simple',
            },

        ],
    },
];

export const links: { [key: string]: IAnchors } = {
    main: {
        next: {
            name: 'Whom',
            link: '#whom',
        },
        prev: undefined,
    },
    whom: {
        next: {
            name: 'Polygon',
            link: '#polygon',
        },
        prev: {
            name: 'Defbox',
            link: '#main',
        },
    },
    polygon: {
        next: {
            name: 'Inside',
            link: '#inside',
        },
        prev: {
            name: 'Whom',
            link: '#whom',
        },
    },
    inside: {
        next: {
            name: 'Offer',
            link: '#offer',
        },
        prev: {
            name: 'Polygon',
            link: '#polygon',
        },
    },
    offer: {
        prev: {
            name: 'Inside',
            link: '#inside',
        },
        next: undefined,
    },
};
