import { CSSProperties } from 'react';

export enum VARIANT {
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    SUCCESS = 'SUCCESS',
}

export interface IAlertProps {
    variant?: VARIANT;
    code?: number;
    message?: string;
    style?: CSSProperties;
}
