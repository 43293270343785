import { createSlice } from '@reduxjs/toolkit';
import { ILab, IError, IStatisticFromLab } from '@/types';
import {
    getLabThank,
    destroyLabThank,
    checkStatusLabThank,
    getStatisticLabThunk,
} from './AsyncThanks';
import {
    getWsLabByUuidThunk,
    destroyWsLabThunk,
    checkStatusWsLabThunk,
    getStatisticWsLabThunk,
} from './AsyncThanks/WorkshopThunks';

interface IInitialState {
    lab?: ILab;
    labState: {
        errors: IError;
        fetching: boolean;
    };
    destroyLabState: {
        errors: IError;
        fetching: boolean;
    };
    statistic?: IStatisticFromLab;
    statisticState: { errors: IError; fetching: boolean };
}

const initialState: IInitialState = {
    lab: undefined,
    labState: {
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    destroyLabState: {
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    statistic: undefined,
    statisticState: {
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
};

export const labWithSchemeSlice = createSlice({
    name: 'labWithSchemeSlice',
    initialState,
    reducers: {
        resetLab(state) {
            state.lab = undefined;
            state.labState.fetching = false;
            state.labState.errors = {
                code: undefined,
                message: undefined,
            };
        },
    },
    extraReducers(builder) {
        // GET LAB
        // // lab
        builder.addCase(getLabThank.pending, (state) => {
            state.labState.fetching = true;
            state.labState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLabThank.fulfilled, (state, action) => {
            state.labState.fetching = false;
            state.lab = action.payload;
            state.labState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLabThank.rejected, (state, action) => {
            state.labState.fetching = false;
            state.labState.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(getWsLabByUuidThunk.pending, (state) => {
            state.labState.fetching = true;
            state.labState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWsLabByUuidThunk.fulfilled, (state, action) => {
            state.labState.fetching = false;
            state.lab = action.payload;
            state.labState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWsLabByUuidThunk.rejected, (state, action) => {
            state.labState.fetching = false;
            state.labState.errors = action.payload as IError;
        });

        // DESTROY LAB
        // // lab
        builder.addCase(destroyLabThank.pending, (state) => {
            state.destroyLabState.fetching = true;
            state.destroyLabState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(destroyLabThank.rejected, (state, action) => {
            state.destroyLabState.fetching = false;
            state.destroyLabState.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(destroyWsLabThunk.pending, (state) => {
            state.destroyLabState.fetching = true;
            state.destroyLabState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(destroyWsLabThunk.rejected, (state, action) => {
            state.destroyLabState.fetching = false;
            state.destroyLabState.errors = action.payload as IError;
        });

        // CHECK STATUS
        // // lab
        builder.addCase(checkStatusLabThank.fulfilled, (state, action) => {
            if (action.payload?.status !== state.lab?.status) {
                state.lab = action.payload;
            }
        });
        // // workshoplabs
        builder.addCase(checkStatusWsLabThunk.fulfilled, (state, action) => {
            if (action.payload?.status !== state.lab?.status) {
                state.lab = action.payload;
            }
        });

        // GET STATISTIC
        // // lab
        builder.addCase(getStatisticLabThunk.pending, (state) => {
            state.statisticState.fetching = true;
            state.statisticState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStatisticLabThunk.fulfilled, (state, action) => {
            state.statistic = action.payload;
            state.statisticState.fetching = false;
            state.statisticState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStatisticLabThunk.rejected, (state, action) => {
            state.statisticState.fetching = false;
            state.statisticState.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(getStatisticWsLabThunk.pending, (state) => {
            state.statisticState.fetching = true;
            state.statisticState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStatisticWsLabThunk.fulfilled, (state, action) => {
            state.statistic = action.payload;
            state.statisticState.fetching = false;
            state.statisticState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStatisticWsLabThunk.rejected, (state, action) => {
            state.statisticState.fetching = false;
            state.statisticState.errors = action.payload as IError;
        });
    },
});

export default labWithSchemeSlice.reducer;
