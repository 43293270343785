import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';
import arrow from './assets/arrow.svg';

const generateContainerPadding = ({ $length }: { $length: number }) => {
    return css`
        padding-left: ${$length * 16 + 16}px;
    `;
};

export const StyledContainer = styled.div<{ $length: number }>`
    box-sizing: border-box;
    width: 100%;
    ${generateContainerPadding};
`;

export const StyledCard = styled.div`
    background: ${baseColors.levelTwo()};
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    cursor: default;
    position: relative;
`;

const generateDirectionArrow = ({
    $direction,
}: {
    $direction: 'from' | 'to' | 'between';
}) => {
    switch ($direction) {
        case 'to':
            return css`
                top: calc(50% + 3px);
                height: calc(50% + 4px);
                border-left: 1px solid ${baseColors.white(0.5)};
                border-top: 1px solid ${baseColors.white(0.5)};
                &::before {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 8px;
                    position: absolute;
                    top: -4px;
                    right: 0;
                    background: url(${arrow});
                    opacity: 0.5;
                }
            `;
        case 'from':
            return css`
                top: -3px;
                height: calc(50% + 4px);
                border-left: 1px solid ${baseColors.white(0.5)};
                border-bottom: 1px solid ${baseColors.white(0.5)};
                &::before {
                    content: '';
                    display: block;
                    width: 5px;
                    height: 8px;
                    position: absolute;
                    bottom: -4px;
                    right: 0;
                    background: url(${arrow});
                    opacity: 0.5;
                }
            `;
        case 'between':
            return css`
                height: 100%;
                border-left: 1px solid ${baseColors.white(0.5)};
            `;
    }
};

const generateOffsetArrow = ({ $index }: { $index: number }) => {
    return css`
        width: ${($index + 1) * 16}px;
        left: -${($index + 1) * 16 + 8}px;
    `;
};

export const StyledConnectArrow = styled.div<{
    $index: number;
    $direction: 'from' | 'to' | 'between';
}>`
    position: absolute;
    box-sizing: border-box;
    z-index: 100;
    ${generateOffsetArrow};
    ${generateDirectionArrow};
`;

// export const StyledVmsWrapper = styled.div`
//     display: flex;
//     background: ${baseColors.levelThree()};
//     box-sizing: border-box;
//     width: fit-content;
//     border-radius: 0 0 6px 6px;
// `;

// export const StyledVm = styled.div`
//     box-sizing: border-box;
//     width: 314px;
//     min-width: 314px;
//     max-width: 314px;
//     padding: 12px 16px 24px 16px;
//     display: flex;
//     flex-direction: column;
//     gap: 24px;
// `;

// export const StyledNotIp = styled.p`
//     ${typography.text.M};
//     opacity: 0.25;
// `;

// export const StyledHeader = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 4px;
// `;

// export const StyledTitle = styled.p`
//     ${typography.title.L};
// `;

// export const StyledImageContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 128px;
// `;

// export const StyledFooter = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 16px;
// `;
