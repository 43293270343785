import { Block } from '@/components';
import { Person, UserList, AchievementList } from './components';
import { StyledSidebar } from './styled';

export const Sidebar = () => {
    return (
        <StyledSidebar>
            <Person />
            <Block title="Achievements" headerStyle={{ padding: '12px 16px' }}>
                <AchievementList />
            </Block>
            <Block
                title="Users like you"
                headerStyle={{ padding: '12px 16px' }}
            >
                <UserList />
            </Block>
        </StyledSidebar>
    );
};
