import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/redux';
import { getProfile } from '@/store/reducers/ProfileSlice';
import { getTemplateListThank } from '@/store/reducers/TemplatesSlice/AsyncThanks';
import { getNewsThunk } from '@/store/reducers/News/AsyncThunks/getNewsThunk';
import { getLayout } from '@/store/reducers/LayoutSlice';
import { MainHeader } from '../../components';
import { StyledMainLayout } from './styled';
import { SideBarContext } from '@/context/sideBarContext';

export const Layout = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getProfile());
        dispatch(getTemplateListThank());
        dispatch(getLayout());
        dispatch(getNewsThunk());
    }, [dispatch]);
    const [isOpened, setIsOpened] = useState(false);
    return (
        <StyledMainLayout>
            <SideBarContext.Provider value={{ isOpened, setIsOpened }}>
                <MainHeader />
                <Outlet />
            </SideBarContext.Provider>
        </StyledMainLayout>
    );
};
