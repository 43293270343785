import { nanoid } from '@reduxjs/toolkit';
import { AddIcon, MinusIcon } from '@/components';
import { IOffer } from '../../types';
import { StyledOfferCard, StyledHeader, StyledRow } from './styled';

export const OfferCard = (props: IOffer) => {
    const { title, rows } = props;

    const renderValue = (enabled: boolean, value?: string) => {
        if (!!value) {
            return <span>{value}</span>;
        }
        return enabled ? <AddIcon size={20} /> : <MinusIcon size={20} />;
    };

    return (
        <StyledOfferCard>
            <StyledHeader>
                <p>{title}</p>
            </StyledHeader>
            {rows.map((r) => {
                return (
                    <StyledRow key={nanoid()}>
                        <p>{r.title}</p>
                        {renderValue(r.enabled, r.value)}
                    </StyledRow>
                );
            })}
        </StyledOfferCard>
    );
};
