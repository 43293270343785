import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 1;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    padding: 8px;
    background: ${baseColors.levelTwo()};
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 8px;
`;

export const StyledTitle = styled.p`
    ${typography.title.XL};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const StyledActionContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledTracks = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
`;

export const StyledLabList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
