import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IError } from '../../types/error';
import { ILaunchCard } from '../../components/Card/types';
import { modalSlice } from './ModalSlice';
import { IDeployElasticAndCreateLaunchArgs } from './types/cards';
import { launchCardListSlice } from './LaunchCardsSlice';
import { apiClient } from '../../api/apiClient';

interface IInitialState {
    loader: boolean;
    error: IError;
}

const initialState: IInitialState = {
    loader: false,
    error: {
        code: undefined,
        message: undefined,
    },
};

export const deployElasticAndCreateLaunch = createAsyncThunk<
    ILaunchCard[],
    IDeployElasticAndCreateLaunchArgs,
    { rejectValue: IError }
>(
    'deployElasticAndCreateSlice/deployElasticAndCreateLaunch',
    async function (args, { rejectWithValue, dispatch }) {
        const { labId, difficulty } = args;

        try {
            await apiClient.post(
                `/api/v1/labtemplates/${String(labId)}/run_lab_with_siem/`,
                {
                    difficulty: String(difficulty),
                }
            );

            const response = await apiClient.get('/api/v1/labs/');
            const data = await response.data;
            dispatch(modalSlice.actions.close());
            dispatch(launchCardListSlice.actions.cardFetching(data));
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const deployElasticAndCreateSlice = createSlice({
    name: 'deployElasticAndCreateSlice',
    initialState,
    reducers: {
        reset(state) {
            state.error.code = undefined;
            state.error.message = undefined;
            state.loader = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(deployElasticAndCreateLaunch.pending, (state) => {
            state.loader = true;
            state.error.code = undefined;
            state.error.message = undefined;
        });
        builder.addCase(
            deployElasticAndCreateLaunch.rejected,
            (state, action) => {
                state.loader = false;
                state.error.code = action.payload?.code;
                state.error.message = action.payload?.message;
            }
        );
    },
});

export default deployElasticAndCreateSlice.reducer;
