import { LoaderDots, Tag } from '@/components';
import { STATUS_LAB, statusLabColor, statusLabText } from '@/constants';
import { StyledText, StyledContainer } from './styled';

interface IProps {
    status: STATUS_LAB;
    text?: string;
}

export const Loader = ({ status, text }: IProps) => {
    return (
        <StyledContainer>
            {text && (
                <>
                    <Tag
                        title={statusLabText[status]}
                        style={{
                            background: statusLabColor[status],
                            textTransform: 'uppercase',
                        }}
                    />
                    <StyledText style={{ color: statusLabColor[status] }}>
                        {text}
                    </StyledText>
                </>
            )}
            <LoaderDots color={statusLabColor[status]} />
        </StyledContainer>
    );
};
