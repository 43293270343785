import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const SchevronRightIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.35984 14.7682C4.93556 14.4147 4.87824 13.7841 5.2318 13.3598L9.69832 7.99999L5.2318 2.64018C4.87824 2.2159 4.93556 1.58534 5.35984 1.23177C5.78412 0.878209 6.41468 0.935533 6.76825 1.35981L11.7682 7.35981C12.0773 7.73066 12.0773 8.26933 11.7682 8.64018L6.76825 14.6402C6.41468 15.0645 5.78412 15.1218 5.35984 14.7682Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.25671 21.669C6.88726 21.2585 6.92053 20.6262 7.33104 20.2567L16.5052 12L7.33104 3.7433C6.92053 3.37384 6.88726 2.74155 7.25672 2.33104C7.62617 1.92053 8.25846 1.88726 8.66897 2.25671L18.669 11.2567C18.8797 11.4464 19 11.7165 19 12C19 12.2835 18.8797 12.5537 18.669 12.7433L8.66897 21.7433C8.25846 22.1128 7.62617 22.0795 7.25671 21.669Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
