import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IStatisticFromLab, IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { LAB_TYPE } from '@/constants';

export const getStatisticLabThunk = createAsyncThunk<
    IStatisticFromLab,
    { uuid: string; basePath?: LAB_TYPE },
    { rejectValue: IError }
>(
    'labWithSchemeSlice/getStatisticLabThunk',
    async ({ uuid, basePath = LAB_TYPE.DEFAULT }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `api/v1/${basePath}/${uuid}/get_stats/`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
