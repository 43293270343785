import styled from 'styled-components';
import rect from './Rectangle.svg';

export const StyledInnerMarkedText = styled.mark<{
    $color?: string;
    $offset?: number;
    $radius?: number;
}>`
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    background-image: url(${rect});
    background-color: rgba(255, 255, 255, 0);
    background-repeat: repeat-x;
    background-position-y: 0.3rem;
    background-size: 1rem;
`;
