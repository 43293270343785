import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledContainer = styled.div`
    background: ${baseColors.levelOne()};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 8px 88px;
`;

export const StyledHeader = styled.div`
    box-sizing: border-box;
    padding: 8px 0;
    display: flex;
    gap: 8px;
    align-items: center;
    background: ${baseColors.levelOne()};
    border-bottom: 1px solid ${baseColors.white(0.1)};
    position: sticky;
    top: -8px;
    left: 0;
    z-index: 1;
`;

export const StyledDescription = styled.span`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledHeaderSeparator = styled.div`
    width: 1px;
    height: 8px;
    min-width: 1px;
    min-height: 8px;
    background: ${baseColors.white(0.25)};
`;

export const StyledTitleContainer = styled.div`
    box-sizing: border-box;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.h1`
    ${typography.title.XXXXL};
`;

export const StyledSubtitle = styled.p`
    ${typography.title.L};
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 860px;
    > ul,
    > ol > {
        box-sizing: border-box;
        padding: 16px;
    }
    > ul > li,
    > ol > li {
        ${typography.text.M};
        box-sizing: border-box;
        padding-bottom: 8px;
    }
    > p {
        ${typography.text.L};
        box-sizing: border-box;
        padding: 16px 0;
    }
    > h2 {
        ${typography.title.XXL};
        box-sizing: border-box;
        padding: 40px 0 16px 0;
    }
    > img,
    p > img,
    span > img,
    a > img,
    h1 > img,
    h2 > img,
    h3 > img {
        width: 100%;
        box-sizing: border-box;
        max-width: 640px;
        margin: 0 16px;
    }
`;
