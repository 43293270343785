import styled from 'styled-components';
import { typography, baseColors } from '@/constants';
import { StyledContentWrapper } from '../../styled';

export const StyledPolygon = styled.div`
    ${StyledContentWrapper};
    display: flex;
    flex-direction: column;
    gap: 88px;
    box-sizing: border-box;
    padding-top: 44px;
`;

export const StyledTitleContainer = styled.div``;

export const StyledTitleDescriptionContainerTop = styled.div`
    display: flex;
`;

export const StyledTitleDescriptionContainerBottom = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const StyledTitleDescription = styled.div`
    width: 25%;
    > p {
        ${typography.text.M};
        font-style: italic;
    }
    @media (max-width: 920px) {
        > p {
            ${typography.text.S};
            font-style: italic;
        }
    }
    @media (max-width: 768px) {
        width: 50%;
    }
`;

export const StyledFootnotLeft = styled.div`
    width: 12.5%;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-left: 12px;
    &::before {
        content: '';
        display: block;
        height: 1px;
        width: calc(100% - 12px);
        position: absolute;
        background: ${baseColors.white(0.25)};
    }
    &::after {
        content: '';
        display: block;
        height: 1px;
        width: calc(50% - 12px);
        position: absolute;
        background: ${baseColors.white(0.25)};
        left: 100%;
        transform: rotate(45deg);
        transform-origin: top left;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledFootnotRight = styled.div`
    width: calc(12.5%);
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-right: 12px;
    &::before {
        content: '';
        display: block;
        height: 1px;
        width: calc(100% - 12px);
        position: absolute;
        background: ${baseColors.white(0.25)};
    }
    &::after {
        content: '';
        display: block;
        height: 1px;
        width: calc(50% - 12px);
        position: absolute;
        background: ${baseColors.white(0.25)};
        left: calc(-50% + 12px);
        transform: rotate(45deg);
        transform-origin: top right;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledTitleImage = styled.img`
    width: 100%;
`;

export const StyledSeparator = styled.div`
    height: 1px;
    width: 100%;
    background: ${baseColors.white(0.1)};
`;

export const StyledCardsList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    @media (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledCardListTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledCardListTitle = styled.p`
    ${typography.title.XL};
    max-width: 220px;
`;

export const StyledCardListSubitle = styled.p`
    ${typography.text.L};
    max-width: 220px;
`;

export const StyledBanner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    height: 88%;
    box-sizing: border-box;
    padding: 0 16px;
    @media (max-width: 768px) {
        padding: 56px 88px 88px 88px;
    }
    @media (max-width: 560px) {
        padding: 56px 40px 88px 40px;
    }
`;

export const StyledBannerTitle = styled.p`
    ${typography.title.XL};
    text-align: center;
    @media (max-width: 560px) {
        ${typography.title.L};
    }
`;
