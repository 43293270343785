import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledAchivement = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledMoreCell = styled(StyledAchivement)`
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

export const StyledImg = styled.img<{ $height: number; $received: boolean }>`
    height: ${({ $height }) => $height}px;
    opacity: ${({ $received }) => ($received ? 1 : 0.25)};
`;

export const StyledTextContainer = styled.div<{ received: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    opacity: ${({ received }) => (received ? 1 : 0.25)};
`;

export const StyledTitle = styled.p`
    ${typography.title.M};
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
`;
