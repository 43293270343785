import styled, { css } from 'styled-components';
import { baseColors, typography } from '../../constants/main';

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 24px;
    justify-content: space-between;
    gap: 10px;
    > span {
        ${typography.title.L};
    }
`;

export const StyledWizardTabsWrapper = styled.div`
    padding: 8px 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    box-sizing: border-box;
`;

const modalContent = css`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 16px 56px 16px;
    overflow: auto;
    max-height: calc(100vh - 245px);
    box-sizing: border-box;
    position: relative;
    box-sizing: border-box;
`;

export const StyledForm = styled.form`
    ${modalContent}
`;

export const StyledModalContent = styled.div`
    ${modalContent}
    row-gap: 32px;
    padding: 44px 56px 44px;
    height: calc(100vh - 360px);
`;

export const StyledModalTitle = styled.span`
    ${typography.text.L};
`;

export const StyledLoaderBckg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${baseColors.card(0.96)};
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

export const StyledFooter = styled.div`
    padding: 24px 0;
    position: sticky;
    bottom: 0;
    background: ${baseColors.card()};
`;

export const StyledCheckStatus = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px 0 24px 0;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`;

// CreateLab

export const StyledPreparedLaunch = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    background: ${baseColors.card()};
    border: 1px solid rgba(255, 255, 255, 0.06);
    border-radius: 8px;
`;

export const StyledPreparedTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
`;

export const StyledPreparedTitle = styled.span`
    ${typography.text.L};
    font-weight: 600;
`;

export const StyledPreparedText = styled.p`
    ${typography.text.M};
`;

export const StyledPreparedLink = styled.span`
    ${typography.text.M};
    color: ${baseColors.primary()};
`;

export const StyledExpandList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
`;

export const StyleConfigText = styled.div`
    ${typography.text.M};
    box-sizing: border-box;
    overflow: auto;
    width: 100%;
    border-radius: 8px;
    background: #1d1f21;
`;

const setUpText = css`
    ${typography.text.M};
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyleSetUpText = styled.p`
    ${setUpText};
`;

//

export const StyleSetUpTextItem = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
`;

export const StyleSetUpTextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyleSetUpTextTitle = styled.p`
    ${typography.text.M};
`;

export const StyleSetUpTextNumber = styled.span`
    ${typography.text.M};
`;

export const StyleSetUpTextSnippet = styled.p`
    ${typography.text.M};
    border-radius: 8px;
    padding: 8px 12px;
    background: #1e2326;
    width: 100%;
    color: #a7ff5f;
    box-sizing: border-box;
`;

//

export const StyledNeedToCheckStatus = styled.span`
    ${typography.text.M};
    padding-left: 12px;
`;

//

export const StyledKeyValue = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledKey = styled.p`
    ${typography.text.M}
    width: 96px;
    color: rgba(255, 255, 255, 0.6);
`;

export const StyledValue = styled.p`
    ${typography.text.M}
    width: calc(100% - 100px);
`;

export const StyledLoaderContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${baseColors.card(0.9)};
    box-sizing: border-box;
    z-index: 1;
`;
