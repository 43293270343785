import { createContext } from 'react';
import { IVmOption } from '../types';

interface IProps {
    currentVmOption?: IVmOption;
    setVmOption?: (id: IVmOption | undefined) => void;
    taskOptions: IVmOption[];
    vmOptions: IVmOption[];
}

export const TerminalContext = createContext({
    taskOptions: [],
    vmOptions: [],
} as IProps);
