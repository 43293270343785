import { ITask, ILab } from '@/types';
import { Button, PopUp } from '@/components';
import { SHAPE } from '@/components/Button/types';
import { useState } from 'react';
import { Content } from './components';

interface IProps {
    task: ITask;
    uuid?: ILab['uuid'];
}

export const Hint = ({ task, uuid }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    return (
        <>
            <PopUp
                opened={isOpened}
                header={`Hints of task "${task.name}"`}
                onClose={() => setIsOpened(false)}
            >
                <Content taskId={task.id} uuid={uuid} />
            </PopUp>
            <Button
                view={SHAPE.GHOST}
                type="button"
                onClick={() => {
                    setIsOpened(true);
                }}
            >
                Hint
            </Button>
        </>
    );
};
