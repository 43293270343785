import styled from 'styled-components';

export const StyledPage = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    overflow-y: auto;
    height: calc(100vh - 57px);
`;
