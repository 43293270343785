import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';
import { BannerConditionType } from './types';

export const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    @media (max-width: 768px) {
        gap: 16px;
    }
`;

export const StyledSlider = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;
`;

export const StyledSlideImage = styled.img`
    width: 100%;
    @media (max-width: 768px) {
        width: calc(100% - 24px);
        position: relative;
        top: 8px;
        left: 12px;
        z-index: 1;
    }
`;

const contentCollapsedStyle = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const contentExpandedStyle = css`
    box-sizing: border-box;
    padding: 40px 96px 0 96px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    gap: 16px;
    @media (max-width: 1280px) {
        padding: 24px 24px 0 40px;
        gap: 8px;
    }
    @media (max-width: 768px) {
        padding: 0 40px;
    }
`;

const generateContentStyle = ({
    $condition,
}: {
    $condition: BannerConditionType;
}) => {
    if ($condition === 'closed') {
        return contentCollapsedStyle;
    }
    return contentExpandedStyle;
};

export const StyledContent = styled.div<{ $condition: BannerConditionType }>`
    ${generateContentStyle};
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
    @media (max-width: 1280px) {
        ${typography.title.M};
    }
    @media (max-width: 768px) {
        ${typography.title.L};
    }
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

const generateCondition = ({
    $condition,
}: {
    $condition: BannerConditionType;
}) => {
    if ($condition === 'closed') {
        return css`
            writing-mode: vertical-rl;
        `;
    }
    return css`
        writing-mode: horizontal-tb;
    `;
};

export const StyledExpander = styled.span<{
    $condition: BannerConditionType;
}>`
    ${typography.text.S};
    ${generateCondition};
    opacity: 0.5;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
`;

export const StyledTabMini = styled.div<{ $active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    opacity: ${({ $active }) => ($active ? 1 : 0.5)};
    &::before {
        content: '';
        border-radius: 20px;
        display: block;
        width: 6px;
        height: 6px;
        background: ${({ $active }) =>
            $active ? baseColors.error() : baseColors.white()};
    }
    &:hover {
        opacity: 1;
    }
`;

export const StyledFooterContent = styled.div`
    order: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    > p {
        max-width: 440px;
    }
`;
