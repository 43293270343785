import { AddIcon, MinusIcon, Button } from '@/components';
import { SHAPE } from '@/components/Button/types';
import { nanoid } from '@reduxjs/toolkit';
import { examplePrice, exampleTitles } from './constants';
import {
    StyledPricesTable,
    StyledRow,
    StyledHeaderCell,
    StyledTitle,
    StyledPrice,
    StyledAmount,
    StyledUnit,
    StyledTitleCell,
    StyledValueCell,
    StyledFooterCell,
} from './styled';
import { useAppSelector } from '@/hooks/redux';
// import { WriteToUs } from '@/pages/AuthPages/components/WriteToUs/WriteToUs';
import { useState } from 'react';
import { SideBarContext } from '@/context/sideBarContext';

export const PriceTable = () => {
    const profileSlice = useAppSelector((state) => state.ProfileSlice);
    const [isOpened, setIsOpened] = useState(false);
    return (
        <SideBarContext.Provider value={{ isOpened, setIsOpened }}>
            <StyledPricesTable>
                <StyledRow>
                    <div></div>
                    <StyledHeaderCell>
                        <StyledTitle>Basic</StyledTitle>
                        <StyledPrice>
                            <StyledUnit $direction="left">€</StyledUnit>
                            <StyledAmount>0</StyledAmount>
                            <StyledUnit $direction="left">/mo</StyledUnit>
                        </StyledPrice>
                    </StyledHeaderCell>
                    <StyledHeaderCell>
                        <StyledTitle>Supporter</StyledTitle>
                        <StyledPrice>
                            <StyledUnit $direction="left">€</StyledUnit>
                            <StyledAmount>20</StyledAmount>
                            <StyledUnit $direction="left">/mo</StyledUnit>
                        </StyledPrice>
                    </StyledHeaderCell>
                    <StyledHeaderCell>
                        <StyledTitle>Enterprise</StyledTitle>
                        <StyledPrice>On demand</StyledPrice>
                    </StyledHeaderCell>
                </StyledRow>
                {renderValues()}
                <StyledRow>
                    <div></div>
                    <StyledFooterCell>
                        <Button disabled={true} view={SHAPE.GHOST}>
                            Purchased
                        </Button>
                    </StyledFooterCell>
                    <StyledFooterCell>
                        {profileSlice.profile?.is_premium ? (
                            <Button disabled={true} view={SHAPE.GHOST}>
                                Purchased
                            </Button>
                        ) : (
                            <Button
                                onClick={() =>
                                    window.open(
                                        process.env.REACT_APP_PAYMENT_URL,
                                        '_blank'
                                    )
                                }
                                view={SHAPE.GHOST}
                            >
                                Buy
                            </Button>
                        )}
                    </StyledFooterCell>
                    <StyledFooterCell>
                        <Button
                            view={SHAPE.GHOST}
                            onClick={() => setIsOpened && setIsOpened(true)}
                        >
                            Ask for quote
                        </Button>
                    </StyledFooterCell>
                </StyledRow>
                {/* <SideBar title="Ask for quote">
                    <WriteToUs />
                </SideBar> */}
            </StyledPricesTable>
        </SideBarContext.Provider>
    );
};

function renderValues() {
    return getValues().map((val) => {
        return (
            <StyledRow key={nanoid()}>
                {val.map((v) => {
                    if (typeof v === 'boolean')
                        return (
                            <StyledValueCell key={nanoid()}>
                                {v === true ? (
                                    <AddIcon size={20} />
                                ) : (
                                    <MinusIcon size={20} />
                                )}
                            </StyledValueCell>
                        );
                    if (typeof v === 'string')
                        return (
                            <StyledTitleCell
                                key={nanoid()}
                                style={{
                                    background: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                }}
                            >
                                <p>{exampleTitles[v]}</p>
                            </StyledTitleCell>
                        );
                    return null;
                })}
            </StyledRow>
        );
    });
}

function getValues() {
    let titles = structuredClone(examplePrice)[0];
    delete titles.amount;
    delete titles.id;
    delete titles.name;
    return Object.keys(titles).map((t) => {
        const values = examplePrice.map((p) => p[t]);
        return [t, ...values];
    });
}
