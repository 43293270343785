import { FC } from "react";
import { IconProps } from "./types";

export const Icon: FC<IconProps> = ({
  content,
  onClick,
  cursor,
  size,
}) => {
  return (
    <svg
      cursor={cursor || 'inherit'}
      onClick={onClick}
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {content}
    </svg>
  );
};
