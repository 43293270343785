import styled, { css } from "styled-components";
import { typography, baseColors } from "../../../constants/main";

// TabItem

const generateBorderRadius = (variant: "first" | "center" | "last") => {
  switch (variant) {
    case "first":
      return css`
        border-radius: 8px 0 0 8px;
      `;
    case "last":
      return css`
        border-radius: 0 8px 8px 0;
      `;
    default:
      return null;
  }
};

export const StyledTabItem = styled.div<{
  variant: "first" | "center" | "last";
  index: number;
  disabled?: boolean;
}>`
  ${typography.text.M};
  position: relative;
  left: ${({ index }) => `-${index * 10}px`};
  display: flex;
  height: 32px;
  width: fit-content;
  opacity: 1;
  color: ${({ disabled }) =>
    disabled ? "rgba(255, 255, 255, 0.25)" : "rgba(255, 255, 255, 1)"};
  cursor: pointer;

  font-weight: 500;
  ${({ variant }) => generateBorderRadius(variant)};
  overflow: hidden;
  :hover {
    opacity: ${({ disabled }) => (disabled ? "1" : "0.7")};
    color: ${({ disabled }) =>
      disabled ? "rgba(255, 255, 255, 0.25)" : "rgba(255, 255, 255, 0.7)"};
  }
`;

const generatePadding = (variant: "first" | "center" | "last") => {
  switch (variant) {
    case "first":
      return css`
        padding: 6px 8px 6px 12px;
      `;
    case "last":
      return css`
        padding: 6px 12px 6px 8px;
      `;
    default:
      return css`
        padding: 6px 8px;
      `;
  }
};

const generateBeckground = (isActive: boolean, disabled?: boolean) => {
  if (disabled) {
    return css`
      background-color: rgba(255, 255, 255, 0.04);
    `;
  }

  return css`
    background-color: ${isActive
      ? baseColors.primary()
      : "rgba(255, 255, 255, 0.08)"};
  `;
};

export const StyledTabItemBody = styled.div<{
  isActive: boolean;
  variant: "first" | "center" | "last";
  disabled?: boolean;
}>`
  ${({ isActive, disabled }) => generateBeckground(isActive, disabled)};
  ${({ variant }) => generatePadding(variant)};
  display: flex;
  align-items: center;
  gap: 4px;
`;
