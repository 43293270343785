import styled from 'styled-components';
import { scrollStyles } from '@/constants';

export const StyledSidebar = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    padding: 8px;
    min-width: 320px;
    overflow-y: scroll;
    padding-right: 2px;
    margin-right: 2px;
    ${scrollStyles};
`;
