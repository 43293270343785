import { baseColors } from "../../../../../constants/main";
import { IScaleItem } from "../../../../Slider/types";

export const difficultyItems: IScaleItem[] = [
  {
    id: 0,
    value: 0,
    color: baseColors.ok(),
  },
  {
    id: 1,
    value: 1,
    color: baseColors.ok(),
  },
  {
    id: 2,
    value: 2,
    color: baseColors.ok(),
  },
  {
    id: 3,
    value: 3,
    color: baseColors.ok(),
  },
  {
    id: 4,
    value: 4,
    color: baseColors.critical(),
  },
  {
    id: 5,
    value: 5,
    color: baseColors.critical(),
  },
  {
    id: 6,
    value: 6,
    color: baseColors.critical(),
  },
  {
    id: 7,
    value: 7,
    color: baseColors.error(),
  },
  {
    id: 8,
    value: 8,
    color: baseColors.error(),
  },
  {
    id: 9,
    value: 9,
    color: baseColors.error(),
  },
  {
    id: 10,
    value: 10,
    color: baseColors.error(),
  },
];
