import { StyledPagination, StyledPageBtn, StyledLabel } from './styled';
import AarrowUp from './assets/ArrowUp.svg';
import ArrowDown from './assets/ArrowDown.svg';

interface IPage {
    name: string;
    link: string;
}

interface IProps {
    prev?: IPage;
    next?: IPage;
}

export const VerticalPagination = ({ prev, next }: IProps) => {
    return (
        <StyledPagination>
            {prev ? (
                <StyledPageBtn href={prev.link}>
                    <img src={AarrowUp} alt="" />
                    <StyledLabel>{prev.name}</StyledLabel>
                </StyledPageBtn>
            ) : null}
            {next ? (
                <StyledPageBtn href={next.link}>
                    <StyledLabel>{next.name}</StyledLabel>
                    <img src={ArrowDown} alt="" />
                </StyledPageBtn>
            ) : null}
        </StyledPagination>
    );
};
