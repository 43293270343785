import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const VMIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.4855 1.14251C7.80219 0.952498 8.19781 0.952498 8.5145 1.14251L13.5145 4.14251C13.8157 4.32323 14 4.64874 14 5V11C14 11.3513 13.8157 11.6768 13.5145 11.8575L8.5145 14.8575C8.19781 15.0475 7.80219 15.0475 7.4855 14.8575L2.4855 11.8575C2.1843 11.6768 2 11.3513 2 11V5C2 4.64874 2.1843 4.32323 2.4855 4.14251L7.4855 1.14251ZM4 10.4338V6.9765L7 8.5965V12.2338L4 10.4338ZM9 12.2338L12 10.4338V6.9765L9 8.5965V12.2338ZM8 3.16619L11.2433 5.11216L8 6.86353L4.75672 5.11216L8 3.16619Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5144 1.12584C11.8164 0.958052 12.1836 0.958052 12.4856 1.12584L21.4856 6.12584C21.8031 6.30221 22 6.63683 22 7V17C22 17.3632 21.8031 17.6978 21.4856 17.8742L12.4856 22.8742C12.1836 23.0419 11.8164 23.0419 11.5144 22.8742L2.51436 17.8742C2.19689 17.6978 2 17.3632 2 17V7C2 6.63683 2.19689 6.30221 2.51436 6.12584L11.5144 1.12584ZM12 3.14396L4.66924 7.2166L12 10.882L19.3307 7.21659L12 3.14396ZM4 16.4116V9.11805L11 12.6181V20.3005L4 16.4116ZM13 20.3005L20 16.4116V9.11803L13 12.618V20.3005Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
