import { apiClient } from '@/api/apiClient';
import { Alert, Button, Input } from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { useAppSelector } from '@/hooks/redux';
import { profileSlice } from '@/store/reducers/ProfileSlice';
import { FormEventHandler, useState } from 'react';

export const ChangeUsernameForm = () => {
    const { profile } = useAppSelector((state) => state.ProfileSlice);
    const [username, setUsername] = useState(profile?.user);
    const [usernameErr, setUsernameErr] = useState('');

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        setUsernameErr('');
        if (!username) {
            setUsernameErr('Username is required');
        }
        if (usernameErr) return;
        try {
            await apiClient.post('api/v1/userinfo/change_username/', {
                username: username,
            });
            profileSlice.actions.changeUsername(username!);
        } catch (error: any) {
            if (error.response && error.response.data) {
                setUsernameErr(error.response.data.status);
            }
        }
    };
    return (
        <form onSubmit={onSubmit}>
            <Input
                value={username}
                onChange={setUsername}
                placeholder="Username"
            ></Input>
            <Button type="submit">Save</Button>

            {usernameErr && (
                <Alert variant={VARIANT.ERROR} message={usernameErr} />
            )}
        </form>
    );
};
