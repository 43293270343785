import { ReactNode } from 'react';
import { LabContext } from './labContext';
import { LAB_TYPE } from '@/constants';

interface IProps {
    children: ReactNode;
    type: LAB_TYPE;
}

export const LabContextModule = ({ children, type }: IProps) => {
    return (
        <LabContext.Provider value={{ type }}>{children}</LabContext.Provider>
    );
};
