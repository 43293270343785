import { ReactNode, useMemo, useState } from 'react';
import { useAppSelector } from '@/hooks/redux';
import { STATUS_LAB, HIGHLIGHT_TYPE, TASK_TYPE } from '@/constants';
import { ISchemeVm } from '@/types';
import { FlagIcon, TerminalIcon } from '@/components';
import { SideBar, SideBarContext } from '@/components/SideBar';
import { ISideCell } from '@/components/SideBar/types';
import { Terminal, AddFlag } from './components';
import { TerminalContext } from './context/terminalContext';
import { FlagsContext } from './context/flagsContext';
import { IVmOption } from './types';

export const Sidebar = ({ children }: { children: ReactNode }) => {
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    const { tasks } = useAppSelector((state) => state.TaskSlice);
    const { scheme } = useAppSelector((state) => state.SchemeSlice);
    const [currentVmOption, setCurrentVmOption] = useState<
        IVmOption | undefined
    >(undefined);
    const [currentTypeFlag, setCurrentTypeFlag] = useState<
        HIGHLIGHT_TYPE | undefined
    >(undefined);

    const vmOptions: IVmOption[] = useMemo(() => {
        if (scheme) {
            const vms: IVmOption[] = scheme.groups
                .reduce((acc, curr) => {
                    acc = [
                        ...acc,
                        ...curr.vms.filter(
                            (vm) => vm.uuid !== null && vm.id !== null
                        ),
                    ];
                    return acc;
                }, [] as ISchemeVm[])
                .map((filtredVm) => ({
                    id: `${filtredVm.id}_vm`,
                    title: filtredVm.name,
                    description: filtredVm.icon as string,
                    vmId: filtredVm.uuid as string,
                }));
            return vms;
        }
        return [];
    }, [scheme]);

    const taskOptions: IVmOption[] = useMemo(() => {
        if (tasks) {
            return tasks
                .filter((t) => t.type === TASK_TYPE.TASK)
                .map(({ name, target_machine, text, id, is_flag }) => ({
                    title: name,
                    id: `${id}_task`,
                    description: text,
                    vmId: target_machine,
                    is_flag,
                }));
        }
        return [];
    }, [tasks]);

    const [openedCellId, setopenedCellId] = useState<
        ISideCell['id'] | undefined
    >(undefined);

    const cells: ISideCell[] = useMemo(() => {
        if (lab?.status === STATUS_LAB.OPERATIONAL) {
            return [
                {
                    id: `heigh_light`,
                    title: 'Add flags',
                    children: <AddFlag />,
                    icon: <FlagIcon />,
                },
                {
                    id: `terminal`,
                    title: 'SSH terminal',
                    width: 'calc(100vw - 240px)',
                    children: <Terminal />,
                    icon: <TerminalIcon />,
                },
            ];
        }
        return [
            {
                id: `heigh_light`,
                title: 'Add flags',
                children: <AddFlag />,
                icon: <FlagIcon />,
            },
        ];
    }, [lab]);

    const handleSetOpenCellId = (opt: IVmOption | undefined) => {
        setCurrentVmOption(opt);
    };

    const handleSetCurrentTypeFlag = (type?: HIGHLIGHT_TYPE) => {
        setCurrentTypeFlag(type);
    };

    return (
        <SideBarContext.Provider
            value={{
                openedCellId,
                cells,
                setOpenCellId(cellId) {
                    setopenedCellId(cellId);
                    if (typeof cellId === 'undefined') {
                        setCurrentTypeFlag(undefined);
                        setCurrentVmOption(undefined);
                    }
                },
            }}
        >
            <TerminalContext.Provider
                value={{
                    taskOptions,
                    vmOptions,
                    currentVmOption,
                    setVmOption: handleSetOpenCellId,
                }}
            >
                <FlagsContext.Provider
                    value={{
                        uuid: lab?.uuid,
                        currentType: currentTypeFlag,
                        setCurrentType: handleSetCurrentTypeFlag,
                    }}
                >
                    {children}
                    <SideBar />
                </FlagsContext.Provider>
            </TerminalContext.Provider>
        </SideBarContext.Provider>
    );
};
