import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
import {
    StyledWhom,
    StyledTitle,
    StyledColumnsContainer,
    StyledColumn,
    StyledSubtitleLarge,
    StyledTitleMiddle,
    StyledBody,
    ButtonContainer,
} from './styled';
import whom_img from '../../assets/SVGTitles/secondPagesTtotles/whom.svg';

export const Whom = forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const navigate = useNavigate();
    return (
        <StyledWhom ref={ref} id="whom">
            <StyledTitle src={whom_img} alt="whom" />
            <StyledColumnsContainer>
                <StyledColumn>
                    <StyledSubtitleLarge>
                        Defbox is a platform that helps test and improve
                        practical cybersecurity skills
                    </StyledSubtitleLarge>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>SOC managers</StyledTitleMiddle>
                    <StyledBody>
                        To develop and test correlation rules, analysts have to
                        deploy a test infrastructure. Defbox saves analysts
                        time: a test infrastructure in Defbox is deployed and
                        hacked with one click – no need to deploy and maintain
                        test stands.
                    </StyledBody>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>Professors</StyledTitleMiddle>
                    <StyledBody>
                        Teach your students with real cyber attacks on real
                        infrastructure. Students will get strong practical
                        skills as they use real-world tools to defend a live
                        system.
                    </StyledBody>
                </StyledColumn>
                <StyledColumn>
                    <StyledTitleMiddle>To any users</StyledTitleMiddle>
                    <StyledBody>
                        Look how cyber attacks look in real time. Become a cyber
                        defender, protecting assets from cyber threats.
                    </StyledBody>
                </StyledColumn>
            </StyledColumnsContainer>
            <ButtonContainer>
                <Button
                    onClick={() => navigate('/auth/write_to_us')}
                    variant="secondary"
                >
                    Get in touch
                </Button>
                <span>
                    Do you represent a company or university? Leave us your
                    email and we will get back to you
                    <br />
                    with our options for SOCs and universities.
                </span>
            </ButtonContainer>
        </StyledWhom>
    );
});
