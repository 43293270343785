import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const LabsIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.09091 3H6C5.44772 3 5 2.55228 5 2C5 1.44772 5.44772 1 6 1H10C10.5523 1 11 1.44772 11 2C11 2.55228 10.5523 3 10 3L10 6.44702L14.7784 12.3722C15.0992 12.7701 15.0685 13.3457 14.7071 13.7071L13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15H3.00001C2.73479 15 2.48044 14.8946 2.2929 14.7071L1.2929 13.7071C0.929147 13.3434 0.90071 12.763 1.22716 12.3654L6.09091 6.44205V3ZM7.86376 7.43459C7.94601 7.33442 8.00738 7.22001 8.04546 7.09807C8.08267 7.21721 8.14212 7.3292 8.22159 7.42775L12.6575 12.9283L12.5858 13H3.41422L3.34816 12.9339L7.86376 7.43459Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 6.58152V3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H8C7.44772 1 7 1.44772 7 2C7 2.55229 7.44772 3 8 3H9V6.58152C6.06817 7.76829 4 10.6426 4 14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14C20 10.6426 17.9318 7.76829 15 6.58152ZM11 7.92958L9.75043 8.43539C7.8462 9.20621 6.43132 10.9237 6.08297 13H17.917C17.5687 10.9237 16.1538 9.20621 14.2496 8.43539L13 7.92958V3H11V7.92958ZM17.917 15H6.08296C6.55904 17.8377 9.027 20 12 20C14.973 20 17.441 17.8377 17.917 15Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
