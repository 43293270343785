export enum VM_ICON {
    BALANSER = 'Balancer',
    WEB_SERVER = 'Web-Server',
    DATABASE = 'Database',
    GENERIC_SERVER = 'Generic-Server',
}

export enum INFO_ITEM_TYPE {
    URL = 'URL',
    STRING = 'STRING',
    PASSWORD = 'PASSWORD',
}
