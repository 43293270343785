import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { ILab, IStatsOfTheLab, IError, ILabTeamplate } from '@/types';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { RootState, AppDispatch } from '@/store/store';
import {
    ILaunchCardList,
    IDeployElasticAndCreateLaunchArgs,
    ICreateLaunchArgs,
} from './types/cards';
import { labsSlice } from './LabsSlice';
import { modalSlice } from './ModalSlice';
import { apiClient } from '@/api/apiClient';
import { NavigateFunction } from 'react-router-dom';
import { UuidWithBasePath } from './types/general';

let store: ToolkitStore<RootState>;

interface IGotoFunction {
    fn: NavigateFunction;
    name: string;
    labId: number;
}

export const injectStore = (_store: ToolkitStore<RootState>) => {
    store = _store;
};

export const getLaunchCardById = createAsyncThunk<
    ILab,
    String,
    { rejectValue: IError }
>(
    'launchCardList/getLaunchCardById',
    async function (uuid, { rejectWithValue }) {
        try {
            const response = await apiClient.get(`/api/v1/labs/${uuid}`, {
                requestName: 'getLaunchCardById',
            });
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const getWorkshopLaunchById = createAsyncThunk<
    ILab,
    string,
    { rejectValue: IError }
>('labsSlice/getWorkshopLabById', async (id, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/workshoplabs/${id}/`, {
            requestName: 'workshopLabById',
        });
        return response.data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const checkWorkshopLaunchById = createAsyncThunk<
    ILab,
    string,
    { rejectValue: IError }
>('labsSlice/checkWorkshopLaunch', async (id, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/workshoplabs/${id}/`, {
            requestName: 'workshopLabById',
        });
        return response.data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getLaunchCards = createAsyncThunk<
    ILab[],
    string | IGotoFunction,
    { rejectValue: IError }
>(
    'launchCardList/getLaunchCards',
    async function (args, { rejectWithValue, dispatch }) {
        console.trace('getLaunchCards', args);
        try {
            const response = await apiClient.get('/api/v1/labs/', {
                params: {
                    lab_template__name__icontains:
                        typeof args === 'string' ? args : args.name,
                },
                requestName: 'getLaunchCards',
            });
            const data = await response.data;
            if (data && data.length) {
                await dispatch(getLaunchCardById(data[0].uuid));
            }
            if (
                typeof args !== 'string' &&
                response.status >= 200 &&
                data &&
                data.length
            ) {
                const { fn, labId } = args;
                fn(`/labs/${labId}/launches/${data[0].uuid}/info`);
            }
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const getStats = createAsyncThunk<
    IStatsOfTheLab,
    UuidWithBasePath,
    { rejectValue: IError }
>(
    'launchCardList/getStats',
    async ({ uuid, basePath }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `api/v1/${basePath}/${uuid}/get_stats/`,
                {
                    requestName: 'getStats',
                }
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const createLaunch = createAsyncThunk<
    ILab[],
    ICreateLaunchArgs,
    { rejectValue: IError }
>(
    'launchCardList/createLaunch',
    async function (args, { rejectWithValue, dispatch }) {
        const { lab_id, difficulty, host, user, pass } = args;
        try {
            await apiClient.post('/api/v1/profiles/', {
                elastic_host: host,
                elastic_user: user,
                elastic_password: pass,
            });

            await apiClient.post(
                `/api/v1/labtemplates/${lab_id}/run_lab_without_siem/`,
                {
                    difficulty: String(difficulty),
                }
            );

            const response = await apiClient.get('/api/v1/labs/');
            const data = await response.data;
            dispatch(modalSlice.actions.close());
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const deployElasticAndCreateLaunch = createAsyncThunk<
    ILab[],
    IDeployElasticAndCreateLaunchArgs,
    { rejectValue: IError }
>(
    'launchCardList/deployElasticAndCreateLaunch',
    async function (args, { rejectWithValue, dispatch }) {
        const { labId, difficulty } = args;
        try {
            await apiClient.post(
                `/api/v1/labtemplates/${String(labId)}/run_lab_with_siem/`,
                {
                    difficulty: String(difficulty),
                },
                {
                    requestName: 'deployElasticAndCreateLaunch',
                }
            );
            const response = await apiClient.get('/api/v1/labs/');
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const runLab = createAsyncThunk<
    void,
    {
        labId: string;
        name: string;
        difficulty: number;
        goToFirstCard?: NavigateFunction;
    },
    { rejectValue: IError }
>(
    'launchCardList/runLab',
    async function (
        { name, labId, difficulty, goToFirstCard },
        { rejectWithValue, dispatch }
    ) {
        try {
            await apiClient.post(
                `/api/v1/labtemplates/${labId}/run_lab_with_siem/`,
                {
                    difficulty: String(difficulty),
                }
            );
            if (!!goToFirstCard) {
                await dispatch(
                    getLaunchCards({
                        labId: Number(labId),
                        fn: goToFirstCard,
                        name: name,
                    })
                );
            } else {
                await dispatch(getLaunchCards(name));
            }
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const runWorkshopLab = createAsyncThunk<
    void,
    string,
    { rejectValue: IError }
>(
    'launchCardList/runLab',
    async function (uuid, { rejectWithValue, dispatch }) {
        try {
            await apiClient.post(
                `/api/v1/workshoplabs/${uuid}/recreate_without_new_lab/`,
                {}
            );
            await dispatch(getWorkshopLaunchById(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const recreateLab = createAsyncThunk<
    void,
    { lab: ILabTeamplate; goToFirstCard?: NavigateFunction },
    { rejectValue: IError }
>('launchCardList/recreateLab', async (args, { rejectWithValue, dispatch }) => {
    try {
        await apiClient.post(
            `/api/v1/labtemplates/${args.lab.id}/recreate/`,
            args.lab
        );
        if (!!args.goToFirstCard) {
            await dispatch(
                getLaunchCards({
                    labId: Number(args.lab.id),
                    name: args.lab.name,
                    fn: args.goToFirstCard,
                })
            );
        } else {
            await dispatch(getLaunchCards(args.lab.name));
        }
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.error,
            });
        }
    }
});

export const destroyLab = createAsyncThunk<
    void,
    { name: string; uuid: string },
    { rejectValue: IError }
>(
    'launchCardList/destroyLab',
    async function ({ name, uuid }, { rejectWithValue, dispatch }) {
        try {
            await apiClient
                .get(
                    `/api/v1/labs/${uuid}/destroy_lab/`,

                    {
                        requestName: 'destroyLab',
                    }
                )
                .then(() => {
                    dispatch(getLaunchCardById(uuid));
                    dispatch(getLaunchCards(name));
                });
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const destroyWorkshopLab = createAsyncThunk<
    void,
    string,
    { rejectValue: IError }
>(
    'launchCardList/destroyLab',
    async function (uuid, { rejectWithValue, dispatch }) {
        try {
            await apiClient
                .get(
                    `/api/v1/workshoplabs/${uuid}/destroy_lab/`,

                    {
                        requestName: 'destroyLab',
                    }
                )
                .then(() => {
                    dispatch(getWorkshopLaunchById(uuid));
                });
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const checkStatus = createAsyncThunk<
    ILab[],
    string,
    { rejectValue: IError }
>('launchCardList/checkStatus', async function (name, { rejectWithValue }) {
    try {
        const response = await apiClient.get('/api/v1/labs/', {
            params: { lab_template__name__icontains: name },
            requestName: 'checkStatus',
        });
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

const initialState: ILaunchCardList = {
    cards: [],
    errorCards: {
        code: undefined,
        message: undefined,
    },
    isLoadingCards: true,
    currentCard: undefined,
    errorCurrentCard: {
        code: undefined,
        message: undefined,
    },
    isLoadingCurrentCard: false,
    errorRunLab: { code: undefined, message: undefined },
    isLoadingRunLab: false,
    errorCheckStatus: {
        code: undefined,
        message: undefined,
    },
    isLoadingCheckStatus: false,
    errorDestroyLab: {
        code: undefined,
        message: undefined,
    },
    isLoadingDestroyLab: false,
    stats: undefined,
    errorStats: {
        code: undefined,
        message: undefined,
    },
    isLoadingStats: false,
    isLoadingRecreateLab: false,
    errorRecreateLab: {
        code: undefined,
        message: undefined,
    },

    error: null,
    loader: false,
    cardsError: {
        code: undefined,
        message: undefined,
    },
    loaderDeployElastic: false,
    loaderCreateLaunch: false,
    errorDeployElastic: {
        code: undefined,
        message: undefined,
    },
    errorCreateLaunch: {
        code: undefined,
        message: undefined,
    },
};

export const launchCardListSlice = createSlice({
    name: 'launchCardList',
    initialState,
    reducers: {
        resetData(state) {
            return initialState;
        },
        resetCreateLaunch(state) {
            state.errorCreateLaunch.code = undefined;
            state.errorCreateLaunch.message = undefined;
            state.loaderCreateLaunch = false;
        },
        resetDeployElastic(state) {
            state.errorDeployElastic.code = undefined;
            state.errorDeployElastic.message = undefined;
            state.loaderDeployElastic = false;
        },
        reset(state) {
            state.loader = false;
            state.error = null;
        },
        loaderFetching(state) {
            state.loader = true;
            state.error = null;
        },
        errorFetching(state, action: PayloadAction<number | null>) {
            state.error = action.payload;
            state.loader = false;
        },
        cardFetching(state, action: PayloadAction<ILab[]>) {
            state.error = null;
            state.loader = false;
            state.cards = action.payload;
        },
        getLaunchCardsFetching(state) {
            state.loader = true;
            state.errorCards = {
                code: undefined,
                message: undefined,
            };
        },
        getLaunchCardsSaccess(state, action) {
            state.loader = false;
            state.cards = action.payload;
            state.errorCards = {
                code: undefined,
                message: undefined,
            };
        },
        getLaunchCardsFailure(state, action) {
            state.cardsError = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
        getCurrentLaunchFetching(state) {
            state.isLoadingCurrentCard = true;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        },
        getCurrentLaunchSaccess(state, action) {
            state.currentCard = action.payload;
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        },
        getCurrentLaunchFailure(state, action) {
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
        runLabFetching(state) {
            state.isLoadingRunLab = true;
            state.errorRunLab = {
                code: undefined,
                message: undefined,
            };
        },
        runLabSaccess(state) {
            const labName = labsSlice.getInitialState().lab?.name;
            const dispatch = store.dispatch as AppDispatch;

            if (labName) {
                dispatch(getLaunchCards(labName));
            }
            state.isLoadingRunLab = false;
            state.errorRunLab = {
                code: undefined,
                message: undefined,
            };
        },
        runLabFailure(state, action) {
            state.isLoadingRunLab = false;
            state.errorRunLab = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
        checkStatusFetching(state) {
            state.isLoadingCheckStatus = true;
            state.errorCheckStatus = {
                code: undefined,
                message: undefined,
            };
        },
        checkStatusSaccess(state, action) {
            state.isLoadingCheckStatus = false;
            if (
                action.payload[0].status !== state.cards[0].status ||
                action.payload[0].story_status !== state.cards[0].story_status
            ) {
                state.cards = action.payload;
                state.currentCard = action.payload[0];
            }
        },
        checkStatusFailure(state, action) {
            state.isLoadingCheckStatus = false;
            state.errorCheckStatus = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
        destroyLabFetching(state) {
            state.isLoadingDestroyLab = true;
            state.errorDestroyLab = {
                code: undefined,
                message: undefined,
            };
        },
        destroyLabSaccess(state) {
            const labName = labsSlice.getInitialState().lab?.name;
            const dispatch = store.dispatch as AppDispatch;
            if (labName) {
                dispatch(getLaunchCards(labName));
            }
            state.isLoadingDestroyLab = false;
            state.errorDestroyLab = {
                code: undefined,
                message: undefined,
            };
        },
        destroyLabFailure(state, action) {
            state.isLoadingDestroyLab = false;
            state.errorDestroyLab = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
        getStatsFetching(state) {
            state.isLoadingStats = true;
            state.errorStats = {
                code: undefined,
                message: undefined,
            };
        },
        getStatsSaccess(state, action) {
            state.stats = action.payload;
            state.isLoadingStats = false;
            state.errorStats = {
                code: undefined,
                message: undefined,
            };
        },
        getStatsFailure(state, action) {
            state.isLoadingStats = false;
            state.errorStats = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(createLaunch.pending, (state) => {
            state.loaderCreateLaunch = true;
            state.errorCreateLaunch.code = undefined;
            state.errorCreateLaunch.message = undefined;
        });
        builder.addCase(createLaunch.fulfilled, (state, action) => {
            state.cards = action.payload;
            state.errorCreateLaunch.code = undefined;
            state.errorCreateLaunch.message = undefined;
            state.loaderCreateLaunch = false;
        });
        builder.addCase(createLaunch.rejected, (state, action) => {
            state.errorCreateLaunch.code = action.payload?.code;
            state.errorCreateLaunch.message = action.payload?.message;
            state.loaderCreateLaunch = false;
        });
        builder.addCase(deployElasticAndCreateLaunch.pending, (state) => {
            state.loaderDeployElastic = true;
            state.errorDeployElastic.code = undefined;
            state.errorDeployElastic.message = undefined;
        });
        builder.addCase(
            deployElasticAndCreateLaunch.fulfilled,
            (state, action) => {
                state.loaderDeployElastic = false;
                state.cards = action.payload;
            }
        );
        builder.addCase(
            deployElasticAndCreateLaunch.rejected,
            (state, action) => {
                state.loaderDeployElastic = false;
                state.errorDeployElastic.code = action.payload?.code;
                state.errorDeployElastic.message = action.payload?.message;
            }
        );
        builder.addCase(getLaunchCards.pending, (state) => {
            state.loader = true;
            state.isLoadingCards = true;
            state.cards = [];
            state.cardsError.code = undefined;
            state.cardsError.message = undefined;
        });
        builder.addCase(getLaunchCards.fulfilled, (state, action) => {
            state.loader = false;
            state.isLoadingCards = false;
            state.cards = action.payload;
        });
        builder.addCase(getLaunchCards.rejected, (state, action) => {
            state.loader = false;
            state.isLoadingCards = false;
            state.cardsError.code = action.payload?.code;
            state.cardsError.message = action.payload?.message;
        });
        builder.addCase(getLaunchCardById.pending, (state) => {
            state.currentCard = undefined;
            state.isLoadingCurrentCard = true;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLaunchCardById.fulfilled, (state, action) => {
            state.currentCard = action.payload;
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLaunchCardById.rejected, (state, action) => {
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(getWorkshopLaunchById.pending, (state) => {
            state.currentCard = undefined;
            state.isLoadingCurrentCard = true;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopLaunchById.fulfilled, (state, action) => {
            state.currentCard = action.payload;
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopLaunchById.rejected, (state, action) => {
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(checkWorkshopLaunchById.fulfilled, (state, action) => {
            state.currentCard = action.payload;
            state.isLoadingCurrentCard = false;
            state.errorCurrentCard = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runLab.pending, (state) => {
            state.isLoadingRunLab = true;
            state.errorRunLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runLab.fulfilled, (state) => {
            state.isLoadingRunLab = false;
            state.errorRunLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runLab.rejected, (state, action) => {
            state.isLoadingRunLab = false;
            state.errorRunLab = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(checkStatus.pending, (state) => {
            state.isLoadingCheckStatus = true;
            state.errorCheckStatus = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(checkStatus.fulfilled, (state, action) => {
            state.isLoadingCheckStatus = false;
            if (
                action.payload[0].status !== state.cards[0].status ||
                action.payload[0].story_status !== state.cards[0].story_status
            ) {
                state.cards = action.payload;
                state.currentCard = action.payload[0];
            }
        });

        builder.addCase(checkStatus.rejected, (state, action) => {
            state.isLoadingCheckStatus = false;
            state.errorCheckStatus = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(destroyLab.pending, (state) => {
            state.isLoadingDestroyLab = true;
            state.errorDestroyLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(destroyLab.fulfilled, (state) => {
            state.isLoadingDestroyLab = false;
            state.errorDestroyLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(destroyLab.rejected, (state, action) => {
            state.isLoadingDestroyLab = false;
            state.errorDestroyLab = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(getStats.pending, (state) => {
            state.stats = undefined;
            state.isLoadingStats = true;
            state.errorStats = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStats.fulfilled, (state, action) => {
            state.stats = action.payload;
            state.isLoadingStats = false;
            state.errorStats = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getStats.rejected, (state, action) => {
            state.isLoadingStats = false;
            state.errorStats = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(recreateLab.pending, (state) => {
            state.isLoadingRecreateLab = true;
            state.errorRecreateLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateLab.fulfilled, (state) => {
            state.isLoadingRecreateLab = false;
            state.errorRecreateLab = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateLab.rejected, (state, action) => {
            state.isLoadingRecreateLab = false;
            state.errorRecreateLab = action.payload as IError;
        });
    },
});

export default launchCardListSlice.reducer;
