import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const TerminalIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M4.29289 5.29289C4.68342 4.90237 5.31658 4.90237 5.70711 5.29289L7.70711 7.29289C8.09763 7.68342 8.09763 8.31658 7.70711 8.70711L5.70711 10.7071C5.31658 11.0976 4.68342 11.0976 4.29289 10.7071C3.90237 10.3166 3.90237 9.68342 4.29289 9.29289L5.58579 8L4.29289 6.70711C3.90237 6.31658 3.90237 5.68342 4.29289 5.29289Z"
                            fill={fill}
                        />
                        <path
                            d="M9 9C8.44772 9 8 9.44771 8 10C8 10.5523 8.44772 11 9 11H11C11.5523 11 12 10.5523 12 10C12 9.44771 11.5523 9 11 9L9 9Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1 2C1 1.44772 1.44772 1 2 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2ZM3 3V13H13V3H3Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L10.7071 11.2929C11.0976 11.6834 11.0976 12.3166 10.7071 12.7071L7.70711 15.7071C7.31658 16.0976 6.68342 16.0976 6.29289 15.7071C5.90237 15.3166 5.90237 14.6834 6.29289 14.2929L8.58579 12L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289Z"
                            fill={fill}
                        />
                        <path
                            d="M13 14C12.4477 14 12 14.4477 12 15C12 15.5523 12.4477 16 13 16H17C17.5523 16 18 15.5523 18 15C18 14.4477 17.5523 14 17 14H13Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V3ZM4 4V20H20V4H4Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
