import styled from 'styled-components';
import { typography, link, baseLinkStyle } from '../../constants/main';

export const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 640px;
    max-width: 1440px;
    @media (max-width: 1280px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledLogo = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    height: 48px;
    cursor: pointer;
    @media (max-width: 1280px) {
        flex: 0 0;
    }
`;

export const StyledLogoText = styled.span`
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 1280px) {
        font-size: 14px;
    }
`;

export const StyledLeftCol = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 72px 0 72px;
    box-sizing: border-box;
    @media (max-width: 1280px) {
        width: 100%;
        max-width: 784px;
    }
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    @media (max-width: 1280px) {
        display: none;
    }
`;

export const StyledTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledTitle = styled.h1`
    font-family: Open Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
`;

export const StyledSubtitle = styled.p`
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const StyledRightCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 640px;
    gap: 48px;
    padding: 0 72px;
    @media (max-width: 1280px) {
        height: auto;
        margin-top: 50px;
        gap: 25px;
    }
`;

export const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const StyledFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledPromoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledPromoTitle = styled.p`
    ${typography.text.L};
    color: rgba(255, 255, 255, 0.8);
`;

export const StyledPromoText = styled.p`
    ${typography.text.M};
    color: rgba(255, 255, 255, 0.64);
`;

export const StyledContainerFormLogoHeader = styled.div`
    cursor: pointer;
    display: flex;
    gap: 16px;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 32px;
    > span {
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #ffffff;
    }
`;

export const StyledPrivacyPolicyContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const StyledPrivacyPolicy = styled.a`
    ${baseLinkStyle};
`;
