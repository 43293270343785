import { Icon } from "../Icon";

interface IconClosedEyeProps {
  figureFill?: string;
  bckgFill?: string;
  size?: number;
}

export const IconClosedEye = (props: IconClosedEyeProps) => {
  const { figureFill, bckgFill, size } = props;
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.845 17.345C20.6225 15.357 22 11.5 22 11.5C22 11.5 19.5 4.5 12 4.5C9.41403 4.5 7.42246 5.33223 5.92285 6.42277L16.845 17.345Z"
            fill="#817F89"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.7368 14.8226C16.5229 13.9393 17 12.7754 17 11.5C17 8.73857 14.7617 6.5 12 6.5C10.7246 6.5 9.56104 6.9775 8.67773 7.76349L15.7368 14.8226Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.29297 4.79289C4.68359 4.40237 5.31641 4.40237 5.70703 4.79289L10.0957 9.18181C10.6138 8.75579 11.2773 8.5 12.0005 8.5C13.6572 8.5 15.0005 9.84315 15.0005 11.5C15.0005 12.2231 14.7446 12.8864 14.3184 13.4044L18.707 17.7929C19.0977 18.1834 19.0977 18.8166 18.707 19.2071C18.3164 19.5976 17.6836 19.5976 17.293 19.2071L4.29297 6.20711C3.90234 5.81658 3.90234 5.18342 4.29297 4.79289ZM3.29297 8.79289C2.90234 9.18342 2.90234 9.81658 3.29297 10.2071L11.293 18.2071C11.6836 18.5976 12.3164 18.5976 12.707 18.2071C13.0977 17.8166 13.0977 17.1834 12.707 16.7929L4.70703 8.79289C4.31641 8.40237 3.68359 8.40237 3.29297 8.79289ZM6.70703 15.7929L3.70703 12.7929C3.31641 12.4024 2.68359 12.4024 2.29297 12.7929C1.90234 13.1834 1.90234 13.8166 2.29297 14.2071L5.29297 17.2071C5.68359 17.5976 6.31641 17.5976 6.70703 17.2071C7.09766 16.8166 7.09766 16.1834 6.70703 15.7929Z"
            fill="#817F89"
          />
        </>
      }
    />
  );
};
