import styled from 'styled-components';
import { typography } from '@/constants';
import { StyledContentWrapper } from '../../styled';

export const StyledInside = styled.div`
    ${StyledContentWrapper};
    display: flex;
    flex-direction: column;
    gap: 64px;
    box-sizing: border-box;
    padding-top: 88px;
    @media (max-width: 1280px) {
        gap: 40px;
    }
    @media (max-width: 960px) {
        min-height: auto;
    }
    @media (max-width: 768px) {
        min-height: auto;
    }
`;

export const StyledTitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledSubtitle = styled.h2`
    margin: 0;
    padding: 0;
    ${typography.title.XL};
    text-align: center;
    @media (max-width: 768px) {
        ${typography.title.L};
        > br {
            display: none;
        }
    }
`;

export const StyledTitleImage = styled.img`
    width: 100%;
`;
