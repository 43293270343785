import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { ITracksReasponseData, IState } from '../types';

export const getTracksThunk = createAsyncThunk<
    ITracksReasponseData,
    void,
    { rejectValue: IError }
>('labListsSlice/getTracksThunk', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/tracks/');
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getTracksExtraReducer = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(getTracksThunk.pending, (state) => {
        state.tracksState.fetching = true;
        state.tracksState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTracksThunk.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.tracksState = {
            ...action.payload,
            data: results,
            fetching: false,
            errors: {
                code: undefined,
                message: undefined,
            },
        };
    });
    builder.addCase(getTracksThunk.rejected, (state, action) => {
        state.tracksState = {
            ...state.tracksState,
            fetching: false,
            errors: action.payload as IError,
        };
    });
};
