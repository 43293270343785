import { Form, Field } from 'react-final-form';
import { apiClient } from '@/api/apiClient';
import { Alert, Button, Input, IconButton, CloseIcon } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { changePasswordThunk } from '@/store/reducers/ProfileSlice/AsyncThunk';
import { VARIANT } from '@/components/Alert/types';
import { FormEventHandler, useState } from 'react';
import { required } from '@/utils/validate';
import { StyledForm, StyledHeader } from '../styled';

interface IProps {
    onClose?: () => void;
}

interface IValues {
    old_password?: string;
    new_password?: string;
    repeat_new_password?: string;
}

export const ChangePassword = ({ onClose }: IProps) => {
    const dispatch = useAppDispatch();
    const {
        changePasswordState: { fetching, errors: responseErrors },
    } = useAppSelector((state) => state.ProfileSlice);
    const submitHandler = async (args: IValues) => {
        const { new_password, old_password, repeat_new_password } = args;
        if (new_password && old_password && repeat_new_password) {
            const resp = await dispatch(
                changePasswordThunk({ new_password, old_password })
            );
            if (resp.meta.requestStatus === 'fulfilled' && onClose) {
                onClose();
            }
        }
    };
    return (
        <>
            <StyledHeader>
                <span>Change password</span>
                <IconButton
                    icon={<CloseIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onClose) onClose();
                    }}
                />
            </StyledHeader>
            <Form
                onSubmit={submitHandler}
                validate={(values) => {
                    const { old_password, new_password, repeat_new_password } =
                        values;
                    const validateRepeatPassword = () => {
                        if (!new_password) {
                            return 'New password is required';
                        }
                        if (!repeat_new_password) {
                            return 'Repeat password is required';
                        }
                        if (new_password !== repeat_new_password) {
                            return 'The repeated password does not match the new password';
                        }
                        return undefined;
                    };
                    return {
                        old_password: required(old_password),
                        new_password: required(new_password),
                        repeat_new_password: validateRepeatPassword(),
                    };
                }}
            >
                {({ handleSubmit, pristine, values, errors }) => {
                    const isDisabled =
                        fetching ||
                        pristine ||
                        (!!errors && Object.values(errors).length > 0);
                    return (
                        <StyledForm onSubmit={handleSubmit}>
                            {responseErrors.message && (
                                <Alert
                                    variant={VARIANT.ERROR}
                                    message={responseErrors.message}
                                />
                            )}
                            <Field name="old_password">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            label="Old password"
                                            value={value}
                                            onChange={onChange}
                                            type="password"
                                        />
                                    );
                                }}
                            </Field>
                            <Field name="new_password">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            label="New password"
                                            value={value}
                                            onChange={onChange}
                                            type="password"
                                        />
                                    );
                                }}
                            </Field>
                            <Field name="repeat_new_password">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            label="Repeat new password"
                                            value={value}
                                            onChange={onChange}
                                            type="password"
                                        />
                                    );
                                }}
                            </Field>
                            <Button type="submit" disabled={isDisabled}>
                                Change
                            </Button>
                        </StyledForm>
                    );
                }}
            </Form>
        </>
    );
};
