import { IPrice } from './types';

export const exampleTitles: {
    [key: string]: string;
} = {
    polygons: 'Polygons',
    premium_polygons: 'Premium polygons',
    ssh_access: 'SSH access to the polygon',
    teacher_personal_page: 'Teachers personal page',
    custom_polygons: 'Custom polygons and scenarios',
    connecting_siem: 'Connecting polygons to your SIEM',
    prioritization_task: 'Prioritization of tasks in our roadmap',
};

export const examplePrice: IPrice[] = [
    {
        id: 0,
        name: 'Basic',
        amount: 0,
        polygons: true,
        premium_polygons: false,
        ssh_access: false,
        teacher_personal_page: false,
        custom_polygons: false,
        connecting_siem: false,
        prioritization_task: false
    },
    {
        id: 1,
        name: 'Premium',
        amount: 20,
        polygons: true,
        premium_polygons: true,
        ssh_access: true,
        teacher_personal_page: false,
        custom_polygons: false,
        connecting_siem: false,
        prioritization_task: false,
    },
    {
        id: 2,
        name: 'Professor',
        amount: undefined,
        polygons: true,
        premium_polygons: true,
        ssh_access: true,
        teacher_personal_page: true,
        custom_polygons: true,
        connecting_siem: true,
        prioritization_task: true,
    },
];
