import { FC } from 'react';
import { VARIANT, IAlertProps } from './types';
import { StyledAlert, StyledErrCode, StyledErrMessage } from './styled';
import { IconWarning } from '../Icons/Warning/IconWarning';
import { IconError } from '../Icons/Error/IconError';
import { IconOk } from '../Icons/Ok/IconOk';

export const Alert: FC<IAlertProps> = ({
    variant = VARIANT.ERROR,
    code,
    message,
    style,
}) => {
    return (
        <StyledAlert variant={variant} style={{ ...style }}>
            {variant === VARIANT.ERROR && <IconError />}
            {variant === VARIANT.WARNING && <IconWarning />}
            {variant === VARIANT.SUCCESS && <IconOk />}
            <StyledErrMessage>
                <StyledErrCode>{code} </StyledErrCode>
                {message}
            </StyledErrMessage>
        </StyledAlert>
    );
};
