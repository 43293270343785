import { useEffect, useState } from 'react';
import GlobalStyled from './GlobalStyles';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from '@/router/AppRouter';
import { PopUp } from './components/PopUp/PopUp';
import { WindowWidthContext } from './context/generalContext';
import { ToastProvider } from './components/Toast/ToastProvider';
import { Toast } from './components/Toast/Toast';

function App() {
    const [appScrollTop, setAppScrollTop] = useState(0);
    const [windowWidth, setWindowWidth] = useState<number | null | undefined>(
        0
    );

    const windowScrollHandler = (e: any) => {
        setAppScrollTop(e.target!.documentElement!.scrollTop);
    };

    const windowResizeHandler = () => {
        setWindowWidth(window.document.body.offsetWidth);
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        document.addEventListener('scroll', windowScrollHandler);
        window.addEventListener('resize', windowResizeHandler);
        return () => {
            document.removeEventListener('scroll', windowScrollHandler);
            window.removeEventListener('resize', windowResizeHandler);
        };
    }, []);

    return (
        <>
            <ToastProvider>
                <WindowWidthContext.Provider value={windowWidth}>
                    <div className="App">
                        <GlobalStyled />
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                        <PopUp />
                    </div>
                </WindowWidthContext.Provider>

                <Toast />
            </ToastProvider>
        </>
    );
}

export default App;
