import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { typography, baseColors } from '../../../constants/main';

export const StyledMenu = styled.div<{ cut: boolean }>`
    width: ${({ cut }) => (cut ? '56' : '240px')};
    min-width: ${({ cut }) => (cut ? '56px' : '240px')};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const StyledContainer = styled.div`
    box-sizing: border-box;
`;

export const StyledContainerLogo = styled(NavLink)`
    height: 48px;
    padding: 0 20px;
    display: flex;
    gap: 4px;
    align-items: center;
    text-decoration: none;
`;

export const StyledPremium = styled.span`
    font-size: 10px;
    line-height: 10px;
    font-weight: 800;
    letter-spacing: 1px;
    color: ${baseColors.white(0.5)};
`;

export const itemStyle = css`
    padding: 8px;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    > div.item {
        position: relative;
        padding: 6px 12px 6px 12px;
        box-sizing: border-box;
        display: flex;
        gap: 8px;
        align-items: center;
        opacity: 0.7;
        border-radius: 4px;
        > p {
            ${typography.text.M};
            color: ${baseColors.white()};
        }
    }
    &:hover > div.item {
        opacity: 1;
        background: ${baseColors.levelOne()};
    }
`;

export const StyledFooter = styled.div``;

export const StyledPrivacyPolicy = styled.div<{ $isCut: boolean }>`
    padding: 24px 20px;
    display: flex;
    justify-content: ${({ $isCut }) => ($isCut ? 'center' : 'flex-start')};
    > p {
        ${({ $isCut }) => ($isCut ? 'writing-mode: vertical-rl;' : null)}
        ${typography.text.S};
        > a {
            text-decoration: none;
            color: ${baseColors.link()};
        }
    }
`;

export const StyledMenuItemButton = styled.button`
    ${itemStyle};
    background: none;
    width: 100%;
    cursor: pointer;
    > div.item {
        justify-content: space-between;
        > div.titleWrapper {
            display: flex;
            gap: 8px;
            > p {
                ${typography.text.M};
                color: ${baseColors.white()};
            }
        }
        > div.actions {
            display: none;
        }
        > div.moreIcon {
            display: block;
        }
    }

    &:hover > div.item > div.actions {
        display: flex;
        width: fit-content;
        gap: 12px;
    }

    &:hover > div.item > div.moreIcon {
        display: none;
    }
`;

export const StyledMenuItem = styled(NavLink)<{ marked?: boolean }>`
    ${itemStyle};
    div.item {
        ${({ marked }) =>
            marked
                ? `
                    background: ${baseColors.primary()};
                    opacity: 1;
                `
                : ''}
    }
    &.active {
        cursor: default;
    }
    &.active > div.item {
        opacity: 1;
        background: ${baseColors.primary()};
    }
    &.active:hover > div.item {
        opacity: 1;
        background: ${baseColors.primary()};
    }
    &.active.partial > div.item {
        cursor: pointer;
        opacity: 0.7;
        background: ${baseColors.black()};
        &::before {
            content: '';
            display: block;
            width: 4px;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            border-radius: 12px;
            background: ${baseColors.primary()};
        }
    }
    &.active.partial:hover > div.item {
        opacity: 1;
        background: ${baseColors.levelOne()};
    }
`;

export const StyledMenuItemPlansFull = styled.div`
    margin-top: 12px;
    box-sizing: border-box;
    padding: 8px;
    > div.container {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        box-sizing: border-box;
        padding: 16px;
        padding-bottom: 24px;
        background: ${baseColors.levelTwo()};
        > div.textContainer {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > p.header {
                ${typography.title.S};
                text-align: center;
            }
            > p.subheader {
                ${typography.text.S};
                text-align: center;
            }
        }
    }
`;
