import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledMenuItem } from '../../styled';

interface IProps {
    path: string;
    name: string;
    icon?: ReactNode;
    cut?: boolean;
    marked?: boolean;
}

export const ItemMenu = ({ path, name, icon, cut, marked = false }: IProps) => {
    const location = useLocation();
    const [partialMatch, setPartialMatch] = useState<string>('');

    useEffect(() => {
        const cutLink = path.split('/')[path.split('/').length - 1];
        const pathes = location.pathname.split('/');
        if (
            location.pathname.split('/').join('') !== path &&
            pathes.includes(cutLink)
        ) {
            setPartialMatch('partial');
        } else {
            setPartialMatch('');
        }
    }, [location, path, setPartialMatch]);
    return (
        <StyledMenuItem to={path} className={partialMatch} marked={marked}>
            <div className="item">
                {icon}
                {!cut && <p>{name}</p>}
            </div>
        </StyledMenuItem>
    );
};
