import { baseColors } from "./main";

interface IDifficulity {
  activeColor: string;
  disabledColor: string;
  itemsAmount: number;
  indexActiveIndex?: number;
}

interface IDifficulConfid {
  [key: string]: IDifficulity;
}

export const difficulity: IDifficulConfid = {
  "0": {
    activeColor: baseColors.ok(),
    disabledColor: baseColors.ok(0.25),
    itemsAmount: 3,
    indexActiveIndex: undefined,
  },
  "1": {
    activeColor: baseColors.ok(),
    disabledColor: baseColors.ok(0.25),
    itemsAmount: 3,
    indexActiveIndex: 0,
  },
  "2": {
    activeColor: baseColors.ok(),
    disabledColor: baseColors.ok(0.25),
    itemsAmount: 3,
    indexActiveIndex: 1,
  },
  "3": {
    activeColor: baseColors.ok(),
    disabledColor: baseColors.ok(0.25),
    itemsAmount: 3,
    indexActiveIndex: 2,
  },
  "4": {
    activeColor: baseColors.critical(),
    disabledColor: baseColors.critical(0.25),
    itemsAmount: 3,
    indexActiveIndex: 0,
  },
  "5": {
    activeColor: baseColors.critical(),
    disabledColor: baseColors.critical(0.25),
    itemsAmount: 3,
    indexActiveIndex: 1,
  },
  "6": {
    activeColor: baseColors.critical(),
    disabledColor: baseColors.critical(0.25),
    itemsAmount: 3,
    indexActiveIndex: 2,
  },
  "7": {
    activeColor: baseColors.error(),
    disabledColor: baseColors.error(0.25),
    itemsAmount: 4,
    indexActiveIndex: 0,
  },
  "8": {
    activeColor: baseColors.error(),
    disabledColor: baseColors.error(0.25),
    itemsAmount: 4,
    indexActiveIndex: 1,
  },
  "9": {
    activeColor: baseColors.error(),
    disabledColor: baseColors.error(0.25),
    itemsAmount: 4,
    indexActiveIndex: 2,
  },
  "10": {
    activeColor: baseColors.error(),
    disabledColor: baseColors.error(0.25),
    itemsAmount: 4,
    indexActiveIndex: 3,
  },
};
