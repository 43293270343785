import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError, FetchAuthResponse, FetchAuthArgs } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IState } from '../types';

export const fetchAuthThunk = createAsyncThunk<
    FetchAuthResponse,
    FetchAuthArgs,
    { rejectValue: IError }
>('auth/fetchAuth', async function (args, { rejectWithValue }) {
    const { name, pass, navigate } = args;
    try {
        const response = await apiClient.post('api/token/', {
            username: name,
            password: pass,
        });
        const data = await response.data;
        if (navigate) {
            navigate('/');
        }
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const fetchAuthBuilder = (builder: ActionReducerMapBuilder<IState>) => {
    builder.addCase(fetchAuthThunk.pending, (state) => {
        state.isLoading = true;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(fetchAuthThunk.fulfilled, (state, action) => {
        state.auth = true;
        state.isLoading = false;
        state.refresh = action.payload.refresh;
        state.access = action.payload.access;
        localStorage.setItem(
            'token',
            JSON.stringify({
                refresh: action.payload.refresh,
                access: action.payload.access,
            })
        );
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(fetchAuthThunk.rejected, (state, action) => {
        localStorage.clear();
        state.isLoading = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};
