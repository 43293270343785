import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const MapIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.5468 1.16275C14.8296 1.34741 15 1.6623 15 2V11.9512C15 12.3481 14.7653 12.7073 14.402 12.8669L10.402 14.623C10.1458 14.7354 9.8542 14.7354 9.59801 14.623L6 13.0434L2.40199 14.623C2.09277 14.7587 1.73593 14.7292 1.45319 14.5446C1.17044 14.3599 1 14.045 1 13.7073V3.7561C1 3.35927 1.23465 2.99998 1.59801 2.84046L5.59801 1.08436C5.8542 0.971884 6.1458 0.971884 6.40199 1.08436L10 2.66397L13.598 1.08436C13.9072 0.948606 14.2641 0.978088 14.5468 1.16275ZM3 4.4092V12.1762L5.5 11.0786V3.31164L3 4.4092ZM9.5 12.3957L6.5 11.0786V3.31164L9.5 4.62872V12.3957ZM10.5 12.3957L13 11.2981V3.53115L10.5 4.62872V12.3957Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M6.90289 10.6483C7.0797 10.8604 7.05109 11.1757 6.83899 11.3525C6.61061 11.5429 6.40311 11.7581 6.22333 11.9922C6.05512 12.2112 5.74122 12.2523 5.52223 12.0841C5.30323 11.9159 5.26206 11.602 5.43027 11.383C5.65578 11.0894 5.91474 10.8211 6.19867 10.5844C6.41078 10.4076 6.72607 10.4362 6.90289 10.6483Z"
                            fill={fill}
                        />
                        <path
                            d="M5.10937 13.0069C5.38257 13.0471 5.5715 13.3011 5.53134 13.5743C5.51068 13.7149 5.5 13.857 5.5 14C5.5 14.2761 5.27614 14.5 5 14.5C4.72386 14.5 4.5 14.2761 4.5 14C4.5 13.8071 4.5144 13.6164 4.54197 13.4289C4.58213 13.1557 4.83616 12.9668 5.10937 13.0069Z"
                            fill={fill}
                        />
                        <path
                            d="M10.7272 10.3645C10.8954 10.1455 11.2092 10.1043 11.4282 10.2725C11.5745 10.3848 11.7165 10.5094 11.8536 10.6465C11.9669 10.7598 12.0783 10.868 12.1879 10.9712C12.3889 11.1605 12.3984 11.4769 12.209 11.678C12.0197 11.879 11.7033 11.8884 11.5022 11.6991C11.3851 11.5888 11.2665 11.4736 11.1464 11.3536C11.0394 11.2465 10.93 11.1508 10.8192 11.0656C10.6001 10.8974 10.559 10.5835 10.7272 10.3645Z"
                            fill={fill}
                        />
                        <path
                            d="M18.7572 10.5713C18.994 10.7134 19.0708 11.0205 18.9287 11.2573C18.8407 11.4041 18.7504 11.5468 18.6578 11.6848C18.5038 11.9141 18.1932 11.9751 17.9639 11.8212C17.7347 11.6673 17.6736 11.3566 17.8275 11.1274C17.9099 11.0048 17.991 10.8765 18.0713 10.7428C18.2133 10.506 18.5205 10.4292 18.7572 10.5713Z"
                            fill={fill}
                        />
                        <path
                            d="M17.3513 12.5192C17.5059 12.748 17.4458 13.0588 17.217 13.2134C16.9016 13.4266 16.5589 13.5875 16.1865 13.6792C15.9184 13.7452 15.6475 13.5813 15.5815 13.3132C15.5155 13.0451 15.6793 12.7742 15.9474 12.7082C16.1922 12.6479 16.4275 12.54 16.6571 12.3849C16.8859 12.2303 17.1967 12.2904 17.3513 12.5192Z"
                            fill={fill}
                        />
                        <path
                            d="M17.3536 7.35354C17.5488 7.15828 17.8654 7.15828 18.0607 7.35354L18.4142 7.70706L18.7677 7.35356C18.963 7.15829 19.2795 7.15829 19.4748 7.35356C19.6701 7.54882 19.6701 7.8654 19.4748 8.06066L19.1213 8.41417L19.4749 8.76775C19.6701 8.96301 19.6701 9.2796 19.4749 9.47486C19.2796 9.67012 18.963 9.67012 18.7678 9.47486L18.4142 9.12128L18.0606 9.47488C17.8653 9.67014 17.5487 9.67014 17.3535 9.47488C17.1582 9.27961 17.1582 8.96303 17.3535 8.76777L17.7071 8.41417L17.3536 8.06065C17.1583 7.86538 17.1583 7.5488 17.3536 7.35354Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M22.543 1.16025C22.8279 1.3445 23 1.66067 23 2V19C23 19.3935 22.7692 19.7504 22.4104 19.9119L15.7437 22.9119C15.4827 23.0294 15.1839 23.0294 14.923 22.9119L8.66667 20.0966L2.41036 22.9119C2.10092 23.0512 1.74198 23.024 1.45703 22.8398C1.17208 22.6555 1 22.3393 1 22V5C1 4.60649 1.23079 4.24956 1.58964 4.08808L8.2563 1.08808C8.51727 0.970643 8.81606 0.970643 9.07703 1.08808L15.3333 3.90342L21.5896 1.08808C21.8991 0.948833 22.258 0.976006 22.543 1.16025ZM3 5.64659V20.4534L7.5 18.4284V3.62159L3 5.64659ZM9.07703 18.0881C8.89456 18.006 8.6936 17.9813 8.5 18.014V10.538C8.75081 10.4877 9.0037 10.4676 9.25397 10.4805C9.52975 10.4946 9.76478 10.2825 9.77893 10.0067C9.79308 9.73096 9.58099 9.49592 9.30521 9.48178C9.03438 9.46788 8.76483 9.4827 8.5 9.52287V3.17159L8.66667 3.09659L14.5 5.72159V12.5892C14.4659 12.5626 14.4277 12.54 14.3859 12.5224C14.1454 12.4213 13.8885 12.2857 13.6139 12.1103C13.3812 11.9616 13.072 12.0298 12.9234 12.2625C12.7747 12.4952 12.8429 12.8044 13.0756 12.953C13.3911 13.1546 13.6984 13.3181 13.9982 13.4442C14.1711 13.5169 14.3619 13.485 14.5 13.3773V20.5284L9.07703 18.0881ZM15.5 20.8284L21 18.3534V3.54659L15.7437 5.91192C15.6652 5.94725 15.5833 5.97195 15.5 5.98602V20.8284Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
