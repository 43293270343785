import styled, { css } from 'styled-components';
import { generateMedia } from './stylesGenerators';
import bckg from './assets/img/bckg.svg';
import { baseColors } from '@/constants';

export const StyledContentWrapper = css`
    min-height: 100vh;
    max-width: 1440px;
`;

export const StyledPage = styled.div<{}>`
    position: relative;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    scroll-behavior: smooth;
    background: ${`radial-gradient(${baseColors.black(
        0
    )}, ${baseColors.black()}), url(${bckg}), ${baseColors.black()}`};

    background-repeat: repeat;
`;

export const StyledContent = styled.div`
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    @media (max-width: 768px) {
        padding: 0 40px;
    }
    @media (max-width: 360px) {
        padding: 0 24px;
    }
`;

export const StyledOffsetPage = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 184px;
    padding-left: 24px;
    ${generateMedia({
        768: `width: 96px`,
        560: `display: none;`,
        360: `width: 24px;`,
    })};
    height: 100vh;
    position: sticky;
    top: 0;
`;
