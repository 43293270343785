import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { IState } from '../types';

export const changePasswordThunk = createAsyncThunk<
    void,
    { old_password: string; new_password: string },
    { rejectValue: IError }
>('profileSlice/changePasswordThunk', async (data, { rejectWithValue }) => {
    try {
        await apiClient.post('/api/v1/userinfo/change_password/', data);
    } catch (err) {
        console.log('err: ', err);
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.status,
            });
        }
    }
});

export const changePasswordBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(changePasswordThunk.pending, (state) => {
        state.changePasswordState.fetching = true;
        state.changePasswordState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(changePasswordThunk.fulfilled, (state) => {
        state.changePasswordState.fetching = false;
        state.changePasswordState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(changePasswordThunk.rejected, (state, action) => {
        state.changePasswordState.fetching = false;
        state.changePasswordState.errors = action.payload as IError;
    });
};
