import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLabThank } from '@/store/reducers/LabSlice/AsyncThanks';
import { ILabTeamplate, ILab } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';
import { LAB_TYPE } from '@/constants';

export const destroyLabThank = createAsyncThunk<
    void,
    {
        template_id: ILabTeamplate['id'];
        uuid_lab: ILab['uuid'];
        basePath?: LAB_TYPE;
    },
    { rejectValue: IError }
>(
    'labWithSchemeSlice/destroyLabThank',
    async (
        { template_id, uuid_lab, basePath = LAB_TYPE.DEFAULT },
        { rejectWithValue, dispatch }
    ) => {
        try {
            await apiClient.get(`/api/v1/${basePath}/${uuid_lab}/destroy_lab/`);
            await dispatch(getLabThank({ templateId: Number(template_id) }));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);
