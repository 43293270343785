import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const CloseEyeIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M1.75746 5.02985C2.29106 4.89645 2.83175 5.21901 2.96847 5.7509L2.97244 5.76491C2.97717 5.78113 2.98604 5.8103 2.99971 5.85057C3.0271 5.93131 3.07333 6.05538 3.14343 6.20834C3.28438 6.51585 3.51681 6.92919 3.87757 7.34149C4.57625 8.13998 5.80834 8.99999 7.99999 8.99999C10.1916 8.99999 11.4237 8.13998 12.1224 7.34149C12.4832 6.92919 12.7156 6.51585 12.8566 6.20834C12.9267 6.05538 12.9729 5.93131 13.0003 5.85057C13.0139 5.8103 13.0228 5.78113 13.0275 5.76491L13.0315 5.75088C13.1682 5.219 13.7089 4.89645 14.2425 5.02985C14.7783 5.1638 15.1041 5.70673 14.9701 6.24253L14.0003 6.00006C14.9701 6.24253 14.9698 6.24369 14.9698 6.24369L14.9695 6.24495L14.9688 6.24774L14.9671 6.25445L14.9624 6.27228C14.9586 6.28608 14.9537 6.30372 14.9475 6.32491C14.9352 6.36729 14.9177 6.42406 14.8942 6.49316C14.8474 6.63117 14.7765 6.8196 14.6747 7.04165C14.4719 7.48413 14.1418 8.0708 13.6276 8.6585C12.5762 9.86001 10.8083 11 7.99999 11C5.19165 11 3.42374 9.86001 2.37242 8.6585C1.85818 8.0708 1.52811 7.48413 1.3253 7.04165C1.22353 6.8196 1.15257 6.63117 1.10575 6.49316C1.0823 6.42406 1.0648 6.36729 1.05244 6.32491C1.04626 6.30372 1.04135 6.28608 1.03762 6.27228L1.0329 6.25445L1.03117 6.24774L1.03046 6.24495L1.03014 6.24369C1.03014 6.24369 1.02985 6.24253 1.99968 6.00007L1.02985 6.24253C0.895901 5.70673 1.22166 5.1638 1.75746 5.02985Z"
                            fill={fill}
                        />
                        <path
                            d="M1.29289 11.2929C0.902369 11.6834 0.902369 12.3166 1.29289 12.7071C1.68342 13.0976 2.31658 13.0976 2.70711 12.7071L3.41421 12C3.80474 11.6095 3.80474 10.9763 3.41421 10.5858C3.02369 10.1953 2.39052 10.1953 2 10.5858L1.29289 11.2929Z"
                            fill={fill}
                        />
                        <path
                            d="M7 14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14V13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13V14Z"
                            fill={fill}
                        />
                        <path
                            d="M14.7071 11.2929L14 10.5858C13.6095 10.1953 12.9763 10.1953 12.5858 10.5858C12.1953 10.9763 12.1953 11.6095 12.5858 12L13.2929 12.7071C13.6834 13.0976 14.3166 13.0976 14.7071 12.7071C15.0976 12.3166 15.0976 11.6834 14.7071 11.2929Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M2.62863 7.07151C3.13981 6.86704 3.71974 7.11426 3.92656 7.62379L3.9271 7.62508C3.92854 7.62847 3.93189 7.63631 3.93725 7.64831C3.94797 7.67232 3.96668 7.71287 3.99406 7.76762C4.04887 7.87724 4.13799 8.04294 4.26672 8.24619C4.52464 8.65343 4.93787 9.20567 5.54769 9.76005C6.75108 10.854 8.76512 12 12 12C15.2349 12 17.249 10.854 18.4523 9.76005C19.0622 9.20567 19.4754 8.65343 19.7333 8.24619C19.8621 8.04294 19.9512 7.87724 20.006 7.76762C20.0334 7.71288 20.0521 7.67232 20.0628 7.64831C20.0682 7.63632 20.0715 7.62847 20.0729 7.62508L20.0735 7.62373C20.2804 7.11424 20.8603 6.86705 21.3714 7.07152C21.8842 7.27663 22.1336 7.8586 21.9285 8.37138L21.0739 8.02955C21.9285 8.37141 21.928 8.37254 21.928 8.37254L21.9264 8.37669L21.9234 8.38385L21.9152 8.40359C21.9086 8.41912 21.8999 8.43935 21.8889 8.46398C21.8669 8.51321 21.8358 8.58008 21.7948 8.66205C21.7129 8.82587 21.5911 9.0508 21.423 9.31629C21.0871 9.84655 20.5629 10.5443 19.7977 11.2399C18.2511 12.646 15.7651 14 12 14C8.23492 14 5.74897 12.6459 4.20235 11.2399C3.43717 10.5443 2.91291 9.84655 2.57708 9.31629C2.40893 9.0508 2.28711 8.82587 2.2052 8.66205C2.16422 8.58008 2.13313 8.51321 2.11113 8.46398C2.10014 8.43935 2.0914 8.41912 2.08484 8.40359L2.07661 8.38385L2.07368 8.37669L2.07252 8.3738L2.07201 8.37253C2.07201 8.37253 2.07155 8.37138 2.99987 8.00005L2.07155 8.37138C1.86643 7.8586 2.11585 7.27663 2.62863 7.07151Z"
                            fill={fill}
                        />
                        <path
                            d="M19.2929 17.7071C19.6834 18.0976 20.3166 18.0976 20.7071 17.7071C21.0976 17.3166 21.0976 16.6834 20.7071 16.2929L19.2071 14.7929C18.8166 14.4024 18.1834 14.4024 17.7929 14.7929C17.4024 15.1834 17.4024 15.8166 17.7929 16.2071L19.2929 17.7071Z"
                            fill={fill}
                        />
                        <path
                            d="M3.29289 17.7071C3.68342 18.0976 4.31658 18.0976 4.70711 17.7071L6.20711 16.2071C6.59763 15.8166 6.59763 15.1834 6.20711 14.7929C5.81658 14.4024 5.18342 14.4024 4.79289 14.7929L3.29289 16.2929C2.90237 16.6834 2.90237 17.3166 3.29289 17.7071Z"
                            fill={fill}
                        />
                        <path
                            d="M11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V19Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
