import { createContext } from 'react';
import { LAB_TYPE } from '@/constants';

interface IProps {
    type: LAB_TYPE;
}

export const LabContext = createContext({
    type: LAB_TYPE.DEFAULT,
} as IProps);
