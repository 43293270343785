import styled, { css } from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledOfferCard = styled.div`
    background: ${baseColors.primary()};
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
    padding-bottom: 16px;
`;

const rowStyle = css`
    box-sizing: border-box;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
`;

export const StyledHeader = styled.div`
    ${rowStyle};
    border-bottom: 1px solid ${baseColors.white(0.1)};
    > p {
        ${typography.title.L}
    }
`;

export const StyledRow = styled.div`
    ${rowStyle};
    > span {
        ${typography.title.M};
        text-align: right;
    }
    > p {
        ${typography.text.M};
    }
`;
