const POSITION_OFFSET = 4;
const ARROW_HEIGHT_NUM = 7;

export const DEFAULT_ARROW_PADDING = 6;
export const ARROW_WIDTH = '14px';
export const ARROW_HEIGHT = `${ARROW_HEIGHT_NUM}px`;
export const INNER_ARROW_SIZE = '14px';
export const INNER_ARROW_OFFSET = `10px`;

export const OFFSET = ARROW_HEIGHT_NUM + POSITION_OFFSET;
export const MAX_WIDTH = 246;

export enum PLACEMENTS {
  auto = 'auto',
  autoLeft = 'autoLeft',
  autoRight = 'autoRight',
  top = 'top',
  bottom = 'bottom',
  right = 'right',
  left = 'left',
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
  rightTop = 'rightTop',
  rightBottom = 'rightBottom',
}

export enum PLACEMENTS_MAP {
  auto = 'auto',
  autoRight = 'auto-start',
  autoLeft = 'auto-end',
  top = 'top',
  topRight = 'top-start',
  topLeft = 'top-end',
  bottom = 'bottom',
  bottomRight = 'bottom-start',
  bottomLeft = 'bottom-end',
  left = 'left',
  leftBottom = 'left-start',
  leftTop = 'left-end',
  right = 'right',
  rightBottom = 'right-start',
  rightTop = 'right-end',
}
