import styled from 'styled-components';
import { typography } from '../../../../constants/main';

export const StyledFormulaWrapper = styled.div`
    padding: 32px 56px 56px 56px;
`;

export const StyledFormula = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    > span {
        ${typography.text.M}
    }
`;

export const StyledSeparator = styled.div`
    height: 1px;
    width: 100%;
    background: #fff;
`;

export const StyledDivision = styled.div`
    display: flex;
    gap: 4px;
    flex-direction: column;
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    color: rgba(255, 255, 255, 0.7);
`;

export const StyledContentBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px 72px 72px 72px;
    gap: 32px;
`;

export const StyledContentSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    max-width: 440px;
`;
