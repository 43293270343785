import { IDropdownMenuItem } from '../types';
import {
    StyledDropdownMenuItem,
    StyledTextContainer,
    StyledTitle,
    StyledDescription,
} from '../styled';

interface IProps extends IDropdownMenuItem {
    onClickHandler: (id: IDropdownMenuItem['id']) => void;
}

export const DropdownMenuItem = (props: IProps) => {
    const { onClickHandler, id, title, description, icon } = props;
    return (
        <StyledDropdownMenuItem key={id} onClick={() => onClickHandler(id)}>
            {icon}
            {(title || description) && (
                <StyledTextContainer>
                    {title && <StyledTitle>{title}</StyledTitle>}
                    {description && (
                        <StyledDescription>{description}</StyledDescription>
                    )}
                </StyledTextContainer>
            )}
        </StyledDropdownMenuItem>
    );
};
