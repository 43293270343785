import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IInitialState } from './types/siem';
import { ISiem } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';

export const getSiems = createAsyncThunk<ISiem, void, { rejectValue: IError }>(
    'siemSlice/getSiems',
    async function (_, { rejectWithValue }) {
        try {
            const response = await apiClient.get('/api/v1/siems/', {
                requestName: 'getSiems',
            });
            const data = await response.data[0];
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

const initialState: IInitialState = {
    siem: undefined,
    error: {
        code: undefined,
        message: undefined,
    },
    isLoading: false,
};

export const siemSlice = createSlice({
    name: 'siemSlice',
    initialState,
    reducers: {
        resetSiem(state) {
            state.siem = undefined;
        },
        getSiemsFetching(state) {
            state.isLoading = true;
            state.error.code = undefined;
            state.error.message = undefined;
        },
        getSiemsSaccess(state, action) {
            state.isLoading = false;
            state.siem = action.payload;
            state.error.code = undefined;
            state.error.message = undefined;
        },
        getSiemsFailure(state, action) {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        },
    },
    extraReducers(builder) {
        builder.addCase(getSiems.pending, (state) => {
            state.isLoading = true;
            state.error.code = undefined;
            state.error.message = undefined;
        });
        builder.addCase(getSiems.fulfilled, (state, action) => {
            state.isLoading = false;
            state.siem = action.payload;
            state.error.code = undefined;
            state.error.message = undefined;
        });
        builder.addCase(getSiems.rejected, (state, action) => {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });
    },
});

export default siemSlice.reducer;
