import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const AttackIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.4113 2.41135C12.9636 2.41135 13.4113 2.85906 13.4113 3.41135V6.30511C13.4113 6.57033 13.3059 6.82468 13.1184 7.01222L9.00259 11.128L9.26009 11.3855C9.65062 11.7761 9.65062 12.4092 9.26009 12.7997C8.86957 13.1903 8.23641 13.1903 7.84588 12.7997L6.88633 11.8402L6.88126 11.8352L6.25691 11.2108C6.23249 11.2418 6.20599 11.2717 6.17739 11.3003L4.40962 13.0681C4.0191 13.4586 3.38593 13.4586 2.99541 13.0681C2.60488 12.6776 2.60488 12.0444 2.99541 11.6539L4.76317 9.88611C4.79178 9.85751 4.82168 9.831 4.85269 9.80659L3.98749 8.94139L3.9824 8.93627L3.02294 7.97681C2.63242 7.58628 2.63242 6.95312 3.02294 6.56259C3.41347 6.17207 4.04663 6.17207 4.43715 6.56259L4.69462 6.82006L8.81043 2.70424C8.99797 2.5167 9.25232 2.41135 9.51754 2.41135L12.4113 2.41135ZM11.4113 5.8909L7.58838 9.71382L6.10883 8.23427L9.93175 4.41135H11.4113V5.8909Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.5355 4.53549C20.5355 3.98321 20.0878 3.53549 19.5355 3.53549L15.2929 3.53549C15.0276 3.53549 14.7733 3.64085 14.5858 3.82839L8.22182 10.1923L7.51472 9.48523C7.1242 9.09471 6.49103 9.09471 6.10051 9.48523C5.70999 9.87576 5.70999 10.5089 6.10051 10.8994L7.50923 12.3082L7.51469 12.3137L11.7573 16.5563L11.7628 16.5617L13.1716 17.9705C13.5621 18.361 14.1953 18.361 14.5858 17.9705C14.9763 17.58 14.9763 16.9468 14.5858 16.5563L13.8787 15.8492L20.2426 9.48524C20.4301 9.2977 20.5355 9.04335 20.5355 8.77813L20.5355 4.53549ZM12.4645 14.435L18.5355 8.36392V5.53549L15.7071 5.53549L9.63603 11.6065L12.4645 14.435Z"
                            fill={fill}
                        />
                        <path
                            d="M6.80766 14.435C7.19819 14.0444 7.83135 14.0444 8.22188 14.435L9.63609 15.8492C10.0266 16.2397 10.0266 16.8729 9.63609 17.2634C9.24557 17.6539 8.6124 17.6539 8.22188 17.2634L6.80766 15.8492C6.41714 15.4587 6.41714 14.8255 6.80766 14.435Z"
                            fill={fill}
                        />
                        <path
                            d="M6.10054 16.5563C5.71001 16.1658 5.07685 16.1658 4.68633 16.5563C4.2958 16.9468 4.2958 17.58 4.68633 17.9705L6.10054 19.3847C6.49106 19.7753 7.12423 19.7753 7.51475 19.3847C7.90528 18.9942 7.90528 18.361 7.51475 17.9705L6.10054 16.5563Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
