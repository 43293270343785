import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledHelpBar = styled.div`
    box-sizing: border-box;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 320px;
    min-width: 320px;
    height: calc(100vh - 48px);
    overflow: auto;
`;

export const StyledIsland = styled.div`
    box-sizing: border-box;
    padding: 24px;
    background: ${baseColors.levelTwo()};
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
`;

export const StyledMentor = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledMentorMessage = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    > p {
        ${typography.text.M};
    }
    > p.secondMessage {
        color: ${baseColors.white(0.5)};
    }
`;

export const StyledMentorMessageFooter = styled.div`
    display: flex;
    > div.middle_image {
        background: ${baseColors.white()};
        height: 2px;
        width: 100%;
    }
`;

export const StyledMentorCharacter = styled.div`
    display: flex;
    gap: 16px;
    > img.imageCharacter {
        border-radius: 200px;
    }
    > div.textContainer {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding-top: 8px;
        > p.name {
            ${typography.title.M};
        }
        > p.jobTitle {
            ${typography.text.S};
        }
    }
`;

export const StyledStatistic = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    > div.container {
        display: flex;
        gap: 24px;
    }
`;

export const StyledStatisticBloc = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
`;

export const StyledFactoid = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    > p.unit {
        ${typography.text.S};
        color: ${baseColors.white(0.5)};
    }
    > p.title {
        ${typography.text.S};
    }
`;

export const StyledCountContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    > span.count {
        ${typography.title.XXL};
    }
    > div.compareContainer {
        display: flex;
        align-items: center;
        height: fit-content;
        > span.compareCount {
            ${typography.title.S};
            color: ${baseColors.ok()};
        }
    }
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledSeparator = styled.div`
    height: 1px;
    background: ${baseColors.white(0.1)};
    width: 100%;
`;
