import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledTag = styled.div`
    padding: 2px 6px;
    background-color: ${baseColors.primary()};
    border-radius: 4px;
    width: fit-content;
    display: flex;
    gap: 4px;
    align-items: center;
    max-height: 20px;
    letter-spacing: 1px;
    & span {
        ${typography.title.S};
    }
`;
