import styled from 'styled-components'
import { typography } from '../../constants/main'

export const StyledIconButton = styled.button`
    background: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        opacity: 0.25;
        &:hover {
            opacity: 0.25;
        }
    }
`

export const StyledLabel = styled.span`
    ${typography.text.S};
    text-transform: uppercase;
    line-height: 20px;
    font-weight: 600;
`
