import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { typography, baseColors } from '../../../constants/main';

interface StyledItemProps {
    isActive: boolean;
    size: 'L' | 'M' | 'S';
    $color: string;
    $stylesConditions?: {
        active: string;
        normal: string;
    };
}

interface StyledItemLinkProps extends Omit<StyledItemProps, 'isActive'> {}

export const StyledTabList = styled.div<{ height: number }>`
    display: flex;
    gap: 16px;
    box-sizing: border-box;
    height: ${({ height }) => `${height}px`};
`;

const styleItem = css`
    height: inherit;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
`;

const styleActiveItem = ({ $color }: { $color: string }) => {
    return css`
        opacity: 1;
        &::before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 4px;
            border-radius: 12px;
            background: ${$color};
        }
    `;
};

const generateItemCondition = ({
    isActive,
    $stylesConditions,
}: StyledItemProps) => {
    if ($stylesConditions) {
        if (isActive) {
            return $stylesConditions.active;
        }
        return $stylesConditions.normal;
    }
    if (isActive) {
        return styleActiveItem;
    }
    return null;
};

export const StyledTabItem = styled.div<StyledItemProps>`
    ${styleItem};
    ${generateItemCondition};
    > span {
        ${({ size }) => typography.text[size]};
        color: inherit;
    }
`;

export const StyledTabItemLink = styled(NavLink)<StyledItemLinkProps>`
    ${styleItem};
    text-decoration: none;
    color: ${baseColors.white()};
    &.active {
        ${({ $stylesConditions, $color }) =>
            generateItemCondition({
                isActive: true,
                $stylesConditions,
                size: 'M',
                $color,
            })}
    }
    > span {
        ${({ size }) => typography.text[size]};
        color: inherit;
    }
`;
