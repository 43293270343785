import { Icon } from '../Icon';

interface IconMailProps {
    figureFill?: string;
    size?: number;
}

export const IconMail = (props: IconMailProps) => {
    const { figureFill, size } = props;
    return (
        <Icon
            size={size}
            content={
                <>
                    <path
                        d="M3.55046 7.49623C2.94817 6.96086 2 7.19416 2 8V16C2 18.2091 3.79086 20 6 20H18C20.2091 20 22 18.2091 22 16V8C22 7.19417 21.0518 6.96087 20.4496 7.49624L12.9966 14.1211C12.4282 14.6263 11.5718 14.6263 11.0035 14.1211L3.55046 7.49623Z"
                        fill={figureFill || 'white'}
                    />
                    <path
                        d="M18.3562 5.34312C18.8976 4.86187 18.7244 4 18 4H6C5.27563 4 5.10238 4.86185 5.64378 5.3431L12 10.9931L18.3562 5.34312Z"
                        fill={figureFill || 'white'}
                    />
                </>
            }
        />
    );
};
