import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ITimeLineElements } from '@/types/timeline';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types/error';
import { UuidWithBasePath } from './types/general';

export interface IInputTimelineElem extends Omit<ITimeLineElements, 'id'> {}

interface IInitialState {
    timeline: IInputTimelineElem[];
    errorTimeLine: IError;
    isLoadingTimeline: boolean;
}

const initialState: IInitialState = {
    timeline: [],
    errorTimeLine: {
        code: undefined,
        message: undefined,
    },
    isLoadingTimeline: false,
};

export const getTimeline = createAsyncThunk<
    IInputTimelineElem[],
    UuidWithBasePath,
    { rejectValue: IError }
>('timelineSlice/getTimeline', async ({uuid, basePath}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/${basePath}/${uuid}/timeline/`, {
            // requestName: 'getTimeline',
        });
        const data = await response.data.result;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const checkTimeline = createAsyncThunk<
    IInputTimelineElem[],
    UuidWithBasePath,
    { rejectValue: IError }
>('timelineSlice/checkTimeline', async ({uuid, basePath}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/${basePath}/${uuid}/timeline/`, {
            requestName: 'checkTimeline',
        });
        const data = await response.data.result;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const timelineSlice = createSlice({
    name: 'timelineSlice',
    initialState,
    reducers: {
        getTimelineFetching(state) {
            state.isLoadingTimeline = true;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        },
        getTimelineSaccess(state, action) {
            state.timeline = action.payload;
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        },
        getTimelineFailure(state, action) {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
        checkTimelineFetching(state) {
            state.isLoadingTimeline = true;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        },
        checkTimelineSaccess(state, action) {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
            if (action.payload.length > state.timeline.length) {
                state.timeline = action.payload;
            }
        },
        checkTimelineFailure(state, action) {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(getTimeline.pending, (state) => {
            state.isLoadingTimeline = true;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTimeline.fulfilled, (state, action) => {
            state.timeline = action.payload;
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTimeline.rejected, (state, action) => {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });

        builder.addCase(checkTimeline.pending, (state) => {
            state.isLoadingTimeline = true;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(checkTimeline.fulfilled, (state, action) => {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: undefined,
                message: undefined,
            };
            if (action.payload.length > state.timeline.length) {
                state.timeline = action.payload;
            }
        });
        builder.addCase(checkTimeline.rejected, (state, action) => {
            state.isLoadingTimeline = false;
            state.errorTimeLine = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
    },
});

export default timelineSlice.reducer;
