import { createContext } from 'react';
import { HIGHLIGHT_TYPE } from '@/constants';

interface IProps {
    uuid?: string;
    currentType?: HIGHLIGHT_TYPE;
    setCurrentType?: (ct: HIGHLIGHT_TYPE | undefined) => void;
}

export const FlagsContext = createContext({} as IProps);
