import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const WorkshopIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.4453 1.16795C7.7812 0.944017 8.2188 0.944017 8.5547 1.16795L14.5547 5.16795C14.8329 5.35342 15 5.66565 15 6C15 6.33435 14.8329 6.64658 14.5547 6.83205L12 8.53518V12C12 12.9907 11.3977 13.7651 10.676 14.2463C9.94735 14.732 8.99832 15 8 15C7.00168 15 6.05265 14.732 5.32398 14.2463C4.6023 13.7651 4 12.9907 4 12V8.53518L1.4453 6.83205C1.1671 6.64658 1 6.33435 1 6C1 5.66565 1.1671 5.35342 1.4453 5.16795L7.4453 1.16795ZM6 12C6 12.1139 6.06927 12.3394 6.43338 12.5822C6.7905 12.8202 7.34146 13 8 13C8.65854 13 9.2095 12.8202 9.56662 12.5822C9.93073 12.3394 10 12.1139 10 12V9.86852L8.5547 10.8321C8.2188 11.056 7.7812 11.056 7.4453 10.8321L6 9.86852V12ZM12.1972 6L10.4582 7.15934C10.45 7.16463 10.4419 7.17002 10.4339 7.17554L8 8.79815L5.56609 7.17554C5.55807 7.17003 5.54997 7.16462 5.54178 7.15934L3.80278 6L8 3.20185L12.1972 6Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.4453 2.16795C11.7812 1.94402 12.2188 1.94402 12.5547 2.16795L21.5547 8.16795C21.8329 8.35342 22 8.66565 22 9C22 9.33435 21.8329 9.64658 21.5547 9.83205L18 12.2019V17C18 19.9581 15.0939 22 12 22C8.90606 22 6 19.9581 6 17V12.2018L2.4453 9.83205C2.1671 9.64658 2 9.33435 2 9C2 8.66565 2.1671 8.35342 2.4453 8.16795L11.4453 2.16795ZM8 17C8 18.4602 9.5711 20 12 20C14.4289 20 16 18.4602 16 17V13.5352L12.5547 15.8321C12.2188 16.056 11.7812 16.056 11.4453 15.8321L8 13.5352V17ZM4.80278 9L12 13.7982L19.1972 9L12 4.20185L4.80278 9Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
