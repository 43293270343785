import { nanoid } from 'nanoid';
import { ISchemeGroup } from '@/types';
import { StyledCard, StyledConnectArrow, StyledContainer } from './styled';
import { Vm, Tabs, Info, ArrowInfoButton, HealthChecks } from './components';
import { useContext, useState } from 'react';
import { ConnectionContext } from '../../context/connectionContext';

export const NetworkCard = (props: ISchemeGroup) => {
    const { vms, information, id } = props;

    const [activeTab, setActiveTab] = useState(0);
    const { connections } = useContext(ConnectionContext);

    const renderContent = () => {
        switch (activeTab) {
            case 1:
                return <Info content={information} />;
            case 2:
                return (
                    <HealthChecks
                        checks={information.healthchecks ?? undefined}
                    />
                );
            default:
                return <Vm vms={vms} />;
        }
    };
    return (
        <StyledContainer $length={connections?.length ?? 0}>
            <StyledCard onMouseDown={(e) => e.stopPropagation()}>
                {connections?.map((c, idx) => {
                    const { from, to } = c;
                    const getDirection = () => {
                        if (from === id) {
                            return 'from';
                        }
                        if (to === id) {
                            return 'to';
                        }
                        return 'between';
                    };

                    const direction = getDirection();

                    return (
                        <StyledConnectArrow
                            $direction={direction}
                            $index={idx}
                            key={nanoid()}
                        >
                            {direction === 'to' && (
                                <ArrowInfoButton rules={c.rules} />
                            )}
                        </StyledConnectArrow>
                    );
                })}
                <Tabs
                    group={props}
                    activeKey={activeTab}
                    onChange={(id) => setActiveTab(id as number)}
                />
                {renderContent()}
            </StyledCard>
        </StyledContainer>
    );
};
