import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const LinkIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M13.7426 3.75736C13.7426 3.20507 13.2949 2.75736 12.7426 2.75736H7.79287C7.24058 2.75736 6.79287 3.20507 6.79287 3.75736C6.79287 4.30964 7.24058 4.75736 7.79287 4.75736L10.3284 4.75736L3.55022 11.5355C3.15969 11.926 3.15969 12.5592 3.55022 12.9497C3.94074 13.3403 4.57391 13.3403 4.96443 12.9497L11.7426 6.17155V8.70711C11.7426 9.25939 12.1903 9.70711 12.7426 9.70711C13.2949 9.70711 13.7426 9.25939 13.7426 8.70711V3.75736Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M19.3639 5.63604C19.3639 5.08375 18.9162 4.63604 18.3639 4.63604H12.3639C11.8116 4.63604 11.3639 5.08375 11.3639 5.63604C11.3639 6.18832 11.8116 6.63604 12.3639 6.63604H15.9497L4.92888 17.6569C4.53836 18.0474 4.53836 18.6805 4.92888 19.0711C5.3194 19.4616 5.95257 19.4616 6.34309 19.0711L17.3639 8.05025V11.636C17.3639 12.1883 17.8116 12.636 18.3639 12.636C18.9162 12.636 19.3639 12.1883 19.3639 11.636V5.63604Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
