import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const AddIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8.94975 3.05026C8.94975 2.49798 8.50203 2.05026 7.94975 2.05026C7.39746 2.05026 6.94975 2.49798 6.94975 3.05026V7.00001H3C2.44772 7.00001 2 7.44773 2 8.00001C2 8.55229 2.44772 9.00001 3 9.00001H6.94975V12.9498C6.94975 13.502 7.39746 13.9498 7.94975 13.9498C8.50203 13.9498 8.94975 13.502 8.94975 12.9498V9.00001H12.8995C13.4518 9.00001 13.8995 8.55229 13.8995 8.00001C13.8995 7.44773 13.4518 7.00001 12.8995 7.00001H8.94975V3.05026Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M13.4853 3.51471C13.4853 2.96242 13.0376 2.51471 12.4853 2.51471C11.933 2.51471 11.4853 2.96242 11.4853 3.51471V11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H11.4853V20.4853C11.4853 21.0376 11.933 21.4853 12.4853 21.4853C13.0376 21.4853 13.4853 21.0376 13.4853 20.4853V13H20.9706C21.5228 13 21.9706 12.5523 21.9706 12C21.9706 11.4477 21.5228 11 20.9706 11H13.4853V3.51471Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
