import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const UserIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 3C7.44772 3 7 3.44772 7 4C7 4.55228 7.44772 5 8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3ZM5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4C11 5.65685 9.65685 7 8 7C6.34315 7 5 5.65685 5 4Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 10C5.79086 10 4 11.7909 4 14C4 14.5523 3.55228 15 3 15C2.44772 15 2 14.5523 2 14C2 10.6863 4.68629 8 8 8C11.3137 8 14 10.6863 14 14C14 14.5523 13.5523 15 13 15C12.4477 15 12 14.5523 12 14C12 11.7909 10.2091 10 8 10Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 2C9.79086 2 8 3.79086 8 6C8 8.20914 9.79086 10 12 10C14.2091 10 16 8.20914 16 6C16 3.79086 14.2091 2 12 2ZM10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6C14 7.10457 13.1046 8 12 8C10.8954 8 10 7.10457 10 6Z"
                            fill={fill}
                        />
                        <path
                            d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21C19 21.5523 19.4477 22 20 22C20.5523 22 21 21.5523 21 21C21 16.0294 16.9706 12 12 12C7.02944 12 3 16.0294 3 21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
