import { RefObject, SyntheticEvent, useEffect } from 'react'

export const useOutsideClick = (
    ref: RefObject<HTMLElement>,
    callback: (arg: SyntheticEvent) => void
) => {
    const handleClick = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback(e)
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.addEventListener('click', handleClick)
        }

        return () => {
            if (typeof window !== 'undefined') {
                document.removeEventListener('click', handleClick)
            }
        }
    })
}
