import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IWorkshopCreateRequest } from '@/types/workshop';
import { getWorkshopListThunk } from './';

export const createWorkshopThunk = createAsyncThunk<
    void,
    IWorkshopCreateRequest,
    { rejectValue: IError }
>(
    'workshopWithSchemeSlice/createWorkshopThunk',
    async (
        { dataRequest, closeModalHandler },
        { rejectWithValue, dispatch }
    ) => {
        try {
            await apiClient.post(`/api/v1/workshops/`, dataRequest);
            await dispatch(getWorkshopListThunk());
            await closeModalHandler(false);
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
