import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IInitialState } from './types/labs';
import { ILabTeamplate } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';

export const getLabsList = createAsyncThunk<
    ILabTeamplate[],
    void,
    { rejectValue: IError }
>('labsSlice/getLabsList', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/labtemplates/', {
            requestName: 'labtemplates',
        });
        const labs = response.data;
        return labs;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getTemplateById = createAsyncThunk<
    ILabTeamplate,
    number,
    { rejectValue: IError }
>('labsSlice/getTemplateById', async (id, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/labtemplates/${id}/`, {
            requestName: 'labTemplatesById',
        });
        return response.data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getWorkshopLabById = createAsyncThunk<
    ILabTeamplate,
    string,
    { rejectValue: IError }
>('labsSlice/getWorkshopLabById', async (id, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/workshoplabs/${id}/`, {
            requestName: 'workshopLabById',
        });
        return response.data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

const initialState: IInitialState = {
    labs: [],
    error: {
        code: undefined,
        message: undefined,
    },
    isLoading: false,
    lab: undefined,
    isLoadingLab: false,
    errorLab: {
        code: undefined,
        message: undefined,
    },
};

export const labsSlice = createSlice({
    name: 'labsSlice',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        getLabListFetching(state) {
            state.isLoading = true;
            state.error.code = undefined;
            state.error.message = undefined;
        },
        getLabsListSaccess(state, action) {
            state.isLoading = false;
            state.error.code = undefined;
            state.error.message = undefined;
            state.labs = action.payload;
        },
        getLabsListFailure(state, action) {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        },
        getTemplateByIdFetching(state) {
            state.isLoadingLab = true;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
        },
        getTemplateByIdSaccess(state, action) {
            state.isLoadingLab = false;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
            state.lab = action.payload;
        },
        getTemplateByIdFailure(state, action) {
            state.isLoadingLab = false;
            state.errorLab.code = action.payload?.code;
            state.errorLab.message = action.payload?.message;
        },
    },
    extraReducers(builder) {
        builder.addCase(getLabsList.pending, (state) => {
            state.isLoading = true;
            state.error.code = undefined;
            state.error.message = undefined;
        });
        builder.addCase(getLabsList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.labs = action.payload;
        });
        builder.addCase(getLabsList.rejected, (state, action) => {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });
        builder.addCase(getTemplateById.fulfilled, (state, action) => {
            state.isLoadingLab = false;
            state.lab = action.payload;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
        });
        builder.addCase(getTemplateById.pending, (state) => {
            state.isLoadingLab = true;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
        });
        builder.addCase(getTemplateById.rejected, (state, action) => {
            state.errorLab.code = action.payload?.code;
            state.errorLab.message = action.payload?.message;
            state.isLoadingLab = false;
        });
        builder.addCase(getWorkshopLabById.fulfilled, (state, action) => {
            state.isLoadingLab = false;
            state.lab = action.payload;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
        });
        builder.addCase(getWorkshopLabById.pending, (state) => {
            state.isLoadingLab = true;
            state.errorLab.code = undefined;
            state.errorLab.message = undefined;
        });
        builder.addCase(getWorkshopLabById.rejected, (state, action) => {
            state.errorLab.code = action.payload?.code;
            state.errorLab.message = action.payload?.message;
            state.isLoadingLab = false;
        });
    },
});

export default labsSlice.reducer;
