import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './contants';
import { getNewsBuilder } from './AsyncThunks/getNewsThunk';

export const newsSlice = createSlice({
    name: 'walkthroughsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        getNewsBuilder(builder);
    },
});

export default newsSlice.reducer;
