import { CSSProperties, useMemo, useState, FC } from 'react';
import { IAchievement } from '@/types/achievements';
import {
    StyledAchivement,
    StyledImg,
    StyledTitle,
    StyledDescription,
    StyledTextContainer,
    StyledMoreCell,
} from './styled';
import { baseColors } from '@/constants';
import ach_active from '@/assets/ach_active.png';

interface IProps {
    styleContainer?: CSSProperties;
    imageHeight?: number;
    achievements: IAchievement[];
    maxItems?: number;
}

export const Achivements = ({
    achievements: inputAchievements,
    styleContainer,
    imageHeight = 90,
    maxItems,
}: IProps) => {
    const [showMore, setShoeMore] = useState(false);
    const achievements = useMemo<IAchievement[]>(() => {
        if (maxItems && inputAchievements.length - maxItems > 0) {
            return inputAchievements.filter((a, idx) => idx <= maxItems - 1);
        }
        return inputAchievements;
    }, [inputAchievements, maxItems]);
    const moreAchievments = useMemo<IAchievement[] | null>(() => {
        if (maxItems && inputAchievements.length - maxItems > 0) {
            return inputAchievements.filter((a, idx) => idx > maxItems - 1);
        }
        return null;
    }, [maxItems, inputAchievements]);

    const RenderAchivement: FC<IAchievement> = (a) => (
        <StyledAchivement key={a.id} style={styleContainer}>
            <StyledImg
                $received={a.received}
                src={a.picture ?? ach_active}
                $height={imageHeight}
            />

            <StyledTextContainer received={a.received}>
                <StyledTitle>{a.title}</StyledTitle>
                <StyledDescription>{a.description}</StyledDescription>
            </StyledTextContainer>
        </StyledAchivement>
    );
    return (
        <>
            {achievements.map((a) => (
                <RenderAchivement {...a} key={a.id} />
            ))}
            {!!moreAchievments && !showMore ? (
                <StyledMoreCell
                    style={{
                        maxHeight: `${imageHeight}px`,
                    }}
                >
                    <StyledDescription
                        onClick={() => setShoeMore(true)}
                        style={{
                            textAlign: 'center',
                            color: baseColors.link(),
                        }}
                    >
                        View more... +{moreAchievments.length}
                    </StyledDescription>
                </StyledMoreCell>
            ) : null}
            {!!moreAchievments && showMore ? (
                <>
                    {moreAchievments.map((ma) => (
                        <RenderAchivement {...ma} key={ma.id} />
                    ))}
                </>
            ) : null}
        </>
    );
};
