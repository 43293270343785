import { createSlice } from '@reduxjs/toolkit';
import { IError, IScheme, IHealthChecks, ISchemeGroup } from '@/types';
import { getSchemeThank, checkChangeSchemeThank } from './AsyncThanks';
import {
    checkChangeSchemeWsThunk,
    getSchemeWsThunk,
} from './AsyncThanks/WorkshopThunks';

interface IInitialState {
    fetching: boolean;
    errors: IError;
    scheme?: IScheme;
}

const initialState: IInitialState = {
    fetching: false,
    errors: {
        code: undefined,
        message: undefined,
    },
    scheme: undefined,
};

export const schemeSlice = createSlice({
    name: 'schemeSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // GET SCHEME
        // // lab
        builder.addCase(getSchemeThank.pending, (state) => {
            state.fetching = true;
            state.errors = { code: undefined, message: undefined };
        });
        builder.addCase(getSchemeThank.fulfilled, (state, action) => {
            state.fetching = false;
            state.errors = { code: undefined, message: undefined };
            state.scheme = action.payload;
        });
        builder.addCase(getSchemeThank.rejected, (state, action) => {
            state.fetching = false;
            state.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(getSchemeWsThunk.pending, (state) => {
            state.fetching = true;
            state.errors = { code: undefined, message: undefined };
        });
        builder.addCase(getSchemeWsThunk.fulfilled, (state, action) => {
            state.fetching = false;
            state.errors = { code: undefined, message: undefined };
            state.scheme = action.payload;
        });
        builder.addCase(getSchemeWsThunk.rejected, (state, action) => {
            state.fetching = false;
            state.errors = action.payload as IError;
        });

        // CHECK CHANGE
        // // lab
        builder.addCase(checkChangeSchemeThank.fulfilled, (state, action) => {
            const isSchemeChanged = () => {
                const getChecks = (arg: ISchemeGroup[]) => {
                    return arg.reduce((acc, curr) => {
                        const checks = curr.information.healthchecks;
                        if (checks) {
                            acc = [...acc, ...checks];
                        }
                        return acc;
                    }, [] as IHealthChecks[]);
                };
                const inputChecks = getChecks(action.payload.groups);
                const stateChecks = getChecks(state.scheme?.groups ?? []);
                if (inputChecks.length !== stateChecks.length) {
                    return true;
                }
                for (let i = 0; i < inputChecks.length; i++) {
                    const serchCheck = stateChecks.find(
                        (sch) => sch.name === inputChecks[i].name
                    );
                    if (serchCheck) {
                        if (serchCheck.result !== inputChecks[i].result) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                return false;
            };
            if (isSchemeChanged()) {
                state.scheme = action.payload;
            }
        });
        // // workshoplabs
        builder.addCase(checkChangeSchemeWsThunk.fulfilled, (state, action) => {
            const isSchemeChanged = () => {
                const getChecks = (arg: ISchemeGroup[]) => {
                    return arg.reduce((acc, curr) => {
                        const checks = curr.information.healthchecks;
                        if (checks) {
                            acc = [...acc, ...checks];
                        }
                        return acc;
                    }, [] as IHealthChecks[]);
                };
                const inputChecks = getChecks(action.payload.groups);
                const stateChecks = getChecks(state.scheme?.groups ?? []);
                if (inputChecks.length !== stateChecks.length) {
                    return true;
                }
                for (let i = 0; i < inputChecks.length; i++) {
                    const serchCheck = stateChecks.find(
                        (sch) => sch.name === inputChecks[i].name
                    );
                    if (serchCheck) {
                        if (serchCheck.result !== inputChecks[i].result) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                return false;
            };
            if (isSchemeChanged()) {
                state.scheme = action.payload;
            }
        });
    },
});

export default schemeSlice.reducer;
