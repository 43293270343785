import {
    StyledFormula,
    StyledDivision,
    StyledSeparator,
    StyledDescription,
    StyledContentSection,
} from './styled';

export const Formula = () => {
    return (
        <StyledContentSection>
            <StyledFormula style={{ display: 'inline-flex' }}>
                <StyledDivision>
                    <StyledFormula>
                        <span>1</span>
                    </StyledFormula>
                    <StyledSeparator />
                    <StyledFormula>
                        <span>delta</span>
                        <span>*</span>
                        <span>(attemptNumber)</span>
                    </StyledFormula>
                </StyledDivision>
                <StyledFormula>
                    <span>*</span>
                    <span>500</span>
                    <span>*</span>
                    <span>difficulty</span>
                </StyledFormula>
            </StyledFormula>
            <StyledDescription>
                Where delta is the difference between your earliest detect and
                attack start time, and attemptNumber denotes many times you ran
                labs with the same difficulty and type.
            </StyledDescription>
        </StyledContentSection>
    );
};
