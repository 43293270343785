import { useAppSelector } from '@/hooks/redux';
import { LoaderDots } from '@/components';
import { NetworkCard } from './components';
import { StyledInformation } from './styled';
import { ConnectionContext } from './context/connectionContext';
import { StyledPageLoaderContainer } from '@/pages/styled';

export const Information = () => {
    const { scheme, fetching } = useAppSelector((state) => state.SchemeSlice);
    return (
        <ConnectionContext.Provider
            value={{ connections: scheme?.connections }}
        >
            <StyledInformation>
                {fetching ? (
                    <StyledPageLoaderContainer>
                        <LoaderDots />
                    </StyledPageLoaderContainer>
                ) : (
                    scheme?.groups.map((s) => <NetworkCard {...s} key={s.id} />)
                )}
            </StyledInformation>
        </ConnectionContext.Provider>
    );
};
