import { useMemo, useState } from 'react';

interface IProps {
    list?: any[];
    length?: number;
    lastVisibleElemIndex?: number;
}

export const useSlider = ({
    list: inputList = [],
    length = 3,
    lastVisibleElemIndex = 2,
}: IProps) => {
    // const initLength = () => {
    //     // if (inputLength > inputList.length || inputLength === 0) {
    //     //     return inputList.length;
    //     // }
    //     return inputLength;
    // };
    // const [length] = useState(initLength());
    const [lastIndex, setLastIndex] = useState(lastVisibleElemIndex);
    const isDisabled = () => {
        if (!inputList.length || length - 1 >= inputList.length - 1) {
            return true;
        }
        return false;
    };

    const isNextDisabled = () => {
        if (isDisabled() || lastIndex >= inputList.length - 1) {
            return true;
        }
        return false;
    };

    const list = useMemo(() => {
        // console.log('lastIndex: ', lastIndex);
        // console.log('length: ', length);

        return inputList.filter(
            (_, idx) => idx <= lastIndex && idx >= lastIndex - 2
        );
    }, [inputList, lastIndex, length]);

    const next = () => {
        if (!isNextDisabled()) {
            setLastIndex(lastIndex + 1);
            return;
        }
        setLastIndex(length - 1);
    };

    const isPrevDisabled = () => {
        if (isDisabled() || lastIndex <= length - 1) {
            return true;
        }
        return false;
    };

    const prev = () => {
        if (!isPrevDisabled()) {
            setLastIndex(lastIndex - 1);
            return;
        }
        setLastIndex(inputList.length - 1);
    };

    return {
        list,
        isDisabled: isDisabled(),
        isNexDisabled: isNextDisabled(),
        isPrevDisabled: isPrevDisabled(),
        next,
        prev,
    };
};
