import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const PlayIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.54242 2.11084C4.87549 1.93943 5.27642 1.96855 5.58124 2.18627L12.5812 7.18627C12.844 7.37398 13 7.67705 13 8C13 8.32296 12.844 8.62603 12.5812 8.81374L5.58124 13.8137C5.27642 14.0315 4.87549 14.0606 4.54242 13.8892C4.20935 13.7178 4 13.3746 4 13V3C4 2.62542 4.20935 2.28224 4.54242 2.11084ZM6 4.94319V11.0568L10.2795 8L6 4.94319Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5547 7.16795C10.2478 6.96338 9.8533 6.94431 9.52814 7.11833C9.20298 7.29235 9 7.63121 9 8V16C9 16.3688 9.20298 16.7077 9.52814 16.8817C9.8533 17.0557 10.2478 17.0366 10.5547 16.8321L16.5547 12.8321C16.8329 12.6466 17 12.3344 17 12C17 11.6656 16.8329 11.3534 16.5547 11.168L10.5547 7.16795ZM14.1972 12L11 14.1315V9.86852L14.1972 12Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
