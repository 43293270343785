import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    width: 174px;
    max-width: 174px;
    min-width: 174px;
`;

export const StyledIconContainer = styled.div`
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    border-radius: 100px;
    background: ${baseColors.white(0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledValueContainer = styled.div``;

export const StyledKey = styled.p`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledValue = styled.p`
    ${typography.title.L};
`;
