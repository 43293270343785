import styled from 'styled-components';
import { typography, baseColors, baseColorHover } from '@/constants';

export const StyledAccordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledItem = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
    background: ${baseColors.levelTwo()};
    overflow: hidden;
`;

export const StyleHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background: ${baseColorHover.levelTwo()};
    }
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const StyledTitle = styled.span`
    ${typography.title.M};
    text-transform: uppercase;
`;

export const StyledLoaderContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 96px 0;
`;
