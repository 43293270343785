import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ITask, IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IInitialState } from '../types';

export const getTasksWithoutLoaderThunk = createAsyncThunk<
    ITask[],
    string,
    { rejectValue: IError }
>(
    'taskSlice/getTasksWithoutLoaderThunk',
    async function (uuid, { rejectWithValue }) {
        try {
            const response = await apiClient.get(`/api/v1/labs/${uuid}/tasks/`);
            const data = await response.data.results;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const getTasksWithoutLoaderBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(getTasksWithoutLoaderThunk.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.fetching = false;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTasksWithoutLoaderThunk.rejected, (state, action) => {
        state.fetching = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};

export const getTasksWithoutLoadersWsThunk = createAsyncThunk<
    ITask[],
    string,
    { rejectValue: IError }
>(
    'taskSlice/getTasksWithoutLoadersWsThunk',
    async function (uuid, { rejectWithValue }) {
        try {
            const response = await apiWorkshopLabClient.get(
                `/api/v1/labs/${uuid}/tasks/`
            );
            const data = await response.data.results;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const getTasksWithoutLoadersWsBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(
        getTasksWithoutLoadersWsThunk.fulfilled,
        (state, action) => {
            state.tasks = action.payload;
            state.fetching = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        }
    );
    builder.addCase(getTasksWithoutLoadersWsThunk.rejected, (state, action) => {
        state.fetching = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};
