import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledWorkShop = styled.div`
    background: ${baseColors.levelOne()};
    width: 100%;
`;

export const StyledContainer = styled.div`
    width: 100%;
    height: calc(100% - 48px);
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px;
    gap: 8px;
`;
