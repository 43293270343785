import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const OkIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM10.7071 7.70711C11.0976 7.31658 11.0976 6.68342 10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.2929 6.29289L7.52571 8.06008L6.7319 7.20747C6.35556 6.80326 5.7228 6.78066 5.31859 7.15699C4.91437 7.53333 4.89177 8.16609 5.26811 8.57031L6.76811 10.1814C6.95305 10.3801 7.21079 10.495 7.48216 10.4998C7.75352 10.5047 8.0152 10.399 8.20711 10.2071L10.7071 7.70711Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M16.6971 10.717C17.0931 10.332 17.102 9.69891 16.717 9.30292C16.332 8.90694 15.6989 8.89802 15.3029 9.28301L10.8571 13.6053L8.69708 11.5052C8.30109 11.1202 7.66799 11.1292 7.28301 11.5251C6.89802 11.9211 6.90694 12.5542 7.30292 12.9392L10.1601 15.717C10.5482 16.0943 11.1661 16.0943 11.5542 15.717L16.6971 10.717Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
