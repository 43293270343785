import { createContext } from "react";

export enum TOAST_TYPE {
  SUCCESS,
  ERROR
}
export type ToastContextType = {
  isShow: boolean;
  text: string;
  title: string;
  setIsShow: Function;
  setText: Function;
  setTitle: Function;
  setType: Function;
  type: TOAST_TYPE;
  toast: Function;
};

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType
);
