import React, {
    useState,
    useRef,
    forwardRef,
    ChangeEvent,
    SyntheticEvent,
    useEffect,
} from 'react';
import { IconOpenedEye, IconClosedEye } from '../../Icons/index';
import { IconButton } from '../../IconButton/IconButton';
import {
    StyledInputWrapper,
    StyledInput,
    StyledLabel,
    StyledDescription,
    StyledInputContainer,
    StyledIconContainer,
} from './styled';
import { InputProps } from './types';

export const Input = forwardRef(
    (
        {
            cursor,
            value: innreValue = '',
            label,
            description,
            htmlFor,
            id,
            name,
            type,
            onChange,
            width,
            placeholder,
            readOnly,
            iconRight,
            onClickIconRight,
            disabled,
            style,
            ...props
        }: InputProps,
        ref: React.ForwardedRef<HTMLInputElement>
    ) => {
        const [value, setValue] = useState<string>(innreValue);
        const [isFocused, setIsFocused] = useState<boolean>(false);
        const [isHover, setIsHover] = useState<boolean>(false);
        const [isError, setIsError] = useState<boolean>(false);
        const [passMode, setPassMode] = useState<'text' | 'password'>(
            'password'
        );

        useEffect(() => {
            setValue(innreValue);
        }, [innreValue, setValue]);

        const changeHandler = (newValue: string) => {
            setValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        };

        const clickEyeHandler = (e: SyntheticEvent) => {
            e.preventDefault();
            if (passMode === 'password') {
                setPassMode('text');
                return;
            }
            setPassMode('password');
        };

        const clickIconHandler = (e: SyntheticEvent) => {
            e.preventDefault();
            if (onClickIconRight) onClickIconRight(e);
        };

        const inputRef = useRef<HTMLInputElement>(null);

        const renderInputPass = (
            <StyledInputContainer>
                <StyledInput
                    style={style}
                    disabled={disabled}
                    cursor={cursor}
                    readOnly={readOnly}
                    type={passMode}
                    id={id}
                    name={name}
                    ref={inputRef}
                    autoComplete="new-password"
                    value={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        changeHandler(e.target.value)
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    hover={isHover}
                    focus={isFocused}
                    placeholder={placeholder}
                />
                <StyledIconContainer>
                    <IconButton
                        icon={
                            passMode === 'password' ? (
                                <IconOpenedEye />
                            ) : (
                                <IconClosedEye />
                            )
                        }
                        onClick={(e) => clickEyeHandler(e)}
                    />
                </StyledIconContainer>
            </StyledInputContainer>
        );

        return (
            <StyledInputWrapper width={width}>
                {label && <StyledLabel htmlFor={htmlFor}>{label}</StyledLabel>}
                {type === 'password' ? (
                    renderInputPass
                ) : (
                    <StyledInputContainer>
                        <StyledInput
                            style={style}
                            disabled={disabled}
                            cursor={cursor}
                            readOnly={readOnly}
                            type={type}
                            id={id}
                            name={name}
                            ref={inputRef}
                            autoComplete="new-password"
                            value={value}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                changeHandler(e.target.value)
                            }
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                            hover={isHover}
                            focus={isFocused}
                            placeholder={placeholder}
                        />
                        {iconRight && (
                            <StyledIconContainer>
                                <IconButton
                                    icon={iconRight}
                                    onClick={(e) => clickIconHandler(e)}
                                />
                            </StyledIconContainer>
                        )}
                    </StyledInputContainer>
                )}
                {description && (
                    <StyledDescription>{description}</StyledDescription>
                )}
            </StyledInputWrapper>
        );
    }
);
