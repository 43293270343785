import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const RefreshIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M7.31352 1.29131C6.84583 0.997563 6.22857 1.13857 5.93483 1.60626C5.64108 2.07395 5.78209 2.69121 6.24978 2.98496L6.55326 3.17557C3.93844 3.82292 2 6.18508 2 8.99999C2 12.3137 4.68629 15 8 15C11.3137 15 14 12.3137 14 8.99999C14 8.4477 13.5523 7.99999 13 7.99999C12.4477 7.99999 12 8.4477 12 8.99999C12 11.2091 10.2091 13 8 13C5.79086 13 4 11.2091 4 8.99999C4 7.14046 5.26888 5.57729 6.98803 5.1291L6.80585 5.41917C6.5121 5.88686 6.65311 6.50412 7.1208 6.79787C7.58849 7.09161 8.20575 6.9506 8.4995 6.48291L9.97044 4.14095C10.2642 3.67326 10.1232 3.056 9.65549 2.76225L7.31352 1.29131Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M14.4382 5.66103C14.7842 5.23057 14.7157 4.60112 14.2853 4.25511L11.0692 1.66993C10.6388 1.32392 10.0093 1.39238 9.66328 1.82283C9.31727 2.25329 9.38573 2.88274 9.81618 3.22876L10.8735 4.07869C6.98864 4.62617 4 7.96407 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.82459 8.46673 6.22533 11.5885 6.01389L10.2942 7.62408C9.94818 8.05454 10.0166 8.684 10.4471 9.03001C10.8775 9.37602 11.507 9.30757 11.853 8.87711L14.4382 5.66103Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
