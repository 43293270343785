import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: -8px;
    background: ${baseColors.primary()};
    border-radius: 4px;
    z-index: 100;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    > div.section {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;
