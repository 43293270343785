import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { authSlice } from '@/store/reducers/AuthUserSlice';
import { fetchAuthThunk } from '@/store/reducers/AuthUserSlice/AsyncThunks';
import { Input } from '../../../../components/Input/Input/Input';
import { Button } from '../../../../components/Button/Button';
import { StyledFormContainer, StyledFieldsContainer } from '../../styled';
import { Alert } from '../../../../components/Alert/Alert';
import { VARIANT } from '../../../../components/Alert/types';
import { LoaderDots } from '../../../../components/Loader/LoaderDots/LoaderDots';
import { baseColors } from '../../../../constants/main';

export const SignInForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isLoading, error } = useAppSelector((state) => state.authReducer);
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');

    const clickHandler = () => {
        dispatch(fetchAuthThunk({ name: user, pass: pass, navigate }));
    };

    useEffect(() => {
        dispatch(
            authSlice.actions.authFetchingErrorAction({
                code: undefined,
                message: undefined,
            })
        );
    }, []);

    const isDisabled = user === '' || pass === '';

    return (
        <StyledFormContainer>
            <StyledFieldsContainer>
                {error.code !== undefined && (
                    <Alert
                        variant={VARIANT.ERROR}
                        code={error.code}
                        message={error.message}
                    />
                )}
                <Input
                    id="user"
                    name="user"
                    type="text"
                    label="User"
                    onChange={(e) => setUser(e)}
                />
                <Input
                    id="pass"
                    type="password"
                    label="Password"
                    onChange={(e) => setPass(e)}
                />
            </StyledFieldsContainer>
            {isLoading ? (
                <LoaderDots color={baseColors.primary()} />
            ) : (
                <Button disabled={isDisabled} onClick={clickHandler}>
                    Log in
                </Button>
            )}
        </StyledFormContainer>
    );
};
