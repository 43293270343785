import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledStep = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledStepTextContainer = styled.div`
    box-sizing: border-box;
    padding: 8px;
    margin-top: 4px;
`;

const text = css`
    ${typography.text.L};
    text-transform: uppercase;
    @media (max-width: 768px) {
        ${typography.text.M};
    }
`;

export const StyledText = styled.p`
    ${text};
    color: ${baseColors.white(0.5)};
    @media (max-width: 768px) {
        color: ${baseColors.white(0.5)};
    }
`;

export const StyledAccent = styled.span`
    ${text};
    color: ${baseColors.white()};
`;

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledStepsItemsContainer = styled.div`
    display: flex;
    height: 12px;
    box-sizing: border-box;
    padding-top: 6px;
`;

export const StyledStepItem = styled.img``;

export const StyledSideImage = styled.img``;

export const StyledMiddleImage = styled.div`
    width: 100%;
    height: 12px;
    display: flex;
    flex-direction: column;
    &::before {
        content: '';
        display: block;
        height: 6px;
        width: 100%;
        background: ${baseColors.white()};
    }
`;
