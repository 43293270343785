import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';
import { IHighlightData } from '@/types/highlights';
import { LAB_TYPE } from '@/constants/labs';
import { getFlagsThunk } from './';

export const addFlagThunk = createAsyncThunk<
    void,
    { uuid: string; basePath?: LAB_TYPE; data: IHighlightData },
    { rejectValue: IError }
>(
    'flagsSlice/addFlagThunk',
    async (
        { uuid, basePath = LAB_TYPE.DEFAULT, data },
        { rejectWithValue, dispatch }
    ) => {
        try {
            await apiClient.post(
                `/api/v1/${basePath}/${uuid}/add_highlight/`,
                data
            );
            await dispatch(getFlagsThunk({ uuid, basePath }));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
