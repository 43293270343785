import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IVM, IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { UuidWithBasePath } from './types/general';

interface IInitialState {
    vms: IVM[];
    isLoadingVM: boolean;
    errorVM: IError;
}

const initialState: IInitialState = {
    vms: [],
    isLoadingVM: false,
    errorVM: {
        code: undefined,
        message: undefined,
    },
};

export const getVM = createAsyncThunk<
    IVM[],
    UuidWithBasePath,
    { rejectValue: IError }
>('vmSlice/getVM', async ({ uuid, basePath }, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `/api/v1/${basePath}/${uuid}/vms/`,
            {
                requestName: 'getVM',
            }
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const checkVM = createAsyncThunk<
    IVM[],
    UuidWithBasePath,
    { rejectValue: IError }
>('vmSlice/checkVM', async ({ uuid, basePath }, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `/api/v1/${basePath}/${uuid}/vms/`,
            {
                requestName: 'checkVM',
            }
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const vmSlice = createSlice({
    name: 'vmSlice',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        getVMFetching(state) {
            // state.vms = [];
            state.isLoadingVM = true;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        },
        getVMSacces(state, action) {
            state.vms = action.payload;
            state.isLoadingVM = false;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        },
        getVMFailure(state, action) {
            state.isLoadingVM = false;
            state.errorVM = action.payload;
        },

        checkVMFetching(state) {
            state.isLoadingVM = true;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        },
        checkVMSacces(state, action: PayloadAction<IVM[]>) {
            state.isLoadingVM = false;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        },
        checkVMFailure(state, action) {
            state.isLoadingVM = false;
            state.errorVM = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getVM.pending, (state) => {
            // state.vms = [];
            state.isLoadingVM = true;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getVM.fulfilled, (state, action) => {
            state.vms = action.payload;
            state.isLoadingVM = false;
            state.errorVM = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getVM.rejected, (state, action) => {
            state.isLoadingVM = false;
            state.errorVM = action.payload as IError;
        });
    },
});

export default vmSlice.reducer;
