import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IInitialState } from '../types';
import { getTasksWithoutLoaderThunk, getTasksWithoutLoadersWsThunk } from '.';

export const checkTasksThunk = createAsyncThunk<
    void,
    { uuid: string; cardId: number },
    { rejectValue: IError }
>(
    'taskSlice/checkTasks',
    async function ({ uuid, cardId }, { rejectWithValue, dispatch }) {
        try {
            await apiClient.get(
                `/api/v1/labs/${uuid}/tasks/${cardId}/check_answer/`
            );
            dispatch(getTasksWithoutLoaderThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const checkTasksBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(checkTasksThunk.pending, (state) => {
        state.checkTaskState.fetching = true;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksThunk.fulfilled, (state) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksThunk.rejected, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error.code = action.payload?.code;
        state.checkTaskState.error.message = action.payload?.message;
    });
};

export const checkTasksWsThunk = createAsyncThunk<
    void,
    { uuid: string; cardId: number },
    { rejectValue: IError }
>(
    'taskSlice/checkTasksWs',
    async function ({ uuid, cardId }, { rejectWithValue, dispatch }) {
        try {
            await apiWorkshopLabClient.get(
                `/api/v1/labs/${uuid}/tasks/${cardId}/check_answer/`
            );
            dispatch(getTasksWithoutLoadersWsThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const checkTasksWsBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(checkTasksWsThunk.pending, (state) => {
        state.checkTaskState.fetching = true;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWsThunk.fulfilled, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(checkTasksWsThunk.rejected, (state, action) => {
        state.checkTaskState.fetching = false;
        state.checkTaskState.error.code = action.payload?.code;
        state.checkTaskState.error.message = action.payload?.message;
    });
};
