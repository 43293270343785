import { Icon } from "../Icon";

export const IconError = () => {
  return (
    <Icon
      content={
        <>
          <path
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill="white"
          />
          <path
            d="M9.52513 7.40381C8.93935 6.81802 7.9896 6.81802 7.40381 7.40381C6.81802 7.98959 6.81802 8.93934 7.40381 9.52513L9.87868 12L7.40381 14.4749C6.81802 15.0607 6.81802 16.0104 7.40381 16.5962C7.9896 17.182 8.93935 17.182 9.52513 16.5962L12 14.1213L14.4749 16.5962C15.0607 17.182 16.0104 17.182 16.5962 16.5962C17.182 16.0104 17.182 15.0607 16.5962 14.4749L14.1213 12L16.5962 9.52513C17.182 8.93934 17.182 7.98959 16.5962 7.40381C16.0104 6.81802 15.0607 6.81802 14.4749 7.40381L12 9.87868L9.52513 7.40381Z"
            fill="#CE224B"
          />
        </>
      }
    />
  );
};
