import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

const generateActiveTab = ({ $active }: { $active: boolean }) => {
    if ($active) {
        return css`
            background: ${baseColors.levelThree()};
            border-radius: 6px 6px 0 0;
        `;
    }
    return null;
};

export const StyledTab = styled.div<{ $active: boolean }>`
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    ${generateActiveTab}
`;

export const StyledTabTitle = styled.span`
    ${typography.title.S};
`;

export const StyledTabCounters = styled.div`
    display: flex;
    gap: 4px;
`;
