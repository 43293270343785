import { css } from 'styled-components';

interface IMedia {
    768: string;
    560: string;
    360: string;
}

export const generateMedia = (media: IMedia) => {
    return css`
        @media (max-width: 768px) {
            ${media[768]}
        }
        @media (max-width: 560px) {
            padding: 0 40px;
            ${media[560]}
        }
        @media (max-width: 360px) {
            ${media[360]}
        }
    `;
};
