import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { typography, baseColors } from '@/constants';

export const StyledHeader = styled.div`
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0 24px;
    box-sizing: border-box;
    background: ${baseColors.black(0.5)};
    @media (max-width: 560px) {
        height: 40px;
    }
`;

export const StyledNav = styled.div`
    display: flex;
    gap: 16px;
`;

export const StyledLink = styled(NavLink)`
    ${typography.text.M};
    text-transform: uppercase;
    text-decoration: none;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
    @media (max-width: 560px) {
        ${typography.text.S};
    }
`;
