import { useState, ReactNode, useContext, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { LAB_TYPE } from '@/constants';
import {
    addFlagThunk,
    addFlagWsThunk,
} from '@/store/reducers/FlagsSlice/AsyncThunks';
import {
    Select,
    Input,
    Button,
    VarIcon,
    DocIcon,
    WebIcon,
    UserIcon,
    Alert,
} from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { HIGHLIGHT_TYPE } from '@/constants';
import { ToastContext } from '@/components/Toast/ToastContext';
import { FlagsContext } from '../../context/flagsContext';
import { LabContext } from '@/pages/LabWithScheme/context/labContext';

export const AddFlag = () => {
    const { highlights, addHighlightState } = useAppSelector(
        (state) => state.FlagsSlice
    );

    const { fetching: addHighlightFetching, errors: errorAddHighlight } =
        addHighlightState;

    const dispatch = useAppDispatch();
    const { setIsShow, setTitle } = useContext(ToastContext);
    const { uuid, currentType } = useContext(FlagsContext);
    const { type: labType } = useContext(LabContext);

    const [hType, setHType] = useState<HIGHLIGHT_TYPE | undefined>(currentType);
    const [value, setValue] = useState<string>('');

    const isDisabled = !Boolean(hType) || value === '' || addHighlightFetching;

    const clickHandler = async () => {
        if (isDisabled || !hType || !uuid) return;
        try {
            const { meta } = await dispatch(
                labType === LAB_TYPE.DEFAULT
                    ? addFlagThunk({
                          uuid,
                          data: { type: hType, value: value },
                      })
                    : addFlagWsThunk({
                          uuid,
                          data: { type: hType, value: value },
                      })
            );
            if (meta.requestStatus === 'fulfilled') {
                setTitle('Flag has been added');
                setIsShow(true);
            }
        } catch (e) {}
    };

    const icons: { [key: HIGHLIGHT_TYPE | string]: ReactNode } = {
        [HIGHLIGHT_TYPE.ENVIRONMENTAL_VAR]: <VarIcon variant="S" />,
        [HIGHLIGHT_TYPE.FILE]: <DocIcon variant="S" />,
        [HIGHLIGHT_TYPE.URL]: <WebIcon variant="S" />,
        [HIGHLIGHT_TYPE.USER_NAME]: <UserIcon variant="S" />,
    };

    let arr: HIGHLIGHT_TYPE[] = [];
    const HLOptions: IDropdownMenuItem[] = highlights
        ? highlights.not_found.reduce((acc, curr) => {
              if (!arr.includes(curr.type)) {
                  acc = [
                      ...acc,
                      {
                          id: curr.type,
                          title: curr.type,
                          icon: icons[curr.type],
                      },
                  ];
                  arr.push(curr.type);
              }
              return acc;
          }, [] as IDropdownMenuItem[])
        : [];
    useEffect(() => {
        if (!hType) {
            setHType(currentType);
        }
    }, [hType, currentType]);

    return (
        <>
            {errorAddHighlight.code && (
                <Alert
                    variant={VARIANT.ERROR}
                    code={errorAddHighlight.code}
                    message={errorAddHighlight.message}
                />
            )}
            <Select
                label="Type"
                options={HLOptions}
                defaultValue={currentType}
                onChange={(i) => setHType(i.id as unknown as HIGHLIGHT_TYPE)}
            />
            <Input label="Value" onChange={(str) => setValue(str)} />
            <Button onClick={clickHandler} disabled={isDisabled}>
                Add flag
            </Button>
        </>
    );
};
