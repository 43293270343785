import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IHighlightData } from '@/types/highlights';
import { getFlagsWsThunk } from './';

export const addFlagWsThunk = createAsyncThunk<
    void,
    { uuid: string; data: IHighlightData },
    { rejectValue: IError }
>(
    'flagsSlice/addFlagWsThunk',
    async ({ uuid, data }, { rejectWithValue, dispatch }) => {
        try {
            await apiWorkshopLabClient.post(
                `/api/v1/workshoplabs/${uuid}/add_highlight/`,
                data
            );
            await dispatch(getFlagsWsThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
