import styled, { css, CSSProp } from 'styled-components';
import { typography } from '../../constants/main';

const gap = {
    M: css`
        gap: 8px;
    `,
    S: css`
        gap: 4px;
    `,
};

export const StyledIconButton = styled.button<{
    size: 'M' | 'S';
    $CSSProp?: CSSProp;
}>`
    background: none;
    height: fit-content;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    ${({ size }) => gap[size]};
    align-items: center;
    padding: 0px;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
    &:disabled {
        opacity: 0.25;
        &:hover {
            opacity: 0.25;
        }
    }
    ${({ $CSSProp }) => $CSSProp};
`;

export const StyledLabel = styled.span<{ size: 'M' | 'S'; $CSSProp?: CSSProp }>`
    ${({ size }) => typography.title[size]};
    white-space: nowrap;
    text-transform: uppercase;
    ${({ $CSSProp }) => $CSSProp};
`;
