export const getTimeRemaining = (endtime: string, today: string) => {
  const t = Date.parse(endtime) - Date.parse(today);
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export const setFormatTimer = (num: number) => {
  if (String(num).split("").length === 1) {
    return `0${num}`;
  }
  return String(num);
};
