import { Form, Field } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Button, IconLogo, Alert } from '@/components';
import { runWsTemplateThunk } from '@/store/reducers/TemplatesSlice/AsyncThanks';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    StyledContainer,
    StyledFormContainer,
    StyledRightCol,
    StyledLeftCol,
    StyledLogo,
    StyledLogoText,
    StyledTitleContainer,
    StyledTitleWrapper,
    StyledTitle,
    StyledSubtitle,
} from './styled';
import { required } from '@/utils/validate';

export const Invite = () => {
    const dispatch = useAppDispatch();
    const {
        runTemplateState: { error: runError },
    } = useAppSelector((state) => state.TemplatesSlice);
    const { code } = useParams();
    const navigate = useNavigate();

    interface IValues {
        name?: string;
        code?: string;
    }
    const initialValues: IValues = {
        name: undefined,
        code,
    };

    const submitHandler = (args: IValues) => {
        const { code: inviteCode, name } = args;
        if (inviteCode && name) {
            dispatch(
                runWsTemplateThunk({
                    code: inviteCode,
                    name,
                    gotToLab: navigate,
                })
            );
        }
    };
    return (
        <StyledContainer>
            <StyledLeftCol>
                <StyledLogo
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    <IconLogo size={32} />
                    <StyledLogoText>DefenseBox</StyledLogoText>
                </StyledLogo>
                <StyledTitleContainer>
                    <StyledTitleWrapper>
                        <StyledTitle>Start</StyledTitle>
                        <StyledSubtitle>your Lab</StyledSubtitle>
                    </StyledTitleWrapper>
                </StyledTitleContainer>
            </StyledLeftCol>
            <StyledRightCol>
                <Form
                    initialValues={initialValues}
                    validate={(checkValues) => {
                        return {
                            name: required(checkValues.name),
                            code: required(checkValues.code),
                        };
                    }}
                    onSubmit={submitHandler}
                    render={({ handleSubmit, errors, pristine }) => {
                        console.log(errors);
                        const isDisabled =
                            pristine || errors?.code || errors?.name;
                        return (
                            <StyledFormContainer onSubmit={handleSubmit}>
                                {runError.message && (
                                    <Alert
                                        code={runError.code}
                                        message={runError.message}
                                    />
                                )}
                                <Field name="name" id="name">
                                    {({ input: { onChange, value } }) => {
                                        return (
                                            <Input
                                                value={value}
                                                type="text"
                                                label="User name"
                                                onChange={onChange}
                                            />
                                        );
                                    }}
                                </Field>
                                {!code && (
                                    <Field name="code" id="code">
                                        {({ input: { onChange, value } }) => {
                                            return (
                                                <Input
                                                    value={value}
                                                    type="text"
                                                    label="Code"
                                                    onChange={onChange}
                                                />
                                            );
                                        }}
                                    </Field>
                                )}
                                <Button type="submit" disabled={isDisabled}>
                                    Run lab
                                </Button>
                            </StyledFormContainer>
                        );
                    }}
                />
            </StyledRightCol>
        </StyledContainer>
    );
};
