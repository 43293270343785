import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILabTeamplate } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';

export const getTemplateByIdThank = createAsyncThunk<
    ILabTeamplate,
    number,
    { rejectValue: IError }
>(
    'templateSlice/getTemplateByIdThank',
    async (templateId, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/labtemplates/${templateId}/`
            );
            const data = await response.data;

            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
