import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { IState } from '../types';
import { getProfile } from '../';

export const unsubscribeThunk = createAsyncThunk<
    { status: string },
    void,
    { rejectValue: IError }
>('profileSlice/unsubscribeThunk', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await apiClient.post('/api/crm/unsubscribe', {});
        if (response.status >= 200 && response.status < 300) {
            dispatch(getProfile());
        }
        return response.data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const unsubscribeBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(unsubscribeThunk.pending, (state) => {
        state.unsubscribeState.fetching = true;
        state.unsubscribeState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(unsubscribeThunk.fulfilled, (state) => {
        state.unsubscribeState.fetching = false;
        state.unsubscribeState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(unsubscribeThunk.rejected, (state, action) => {
        state.unsubscribeState.fetching = false;
        state.unsubscribeState.errors = action.payload as IError;
    });
};
