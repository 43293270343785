import { IInitialState } from './types';

export const initialState: IInitialState = {
    hint: undefined,
    messageAboutEmptyHint: undefined,
    openHintState: {
        fetching: false,
        error: {
            code: undefined,
            message: undefined,
        },
    },
    tasks: undefined,
    fetching: false,
    error: {
        code: undefined,
        message: undefined,
    },
    checkTaskState: {
        fetching: false,
        error: {
            code: undefined,
            message: undefined,
        },
    },
    hintState: {
        fetching: false,
        error: {
            code: undefined,
            message: undefined,
        },
    },
};
