import { Icon } from "../Icon";

interface IconOpenedEyeProps {
  figureFill?: string;
  bckgFill?: string;
  size?: number;
}

export const IconOpenedEye = (props: IconOpenedEyeProps) => {
  const { figureFill, bckgFill, size } = props;
  return (
    <Icon
      size={size}
      content={
        <>
          <path
            d="M22 12C22 12 19.5 19 12 19C4.5 19 2 12 2 12C2 12 4.5 5 12 5C19.5 5 22 12 22 12Z"
            fill="#817F89"
          />
          <path
            d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z"
            fill="white"
          />
          <path
            d="M10 12C11.1045 12 12 11.1046 12 10C12 9.64033 11.9048 9.30284 11.7388 9.01121C11.8247 9.00379 11.9121 9 12 9C13.6567 9 15 10.3431 15 12C15 13.6569 13.6567 15 12 15C10.3433 15 9 13.6569 9 12C9 11.912 9.00391 11.8249 9.01123 11.7389C9.30273 11.9051 9.64014 12 10 12Z"
            fill="#817F89"
          />
        </>
      }
    />
  );
};
