import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 64px 1fr;
    box-sizing: border-box;
    padding: 16px 64px 32px 16px;
    border-radius: 8px;
    gap: 16px;
    border: 1px solid ${baseColors.white(0.1)};
    background: ${baseColors.levelThree()};
`;

export const StyledImageContainer = styled.div`
    width: 64px;
    heigh: 64px;
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${baseColors.levelTwo()};
    border-radius: 200px;
    overflow: hidden;
    > img.userpic_inlncr {
        width: 64px;
        heighr: 64px;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    paddin-top: 12px;
`;

export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledName = styled.p`
    ${typography.title.L};
`;

export const StyledJobTitle = styled.p`
    ${typography.text.S};
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

export const StyledLabList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;
