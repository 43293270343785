import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { getWalkthroughsBuilder } from './AsyncThunks';

export const walkthroughsSlice = createSlice({
    name: 'walkthroughsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        getWalkthroughsBuilder(builder);
    },
});

export default walkthroughsSlice.reducer;
