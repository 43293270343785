import { AddIcon, MinusIcon, LoaderDots } from '@/components';
import {
    StyledAccordion,
    StyledItem,
    StyleHeader,
    StyledTitleContainer,
    StyledTitle,
    StyledLoaderContainer,
} from './styled';
import { IProps } from './types';

export const Accordion = ({
    fetching,
    openedItems = [],
    list = [],
    openItem,
    closeItem,
}: IProps) => {
    return (
        <StyledAccordion>
            {fetching ? (
                <StyledLoaderContainer>
                    <LoaderDots />
                </StyledLoaderContainer>
            ) : (
                list.map((i) => (
                    <StyledItem key={i.name}>
                        <StyleHeader
                            onClick={() => {
                                if (!openedItems.includes(i.name)) {
                                    if (openItem) openItem(i.name);
                                } else {
                                    if (closeItem) closeItem(i.name);
                                }
                            }}
                        >
                            <StyledTitleContainer>
                                {i.icon}
                                <StyledTitle>{i.title}</StyledTitle>
                            </StyledTitleContainer>
                            {openedItems.includes(i.name) ? (
                                <MinusIcon />
                            ) : (
                                <AddIcon />
                            )}
                        </StyleHeader>
                        {openedItems.includes(i.name) && i.content}
                    </StyledItem>
                ))
            )}
        </StyledAccordion>
    );
};
