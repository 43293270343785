import React, { SyntheticEvent } from 'react';
import { StyledIconButton, StyledLabel } from './styled';

interface IProps {
    disabled?: boolean;
    icon: React.ReactNode;
    onClick?: (e: SyntheticEvent) => void;
    label?: string;
}

export const IconButton = ({
    icon,
    onClick,
    disabled = false,
    label,
}: IProps) => {
    return (
        <StyledIconButton
            onClick={onClick && ((e) => onClick(e))}
            disabled={disabled}
        >
            {label && <StyledLabel>{label}</StyledLabel>}
            {icon}
        </StyledIconButton>
    );
};
