import { SyntheticEvent } from 'react';
import { StyledMenuItemButton } from '../../styled';
import { ExitIcon } from '@/components';

interface IProps {
    cut?: boolean;
    onExit?: (e: SyntheticEvent) => void;
}

export const ItemMenuExit = ({ cut, onExit }: IProps) => {
    return (
        <StyledMenuItemButton
            as={'div'}
            onClick={(e) => {
                if (onExit) onExit(e);
            }}
        >
            <div className="item" style={{ fontSize: 14 }}>
                <div className="titleWrapper">
                    <ExitIcon />
                    {!cut && <p>Exit</p>}
                </div>
            </div>
        </StyledMenuItemButton>
    );
};
