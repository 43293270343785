import { css, CSSProp } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ILabTeamplate } from '@/types/labs';
import {
    Button,
    ClockIcon,
    VMIcon,
    StarIcon,
    PlayIcon,
} from '../../../components';
import { ICard, SizeType, DirectionType } from '../types';
import { SHAPE } from '../../../components/Button/types';
import { baseColors } from '../../../constants/main';
import {
    StyledMainContainer,
    StyledCard,
    StyledImageContainer,
    StyledBlockTextContainer,
    StyledBlockTextHeader,
    StyledTitle,
    StyledSubtitle,
    StyledBlockTextFooter,
    StyledConfigList,
    StyledConfigItem,
    StyledConfigWrapper,
    StyledConfigKey,
    StyledConfigValue,
} from '../styled';
// import { VMTerminal } from '../../LabLaunch/components/VMTerminal';

interface IGetImageCardArg {
    direction: DirectionType;
    size: SizeType;
    path: string;
}

type GetImageCardType = (arg: IGetImageCardArg) => CSSProp | undefined;

const getImageCard: GetImageCardType = (arg) => {
    const { size, path } = arg;
    switch (size) {
        case 'M':
            return css`
                background-image: url(${path});
                background-color: ${baseColors.levelTwo()};
                background-position: right;
                background-repeat: no-repeat;
            `;
        default:
            return undefined;
    }
};

export const Card = ({ direction = 'left', templates, top, click }: ICard) => {
    const navigate = useNavigate();
    const isTop = false;
    const shapeButton: SHAPE = isTop ? SHAPE.PRIMARY : SHAPE.SECONDARY;
    const renderBlockText = (template: ILabTeamplate, size: SizeType) => {
        return (
            <StyledBlockTextContainer>
                <StyledBlockTextHeader>
                    <StyledTitle size={size}>{template.name}</StyledTitle>
                    <StyledSubtitle size={size}>
                        {template.description}
                    </StyledSubtitle>
                </StyledBlockTextHeader>
                <StyledBlockTextFooter>
                    <StyledConfigList>
                        {template.active_labs > 0 ? (
                            <StyledConfigItem>
                                <StyledConfigWrapper typeOfWrap="value">
                                    <PlayIcon
                                        variant="S"
                                        fill={baseColors.error()}
                                    />
                                    <StyledConfigKey
                                        typeOfWrap="value"
                                        style={{ color: baseColors.error() }}
                                    >
                                        There are active labs
                                    </StyledConfigKey>
                                </StyledConfigWrapper>
                            </StyledConfigItem>
                        ) : null}

                        <StyledConfigItem>
                            <StyledConfigWrapper typeOfWrap="value">
                                <StarIcon variant="S" />
                                <StyledConfigKey typeOfWrap="value">
                                    Premium lab
                                </StyledConfigKey>
                                <StyledConfigValue>
                                    {template.is_premium ? 'Yes' : 'No'}
                                </StyledConfigValue>
                            </StyledConfigWrapper>
                        </StyledConfigItem>
                        <StyledConfigItem>
                            <StyledConfigWrapper typeOfWrap="value">
                                <PlayIcon variant="S" />
                                <StyledConfigKey typeOfWrap="value">
                                    I can run
                                </StyledConfigKey>
                                <StyledConfigValue>
                                    {template.you_can_run ? 'Yes' : 'No'}
                                </StyledConfigValue>
                            </StyledConfigWrapper>
                        </StyledConfigItem>
                        <StyledConfigItem>
                            <StyledConfigWrapper typeOfWrap="value">
                                <VMIcon variant="S" />
                                <StyledConfigKey typeOfWrap="value">
                                    Number of VM
                                </StyledConfigKey>
                                <StyledConfigValue>
                                    {template.nodes.length}
                                </StyledConfigValue>
                            </StyledConfigWrapper>
                        </StyledConfigItem>
                        <StyledConfigItem>
                            <StyledConfigWrapper typeOfWrap="value">
                                <ClockIcon variant="S" />
                                <StyledConfigWrapper typeOfWrap="list">
                                    <span>Startup time</span>
                                    <StyledConfigWrapper typeOfWrap="value">
                                        <StyledConfigKey typeOfWrap="list">
                                            Without elastic
                                        </StyledConfigKey>
                                        <StyledConfigValue>
                                            {
                                                template.average_deployment_time_no_siem
                                            }
                                        </StyledConfigValue>
                                    </StyledConfigWrapper>
                                    <StyledConfigWrapper typeOfWrap="value">
                                        <StyledConfigKey typeOfWrap="list">
                                            With elastic
                                        </StyledConfigKey>
                                        <StyledConfigValue>
                                            {
                                                template.average_deployment_time_siem
                                            }
                                        </StyledConfigValue>
                                    </StyledConfigWrapper>
                                </StyledConfigWrapper>
                            </StyledConfigWrapper>
                        </StyledConfigItem>
                    </StyledConfigList>
                    <Button
                        view={shapeButton}
                        onClick={() => {
                            // navigate(String(template.id));
                            // dispatch(getTemplateById(Number(template.id)));
                            // dispatch(
                            //     getLabThank({
                            //         labName: template.name,
                            //         navigate,
                            //     })
                            // );
                            navigate(String(template.id));
                        }}
                    >
                        View
                    </Button>
                </StyledBlockTextFooter>
            </StyledBlockTextContainer>
        );
    };

    if (templates.length === 1) {
        const textSize: SizeType = isTop ? 'L' : 'M';
        return (
            <StyledCard size="L" direction={direction}>
                {direction === 'left' ? (
                    <>
                        <StyledImageContainer
                            style={{
                                backgroundImage: `url(${templates[0].picture_lg})`,
                            }}
                        ></StyledImageContainer>
                        {renderBlockText(templates[0], textSize)}
                    </>
                ) : (
                    <>
                        {renderBlockText(templates[0], textSize)}
                        <StyledImageContainer
                            style={{
                                backgroundImage: `url(${templates[0].picture_lg})`,
                            }}
                        ></StyledImageContainer>
                    </>
                )}
            </StyledCard>
        );
    }
    const sizes: SizeType[] = direction === 'left' ? ['M', 'S'] : ['S', 'M'];

    return (
        <StyledMainContainer direction={direction}>
            <StyledCard
                size={sizes[0]}
                bckg={getImageCard({
                    direction,
                    size: sizes[0],
                    path:
                        sizes[0] === 'M'
                            ? (templates[0].picture_md as string)
                            : 'not_pictute',
                })}
            >
                {direction === 'left' ? (
                    <>{renderBlockText(templates[0], sizes[0])}</>
                ) : (
                    <>
                        {sizes[0] === 'M' && (
                            <StyledImageContainer></StyledImageContainer>
                        )}
                        {renderBlockText(templates[0], sizes[0])}
                    </>
                )}
            </StyledCard>
            <StyledCard
                size={sizes[1]}
                bckg={getImageCard({
                    direction,
                    size: sizes[1],
                    path:
                        sizes[1] === 'M'
                            ? (templates[1].picture_md as string)
                            : 'not_pictute',
                })}
            >
                {direction === 'left'
                    ? renderBlockText(templates[1], sizes[1])
                    : renderBlockText(templates[1], sizes[1])}
            </StyledCard>
        </StyledMainContainer>
    );
};
