import { nanoid } from '@reduxjs/toolkit';
import left_image from './img/step_image_left.svg';
import right_image from './img/step_image_right.svg';
import step_item from './img/step_image_item.svg';
import {
    StyledStep,
    StyledHeader,
    StyledStepsItemsContainer,
    StyledStepItem,
    StyledSideImage,
    StyledMiddleImage,
    StyledText,
    StyledAccent,
    StyledStepTextContainer,
} from './styled';
import { IStep } from '../../types';

export const Step = ({ text, step }: IStep) => {
    return (
        <StyledStep>
            <StyledHeader>
                <StyledStepsItemsContainer>
                    {Array(step)
                        .fill('')
                        .map(() => (
                            <StyledStepItem src={step_item} key={nanoid()} />
                        ))}
                </StyledStepsItemsContainer>
                <StyledSideImage src={left_image} />
                <StyledMiddleImage />
                <StyledSideImage src={right_image} />
            </StyledHeader>
            <StyledStepTextContainer>
                <StyledText>
                    {text.map((t) => {
                        if (t.type === 'accent') {
                            return (
                                <StyledAccent key={nanoid()}>
                                    {t.text}
                                </StyledAccent>
                            );
                        }
                        return <>{t.text}</>;
                    })}
                </StyledText>
            </StyledStepTextContainer>
        </StyledStep>
    );
};
