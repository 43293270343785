import React, {
    useState,
    useRef,
    forwardRef,
    ChangeEvent,
    SyntheticEvent,
    useEffect,
  } from "react";
  import { IconOpenedEye, IconClosedEye } from "../../Icons/index";
  import { IconButton } from "../../IconButton/IconButton";
  import {
    StyledTextareaWrapper,
    StyledTextarea,
    StyledLabel,
    StyledDescription,
    StyledInputContainer,
    StyledIconContainer,
  } from "./styled";
  import type { TextareaProps } from "./types";
  
  export const Textarea = forwardRef(
    (
      {
        cursor,
        value: innreValue = "",
        label,
        description,
        htmlFor,
        id,
        name,
        onChange,
        width,
        placeholder,
        readOnly,
        iconRight,
        onClickIconRight,
        ...props
      }: TextareaProps,
      ref: React.ForwardedRef<HTMLInputElement>
    ) => {
      const [value, setValue] = useState<string>(innreValue);
      const [isFocused, setIsFocused] = useState<boolean>(false);
      const [isHover, setIsHover] = useState<boolean>(false);
  
      useEffect(() => {
        setValue(innreValue);
      }, [innreValue, setValue]);
  
      const changeHandler = (newValue: string) => {
        setValue(newValue);
        if (onChange) {
          onChange(newValue);
        }
      };
  
      const clickIconHandler = (e: SyntheticEvent) => {
        e.preventDefault();
        if (onClickIconRight) onClickIconRight(e);
      };
  
      const inputRef = useRef<HTMLInputElement>(null);
  
      return (
        <StyledTextareaWrapper width={width}>
          {label && <StyledLabel htmlFor={htmlFor}>{label}</StyledLabel>}
          <StyledInputContainer>
            <StyledTextarea
              cursor={cursor}
              readOnly={readOnly}
              id={id}
              name={name}
              ref={inputRef}
              autoComplete="new-password"
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                changeHandler(e.target.value)
              }
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
              hover={isHover}
              focus={isFocused}
              placeholder={placeholder}
            />
            {iconRight && (
              <StyledIconContainer>
                <IconButton
                  icon={iconRight}
                  onClick={(e) => clickIconHandler(e)}
                />
              </StyledIconContainer>
            )}
          </StyledInputContainer>
          {description && <StyledDescription>{description}</StyledDescription>}
        </StyledTextareaWrapper>
      );
    }
  );
  