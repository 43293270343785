import styled from "styled-components";
import { baseColors } from "../../constants/main";
import { typography } from "../../constants/main";

export const StyledSlider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledLabel = styled.p`
  ${typography.text.S};
`;

export const StyledDescription = styled.p`
  ${typography.text.S};
  opacity: 0.4;
`;

export const StyledList = styled.div`
  display: flex;
  position: relative;
`;

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;
`;

export const StyledSelectedItemsLine = styled.div<{
  indexItem: number;
  color?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: ${({ indexItem }) => indexItem * 36}px;
  background-color: ${({ color }) => (color ? color : baseColors.primary())};
  border-radius: 64px;
`;

export const StyledHoverItemsLine = styled.div<{
  indexItem?: number;
  color?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  height: 36px;
  width: ${({ indexItem }) => (indexItem ? indexItem * 36 : 0)}px;
  background-color: ${({ color }) =>
    color ? color : "rgba(255, 255, 255, 0.025)"};
  border-radius: 64px;
`;
