import { useEffect, useMemo } from 'react';
import { LoaderDots } from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getWorkshopListThunk } from '@/store/reducers/WorkshopSlice/AsyncThunks';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { WorkshopCard, Header } from './components';
import { StyledContainer, StyledWorkShop } from './styled';

export const Workshop = () => {
    const dispatch = useAppDispatch();
    const {
        workshopList,
        workshopListState: { fetching: getWorkshopFetching },
        workshopCreateState: { fetching: createWorkshopFetching },
    } = useAppSelector((store) => store.WorkshopWithShemeSlice);

    useEffect(() => {
        dispatch(getWorkshopListThunk());
    }, [dispatch]);

    const fetching = getWorkshopFetching || createWorkshopFetching;

    const list = useMemo(() => {
        if (workshopList) return [...workshopList].reverse();
        return [];
    }, [workshopList]);
    return (
        <StyledWorkShop>
            <Header />
            {fetching ? (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            ) : (
                <StyledContainer>
                    {list.map((w, idx) => {
                        return (
                            <WorkshopCard
                                workshop={w}
                                isOpened={idx === 0}
                                key={w.id}
                            />
                        );
                    })}
                </StyledContainer>
            )}
        </StyledWorkShop>
    );
};
