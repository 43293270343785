import { createSlice } from '@reduxjs/toolkit';
import {
    getTasksBuilder,
    getTasksWsBuilder,
    getTasksWithoutLoaderBuilder,
    getTasksWithoutLoadersWsBuilder,
    checkTasksBuilder,
    checkTasksWsBuilder,
    checkTasksWithFlagBuilder,
    checkTasksWithFlagWsBuilder,
    getHintBuilder,
    openHintBuilder,
} from './AsyncThunks';
import { initialState } from './constants';

export const taskSlice = createSlice({
    name: 'taskSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        getTasksBuilder(builder);
        getTasksWsBuilder(builder);
        checkTasksBuilder(builder);
        checkTasksWsBuilder(builder);
        checkTasksWithFlagBuilder(builder);
        checkTasksWithFlagWsBuilder(builder);
        openHintBuilder(builder);
        getHintBuilder(builder);
        getTasksWithoutLoaderBuilder(builder);
        getTasksWithoutLoadersWsBuilder(builder);
    },
});

export default taskSlice.reducer;
