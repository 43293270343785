export const headers = [
    {
        title: 'Student name',
        width: 160,
    },
    {
        title: 'Attempts',
        width: 96,
    },
    {
        title: 'Points',
        width: 80,
    },
    {
        title: 'Link',
        width: 240,
    },
];
