import styled from "styled-components";
import { baseColors, typography } from "../../constants/main";

export const StyledCollapse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: ${baseColors.board()};
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 8px;
  width: 100%;
`;

export const StyledHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
  width: 100%;
`;

export const StyledTitle = styled.span`
  ${typography.text.M};
  font-weight: 700;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 48px 24px;
  width: 100%;
  box-sizing: border-box;
`;
