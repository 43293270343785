import { isAxiosError } from 'axios';
import { IHealthChecks } from '@/types/healthChecks';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types/error';
import { UuidWithBasePath } from './types/general';

interface IInitialState {
    healthChecks?: IHealthChecks[];
    isLoadingHealthChecks: boolean;
    errorHealthChecks: IError;
}

const initialState: IInitialState = {
    healthChecks: undefined,
    isLoadingHealthChecks: false,
    errorHealthChecks: {
        code: undefined,
        message: undefined,
    },
};

export const getHealthChecks = createAsyncThunk<
    IHealthChecks[],
    UuidWithBasePath,
    { rejectValue: IError }
>('healthChecksSlice/getHealthChecks', async ({uuid, basePath}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `api/v1/${basePath}/${uuid}/healthchecks`,
            {
                requestName: 'getHealthChecks',
            }
        );

        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const healthChecksSlice = createSlice({
    name: 'healthChecksSlice',
    initialState,
    reducers: {
        resetHealthChecks(state) {
            state.healthChecks = undefined;
            state.isLoadingHealthChecks = false;
            state.errorHealthChecks = {
                code: undefined,
                message: undefined,
            };
        },
        getHealthChecksFetching(state) {
            state.isLoadingHealthChecks = true;
            state.errorHealthChecks = {
                code: undefined,
                message: undefined,
            };
        },
        getHealthChecksSaccess(state, action) {
            state.healthChecks = action.payload;
            state.isLoadingHealthChecks = false;
            state.errorHealthChecks = {
                code: undefined,
                message: undefined,
            };
        },
        getHealthChecksFailure(state, action) {
            state.isLoadingHealthChecks = false;
            state.errorHealthChecks = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(getHealthChecks.pending, (state) => {
            state.isLoadingHealthChecks = true;
            state.errorHealthChecks = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getHealthChecks.fulfilled, (state, action) => {
            state.healthChecks = action.payload;
            state.isLoadingHealthChecks = false;
            state.errorHealthChecks = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getHealthChecks.rejected, (state, action) => {
            state.isLoadingHealthChecks = false;
            state.errorHealthChecks = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
    },
});

export default healthChecksSlice.reducer;
