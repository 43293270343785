import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const FlagIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4 1C4.55228 1 5 1.44772 5 2L14 2C14.381 2 14.7288 2.21647 14.8971 2.55825C15.0654 2.90003 15.0249 3.30775 14.7926 3.60971L12.9539 6L14.7926 8.39029C15.0249 8.69225 15.0654 9.09997 14.8971 9.44175C14.7288 9.78353 14.381 10 14 10H5L5 13H6C6.55228 13 7 13.4477 7 14C7 14.5523 6.55228 15 6 15H2C1.44772 15 1 14.5523 1 14C1 13.4477 1.44772 13 2 13H3L3 2C3 1.44772 3.44772 1 4 1ZM5 8L5 4H11.9691L10.8997 5.39029C10.6232 5.74974 10.6232 6.25026 10.8997 6.60971L11.9691 8H5Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 2C6.55228 2 7 2.44772 7 3L20 3C20.3662 3 20.7032 3.20019 20.8783 3.52183C21.0534 3.84348 21.0387 4.23512 20.8399 4.54272L17.9598 9L20.8399 13.4573C21.0387 13.7649 21.0534 14.1565 20.8783 14.4782C20.7032 14.7998 20.3662 15 20 15H7L7 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20H5L5 3C5 2.44772 5.44772 2 6 2ZM7 13L7 5H18.1633L15.9293 8.45728C15.7159 8.78762 15.7159 9.21238 15.9293 9.54272L18.1633 13H7Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
