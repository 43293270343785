import { useMemo, useContext, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { unsubscribeThunk } from '@/store/reducers/ProfileSlice/AsyncThunk';
import {
    IconButton,
    DropdownMenu,
    MoreIcon,
    PopUp,
    ChangeUsername,
    ChangePassword,
} from '@/components';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { ToastContext } from '@/components/Toast/ToastContext';

export const PersonMenu = () => {
    const {
        profile,
        unsubscribeState: { fetching },
    } = useAppSelector((state) => state.ProfileSlice);
    const { setIsShow, setTitle } = useContext(ToastContext);
    const [isOpenPopUp, setIsOpenPopUp] = useState(false);
    const [modalName, setModalName] = useState<
        'changeName' | 'changePassword' | undefined
    >(undefined);
    const dispatch = useAppDispatch();

    const options = useMemo<IDropdownMenuItem[]>(() => {
        if (profile?.is_premium) {
            return [
                // { id: 0, title: 'Edit userpic' },
                { id: 1, title: 'Unsubscribe' },
                { id: 2, title: 'Change username' },
                { id: 3, title: 'Change password' },
            ];
        }
        return [
            // { id: 0, title: 'Edit userpic' },
            { id: 2, title: 'Change username' },
            { id: 3, title: 'Change password' },
        ];
    }, [profile]);

    const getModalForm = () => {
        switch (modalName) {
            case 'changeName':
                return <ChangeUsername onClose={() => setIsOpenPopUp(false)} />;
            case 'changePassword':
                return <ChangePassword onClose={() => setIsOpenPopUp(false)} />;
            default:
                return null;
        }
    };

    return (
        <>
            <PopUp opened={isOpenPopUp}>{getModalForm()}</PopUp>
            <DropdownMenu
                flip
                disabled={fetching}
                list={options}
                styleContainer={{
                    width: '160px',
                }}
                onClick={(optId) => {
                    if (optId === 3) {
                        setIsOpenPopUp(true);
                        setModalName('changePassword');
                        return;
                    }
                    if (optId === 2) {
                        setIsOpenPopUp(true);
                        setModalName('changeName');
                        return;
                    }
                    if (optId === 1) {
                        const unsubscribe = async () => {
                            const { payload } = await dispatch(
                                unsubscribeThunk()
                            );
                            console.log(payload);
                            if (payload && 'status' in payload) {
                                setTitle(payload.status);
                                setIsShow(true);
                            }
                        };
                        unsubscribe();
                    }
                    return;
                }}
            >
                <IconButton
                    disabled={fetching}
                    size="M"
                    iconLeft={<MoreIcon />}
                />
            </DropdownMenu>
        </>
    );
};
