import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import { IError, IScheme } from '@/types';
import { LAB_TYPE } from '@/constants';

export const getSchemeThank = createAsyncThunk<
    IScheme,
    { uuid: string; basePath?: LAB_TYPE },
    { rejectValue: IError }
>(
    'schemeSlice/getSchemeThank',
    async ({ uuid, basePath = LAB_TYPE.DEFAULT }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/${basePath}/${uuid}/schema/`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
