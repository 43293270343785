import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { INewsListResponseData, IState } from '../types';

export const getNewsThunk = createAsyncThunk<
    INewsListResponseData,
    void,
    { rejectValue: IError }
>('newsSlice/getNewsThunk', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/news/`);
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.status,
            });
        }
    }
});

export const getNewsBuilder = (builder: ActionReducerMapBuilder<IState>) => {
    builder.addCase(getNewsThunk.pending, (state) => {
        state.newsListState.fetching = true;
        state.newsListState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getNewsThunk.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.newsListState = {
            ...action.payload,
            data: results,
            fetching: false,
            errors: {
                code: undefined,
                message: undefined,
            },
        };
    });
    builder.addCase(getNewsThunk.rejected, (state, action) => {
        state.newsListState = {
            ...state.newsListState,
            fetching: false,
            errors: action.payload as IError,
        };
    });
};
