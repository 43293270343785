export enum WIZARD_TABS_NAME {
  SET_UP_THE_ELASTIC = "Set up the elastic",
  TO_EXPOSE_ELASTIC = "To expose elastic",
  DEPLOY_THE_ELASTIC = "Deploy the elastic",
  CREATE_LAB = "Create lab",
}

export const CreateLabWizardTabs = [
  {
    text: WIZARD_TABS_NAME.SET_UP_THE_ELASTIC,
    id: 0,
    isActive: true,
  },
  {
    text: WIZARD_TABS_NAME.TO_EXPOSE_ELASTIC,
    id: 1,
    isActive: false,
  },
  {
    text: WIZARD_TABS_NAME.DEPLOY_THE_ELASTIC,
    id: 2,
    isActive: false,
  },
  {
    text: WIZARD_TABS_NAME.CREATE_LAB,
    id: 3,
    isActive: false,
    disabled: true,
    textForDisabled: "Need to check status",
  },
];
