import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const CrownIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.80004 1.4C8.61119 1.14819 8.3148 1 8.00004 1C7.68529 1 7.3889 1.14819 7.20004 1.4L4.76764 4.64321L2.55474 3.16795C2.21902 2.94413 1.78168 2.94401 1.44583 3.16763C1.10997 3.39125 0.941414 3.7948 1.01843 4.19087L2.18509 10.1909C2.27646 10.6608 2.68802 11 3.16671 11H12.5C12.9589 11 13.3589 10.6877 13.4702 10.2425L14.9702 4.24254C15.0704 3.84173 14.9138 3.42057 14.5761 3.18258C14.2384 2.9446 13.7891 2.93878 13.4453 3.16795L11.2325 4.64321L8.80004 1.4ZM5.80004 6.6L8.00004 3.66667L10.2 6.6C10.5185 7.02459 11.1131 7.12645 11.5547 6.83205L12.4026 6.26684L11.7193 9H3.99099L3.439 6.16115L4.44534 6.83205C4.88694 7.12645 5.4816 7.02459 5.80004 6.6Z"
                            fill={fill}
                        />
                        <path
                            d="M4 13C3.44772 13 3 13.4477 3 14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13H4Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.8683 2.50386C12.6902 2.19229 12.3589 2 12 2C11.6412 2 11.3098 2.19229 11.1318 2.50386L7.6386 8.61695L3.51454 6.14251C3.17513 5.93886 2.74754 5.95457 2.42399 6.18258C2.10044 6.41059 1.94183 6.80799 2.01946 7.19612L4.01946 17.1961C4.11294 17.6635 4.52336 18 5.00004 18H19C19.4767 18 19.8871 17.6635 19.9806 17.1961L21.9806 7.19612C22.0582 6.80799 21.8996 6.41059 21.5761 6.18258C21.2525 5.95457 20.825 5.93886 20.4855 6.14251L16.3615 8.61695L12.8683 2.50386ZM8.86828 10.4961L12 5.01556L15.1318 10.4961C15.2652 10.7296 15.487 10.8995 15.7472 10.9675C16.0074 11.0355 16.284 10.9958 16.5145 10.8575L19.5761 9.02054L18.1802 16H5.81984L4.42395 9.02054L7.48554 10.8575C7.71613 10.9958 7.99271 11.0355 8.25288 10.9675C8.51305 10.8995 8.73487 10.7296 8.86828 10.4961Z"
                            fill={fill}
                        />
                        <path
                            d="M5 20C4.44772 20 4 20.4477 4 21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21C20 20.4477 19.5523 20 19 20H5Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
