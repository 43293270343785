import { Icon } from "../Icon";

export const IconOk = () => {
  return (
    <Icon
      content={
        <>
          <path
            d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0605 8.93933C17.6463 9.5251 17.6464 10.4749 17.0606 11.0606L12.0607 16.0606C11.7794 16.3419 11.3979 16.5 11.0001 16.5C10.6022 16.5 10.2207 16.342 9.93939 16.0607L6.93935 13.0607C6.35356 12.4749 6.35355 11.5251 6.93933 10.9393C7.52511 10.3535 8.47486 10.3535 9.06065 10.9393L11 12.8787L14.9392 8.93935C15.525 8.35356 16.4748 8.35355 17.0605 8.93933Z"
            fill="white"
          />
        </>
      }
    />
  );
};
