import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const TelegramIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M14.9574 3.92797L12.8508 12.3969C12.7049 12.9979 12.2789 13.1419 11.6837 12.8638L8.47425 10.8422L6.92204 12.1187C6.74698 12.2677 6.60109 12.3969 6.28014 12.3969C5.86 12.3969 5.93002 12.2628 5.78997 11.925L4.68125 8.85036L1.50096 8.00596C0.812382 7.83211 0.806546 7.42977 1.65268 7.13672L14.0587 3.0637C14.6248 2.85011 15.1674 3.18291 14.9515 3.92301L14.9574 3.92797Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M21.9391 5.48476L18.9297 19.035C18.7213 19.9966 18.1128 20.2271 17.2625 19.782L12.6775 16.5475L10.4601 18.5899C10.21 18.8284 10.0016 19.035 9.54306 19.035C8.94285 19.035 9.04289 18.8204 8.84282 18.28L7.25892 13.3606L2.71565 12.0095C1.73197 11.7314 1.72364 11.0876 2.9324 10.6187L20.6553 4.10192C21.4639 3.76018 22.2392 4.29265 21.9308 5.47681L21.9391 5.48476Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
