import { createSlice } from '@reduxjs/toolkit';
import { IState } from './types';
import {
    getProgressLabsExtraReducer,
    getTracksExtraReducer,
    getInfluencerLabsBuilder,
} from './AsyncThunks';

const initialState: IState = {
    influencerLabState: {
        data: undefined,
        count: undefined,
        next: undefined,
        previous: undefined,
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    tracksState: {
        data: undefined,
        count: undefined,
        next: undefined,
        previous: undefined,
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    progressLabState: {
        data: undefined,
        count: undefined,
        next: undefined,
        previous: undefined,
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
};

export const labListsSlice = createSlice({
    name: 'labListsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        getInfluencerLabsBuilder(builder);
        getProgressLabsExtraReducer(builder);
        getTracksExtraReducer(builder);
    },
});

export default labListsSlice.reducer;
