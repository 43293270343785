import { nanoid } from 'nanoid';
import { NoData, LoaderDots, Tooltip } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { IAchievement } from '@/types';
import {
    StyledContainer,
    StyledRow,
    StyledLabel,
    StyledContent,
    StyledAchievement,
    StyledEmptyAchievement,
} from './styled';

export const AchievementList = () => {
    const { profile, isLoadingProfile } = useAppSelector(
        (state) => state.ProfileSlice
    );
    const renderAchievements = (
        label: string,
        achieves?: IAchievement[] | null,
        isSecondary?: boolean
    ) => {
        return (
            <StyledRow>
                <StyledLabel>{label}</StyledLabel>
                <StyledContent>
                    {achieves ? (
                        achieves.map((a) => {
                            const { title, picture } = a;
                            return (
                                <Tooltip key={nanoid()} text={title}>
                                    <>
                                        {picture ? (
                                            <StyledAchievement
                                                style={
                                                    isSecondary
                                                        ? { opacity: 0.25 }
                                                        : undefined
                                                }
                                                key={nanoid()}
                                                src={picture}
                                            />
                                        ) : (
                                            <StyledEmptyAchievement
                                                key={nanoid()}
                                            />
                                        )}
                                    </>
                                </Tooltip>
                            );
                        })
                    ) : (
                        <NoData title="No Achievements" size="M" />
                    )}
                </StyledContent>
            </StyledRow>
        );
    };

    const renderContent = () => {
        if (isLoadingProfile) {
            return (
                <StyledPageLoaderContainer style={{ padding: '24px 0 40px 0' }}>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (profile) {
            const { achievements, easy_achievements } = profile;
            return (
                <>
                    {renderAchievements('Latest achievements', achievements)}
                    {renderAchievements(
                        'Easy Achievements',
                        easy_achievements,
                        true
                    )}
                </>
            );
        }
        return <NoData title="No Achievements" size="M" />;
    };

    return <StyledContainer>{renderContent()}</StyledContainer>;
};
