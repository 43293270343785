import { useState, useMemo } from 'react';
import { TextItem, EyeIcon, CloseEyeIcon, IconButton } from '@/components';
import { IProps } from '@/components/TexBlock/TextItem/types';
import { Tooltip } from '@/components';
import { ToastContext } from '@/components/Toast/ToastContext';
import { useContext } from 'react';

// interface IProps {
//     password?: string;
// }

export const PasswordItem = (props: IProps) => {
    const { valueText } = props;
    const [isVisible, setIsVisible] = useState(false);
    const { setIsShow, setTitle } = useContext(ToastContext);

    const visibleText = useMemo<string | undefined>(() => {
        if (valueText) {
            navigator.clipboard.writeText(valueText);
            if (isVisible) {
                return valueText;
            }
            return [...Array(valueText.length)].fill('#').join('');
        }
        return undefined;
    }, [valueText, isVisible]);

    return (
        <TextItem
            {...props}
            keyText="Password"
            valueText={visibleText}
            icon={
                <IconButton
                    onClick={() => {
                        setIsVisible(!isVisible);
                        setTitle('Copied to clipboard');
                        setIsShow(true);
                    }}
                    disabled={!valueText}
                    icon={
                        <Tooltip
                            text={isVisible ? 'Hide' : 'Show'}
                            enabled={!!valueText}
                        >
                            {isVisible ? (
                                <EyeIcon variant="S" />
                            ) : (
                                <CloseEyeIcon variant="S" />
                            )}
                        </Tooltip>
                    }
                />
            }
        />
    );
};
