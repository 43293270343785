import { useContext } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate, useLocation } from 'react-router-dom';
import { CopyItem, TerminalIcon, IconButton, Tag } from '@/components';
import { SideBarContext } from '@/components/SideBar/context';
import { ISchemeVm } from '@/types';
import { VM_ICON, STATUS_LAB } from '@/constants';
import { useAppSelector } from '@/hooks/redux';
import { TerminalContext } from '@/pages/LabWithScheme/components/Sidebar/context/terminalContext';
import balanserIcon from '../../assets/balancer.svg';
import dataBase from '../../assets/database.svg';
import server from '../../assets/server.svg';
import web_server from '../../assets/web_server.svg';
import {
    StyledVmCardList,
    StyledVmCard,
    StyledImageContainer,
    StyledContainer,
    StyledHeader,
    StyledTitleContainer,
    StyledTitle,
    StyledNotIp,
    StyledActionContainer,
} from './styled';
import { SelectTask } from '../';

interface IProps {
    vms: ISchemeVm[];
}

const vmIcon = {
    [VM_ICON.BALANSER]: balanserIcon,
    [VM_ICON.DATABASE]: dataBase,
    [VM_ICON.GENERIC_SERVER]: server,
    [VM_ICON.WEB_SERVER]: web_server,
};

export const Vm = ({ vms }: IProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    const { setVmOption } = useContext(TerminalContext);
    const { setOpenCellId } = useContext(SideBarContext);
    const disabled = lab?.status !== STATUS_LAB.OPERATIONAL;

    return (
        <StyledVmCardList>
            {vms.map((v) => {
                const { name, ip, tasks, icon, uuid, id } = v;
                return (
                    <StyledVmCard key={nanoid()}>
                        <StyledImageContainer>
                            <img src={vmIcon[icon]} alt={icon} />
                        </StyledImageContainer>
                        <StyledContainer>
                            <StyledHeader>
                                <StyledTitleContainer>
                                    <StyledTitle>{name}</StyledTitle>
                                    <Tag title={icon.toUpperCase()} />
                                </StyledTitleContainer>
                                {ip ? (
                                    <CopyItem valueText={ip ?? '–'} />
                                ) : (
                                    <StyledNotIp>
                                        Run lab to see ip address
                                    </StyledNotIp>
                                )}
                            </StyledHeader>
                            <StyledActionContainer>
                                <SelectTask
                                    tasks={tasks}
                                    disabled={disabled}
                                    onClick={(e, t) => {
                                        const {
                                            title,
                                            id: taskId,
                                            description,
                                            is_flag,
                                        } = t;
                                        e.stopPropagation();
                                        navigate(
                                            pathname.replace(
                                                'information',
                                                'tasks'
                                            )
                                        );
                                        setOpenCellId('terminal');
                                        if (setVmOption) {
                                            setVmOption({
                                                title,
                                                id: `${taskId}_task`,
                                                vmId: uuid as string,
                                                description,
                                                is_flag,
                                            });
                                        }
                                    }}
                                />
                                <IconButton
                                    tooltip={
                                        disabled
                                            ? {
                                                  text: 'First you need to run the deploy',
                                              }
                                            : undefined
                                    }
                                    label="OPEN SSH"
                                    iconLeft={<TerminalIcon />}
                                    size="M"
                                    disabled={disabled}
                                    onClick={() => {
                                        setOpenCellId('terminal');
                                        if (setVmOption)
                                            setVmOption({
                                                id: `${id}_vm`,
                                                title: name,
                                                description: icon as string,
                                                vmId: uuid as string,
                                            });
                                    }}
                                />
                            </StyledActionContainer>
                        </StyledContainer>
                    </StyledVmCard>
                );
            })}
        </StyledVmCardList>
    );
};
