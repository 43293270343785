import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IError } from '../../types/error';
import { ICreateLaunchArgs } from './types/cards';
import { ILaunchCard } from '../../components/Card/types';
import { modalSlice } from './ModalSlice';
import { launchCardListSlice } from './LaunchCardsSlice';
import { apiClient } from '../../api/apiClient';

interface IInitialState {
    loader: boolean;
    error: IError;
}

const initialState: IInitialState = {
    loader: false,
    error: {
        code: undefined,
        message: undefined,
    },
};

export const createLaunch = createAsyncThunk<
    ILaunchCard[],
    ICreateLaunchArgs,
    { rejectValue: IError }
>(
    'createLaunchSlice/createLaunch',
    async function (args, { rejectWithValue, dispatch }) {
        const { lab_id, difficulty, access, host, user, pass } = args;
        try {
            apiClient.post('api/v1/profiles/', {
                elastic_host: host,
                elastic_user: user,
                elastic_password: pass,
            });

            apiClient.post(
                `/api/v1/labtemplates/${lab_id}/run_lab_without_siem/`,
                {
                    difficulty: String(difficulty),
                }
            );

            const response = await apiClient.get('/api/v1/labs/');

            const data = await response.data;
            dispatch(launchCardListSlice.actions.cardFetching(data));
            dispatch(modalSlice.actions.close());
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);

export const createLaunchSlice = createSlice({
    name: 'createLaunchSlice',
    initialState,
    reducers: {
        reset(state) {
            state.error.code = undefined;
            state.error.message = undefined;
            state.loader = false;
        },
    },
    extraReducers(builder) {
        builder.addCase(createLaunch.pending, (state) => {
            state.loader = true;
            state.error.code = undefined;
            state.error.message = undefined;
        });
        builder.addCase(createLaunch.rejected, (state, action) => {
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
            state.loader = false;
        });
    },
});

export default createLaunchSlice.reducer;
