import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IWorkshop } from '@/types/workshop';

export const getWorkshopByIdThunk = createAsyncThunk<
    IWorkshop,
    string,
    { rejectValue: IError }
>(
    'workshopWithSchemeSlice/getWorkshopByIdThunk',
    async (workshopId, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/workshops/${workshopId}`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
