import { ReactNode, SyntheticEvent, useState } from 'react';
import { baseColors, baseColorHover } from '@/constants';
import { StyledButton, StyledTitle, StyledTextContainer } from './styled';

interface IProps {
    children: ReactNode;
    onClick?: (e: SyntheticEvent) => void;
    variant?: 'primary' | 'secondary';
    color?: 'white' | 'primary' | 'error';
}

export const Button = ({
    children,
    onClick,
    variant = 'primary',
    color = 'error',
}: IProps) => {
    const [hover, setHover] = useState(false);
    const renderEdge = (side: 'left' | 'right') => {
        const edge = {
            left: {
                primary: (
                    <path
                        d="M20 0H18L0 18.72V36L20 36V0Z"
                        fill={
                            hover
                                ? baseColorHover[color]()
                                : baseColors[color]()
                        }
                    />
                ),
                secondary: (
                    <path
                        d="M20 1H18.5L1 19.117V34.9998L20 34.9998"
                        stroke={
                            hover
                                ? baseColorHover[color]()
                                : baseColors[color]()
                        }
                        stroke-width="2"
                    />
                ),
            },
            right: {
                primary: (
                    <path
                        d="M0 36H2L20 17.28V3.43323e-05L0 1.90735e-05V36Z"
                        fill={
                            hover
                                ? baseColorHover[color]()
                                : baseColors[color]()
                        }
                    />
                ),
                secondary: (
                    <path
                        d="M0 35H1.5L19 16.883V1.00025L0 1.00023"
                        stroke={
                            hover
                                ? baseColorHover[color]()
                                : baseColors[color]()
                        }
                        stroke-width="2"
                    />
                ),
            },
        };

        return (
            <svg
                width="20"
                height="36"
                viewBox="0 0 20 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                {edge[side][variant]}
            </svg>
        );
    };
    return (
        <StyledButton
            type="button"
            onClick={onClick}
            $color={color}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {renderEdge('left')}
            <StyledTextContainer
                $color={color}
                $variant={variant}
                $hover={hover}
            >
                <StyledTitle $color={color} $hover={hover}>
                    {children}
                </StyledTitle>
            </StyledTextContainer>
            {renderEdge('right')}
        </StyledButton>
    );
};
