import styled from 'styled-components';
import { baseColors, typography } from '../../../constants';

export const StyledFactoid = styled.div`
    display: flex;
    gap: 8px;
    position: relative;
`;

export const StyledAsterisk = styled.span`
    ${typography.text.M};
    opacity: 0.5;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const StyledTop = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledValueWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const StyledValue = styled.span`
    color: ${baseColors.white()};
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
`;

export const StyledComparedValue = styled.span`
    ${typography.title.S};
`;

export const StyledKey = styled.span`
    ${typography.text.S};
    color: ${baseColors.white(0.5)};
`;

export const StyledDescription = styled.p`
    ${typography.text.M};
`;
