import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledHeader = styled.div`
    display: flex;
`;

export const StyledFillerHeader = styled.div<{
    $color: string;
    $height: number;
    $width: number;
}>`
    width: ${({ $width }) => $width + '%'};
    height: 100%;
    background: ${({ $color }) => $color};
    height: ${({ $height }) => $height + 'px'};
`;

export const StyledFooter = styled.div`
    display: flex;
`;

export const StyledFillerFooter = styled.div<{
    $color: string;
    $height: number;
    $width: number;
}>`
    width: ${({ $width }) => $width + '%'};
    height: 100%;
    background: ${({ $color }) => $color};
    height: ${({ $height }) => $height + 'px'};
`;

export const StyledContent = styled.div<{ $color: string; $offset: number }>`
    background: ${({ $color }) => $color};
    height: ${({ $offset }) => `calc(100% - ${$offset}px)`};
`;
