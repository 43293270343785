import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ERRORS, IError } from "../../types/error";

interface ITnitialStateError {
  createLaunch: IError;
  getLaunchPage: IError;
  auth: IError;
  scoreboard: IError;
  joinTeam: IError;
}

const initialState: ITnitialStateError = {
  createLaunch: {
    code: undefined,
    message: undefined,
  },
  getLaunchPage: {
    code: undefined,
    message: undefined,
  },
  auth: {
    code: undefined,
    message: undefined,
  },
  scoreboard: {
    code: 500,
    message: undefined,
  },
  joinTeam: {
    code: undefined,
    message: undefined,
  },
};

interface IActionError {
  space: ERRORS;
  error: IError;
}

interface IJTActionError {
  error: IError;
}

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    errorFetching(state, action: PayloadAction<IActionError>) {
      state[action.payload.space].code = action.payload.error.code;
      state[action.payload.space].message = action.payload.error.message;
    },
    errorFetchingJoinTeam(state, action: PayloadAction<IJTActionError>) {
      state.joinTeam.code = action.payload.error.code;
      state.joinTeam.message = action.payload.error.message;
    },
  },
});

export default errorSlice.reducer;
