import { useState, useContext } from 'react';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import {
    StyledFormContainer,
    StyledFieldsContainer,
    StyledPrivacyPolicyContainer,
    StyledPrivacyPolicy,
} from '@/pages/AuthPages/styled';
import { IError } from '@/types';
import { LoaderDots, Alert, Input, Button, Checkbox } from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { ToastContext } from '@/components/Toast/ToastContext';
import { baseColors } from '@/constants';

export const SignUpForm = () => {
    const { setIsShow: setToastIsShow, setTitle: setToastText } =
        useContext(ToastContext);
    const [error, setError] = useState<IError>({
        code: undefined,
        message: undefined,
    });
    const [fetching, setFetching] = useState(false);
    const [consent, setConsent] = useState(true);
    const [acceptPrivacyPolisy, setAcceptPrivacyPolisy] = useState(false);
    interface IInfo extends IError {}
    const [info, setInfo] = useState<IInfo>({
        code: undefined,
        message: undefined,
    });
    const [user, setUser] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const sendVerificationEmail = async () => {
        setFetching(true);
        try {
            const response = apiClient.post(
                '/api/users/send_verification_email/',
                {
                    email: email,
                    username: user,
                    consent,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if ((await response).status === 200) {
                setToastText(`Check your email ${email}`);
                setToastIsShow(true);
                setFetching(false);
                setInfo({
                    code: 200,
                    message: `Check your email ${email}`,
                });
            }
        } catch (err) {
            setFetching(false);
            if (isAxiosError(err))
                setError({
                    code: err.response?.status,
                    message: err.response?.data.status,
                });
        }
    };
    const onClickHandler = () => {
        if (user !== '' && email !== '') {
            sendVerificationEmail();
        }
    };
    const onChangeEmailHandler = (e: string) => {
        setEmail(e);
        setError({
            code: undefined,
            message: undefined,
        });
        setInfo({
            code: undefined,
            message: undefined,
        });
    };
    const onChangeUserHandler = (e: string) => {
        setUser(e);
        setError({
            code: undefined,
            message: undefined,
        });
        setInfo({
            code: undefined,
            message: undefined,
        });
    };
    const isDisabled = user === '' || email === '' || !acceptPrivacyPolisy;
    return (
        <StyledFormContainer>
            <StyledFieldsContainer>
                {error.code === 400 && (
                    <Alert
                        message={error.message}
                        code={error.code}
                        variant={VARIANT.ERROR}
                    />
                )}
                {info.code && (
                    <Alert message={info.message} variant={VARIANT.SUCCESS} />
                )}
                <Input
                    id="user"
                    name="user"
                    type="text"
                    label="User"
                    onChange={onChangeUserHandler}
                />
                <Input
                    id="email"
                    type="text"
                    label="Email"
                    onChange={onChangeEmailHandler}
                />
                <Checkbox
                    label="I alllow defbox to send me emails on rare occasions such as major updates"
                    onChange={(isChecked) => {
                        setConsent(isChecked);
                    }}
                    checked={consent}
                />
                <StyledPrivacyPolicyContainer>
                    <Checkbox
                        label="I accept Defbox"
                        onChange={(isChecked) => {
                            setAcceptPrivacyPolisy(isChecked);
                        }}
                        checked={acceptPrivacyPolisy}
                    />
                    <StyledPrivacyPolicy
                        href="https://defbox.ams3.digitaloceanspaces.com/Privacy%20Policy%20Defbox.pdf"
                        target="_blank"
                    >
                        Privacy Policy
                    </StyledPrivacyPolicy>
                </StyledPrivacyPolicyContainer>
            </StyledFieldsContainer>
            {fetching ? (
                <LoaderDots color={baseColors.primary()} />
            ) : (
                <Button onClick={onClickHandler} disabled={isDisabled}>
                    Send
                </Button>
            )}
        </StyledFormContainer>
    );
};
