import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledPagination = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 16px;
    align-items: center;
`;

export const StyledPageBtn = styled.a`
    display: flex;
    flex-direction: column;
    gap: 12px;
    opacity: 0.5;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        opacity: 1;
    }
`;

export const StyledLabel = styled.span`
    writing-mode: vertical-rl;
    ${typography.text.S};
`;
