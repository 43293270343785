import { useNavigate } from 'react-router-dom';
import {
    Button,
    UserIcon,
    VMIcon,
    StarIcon,
    TaskIcon,
    FlagIcon,
} from '@/components';
import { SHAPE } from '@/components/Button/types';
import { baseColors } from '@/constants';
import { IProps } from '../../types';
import { BaseStatItem, ProgressStatItem } from '../';
import {
    StyledCard,
    StyledContent,
    StyledTextContainer,
    StyledHeader,
    StyledSubheader,
    StyledSubheaderItem,
    StyledTitleContainer,
    StyledTitle,
    StyledIsPremium,
    StyledText,
    StyledSmallText,
    StyledStatContainer,
    StyledStats,
} from './styled';

export const FullCard = ({
    isGeneralStats,
    isUserStats,
    completed,
    total,
    tasks_completed,
    tasks_total,
    max_points,
    flags_found,
    flags_total,
    vm_count,
    submissions,
    lab: { name, description, id, is_premium },
}: IProps) => {
    const navigate = useNavigate();
    return (
        <StyledCard>
            <StyledContent>
                <StyledTextContainer>
                    <StyledHeader>
                        <StyledTitleContainer>
                            <StyledTitle>{name}</StyledTitle>
                            {is_premium && (
                                <StyledIsPremium>Premium</StyledIsPremium>
                            )}
                        </StyledTitleContainer>
                        <StyledSubheader>
                            <StyledSubheaderItem>
                                <StarIcon
                                    variant="S"
                                    fill={baseColors.white(0.5)}
                                />
                                <StyledSmallText>
                                    {`${max_points} points per lab`}
                                </StyledSmallText>
                            </StyledSubheaderItem>
                            <StyledSubheaderItem>
                                <VMIcon
                                    variant="S"
                                    fill={baseColors.white(0.5)}
                                />
                                <StyledSmallText>
                                    {`${vm_count} vm`}
                                </StyledSmallText>
                            </StyledSubheaderItem>
                            <StyledSubheaderItem>
                                <UserIcon
                                    variant="S"
                                    fill={baseColors.white(0.5)}
                                />
                                <StyledSmallText as="span">
                                    {`${submissions} submissions`}
                                </StyledSmallText>
                            </StyledSubheaderItem>
                        </StyledSubheader>
                    </StyledHeader>
                    <StyledText>{description}</StyledText>
                </StyledTextContainer>
                <Button
                    view={SHAPE.SECONDARY}
                    onClick={(e) => navigate(`/labs/${id}`)}
                >
                    View lab
                </Button>
            </StyledContent>
            <StyledStatContainer>
                <StyledStats>
                    <BaseStatItem
                        icon={<TaskIcon />}
                        label="Completed / Total"
                        value={`${tasks_completed}/${tasks_total}`}
                    />
                    <BaseStatItem
                        icon={<FlagIcon />}
                        label="Found / Total"
                        value={`${flags_found}/${flags_total}`}
                    />
                    <ProgressStatItem total={total} completed={completed} />
                </StyledStats>
            </StyledStatContainer>
        </StyledCard>
    );
};
