import { useContext } from 'react';
import { ITask, TaskExpandType } from '@/types';
import { LabContext } from '@/pages/LabWithScheme/context';
import { baseColors, TASK_TYPE, LAB_TYPE } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { SHAPE, ButtonTooltipConfigType } from '@/components/Button/types';
import { CheckHistory } from '@/pages/LabWithScheme/components';
import { Hint } from '../Hint/Hint';
import { InputFlag } from '@/pages/LabWithScheme/components';
import {
    checkTasksThunk,
    checkTasksWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import {
    Block,
    OkIcon,
    FailedIcon,
    ClockIcon,
    EmptyIcon,
    Button,
    TerminalIcon,
    CopyItem,
    PasswordItem,
    LinkIcon,
    StarIcon,
    PayIcon,
} from '@/components';
import {
    StyledHeader,
    StyledStatusWithTitle,
    StyledTitleContainer,
    StyledTitle,
    StyledSubtitle,
    StyledBlockContainer,
    StyledSeparator,
    StyledText,
    StyledTextContainer,
    StyledButtons,
    StyledLabel,
    StyledFooter,
    StyledCongratulations,
} from './styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps extends ITask {
    disabled?: boolean;
    openTerminal?: () => void;
}

export const TaskCard = (props: IProps) => {
    const {
        id,
        name,
        text,
        points,
        in_progress,
        last_checks,
        is_completed,
        completed_text,
        is_premium,
        disabled,
        type,
        siem_info,
        is_flag,
        openTerminal,
    } = props;
    const dispatch = useAppDispatch();
    const { type: labType } = useContext(LabContext);
    const { profile } = useAppSelector((state) => state.ProfileSlice);
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    const isDisabled = !!disabled || in_progress;
    const isSubscribe = (profile?.is_premium && is_premium) || !is_premium;

    const renderSiemInfo = () => {
        if (
            (type === TASK_TYPE.ATTACK || type === TASK_TYPE.URL) &&
            siem_info
        ) {
            const { user, password, detect_url } = siem_info;
            return (
                <StyledTextContainer>
                    <CopyItem valueText={user ?? '–'} keyText="User" />
                    <PasswordItem valueText={password ?? undefined} />
                    {type === TASK_TYPE.ATTACK && (
                        <CopyItem
                            valueText={detect_url ?? '–'}
                            keyText="Detect URL"
                        />
                    )}
                </StyledTextContainer>
            );
        }
        return null;
    };

    const buttonTooltipConfig: ButtonTooltipConfigType | undefined = isDisabled
        ? {
              text: 'First you need to run the deploy',
          }
        : undefined;

    const renderCheckButton = () => {
        const handleClickCheck = () => {
            if (lab?.uuid)
                switch (labType) {
                    case LAB_TYPE.DEFAULT:
                        dispatch(
                            checkTasksThunk({
                                uuid: lab.uuid,
                                cardId: id,
                            })
                        );
                        return;
                    case LAB_TYPE.WORKSHOP:
                        dispatch(
                            checkTasksWsThunk({
                                uuid: lab.uuid,
                                cardId: id,
                            })
                        );
                }
        };
        return (
            <>
                {is_flag ? (
                    <InputFlag
                        disabled={isDisabled}
                        uuid={lab?.uuid}
                        cardId={id}
                    />
                ) : (
                    <Button
                        view={SHAPE.GHOST}
                        tooltip={buttonTooltipConfig}
                        disabled={isDisabled}
                        onClick={() => handleClickCheck()}
                    >
                        check
                    </Button>
                )}
            </>
        );
    };

    const renderButtons = () => {
        if (!isSubscribe) {
            return (
                <Button
                    tooltip={{ text: 'Subscribe to solve this task' }}
                    icon={<PayIcon />}
                    view={SHAPE.PRIMARY}
                    onClick={() => {
                        window.open(
                            process.env.REACT_APP_PAYMENT_URL,
                            '_blank'
                        );
                    }}
                >
                    Subsribe
                </Button>
            );
        }
        switch (type) {
            case TASK_TYPE.URL:
            case TASK_TYPE.ATTACK:
                return (
                    <>
                        <Button
                            tooltip={buttonTooltipConfig}
                            icon={<LinkIcon />}
                            view={SHAPE.PRIMARY}
                            disabled={isDisabled}
                            onClick={() => {
                                if (siem_info?.url)
                                    window.open(siem_info.url, '_blank');
                            }}
                        >
                            solve
                        </Button>

                        {renderCheckButton()}
                    </>
                );
            case TASK_TYPE.TASK:
            default:
                return (
                    <>
                        <Button
                            tooltip={buttonTooltipConfig}
                            icon={<TerminalIcon />}
                            view={SHAPE.PRIMARY}
                            disabled={isDisabled}
                            onClick={() => {
                                if (openTerminal) openTerminal();
                            }}
                        >
                            solve
                        </Button>
                        {renderCheckButton()}
                    </>
                );
        }
    };

    const renderIcon = () => {
        if (last_checks.length) {
            if (in_progress) {
                return <ClockIcon fill={baseColors.primary()} />;
            }
            if (is_completed) {
                return <OkIcon fill={baseColors.ok()} />;
            }
            return <FailedIcon fill={baseColors.error()} />;
        }
        return <EmptyIcon fill={baseColors.white(0.25)} />;
    };

    const renderSubtitle = () => {
        if (last_checks.length) {
            if (in_progress) {
                return <StyledSubtitle>In progress</StyledSubtitle>;
            }
            if (is_completed) {
                return <StyledSubtitle>Success</StyledSubtitle>;
            }
            return <StyledSubtitle>Failed</StyledSubtitle>;
        }
        return <StyledSubtitle>Not started </StyledSubtitle>;
    };

    const renderHeader = () => {
        return (
            <StyledHeader>
                <StyledStatusWithTitle style={{ width: '312px' }}>
                    {renderIcon()}
                    <StyledTitleContainer>
                        <StyledTitle>{name}</StyledTitle>
                        {renderSubtitle()}
                    </StyledTitleContainer>
                </StyledStatusWithTitle>
                <StyledStatusWithTitle>
                    <StarIcon
                        fill={
                            is_completed
                                ? baseColors.warning()
                                : baseColors.white(0.5)
                        }
                    />
                    <StyledTitle
                        color={
                            is_completed
                                ? baseColors.warning()
                                : baseColors.white(0.5)
                        }
                    >
                        {points}
                    </StyledTitle>
                </StyledStatusWithTitle>
            </StyledHeader>
        );
    };

    const isOpenedDefault = () => {
        let taskExpandState: TaskExpandType | string | null =
            sessionStorage.getItem('taskExpandState');
        if (taskExpandState) {
            taskExpandState = JSON.parse(taskExpandState) as TaskExpandType;
            return taskExpandState.includes(id);
        }
        return false;
    };

    const renderCongratulation = () => {
        if (is_completed) {
            return (
                <>
                    <StyledSeparator />
                    <StyledCongratulations>
                        <span className="quote">"</span>
                        <p>
                            {completed_text !== ''
                                ? completed_text
                                : 'Congratulations! You have solved this task. It is time for new feats. But if you decide to do this task again, here is what you will need to do:'}
                        </p>
                    </StyledCongratulations>
                    <StyledSeparator />
                </>
            );
        }
        return null;
    };

    return (
        <Block
            title={renderHeader()}
            headerStyle={{ padding: '16px' }}
            isOpen={isOpenedDefault()}
            onExpand={(expand) => {
                const taskExpandState =
                    sessionStorage.getItem('taskExpandState');
                let changedState = [] as TaskExpandType;
                if (taskExpandState) {
                    changedState = JSON.parse(
                        taskExpandState
                    ) as TaskExpandType;
                }
                if (expand) {
                    changedState.push(id);
                } else {
                    changedState = changedState.filter((i) => i !== id);
                }
                sessionStorage.setItem(
                    'taskExpandState',
                    JSON.stringify(changedState)
                );
            }}
        >
            <StyledBlockContainer>
                {renderCongratulation()}
                <StyledTextContainer>
                    <StyledLabel>Description</StyledLabel>
                    <StyledText>{text}</StyledText>
                </StyledTextContainer>
                {renderSiemInfo()}
                <StyledSeparator />
                <CheckHistory data={last_checks || []} />
                <StyledSeparator />
                <StyledFooter>
                    <StyledButtons>{renderButtons()}</StyledButtons>
                    <Hint task={props} uuid={lab?.uuid} />
                </StyledFooter>
            </StyledBlockContainer>
        </Block>
    );
};
