import styled, { css } from 'styled-components'
import { typography, baseColors } from '../../../constants/main'

export const StyledTextareaWrapper = styled.div<{ width?: string }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    position: relative;
    box-sizing: border-box;
    width: ${({ width }) => (width ? width : '100%')};
`

const generateBckgEndBorder = (hover: boolean, focus: boolean) => {
    if (focus) {
        if (hover) {
            return css`
                background: linear-gradient(
                        0deg,
                        ${baseColors.primary(0.06)},
                        ${baseColors.primary(0.06)}
                    ),
                    linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.03),
                        rgba(255, 255, 255, 0.03)
                    ),
                    ${baseColors.bckg()};
                border: 1px solid ${baseColors.primary(0.8)};
            `
        }
        return css`
            background: linear-gradient(
                    0deg,
                    ${baseColors.primary(0.1)},
                    ${baseColors.primary(0.1)}
                ),
                linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.06),
                    rgba(255, 255, 255, 0.06)
                ),
                #120d21;
            border: 1px solid ${baseColors.primary()};
        `
    }
    if (hover) {
        return css`
            background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.03),
                    rgba(255, 255, 255, 0.03)
                ),
                #120d21;
            border: 1px solid rgba(255, 255, 255, 0.06);
        `
    }
    return css`
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.06),
                rgba(255, 255, 255, 0.06)
            ),
            #120d21;
        border: 1px solid rgba(255, 255, 255, 0.1);
    `
}

export const StyledTextarea = styled.textarea.attrs(
    ({
        type,
        id,
        name,
        autoComplete,
        readOnly,
    }: {
        type?: string | number
        id?: string | number
        name?: string | number
        autoComplete?: 'off' | 'new-password' | 'disabled'
        readOnly?: 'readonly' | ''
    }) => ({
        type: type,
        id: id,
        name: name,
        autoComplete: 'off',
        readOnly: readOnly,
    })
)<{
    hover: boolean
    focus: boolean
    cursor: string
}>`
    display: flex;
    caret-color: #fff;
    width: 100%;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    padding: ${({ type }) =>
        type === 'password' ? '8px 40px 8px 12px' : '8px 12px'};
    gap: 10px;
    border-radius: 8px;
    outline: none;
    color: rgba(255, 255, 255, 0.8);
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    ${({ cursor }) => (cursor ? `cursor: ${cursor}` : null)};
    ${typography.text.M}
    ${({ hover, focus }) => generateBckgEndBorder(hover, focus)}
`

export const StyledInputContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 100%;
`

export const StyledIconContainer = styled.div`
    position: absolute;
    top: 6px;
    right: 8px;
`

export const StyledLabel = styled.label.attrs((htmlFor) => ({
    htmlFor: htmlFor,
}))`
    ${typography.text.S};
    color: rgba(255, 255, 255, 0.8);
`

export const StyledDescription = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    margin: 0;
    margin-top: 4px;
`
