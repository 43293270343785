import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const HomeIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.70711 1.29289C8.31658 0.902369 7.68342 0.902369 7.29289 1.29289L1.29289 7.29289C0.902369 7.68342 0.902369 8.31658 1.29289 8.70711C1.68342 9.09763 2.31658 9.09763 2.70711 8.70711L3 8.41421V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V8.41421L13.2929 8.70711C13.6834 9.09763 14.3166 9.09763 14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289L8.70711 1.29289ZM11.1167 6.53086L8 3.41421L4.88335 6.53086C4.95781 6.67077 5 6.83046 5 7V13H11V7C11 6.83046 11.0422 6.67077 11.1167 6.53086Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.7071 2.29289C12.3166 1.90237 11.6834 1.90237 11.2929 2.29289L2.29289 11.2929C1.90237 11.6834 1.90237 12.3166 2.29289 12.7071C2.68342 13.0976 3.31658 13.0976 3.70711 12.7071L5 11.4142V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V11.4142L20.2929 12.7071C20.6834 13.0976 21.3166 13.0976 21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L12.7071 2.29289ZM17 9.41421L12 4.41421L7 9.41421V20H17V9.41421Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
