import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { StyledPage, StyledOffsetPage, StyledContent } from './styled';
import { Main, Whom, Polygon, Inside, Offer } from './sections';
import { Header, Footer, VerticalPagination } from './components';
import { IAnchors } from './types';
import { links } from './constants';

export const LandingPage = () => {
    const [anchors, setAnchors] = useState<IAnchors>(links.main);

    return (
        <StyledPage>
            <StyledOffsetPage>
                <VerticalPagination next={anchors.next} prev={anchors.prev} />
            </StyledOffsetPage>
            <StyledContent>
                <Header />
                <InView
                    onChange={(inView) => {
                        if (inView) {
                            setAnchors(links['main']);
                        }
                    }}
                    threshold={0.7}
                    key="main"
                >
                    {({ ref }) => {
                        return <Main ref={ref} />;
                    }}
                </InView>
                <InView
                    onChange={(inView) => {
                        if (inView) {
                            setAnchors(links['whom']);
                        }
                    }}
                    threshold={0.3}
                    key="whome"
                >
                    {({ ref }) => {
                        return <Whom ref={ref} />;
                    }}
                </InView>
                <InView
                    onChange={(inView) => {
                        if (inView) {
                            setAnchors(links['polygon']);
                        }
                    }}
                    threshold={0.2}
                    key="polygon"
                >
                    {({ ref }) => {
                        return <Polygon ref={ref} />;
                    }}
                </InView>
                <InView
                    onChange={(inView) => {
                        if (inView) {
                            setAnchors(links['inside']);
                        }
                    }}
                    threshold={0.1}
                    key="Inside"
                >
                    {({ ref }) => {
                        return <Inside ref={ref} />;
                    }}
                </InView>
                <InView
                    onChange={(inView) => {
                        if (inView) {
                            setAnchors(links['offer']);
                        }
                    }}
                    threshold={0.1}
                    key="Inside"
                >
                    {({ ref }) => {
                        return <Offer ref={ref} />;
                    }}
                </InView>
            </StyledContent>
            <Footer />
            <StyledOffsetPage />
        </StyledPage>
    );
};
