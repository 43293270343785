import styled from 'styled-components';

export const StyledInformation = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 8px 0;
    gap: 8px;
    height: 100%;
    overflow-y: auto;
`;
