import { baseColors } from './main';
import { IScaleItem } from '../components/Slider/types';

export const difficultyItems: IScaleItem[] = [
    {
        id: 0,
        value: 0,
        color: baseColors.ok(),
    },
    {
        id: 1,
        value: 1,
        color: baseColors.ok(),
    },
    {
        id: 2,
        value: 2,
        color: baseColors.ok(),
    },
    {
        id: 3,
        value: 3,
        color: baseColors.ok(),
    },
    {
        id: 4,
        value: 4,
        color: baseColors.critical(),
    },
    {
        id: 5,
        value: 5,
        color: baseColors.critical(),
    },
    {
        id: 6,
        value: 6,
        color: baseColors.critical(),
    },
    {
        id: 7,
        value: 7,
        color: baseColors.error(),
    },
    {
        id: 8,
        value: 8,
        color: baseColors.error(),
    },
    {
        id: 9,
        value: 9,
        color: baseColors.error(),
    },
    {
        id: 10,
        value: 10,
        color: baseColors.error(),
    },
];

export enum STATUS_LAB {
    DEPLOY = 'DEPLOY_VM',
    SET_UP_VM = 'SET_UP VM',
    OPERATIONAL = 'OPERATIONAL',
    DEACTIVATED = 'DEACTIVATED',
    TEAR_DOWN = 'TEAR_DOWN',
}

export enum STORY_STATUS {
    DEPLOY = 'DEPLOY',
    PROTECT = 'PROTECT',
    HACK = 'HACK',
    INVESTIGATE = 'INVESTIGATE',
    REDEPLOY = 'REDEPLOY',
    DESTROY = 'DESTROY',
}

export const storyStatusText = {
    DEPLOY: 'Deploy',
    PROTECT: 'Protect',
    HACK: 'Hack',
    INVESTIGATE: 'Investigate',
    REDEPLOY: 'Redeploy',
    DESTROY: 'Destroy',
};

export const statusLabColor = {
    DEPLOY_VM: baseColors.warning(),
    'SET_UP VM': baseColors.critical(),
    OPERATIONAL: baseColors.primary(),
    DEACTIVATED: baseColors.disabled(),
    TEAR_DOWN: baseColors.critical(),
};

export const statusLabText = {
    [STATUS_LAB.DEPLOY]: 'Deploy VM',
    [STATUS_LAB.SET_UP_VM]: 'Set up VM',
    [STATUS_LAB.OPERATIONAL]: 'Operational',
    [STATUS_LAB.DEACTIVATED]: 'Deactivated',
    [STATUS_LAB.TEAR_DOWN]: 'Destruction VM',
};

export const statusLabMessage = {
    DEPLOY_VM: 'We are preparing virtual machines',
    'SET_UP VM': 'We install software on the machines',
    OPERATIONAL: 'Operational',
    DEACTIVATED: 'Laboratory destroyed',
    TEAR_DOWN: 'Destruction VM',
};

export enum LAB_TYPE {
    DEFAULT = 'labs',
    WORKSHOP = 'workshoplabs',
}
