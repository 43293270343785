import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledInfo = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 12px 16px 24px 16px;
    border: 0 0 6px 6px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: ${baseColors.levelThree()};
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    text-align: center;
    width: 100%;
    opacity: 0.25;
`;
