import { useContext } from 'react';
import { TextItem, CopyIcon, IconButton } from '@/components';
import { IProps } from '@/components/TexBlock/TextItem/types';
import { Tooltip } from '@/components';
import { ToastContext } from '@/components/Toast/ToastContext';

export const CopyItem = (props: IProps) => {
    const { valueText } = props;
    const { setIsShow, setTitle } = useContext(ToastContext);

    const copyToClipboard = () => {
        if (valueText) {
            navigator.clipboard.writeText(valueText);
            setIsShow(true);
            setTitle('Copied to clipboard');
        }
    };

    return (
        <TextItem
            {...props}
            icon={
                <Tooltip text="Copy to clipboard" enabled={!!valueText}>
                    <IconButton
                        icon={<CopyIcon variant="S" />}
                        disabled={!valueText}
                        onClick={copyToClipboard}
                    />
                </Tooltip>
            }
        />
    );
};
