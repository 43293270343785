export enum TIMELINE_ELEMENT {
    CREATED = 'CREATED',
    DETECTED = 'DETECT',
    ATTACK = 'ATTACK_INFO',
    MITRE_STAGE = 'MITRE_STAGE',
    GROUP_DETECTED = 'GROUP_DETECTED',
    GROUP_ATTACK = 'GROUP_ATTACK',
    GROUP_DETECTED_WITH_ATTACK = 'GROUP_DETECTED_WITH_ATTACK',
    DESTROY = 'DESTROY',
}

export const timeLineMessage = {
    [TIMELINE_ELEMENT.CREATED]: 'Stand was created',
    [TIMELINE_ELEMENT.DETECTED]: 'Detect',
    [TIMELINE_ELEMENT.ATTACK]: 'Attack',
    [TIMELINE_ELEMENT.DESTROY]: 'Lab was destroyed',
    [TIMELINE_ELEMENT.MITRE_STAGE]: 'Message mitre stage',
};
