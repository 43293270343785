import { useCallback, useEffect, useRef } from "react";
import { createPopper, Instance, VirtualElement } from "@popperjs/core";

import { UsePopperProps } from "./types";
import * as customModifiers from "./popper.modifiers";
import { DEFAULT_ARROW_PADDING, OFFSET, PLACEMENTS_MAP } from "./constants";

export const usePopper = (props: UsePopperProps) => {
  const {
    enabled = false,
    offset = OFFSET,
    flip = true,
    matchWidth = false,
    preventOverflow = true,
    boundary = "clippingParents",
    placement = "auto",
    arrowPadding = DEFAULT_ARROW_PADDING,
    eventListeners = "false",
    modifiers,
  } = props;

  const trigger = useRef<Element | VirtualElement | null>(null);
  const popper = useRef<HTMLElement | null>(null);
  const instance = useRef<Instance | null>(null);

  const cleanup = useRef(() => {});

  const setupPopper = useCallback(() => {
    if (!enabled || !trigger.current || !popper.current) {
      return;
    }
    cleanup.current?.();

    instance.current = createPopper(trigger.current, popper.current, {
      placement: PLACEMENTS_MAP[placement],
      modifiers: [
        customModifiers.innerArrow,
        customModifiers.positionArrow,
        {
          name: "arrow",
          options: {
            padding: arrowPadding,
          },
        },
        {
          name: "offset",
          options: {
            offset: ({ placement: pos }: { placement: any }) => {
              // arrorPadding задаёт минимальный отступ с края для корректного отображения стрелки
              if (pos.includes("start")) {
                return [-arrowPadding, offset];
              }
              if (pos.includes("end")) {
                return [arrowPadding, offset];
              }
              return [0, offset];
            },
          },
        },
        {
          name: "eventListeners",
          enabled: !!eventListeners,
          options: {
            scroll: true,
            resize: true,
          },
        },
        {
          name: "flip",
          enabled: !!flip,
        },
        {
          name: "preventOverflow",
          enabled: !!preventOverflow,
          options: { boundary },
        },
        { ...customModifiers.matchWidth, enabled: !!matchWidth },
        ...(modifiers ?? []),
      ],
    });

    instance.current.forceUpdate();

    cleanup.current = instance.current.destroy;
  }, [
    enabled,
    placement,
    arrowPadding,
    eventListeners,
    flip,
    preventOverflow,
    boundary,
    matchWidth,
    modifiers,
    offset,
  ]);

  useEffect(() => {
    return () => {
      if (!trigger.current && !popper.current) {
        instance.current?.destroy();
        instance.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const referenceRef = useCallback(
    (node: any) => {
      trigger.current = node;
      setupPopper();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setupPopper]
  );

  const popperRef = useCallback(
    (node: any) => {
      popper.current = node;
      setupPopper();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setupPopper]
  );

  const getArrowProps = useCallback((ref = null) => {
    return {
      ref,
      "data-popper-arrow": "",
    };
  }, []);

  const getArrowInnerProps = useCallback(
    (ref = null) => ({
      ref,
      "data-popper-arrow-inner": "",
    }),
    []
  );

  return {
    update() {
      instance.current?.update();
    },
    forceUpdate() {
      instance.current?.forceUpdate();
    },
    referenceRef,
    popperRef,
    getArrowProps,
    getArrowInnerProps,
  };
};
