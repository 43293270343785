import React, { forwardRef } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { TemplateCard, CPBanner } from '../../components';
import { ClockIcon } from '@/components';
import poligon_title_img from '../../assets/SVGTitles/secondPagesTtotles/polygon.svg';
import {
    StyledPolygon,
    StyledTitleContainer,
    StyledTitleDescriptionContainerTop,
    StyledTitleDescriptionContainerBottom,
    StyledTitleDescription,
    StyledFootnotLeft,
    StyledFootnotRight,
    StyledTitleImage,
    StyledCardsList,
    StyledSeparator,
    StyledCardListTitleContainer,
    StyledCardListTitle,
    StyledCardListSubitle,
    StyledBanner,
    StyledBannerTitle,
} from './styled';
import { templates } from './constants';
import { baseColors } from '@/constants';

export const Polygon = forwardRef(
    (_, ref: React.ForwardedRef<HTMLDivElement>) => {
        const renderListTitle = () => {
            return (
                <StyledCardListTitleContainer>
                    <StyledCardListTitle>
                        We add one new lab every two weeks
                    </StyledCardListTitle>
                    <StyledCardListSubitle>
                        Here is a list of current labs:
                    </StyledCardListSubitle>
                </StyledCardListTitleContainer>
            );
        };
        const renderBannerContent = () => {
            return (
                <StyledBanner>
                    <ClockIcon size={40} />
                    <StyledBannerTitle>
                        Each lab can be completed in a few hours. Spend a few
                        hours and learn how to defend against a particular
                        threat.
                    </StyledBannerTitle>
                </StyledBanner>
            );
        };
        return (
            <StyledPolygon ref={ref} id="polygon">
                <StyledTitleContainer>
                    <StyledTitleDescriptionContainerTop>
                        <StyledTitleDescription>
                            <p>
                                Each lab is a cyberpolygon or cyberrange - a set
                                of vulnerable virtual machines, connected to the
                                log collection system.
                            </p>
                        </StyledTitleDescription>
                        <StyledFootnotLeft />
                    </StyledTitleDescriptionContainerTop>
                    <StyledTitleImage src={poligon_title_img} />
                    <StyledTitleDescriptionContainerBottom>
                        <StyledFootnotRight />
                        <StyledTitleDescription>
                            <p>
                                You can interact with the system however you
                                want. Hack it yourself, or SSH into it to
                                protect it from attack.
                            </p>
                        </StyledTitleDescription>
                    </StyledTitleDescriptionContainerBottom>
                </StyledTitleContainer>
                <StyledSeparator />
                <StyledCardsList>
                    {renderListTitle()}
                    {templates.map((t, idx) => {
                        if (idx < 6)
                            return (
                                <TemplateCard {...t} isBorder key={nanoid()} />
                            );
                        return null;
                    })}
                    <CPBanner angleRigthSize={32} color={baseColors.primary()}>
                        {renderBannerContent()}
                    </CPBanner>
                    {templates.map((t, idx) => {
                        if (idx >= 6)
                            return (
                                <TemplateCard {...t} isBorder key={nanoid()} />
                            );
                        return null;
                    })}
                </StyledCardsList>
            </StyledPolygon>
        );
    }
);
