import { Icon } from "../Icon";

export const ShevronDown = () => {
  return (
    <Icon
      content={
        <path
          d="M13.0508 15.5347L17.5618 10.7121C18.4979 9.71127 17.8349 8 16.511 8L7.48903 8C6.1651 8 5.50207 9.71127 6.43824 10.7121L10.9492 15.5347C11.5295 16.1551 12.4705 16.1551 13.0508 15.5347Z"
          fill="white"
          fillOpacity="0.72"
        />
      }
    />
  );
};
