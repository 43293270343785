import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IError, IAchievement } from '@/types';
import { apiClient } from '@/api/apiClient';
import { UuidWithBasePath } from './types/general';

interface IInitialState {
    achivements?: IAchievement[];
    isLoading: boolean;
    error: IError;
    newAchivementsText?: string[];
}

const initialState: IInitialState = {
    achivements: undefined,
    isLoading: false,
    error: {
        code: undefined,
        message: undefined,
    },
    newAchivementsText: undefined,
};

export const checkAchievements = createAsyncThunk<
    IAchievement[],
    UuidWithBasePath,
    { rejectValue: IError }
>('achivementsSlice/checkAchievements', async ({uuid, basePath}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `/api/v1/${basePath}/${uuid}/achievements/`,
            {
                requestName: 'getAchivements',
            }
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getAchievements = createAsyncThunk<
    IAchievement[],
    UuidWithBasePath,
    { rejectValue: IError }
>('achivementsSlice/getAchievements', async ({uuid, basePath}, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `/api/v1/${basePath}/${uuid}/achievements/`,
            {
                requestName: 'getAchivements',
            }
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const achivementsSlice = createSlice({
    name: 'achivementsSlice',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        getAchievementsFetching(state) {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        getAchievementsSuccess(state, action) {
            state.achivements = action.payload;
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        getAchievementsFailure(state, action) {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        },
    },
    extraReducers(builder) {
        builder.addCase(getAchievements.pending, (state) => {
            state.achivements = undefined;
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getAchievements.fulfilled, (state, action) => {
            state.achivements = action.payload;
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getAchievements.rejected, (state, action) => {
            state.isLoading = false;
            state.error.code = action.payload?.code;
            state.error.message = action.payload?.message;
        });

        builder.addCase(checkAchievements.fulfilled, (state, action) => {
            if (!!state.achivements && action.payload) {
                const achivements = state.achivements.map((a) => a.title);
                const resultAchivements = action.payload
                    .map((p) => p.title)
                    .filter((a) => !achivements.includes(a));
                if (resultAchivements.length) {
                    state.newAchivementsText = resultAchivements;
                    state.achivements = action.payload;
                }
            }
        });
    },
});

export default achivementsSlice.reducer;
