import { useRef, useEffect, MutableRefObject } from "react";

type RefType<T> =
  | ((instance: T | null) => void)
  | MutableRefObject<T | null>
  | null;

export const useCombinedRefs = <T>(
  ...refs: RefType<T>[]
): MutableRefObject<T | null> => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
