import { useState, CSSProperties, useEffect } from 'react';
import { PagesTabs } from '@/components';
import { baseColors } from '@/constants';

import {
    StyledContainer,
    StyledSlider,
    StyledSlideImage,
    StyledContent,
    StyledTitle,
    StyledText,
    StyledExpander,
    StyledTabMini,
    StyledFooterContent,
} from './styled';
import { CPBanner } from '../';
import { bannerConfig, tabs } from './constants';
import { BannerConditionType } from './types';

export const Slider = () => {
    const [bannerCondition, setBannerCondition] =
        useState<BannerConditionType>('opened');

    const [content, setContent] = useState<{
        text: string;
        title: string;
        image: string;
        value: string;
    }>({
        text: tabs[0].text,
        title: tabs[0].title,
        image: tabs[0].image,
        value: tabs[0].value,
    });

    useEffect(() => {
        const resizeHandle = () => {
            if (document.body.offsetWidth < 768) {
                setBannerCondition('background');
                return;
            } else if (
                document.body.offsetWidth >= 768 &&
                bannerCondition === 'background'
            ) {
                setBannerCondition('opened');
                return;
            }
        };
        resizeHandle();
        window.addEventListener('resize', resizeHandle);
        return () => {
            window.removeEventListener('resize', resizeHandle);
        };
    }, [setBannerCondition, bannerCondition]);

    return (
        <StyledContainer>
            <PagesTabs
                style={
                    bannerCondition === 'background'
                        ? { order: 1, marginTop: '24px' }
                        : undefined
                }
                renderTab={
                    bannerCondition === 'background'
                        ? (t) => <StyledTabMini $active={t.active} />
                        : undefined
                }
                color={baseColors.error()}
                data={tabs}
                onChange={(key) => {
                    setContent({
                        text: tabs[Number(key)].text,
                        title: tabs[Number(key)].title,
                        image: tabs[Number(key)].image,
                        value: tabs[Number(key)].value,
                    });
                    if (bannerCondition !== 'background')
                        setBannerCondition('opened');
                }}
            />
            <StyledSlider>
                <CPBanner
                    filledHeaderWidth={
                        bannerConfig[bannerCondition].filledHeaderWidth
                    }
                    filledFooterWidth={
                        bannerConfig[bannerCondition].filledFooterWidth
                    }
                    angleRigthSize={
                        bannerConfig[bannerCondition].angleRigthSize
                    }
                    angleLeftSize={bannerConfig[bannerCondition].angleLeftSize}
                    style={bannerConfig[bannerCondition].style as CSSProperties}
                    color={baseColors.error()}
                >
                    <StyledContent $condition={bannerCondition}>
                        {bannerCondition === 'opened' ? (
                            <>
                                <StyledTitle>{content.title}</StyledTitle>
                                <StyledText>{content.text}</StyledText>
                            </>
                        ) : null}
                        <StyledExpander
                            $condition={bannerCondition}
                            onClick={() => {
                                if (bannerCondition === 'closed') {
                                    setBannerCondition('opened');
                                    return;
                                }
                                setBannerCondition('closed');
                            }}
                        >
                            {bannerCondition === 'closed'
                                ? 'Expand'
                                : 'Collapse'}
                        </StyledExpander>
                    </StyledContent>
                </CPBanner>
                <StyledSlideImage src={content.image} />
            </StyledSlider>
            {bannerCondition === 'background' ? (
                <StyledFooterContent>
                    <StyledTitle>{content.title}</StyledTitle>
                    <StyledText>{content.text}</StyledText>
                </StyledFooterContent>
            ) : null}
        </StyledContainer>
    );
};
