import img_1 from './assets/Group 14675.png';

export const tabs = [
    {
        value: 'Integrated SIEM',
        id: 0,
        title: ' All components of the lab are connected to ELK',
        text: 'ELK deployed exclusevely for you. Defbox can connect your SIEM system upon request',
        image: img_1,
    },
    {
        value: 'Healthcheck',
        id: 1,
        title: 'Defbox constantly checks lab if it has been hacked.',
        text: 'Users can hack the system manually or automatically to see if he was able to detect attack',
        image: img_1,
    },
    {
        value: 'Timeline',
        id: 2,
        title: 'Everything defbox do is logged to timeline',
        text: 'Auto exploits mapped into MITRE ATTACK. We evaluate users and grant points depending on the MITRE ATTACK stage, when attack was detected ',
        image: img_1,
    },
    {
        value: 'Highlights',
        id: 3,
        title: 'Get familiar with the system before defending it.',
        text: 'Defbox provide highlights that can help you get familiar with the system. Highlights are hidden in the system, can you find them all?',
        image: img_1,
    },
    {
        value: 'Lifecycle',
        id: 4,
        title: 'Discover. Detect. Repeat',
        text: 'Our labs can be recreated. Click on the button if you want to start again. SIEM will stay the same, only lab will be recreated so it can be exploited again.',
        image: img_1,
    },
];

export const bannerConfig = {
    opened: {
        filledHeaderWidth: 100,
        filledFooterWidth: 40,
        angleRigthSize: 32,
        angleLeftSize: 32,
        style: {
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            width: '50%',
            height: 'calc(100% + 16px)',
        },
    },
    closed: {
        filledHeaderWidth: 100,
        filledFooterWidth: 100,
        angleRigthSize: 24,
        angleLeftSize: 24,
        style: {
            position: 'absolute',
            top: '-8px',
            right: '-44px',
            width: '80px',
            height: 'calc(100% + 16px)',
        },
    },
    background: {
        filledHeaderWidth: 100,
        filledFooterWidth: 40,
        angleRigthSize: 32,
        angleLeftSize: 32,
        style: {
            position: 'absolute',
            top: 0,
            right: 0,
            width: '100%',
            height: 'calc(100% + 16px)',
        },
    },
};
