import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Button } from '@/components';
import { StyledFormContainer, StyledFieldsContainer } from '../../styled';
import { apiWorkshopLabClient as apiWithoutTokenClient } from '@/api/workshopLabApi';

export const VerifyForm = () => {
    const navigate = useNavigate();
    const goToSignIn = () => navigate('/auth');
    const { verify_uuid } = useParams();
    const [uuid] = useState(verify_uuid || '');
    const [pass, setPass] = useState('');

    const clickHandler = () => {
        try {
            apiWithoutTokenClient
                .post(
                    '/api/users/verify_email/',
                    {
                        uuid: uuid,
                        password: pass,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                .then(() => {
                    goToSignIn();
                });
        } catch (err) {
            console.log('err: ', err);
        }
    };
    const isDisabled = pass === '' || uuid === '';
    return (
        <StyledFormContainer>
            <StyledFieldsContainer>
                <Input
                    id="pass"
                    type="password"
                    label="Password"
                    onChange={(p) => setPass(p)}
                />
                <Button disabled={isDisabled} onClick={clickHandler}>
                    Log in
                </Button>
            </StyledFieldsContainer>
        </StyledFormContainer>
    );
};
