import { useNavigate } from "react-router-dom";
import {
    StyledContainer,
    StyledLeftCol,
    StyledRightCol,
    StyledPromoContainer,
    StyledPromoTitle,
    StyledPromoText,
    StyledContainerFormLogoHeader,
    StyledTitle,
    StyledSubtitle,
    StyledTitleContainer,
    StyledLogoText,
    StyledTitleWrapper,
    StyledLogo,
} from './styled';
import { IconLogo } from "../../components/Icons/index";
import { VerifyForm } from './components/VerifyForm/VerifyForm';


export const VerifyPage = () => {
    const navigate = useNavigate();
    const goToMain = () => navigate("/");
    return (
        <StyledContainer>
            <StyledLeftCol>
                <StyledLogo onClick={() => goToMain()}>
                    <IconLogo size={32} />
                    <StyledLogoText>DefenseBox</StyledLogoText>
                </StyledLogo>
                <StyledTitleContainer>
                    <StyledTitleWrapper>
                        <StyledTitle>
                            Verification almost complete
                        </StyledTitle>
                        <StyledSubtitle>
                            Fill in your password and Log In
                        </StyledSubtitle>
                    </StyledTitleWrapper>
                    
                </StyledTitleContainer>
            </StyledLeftCol>
            <StyledRightCol>
                <VerifyForm/> 
                <StyledPromoContainer>
                    <StyledPromoTitle>
                        Experience real-Life Cyber Threats in a safe Environment
                    </StyledPromoTitle>
                    <StyledPromoText>
                        Our platform offers an exersise where users will have to defend
                        against cyber attack and compete among each other to see who is
                        the best cyber-defender
                    </StyledPromoText>
                    <StyledPromoText>
                        Our blue team competition service provides a unique opportunity
                        for users to practice their defensive skills in a safe,
                        controlled environment.
                    </StyledPromoText>
                </StyledPromoContainer>
                <StyledContainerFormLogoHeader>
                    <IconLogo
                        color="rgba(255, 255, 255, 0.25)"
                        cursor="pointer"
                        figureFill="rgba(255, 255, 255, 0.64)"
                    />
                    <span
                        style={{ fontSize: "18px", lineHeight: "20px", opacity: 0.25 }}
                    >
                    DefenseBox
                    </span>
                </StyledContainerFormLogoHeader>
            </StyledRightCol>
        </StyledContainer>
    )
}
