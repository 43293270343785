import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getTimeRemaining } from '@/utils/date';
dayjs.extend(utc);

interface IProps {
    start: boolean;
    deployDate?: string;
}

export const useTimer = ({ start, deployDate }: IProps) => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);

    const setFormatTimer = (num: number) => {
        if (String(num).split('').length === 1) {
            return `0${num}`;
        }
        return String(num);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (start) {
                const today = dayjs
                    .utc()
                    .format('MMMM DD YYYY HH:mm:ss')
                    .toString();
                const firstDate = dayjs
                    .utc(deployDate)
                    .format('MMMM DD YYYY HH:mm:ss')
                    .toString();

                const t = getTimeRemaining(firstDate, today);
                setMinutes(t.minutes);
                setSeconds(t.seconds);
            } else {
                setSeconds(0);
                setMinutes(0);
                clearInterval(interval);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [start, deployDate, setSeconds, setMinutes]);
    return `${setFormatTimer(minutes)}:${setFormatTimer(seconds)}`;
};
