import { CSSProp } from 'styled-components';
import { CSSProperties, SyntheticEvent } from 'react';
import { StyledLogoFont, StyledLogo } from './styled';
import { IconLogo } from '../Icons/Logo/IconLogo';

interface ILogo {
    theme?: 'light' | 'dark';
    color?: string;
    figuteColor?: string;
    style?: CSSProperties;
    textStyle?: CSSProperties;
    textCSSProp?: CSSProp;
    CSSProp?: CSSProp;
    isTextLogo?: boolean;
    onClick?: (e: SyntheticEvent) => void;
}

export const Logo = ({
    theme = 'light',
    figuteColor,
    color,
    style,
    textStyle,
    textCSSProp,
    isTextLogo = true,
    CSSProp,
    onClick,
}: ILogo) => {
    return (
        <StyledLogo style={style} CSSProp={CSSProp} onClick={onClick}>
            <IconLogo color={color} figureFill={figuteColor} />
            {isTextLogo && (
                <StyledLogoFont
                    style={textStyle}
                    opacity={theme === 'light' ? 1 : 0.6}
                    CSSProp={textCSSProp}
                >
                    DEFBOX
                </StyledLogoFont>
            )}
        </StyledLogo>
    );
};
