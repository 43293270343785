import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { TOAST_TYPE, ToastContext } from './ToastContext';
import { StyledToast, StyledTitle } from './styled';
import { IconOk } from '../Icons/Ok/IconOk';
import { IconError } from '../Icons';

export const Toast = () => {
    const { isShow, title, type } = useContext(ToastContext);

    if (isShow) {
        return createPortal(
            <StyledToast $type={type} style={{ padding: '16px' }}>
                {type === TOAST_TYPE.SUCCESS ? <IconOk /> : <IconError />}
                <StyledTitle>{title}</StyledTitle>
            </StyledToast>,
            document.body
        );
    } else {
        return null;
    }
};
