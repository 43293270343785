import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError, ITask, ILab } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IInitialState } from '../types';

export const getHintThunk = createAsyncThunk<
    ITask,
    { taskId: ITask['id']; uuid: ILab['uuid'] },
    { rejectValue: IError }
>('taskSlice/getHintThunk', async ({ taskId, uuid }, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(
            `api/v1/labs/${uuid}/tasks/${taskId}/`
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.status,
            });
        }
    }
});

export const getHintBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(getHintThunk.pending, (state) => {
        state.hintState.fetching = true;
        state.messageAboutEmptyHint = undefined;
        state.hintState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getHintThunk.fulfilled, (state, action) => {
        state.hint = action.payload.hints;
        state.hintState.fetching = false;
        state.hintState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getHintThunk.rejected, (state, action) => {
        state.hintState.fetching = false;
        state.hintState.error = action.payload as IError;
    });
};

export const openHintThunk = createAsyncThunk<
    string | undefined,
    { taskId: ITask['id']; uuid: ILab['uuid'] },
    { rejectValue: IError }
>(
    'taskSlice/openHintThunk',
    async ({ taskId, uuid }, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiClient.get(
                `api/v1/labs/${uuid}/tasks/${taskId}/open_hint/`
            );
            dispatch(getHintThunk({ taskId, uuid }));
            const status = response.status;
            switch (status) {
                case 200:
                    return 'No more hints available for this task';
                case 201:
                default:
                    return undefined;
            }
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const openHintBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(openHintThunk.pending, (state) => {
        state.messageAboutEmptyHint = undefined;
        state.openHintState.fetching = true;
        state.openHintState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(openHintThunk.fulfilled, (state, action) => {
        state.messageAboutEmptyHint = action.payload;
        state.openHintState.fetching = false;
        state.openHintState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(openHintThunk.rejected, (state, action) => {
        state.messageAboutEmptyHint = undefined;
        state.openHintState.fetching = false;
        state.openHintState.error = action.payload as IError;
    });
};
