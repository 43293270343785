import { css } from 'styled-components';
import { PageHeader } from '@/components/HeadersV/PageHeader/PageHeader';
import { StyledPageContainer } from '../styled';
import {
    StyledPrice,
    StyledTeamHint,
    StyledTeamHintText,
    StyledTeamHintTitle,
} from './styled';
import { PriceTable } from '@/components/PriceTable/PriceTable';

export const Price = () => {
    const styleContainer = css`
        padding: 0;
        gap: 0;
    `;
    return (
        <StyledPageContainer cssProp={styleContainer}>
            <PageHeader title="Prices" />
            <StyledPrice>
                <StyledTeamHint>
                    <StyledTeamHintTitle>
                        Support Defbox - Get all labs and SSH access into them.
                    </StyledTeamHintTitle>
                    <StyledTeamHintText>
                        Currently our supporters receive all labs and full SSH
                        access. Your support will allow us to create more
                        advanced labs - Windows included - and deliver more
                        features like lab-hardening and competitions.
                    </StyledTeamHintText>
                </StyledTeamHint>
                <PriceTable />
            </StyledPrice>
        </StyledPageContainer>
    );
};
