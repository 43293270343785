import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import offer_img from '../../assets/SVGTitles/secondPagesTtotles/offer.svg';
import { OfferList, Button } from '../../components';
import {
    StyledOffer,
    StyledTitleContainer,
    ButtonContainer,
    StyledTitleImage,
} from './styled';

export const Offer = forwardRef(
    (_, ref: React.ForwardedRef<HTMLDivElement>) => {
        const navigate = useNavigate();
        return (
            <StyledOffer ref={ref} id="offer">
                <StyledTitleContainer>
                    <StyledTitleImage src={offer_img} />
                    <ButtonContainer>
                        <Button
                            color="primary"
                            onClick={() => navigate('/auth/write_to_us')}
                        >
                            Send email
                        </Button>
                        <span>leave us your email and we will contact you</span>
                    </ButtonContainer>
                </StyledTitleContainer>
                <OfferList />
            </StyledOffer>
        );
    }
);
