import { ReactNode, CSSProperties } from 'react';
import { baseColors } from '@/constants';
import { Angle } from './components/Angle';
import {
    StyledContainer,
    StyledHeader,
    StyledFillerHeader,
    StyledFooter,
    StyledFillerFooter,
    StyledContent,
} from './styled';

interface IProps {
    children?: ReactNode;
    angleLeftSize?: number;
    angleRigthSize?: number;
    filledHeaderWidth?: number;
    filledFooterWidth?: number;
    color?: string;
    style?: CSSProperties;
}

export const CPBanner = (props: IProps) => {
    const {
        children,
        angleLeftSize = 32,
        angleRigthSize = 42,
        color = baseColors.levelTwo(),
        style,
        filledHeaderWidth = 100,
        filledFooterWidth = 40,
    } = props;
    return (
        <StyledContainer style={style}>
            <StyledHeader>
                <Angle fill={color} direction="left" size={angleLeftSize} />
                <StyledFillerHeader
                    $width={filledHeaderWidth}
                    $color={color}
                    $height={angleLeftSize}
                />
            </StyledHeader>
            <StyledContent
                $color={color}
                $offset={angleLeftSize + angleRigthSize}
            >
                {children}
            </StyledContent>
            <StyledFooter>
                <StyledFillerFooter
                    $width={filledFooterWidth}
                    $color={color}
                    $height={angleRigthSize}
                />
                <Angle fill={color} direction="right" size={angleRigthSize} />
            </StyledFooter>
        </StyledContainer>
    );
};
