import { forwardRef } from 'react';
import { Slider } from '../../components';
import {
    StyledInside,
    StyledTitleImage,
    StyledTitleContainer,
    StyledSubtitle,
} from './styled';
import title from '../../assets/SVGTitles/secondPagesTtotles/inside.svg';

export const Inside = forwardRef(
    (_, ref: React.ForwardedRef<HTMLDivElement>) => {
        return (
            <StyledInside ref={ref} id="inside">
                <StyledTitleContainer>
                    <StyledTitleImage src={title} />
                    <StyledSubtitle>
                        Our labs are useful for teaching practical cyber defense
                        skills.
                        <br />
                        What's inside the lab?
                    </StyledSubtitle>
                </StyledTitleContainer>
                <Slider />
            </StyledInside>
        );
    }
);
