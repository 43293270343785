export enum ERRORS {
  CREATE_LAUNCH = "createLaunch",
  GET_LAUNCH_PAGE = "getLaunchPage",
  AUTH = "auth",
  SCOREBOARD = "scoreboard",
  JOIN_TEAM = "joinTeam",
}

export interface IError {
  code?: number;
  message?: string;
}
