import { createGlobalStyle } from 'styled-components';
import { typography, baseColors, link } from '@/constants';

export default createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    body{
        font-family: 'Source Sans Pro', sans-serif;
        color: #fff;
        background-color: #120D21;
    }
    h1, h2, h3, p, span {
        margin: 0;
        padding: 0;
        color: ${baseColors.white()};
    }
    h1{
        ${typography.title.XXL};
    }
    h2{
       ${typography.title.XL};
       
    }
    h3{
       ${typography.title.L};
    }
    p, span{
        ${typography.text.M};
    }
    a {
        ${link.text.M};
    }
    .Page{
        max-width: 804px;
        margin: 0 auto;
        min-height: calc(100vh - 136px);
    }
    button{
        outline: none;
        border: none;
        width: fit-content;
        color: #fff;
    }
    
`;
