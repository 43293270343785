import styled, { css, CSSProp } from 'styled-components';
import { baseColors, typography } from '../../constants/main';
import { ICard, DirectionType, SizeType } from './types';
import pythonImg from './img/python_big.jpg';

const containerStyle: CSSProp = css`
    display: grid;
    gap: 8px;
`;

interface IContainer extends Omit<ICard, 'templates'> {
    size: SizeType;
}
interface IStyledCard extends IContainer {
    bckg?: CSSProp;
}

const generateStyleContainer = ({ size, direction = 'left' }: IContainer) => {
    switch (size) {
        case 'L':
            return css`
                ${containerStyle};
                grid-template-columns: ${direction === 'left'
                    ? '2fr 1fr'
                    : '1fr 2fr'};
                height: 544px;
                min-height: 544px;
                max-height: 544px;
            `;
        case 'M':
            return css`
                ${containerStyle};
                grid-template-columns: 1fr 1fr;
            `;
        case 'S':
            return css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            `;
    }
};

const generateBckg = ({ bckg }: { bckg?: CSSProp }) => {
    if (bckg) {
        return bckg;
    }
    return css`
        background: ${baseColors.levelTwo()};
    `;
};

export const StyledMainContainer = styled.div<{ direction: DirectionType }>`
    ${({ direction }) => generateStyleContainer({ size: 'L', direction })};
`;

export const StyledCard = styled.div<IStyledCard>`
    ${({ bckg }) => generateBckg({ bckg })};
    ${generateStyleContainer};
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid ${baseColors.white(0.12)};
`;

export const StyledImageContainer = styled.div`
    position: relative;
    background-image: url(${pythonImg});
    background-position: center;
    background-repeat: no-repeat;
`;

export const StyledBlockTextContainer = styled.div`
    box-sizing: border-box;
    padding: 24px 40px 40px 40px;
    height: 100%;
    ${generateStyleContainer({ size: 'S' })};
`;

export const StyledBlockTextHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledTitle = styled.p<{ size: SizeType }>`
    ${({ size }) =>
        size === 'L' ? typography.title.XXXL : typography.title.XXL};
`;

export const StyledSubtitle = styled.p<{ size: SizeType }>`
    ${({ size }) => (size === 'L' ? typography.text.XL : typography.text.M)};
`;

export const StyledBlockTextFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const StyledConfigList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledConfigItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const generateStyleWrap = ({
    typeOfWrap,
}: {
    typeOfWrap: 'list' | 'value';
}) => {
    if (typeOfWrap === 'value')
        return css`
            display: flex;
            gap: 24px;
            box-sizing: border-box;
        `;
    return css`
        display: flex;
        flex-direction: column;
        gap: 4px;
        box-sizing: border-box;
        > span {
            ${typography.title.S};
        }
    `;
};

export const StyledConfigWrapper = styled.div<{
    typeOfWrap: 'list' | 'value';
}>`
    ${generateStyleWrap};
`;

export const StyledConfigKey = styled.p<{ typeOfWrap: 'list' | 'value' }>`
    width: 108px;
    ${({ typeOfWrap }) =>
        typeOfWrap === 'list' ? typography.text.S : typography.title.S};
`;

export const StyledConfigValue = styled.p`
    ${typography.text.S};
`;
