import styled from 'styled-components';
import { baseColorHover, baseColors, typography } from '@/constants';

export const StyledContainer = styled.div`
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
`;

export const StyledRow = styled.div`
    box-sizing: border-box;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledLabel = styled.p`
    ${typography.text.S};
`;

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 12px;
`;

export const StyledAchievement = styled.img`
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
`;

export const StyledEmptyAchievement = styled.div`
    width: 72px;
    height: 72px;
    min-width: 72px;
    min-height: 72px;
    max-width: 72px;
    max-height: 72px;
    border-radius: 200px;
    background: ${baseColors.levelOne(0.25)};
`;
