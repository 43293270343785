import { useMemo } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Card } from './Card/Card';
import { ICard } from './types';
import { ILabTeamplate } from '@/types';
import { StyledPageContainer } from '../styled';
import { LoaderDots as Loader } from '@/components';
import { StyledPageLoaderContainer } from '@/pages/styled';

export const Labs = () => {
    const { layout } = useAppSelector((state) => state.LayoutSlice);
    const {
        templates,
        templatesState: { fetching },
    } = useAppSelector((state) => state.TemplatesSlice);

    const labs: ICard[] = useMemo(() => {
        let cards: ICard[] = [];
        const templateIds = templates?.map((t) => t.id);
        if (templates && templates.length && layout && layout.length) {
            layout
                .filter((l) => {
                    if (
                        l.templates.length === 1 &&
                        templateIds?.includes(l.templates[0])
                    ) {
                        return true;
                    }
                    if (
                        l.templates.length === 2 &&
                        templateIds?.includes(l.templates[0]) &&
                        templateIds?.includes(l.templates[1])
                    ) {
                        return true;
                    }
                    return false;
                })
                .forEach((c) => {
                    cards.push({
                        direction: c.direction,
                        templates: [...templates].filter((i) => {
                            const il = i as unknown as ILabTeamplate;
                            return c.templates.includes(il.id);
                        }),
                    });
                });
        }
        return cards;
    }, [templates, layout]);

    return (
        <StyledPageContainer
            style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                boxSizing: 'border-box',
                padding: '8px',
            }}
        >
            {labs.map((l, idx) => (
                <Card {...l} top={idx === 0} key={idx} />
            ))}
            {fetching && (
                <StyledPageLoaderContainer>
                    <Loader />
                </StyledPageLoaderContainer>
            )}
        </StyledPageContainer>
    );
};
