import {
    Block,
    EmptyIcon,
    InProgressIcon,
    IconButton,
    LinkIcon,
    TextItem,
    CopyItem,
} from '@/components';
import { IWorkshop } from '@/types';
import { baseColors } from '@/constants';
import { getDateUTC } from '@/utils';
import { LabList } from './components';
import {
    StyledHeader,
    StyledTitleContainer,
    StyledTitle,
    StyledSubtitle,
    StyledBlockContainer,
    StyledTextContainer,
} from './styled';

interface IProps {
    workshop: IWorkshop;
    isOpened: boolean;
}

export const WorkshopCard = ({ workshop, isOpened }: IProps) => {
    const {
        id,
        name,
        lab_name,
        code,
        active,
        students_count,
        start_date,
        finish_date,
    } = workshop;
    const renderIcon = () => {
        if (active) {
            return <InProgressIcon fill={baseColors.primary()} />;
        }
        return <EmptyIcon fill={baseColors.white(0.5)} />;
    };
    const renderHeader = () => {
        return (
            <StyledHeader>
                {renderIcon()}
                <StyledTitleContainer>
                    <StyledTitle>{name}</StyledTitle>
                    <StyledSubtitle>
                        {active ? 'Active' : 'Not active'}
                    </StyledSubtitle>
                </StyledTitleContainer>
            </StyledHeader>
        );
    };
    const inviteLik = `${window.location.origin}/workshop/invite/${code}`;

    return (
        <Block
            isOpen={isOpened}
            title={renderHeader()}
            actions={
                <IconButton
                    iconLeft={<LinkIcon />}
                    label="Go to workshop"
                    size="M"
                />
            }
        >
            <StyledBlockContainer>
                <StyledTextContainer>
                    <CopyItem valueText={inviteLik} keyText="Link" />
                    <TextItem valueText={lab_name} keyText="Template" />
                    <TextItem
                        valueText={String(students_count)}
                        keyText="Students"
                    />
                    <TextItem
                        valueText={getDateUTC(start_date, 'DD.MM.YYYY, HH:mm')}
                        keyText="Start date"
                    />
                    <TextItem
                        valueText={getDateUTC(finish_date, 'DD.MM.YYYY, HH:mm')}
                        keyText="Finish date"
                    />
                </StyledTextContainer>
                <LabList workshopId={id} />
            </StyledBlockContainer>
        </Block>
    );
};
