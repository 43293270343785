import { FC, memo } from 'react';
import { useParams } from 'react-router-dom';
import { IScoreboardItem } from '@/types/scoreboard';
import { Tooltip, LoaderDots } from '@/components';
import achive from '@/assets/ach_active.png';
import {
    StyledScoreTable,
    StyledRow,
    StyledRowHeader,
    StyledCell,
    StyledCellHeader,
    StyledAchiveCell,
    StyledLoaderContainer,
    StyledAchieveCounter,
    StyledTableLoader,
} from './styled';
import { ISroreListResponseData } from '@/types';

interface IProps {
    // items?: IScoreboardItem[];
    scores?: ISroreListResponseData;
    clickOnRow?: (id: IScoreboardItem['id']) => void;
    getNextScores?: () => void;
    isLoader?: boolean;
}

export const ScoreboardTable = memo(function ScoreboardTable({
    // items = [],
    scores = { count: 0, next: null, previous: null, results: [] },
    isLoader,

    getNextScores,
    clickOnRow,
}: IProps) {
    const { results, next } = scores;
    const { scoreId, privateId } = useParams();
    const RenderRow: FC<IScoreboardItem> = (item) => {
        const { id, user, points, achievements } = item;
        const isActive =
            (!!scoreId && Number(scoreId) === id) ||
            (!!privateId && Number(privateId) === id);
        return (
            <StyledRow
                active={isActive}
                onClick={() => {
                    if (clickOnRow) clickOnRow(id);
                }}
            >
                <StyledCell width="128px">
                    <p>{user}</p>
                </StyledCell>
                <StyledCell width="56px" textAlign="right">
                    <p>{points}</p>
                </StyledCell>

                <StyledAchiveCell>
                    {achievements.length ? (
                        achievements.map((a, idx) => {
                            if (idx <= 3) {
                                return (
                                    <Tooltip
                                        text={a.title}
                                        key={a.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            key={a.id}
                                            src={a.picture ?? achive}
                                            style={{ height: '40px' }}
                                            alt="achieve"
                                        />
                                    </Tooltip>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <div style={{ height: '40px' }}>
                            <p>–</p>
                        </div>
                    )}
                    {achievements.length > 5 ? (
                        <StyledAchieveCounter>
                            +{achievements.length - 5}
                        </StyledAchieveCounter>
                    ) : null}
                </StyledAchiveCell>
            </StyledRow>
        );
    };
    return (
        <StyledScoreTable
            onScroll={(e) => {
                if (
                    getNextScores &&
                    e.currentTarget.scrollHeight -
                        e.currentTarget.scrollTop -
                        e.currentTarget.offsetHeight ===
                        0 &&
                    next &&
                    next !== null &&
                    !isLoader
                ) {
                    console.log('next: ', next);
                    getNextScores();
                }
            }}
        >
            <StyledRowHeader>
                <StyledCellHeader width="128px">
                    <p>User</p>
                </StyledCellHeader>
                <StyledCellHeader width="56px" textAlign="right">
                    <p> Points</p>
                </StyledCellHeader>
                <StyledCellHeader width="100%">
                    <p>Achivements</p>
                </StyledCellHeader>
            </StyledRowHeader>
            {results.map((i) => (
                <RenderRow {...i} key={i.id} />
            ))}
            {isLoader && (
                <StyledTableLoader>
                    <LoaderDots />
                </StyledTableLoader>
            )}
        </StyledScoreTable>
    );
});
