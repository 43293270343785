import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';
import { IGetHighlightResponse, IHighlightRequest } from '@/types/highlights';
import { UuidWithBasePath } from './types/general';

interface IInitialState {
    highlights?: IGetHighlightResponse;
    isLoadingHighlights: boolean;
    errorHighlights: IError;
    addHighlightFetching: boolean;
    errorAddHighlight: IError;
}

const initialState: IInitialState = {
    highlights: undefined,
    isLoadingHighlights: false,
    errorHighlights: {
        code: undefined,
        message: undefined,
    },
    addHighlightFetching: false,
    errorAddHighlight: {
        code: undefined,
        message: undefined,
    },
};

export const getHighlights = createAsyncThunk<
    IGetHighlightResponse,
    UuidWithBasePath,
    { rejectValue: IError }
>(
    'highlightsSlice/getHighlights',
    async ({ uuid, basePath }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/${basePath}/${uuid}/highlights/`,
                {
                    requestName: 'getHighlights',
                }
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const addHighlight = createAsyncThunk<
    void,
    IHighlightRequest,
    { rejectValue: IError }
>(
    'highlightsSlice/addHighlight',
    async ({ uuidWithBase, data }, { rejectWithValue, dispatch }) => {
        const { uuid, basePath } = uuidWithBase;
        try {
            await apiClient.post(
                `/api/v1/${basePath}/${uuid}/add_highlight/`,
                data,
                {
                    requestName: 'addHighlight',
                }
            );
            await dispatch(getHighlights(uuidWithBase));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const highlightsSlice = createSlice({
    name: 'highlightsSlice',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        errorReset(state) {
            const clearedError = {
                code: undefined,
                message: undefined,
            };
            state.errorAddHighlight = clearedError;
            state.errorHighlights = clearedError;
        },
        getHighlightsFetching(state) {
            state.isLoadingHighlights = true;
            state.errorHighlights = {
                code: undefined,
                message: undefined,
            };
        },
        getHighlightsSacces(state, action) {
            state.highlights = action.payload;
            state.isLoadingHighlights = false;
            state.errorHighlights = {
                code: undefined,
                message: undefined,
            };
        },
        getHighlightsFailure(state, action) {
            state.isLoadingHighlights = true;
            state.errorHighlights = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
        addHighlightFetching(state) {
            state.addHighlightFetching = true;
            state.errorAddHighlight = {
                code: undefined,
                message: undefined,
            };
        },
        addHighlightSaccess(state) {
            state.addHighlightFetching = false;
            state.errorAddHighlight = {
                code: undefined,
                message: undefined,
            };
        },
        addHighlightFailure(state, action) {
            state.addHighlightFetching = false;
            state.errorAddHighlight = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(getHighlights.pending, (state) => {
            state.isLoadingHighlights = true;
            state.errorHighlights = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getHighlights.fulfilled, (state, action) => {
            state.highlights = action.payload;
            state.isLoadingHighlights = false;
            state.errorHighlights = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getHighlights.rejected, (state, action) => {
            state.isLoadingHighlights = true;
            state.errorHighlights = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
        builder.addCase(addHighlight.pending, (state) => {
            state.addHighlightFetching = true;
            state.errorAddHighlight = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addHighlight.fulfilled, (state) => {
            state.addHighlightFetching = false;
            state.errorAddHighlight = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addHighlight.rejected, (state, action) => {
            state.addHighlightFetching = false;
            state.errorAddHighlight = {
                code: action.payload?.code,
                message: action.payload?.message,
            };
        });
    },
});

export default highlightsSlice.reducer;
