import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const InfoIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8 2C7.17157 2 6.5 2.67157 6.5 3.5C6.5 4.32843 7.17157 5 8 5C8.82843 5 9.5 4.32843 9.5 3.5C9.5 2.67157 8.82843 2 8 2Z"
                            fill={fill}
                        />
                        <path
                            d="M6 6C5.44772 6 5 6.44772 5 7C5 7.55228 5.44772 8 6 8H7V12H6C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9V7C9 6.44772 8.55228 6 8 6H6Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                            fill={fill}
                        />
                        <path
                            d="M8 10C8 9.44772 8.44772 9 9 9H12C13.1046 9 14 9.89543 14 11V19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19H10V11H9C8.44772 11 8 10.5523 8 10Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
