import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';
import { IGetHighlightResponse } from '@/types/highlights';
import { LAB_TYPE } from '@/constants/labs';

export const getFlagsThunk = createAsyncThunk<
    IGetHighlightResponse,
    { uuid: string; basePath?: LAB_TYPE },
    { rejectValue: IError }
>(
    'flagsSlice/getFlagsThunk',
    async ({ uuid, basePath = LAB_TYPE.DEFAULT }, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/${basePath}/${uuid}/highlights/`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
