import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IGameState {
  prepare: boolean;
  inProgress: boolean;
  stopped: boolean;
  dateComplete: string;
  planned_destroy_date: string | null;
  error: number | null;
  uuid: string;
}

const initialState: IGameState = {
  prepare: true,
  inProgress: false,
  stopped: false,
  dateComplete: "",
  planned_destroy_date: null,
  error: null,
  uuid: "",
};

export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    startFetching(state) {
      state.prepare = false;
      state.inProgress = true;
      state.stopped = false;
      state.error = null;
    },
    destroyDateFetching(state, action: PayloadAction<string | null>) {
      state.planned_destroy_date = action.payload;
    },
    uuidFetching(state, action: PayloadAction<string>) {
      state.uuid = action.payload;
    },
    endFetching(state) {
      state.stopped = true;
      state.inProgress = false;
      state.prepare = false;
      state.dateComplete = "";
      state.planned_destroy_date = null;
      state.error = null;
      state.uuid = "";
    },
    errorFetcing(state, action: PayloadAction<number | null>) {
      state.error = action.payload;
    },
    reset(state) {
      state.prepare = true;
      state.inProgress = false;
      state.stopped = false;
      state.dateComplete = "";
      state.planned_destroy_date = null;
      state.error = null;
      state.uuid = "";
    },
  },
});

export default gameSlice.reducer;
