import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IError, IWalkthroughs } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IState } from '../types';

export const getWalkthroughsThunk = createAsyncThunk<
    IWalkthroughs,
    string,
    { rejectValue: IError }
>(
    'walkthroughsSlice/getWalkthroughsThunk',
    async (uuid, { rejectWithValue }) => {
        try {
            const response = await apiClient.get(
                `/api/v1/labs/${uuid}/walkthroughs/`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.status,
                });
            }
        }
    }
);

export const getWalkthroughsBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(getWalkthroughsThunk.pending, (state) => {
        state.walkthroughsState.fetching = true;
        state.walkthroughsState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getWalkthroughsThunk.fulfilled, (state, action) => {
        state.walkthroughs = action.payload;
        state.walkthroughsState.fetching = false;
        state.walkthroughsState.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getWalkthroughsThunk.rejected, (state, action) => {
        state.walkthroughsState.fetching = false;
        state.walkthroughsState.error = action.payload as IError;
    });
};
