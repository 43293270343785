import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledNoData = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-top: 128px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

export const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 522px;
    text-align: center;
    gap: 8px;
`;

const generateTitleSize = ({ size }: { size?: 'L' | 'M' | 'S' }) => {
    if (size) return typography.title[size];
    return typography.title.L;
};

export const StyledTitle = styled.span<{ size?: 'L' | 'M' | 'S' }>`
    ${generateTitleSize};
    color: ${baseColors.white(0.5)};
`;

const generateTextSize = ({ size }: { size?: 'L' | 'M' | 'S' }) => {
    switch (size) {
        case 'M':
        case 'S':
            return typography.text.S;
        case 'L':
        default:
            return typography.text.M;
    }
};

export const StyledText = styled.p<{ size?: 'L' | 'M' | 'S' }>`
    ${generateTextSize};
    color: ${baseColors.white(0.5)};
`;

export const StyledButtons = styled.div`
    display: flex;
    gap: 8px;
`;
