import { nanoid } from 'nanoid';
import { ILastCheck } from '@/types';
import { InfoIcon, NoData } from '@/components';
import { baseColors } from '@/constants';

import {
    StyledTable,
    StyledTitle,
    StyledBody,
    StyledRow,
    StyledCell,
    StyledHeader,
    StyledValue,
} from './styled';
import { CheckHistoryRow } from './components';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
    title?: string;
    data: ILastCheck[];
}

export const CheckHistory = ({ data, title }: IProps) => {
    return (
        <StyledTable>
            <StyledTitle>{title || 'Check history'}</StyledTitle>
            {data.length !== 0 && (
                <StyledRow>
                    <StyledCell>
                        <StyledHeader>Date</StyledHeader>
                    </StyledCell>
                    <StyledCell>
                        <StyledHeader>Status</StyledHeader>
                    </StyledCell>
                    <StyledCell>
                        <StyledHeader>Description</StyledHeader>
                    </StyledCell>
                </StyledRow>
            )}
            <StyledBody>
                {data && data.length ? (
                    [...data]
                        .sort(
                            (a, b) =>
                                dayjs(b.created).valueOf() -
                                dayjs(a.created).valueOf()
                        )
                        .map((d) => {
                            return <CheckHistoryRow {...d} key={nanoid()} />;
                        })
                ) : (
                    <NoData
                        size="S"
                        title="Check history is empty"
                        style={{ padding: '12px' }}
                    />
                )}
            </StyledBody>
            {data.length ? (
                <StyledCell style={{ opacity: 0.5 }}>
                    <InfoIcon variant="S" />
                    <StyledValue>Date format is UTC</StyledValue>
                </StyledCell>
            ) : null}
        </StyledTable>
    );
};
