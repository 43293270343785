import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledHealthChecks = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 12px 16px 24px 16px;
    border: 0 0 6px 6px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: ${baseColors.levelThree()};
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
`;

export const StyledEmptyContainer = styled.div`
    display: felx;
    align-items: center;
    justify-content: center;
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
    text-align: center;
    width: 100%;
    opacity: 0.25;
`;

export const StyledCheckContainer = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
`;
