import { useContext, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ILastCheck } from '@/types';
import { Select, Button, NoData, LoaderDots } from '@/components';
import { SHAPE } from '@/components/Button/types';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { LAB_TYPE, baseColors } from '@/constants';
import {
    checkTasksThunk,
    checkTasksWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { CheckHistory } from '@/pages/LabWithScheme/components';
import { TerminalContext } from '../../context/terminalContext';
import { LabContext } from '@/pages/LabWithScheme/context/labContext';
import { VMTerminal, InputFlag } from '@/pages/LabWithScheme/components';
import {
    StyledForm,
    StyledFieldContainer,
    StyledLabel,
    StyledFieldDescription,
    StyledTerminalContainer,
} from './styled';
import { IVmOption } from '../../types';

export const Terminal = () => {
    const dispath = useAppDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { currentVmOption, setVmOption, taskOptions, vmOptions } =
        useContext(TerminalContext);
    const { type } = useContext(LabContext);
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    const {
        checkTaskState: { fetching },
        tasks,
    } = useAppSelector((state) => state.TaskSlice);

    const lastCheckTask = useMemo<ILastCheck[] | undefined>(() => {
        if (tasks && currentVmOption) {
            const searchedTask = tasks.find(
                (t) =>
                    String(t.id) ===
                    String(currentVmOption.id).replace('_task', '')
            );
            if (searchedTask)
                return searchedTask.last_checks.filter(
                    (_, idx, arr) => idx === arr.length - 1
                );
        }
        return undefined;
    }, [tasks, currentVmOption]);

    const renderButton = () => {
        if (fetching) {
            return (
                <div
                    style={{
                        height: '36px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <LoaderDots />
                </div>
            );
        }
        if (
            currentVmOption &&
            taskOptions.findIndex((to) => to.id === currentVmOption.id) >= 0
        ) {
            if (currentVmOption.is_flag) {
                return (
                    <InputFlag
                        isCloseSidebar
                        uuid={lab?.uuid}
                        cardId={Number(
                            String(currentVmOption.id).replace('_task', '')
                        )}
                        buttonProps={{ view: SHAPE.PRIMARY }}
                    />
                );
            }
            return (
                <Button
                    onClick={() => {
                        if (lab?.uuid) {
                            const checkRequest = {
                                uuid: lab?.uuid,
                                cardId: Number(
                                    String(currentVmOption.id).replace(
                                        /_task$/,
                                        ''
                                    )
                                ),
                            };
                            dispath(
                                type === LAB_TYPE.DEFAULT
                                    ? checkTasksThunk(checkRequest)
                                    : checkTasksWsThunk(checkRequest)
                            );
                            navigate(pathname.replace(/[aA-zZ]*$/, 'tasks'));
                        }
                    }}
                >
                    Check task
                </Button>
            );
        }
    };

    if (!lab?.terminal_available) {
        return (
            <NoData
                title="Tasks are available to premium users only"
                text="Subscribe now to get the most out of Defbox"
                buttons={[
                    {
                        title: 'Subscribe',
                        variants: SHAPE.PRIMARY,
                        action() {
                            window.open(
                                process.env.REACT_APP_PAYMENT_URL,
                                '_blank'
                            );
                        },
                    },
                ]}
            />
        );
    }

    return (
        <StyledForm>
            <Select
                style={{ maxWidth: '640px' }}
                options={vmOptions}
                label="Select VM"
                defaultValue={currentVmOption?.id}
                onChange={(opt) => {
                    if (setVmOption) setVmOption(opt as IVmOption);
                }}
            />
            <span className="or">OR</span>
            <StyledFieldContainer>
                <Select
                    options={taskOptions.filter((t) => !!t.vmId)}
                    label="Select task"
                    defaultValue={currentVmOption?.id}
                    onChange={(opt) => {
                        if (setVmOption) setVmOption(opt as IVmOption);
                    }}
                />
                {currentVmOption?.description &&
                    currentVmOption?.description.length && (
                        <StyledFieldDescription>
                            <StyledLabel>Description</StyledLabel>
                            {currentVmOption?.description}
                        </StyledFieldDescription>
                    )}
            </StyledFieldContainer>

            {currentVmOption && (
                <StyledTerminalContainer>
                    <StyledLabel style={{ color: baseColors.white(0.8) }}>
                        Terminal
                    </StyledLabel>
                    <VMTerminal uuid={currentVmOption.vmId} />
                </StyledTerminalContainer>
            )}
            {lastCheckTask && (
                <CheckHistory data={lastCheckTask} title="Last check" />
            )}
            {renderButton()}
        </StyledForm>
    );
};
