import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import {
    IScoreboardItem,
    IScoreboardItemDetail,
    IError,
    ISroreListResponseData,
} from '@/types';

interface IInitialState {
    scoreList?: ISroreListResponseData;
    scoreDetail?: IScoreboardItemDetail;
    isLoadingScoreList: boolean;
    errorScoreList: IError;
    isLoadingScoreDetail: boolean;
    errorScoreDetail: IError;
    //
    sList?: IScoreboardItem[];
    loader: boolean;
    scorBError: IError;
    team: string;
    teamError: IError;
    joinTeamLoader: boolean;
}

const initialState: IInitialState = {
    scoreList: undefined,
    scoreDetail: undefined,
    isLoadingScoreList: false,
    errorScoreList: {
        code: undefined,
        message: undefined,
    },
    isLoadingScoreDetail: false,
    errorScoreDetail: {
        code: undefined,
        message: undefined,
    },
    //
    sList: [],
    loader: false,
    scorBError: {
        code: undefined,
        message: undefined,
    },
    team: '',
    teamError: {
        code: undefined,
        message: undefined,
    },
    joinTeamLoader: false,
};

export const getNextScores = createAsyncThunk<
    ISroreListResponseData,
    string,
    { rejectValue: IError }
>('scoreboard/getNextScores', async (path, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(path.replace(/^.*\/api/, '/api'));
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getScoreboard = createAsyncThunk<
    ISroreListResponseData,
    { team: string | undefined; limit: number },
    { rejectValue: IError }
>('scoreboard/getScoreboard', async (params, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/scoreboard/', {
            params,
        });
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getScoreDetail = createAsyncThunk<
    IScoreboardItemDetail,
    number,
    { rejectValue: IError }
>('scoreboard/getScoreDetail', async (scoreId, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/scoreboard/${scoreId}`, {
            requestName: 'getScoreDetail',
        });
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const scoreboardSlice = createSlice({
    name: 'scoreboard',
    initialState,
    reducers: {
        getScoreDetailFetching(state) {
            state.errorScoreDetail = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreDetail = true;
        },
        getScoreDetailSaccess(state, action) {
            state.scoreDetail = action.payload;
            state.errorScoreDetail = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreDetail = false;
        },
        getScoreDetailFailure(state, action) {
            state.errorScoreDetail = action.payload;
            state.isLoadingScoreDetail = false;
        },
        getScoreboardFetching(state) {
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = true;
        },
        getScoreboardSaccess(state, action) {
            state.scoreList = action.payload;
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = false;
        },
        getScoreboardFailure(state, action) {
            state.errorScoreList = action.payload;
            state.isLoadingScoreList = false;
        },
        /////
        sListFetching(state, action: PayloadAction<IScoreboardItem[]>) {
            state.loader = false;
            state.sList = action.payload;
        },
        teamFetching(state, action: PayloadAction<string>) {
            state.loader = false;
            state.team = action.payload;
        },
        loaderFetching(state) {
            state.loader = true;
        },
        scorBErrorFetching(state, action: PayloadAction<IError>) {
            state.loader = false;
            state.scorBError = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
        teamErrorFetching(state, action: PayloadAction<IError>) {
            state.loader = false;
            state.teamError = {
                code: action.payload.code,
                message: action.payload.message,
            };
        },
        joinTeamLoaderFetching(state, action: PayloadAction<boolean>) {
            state.joinTeamLoader = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getNextScores.pending, (state) => {
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = true;
        });
        builder.addCase(getNextScores.fulfilled, (state, action) => {
            // state.scoreList = action.payload;
            if (state.scoreList) {
                state.scoreList = {
                    ...action.payload,
                    results: [
                        ...state.scoreList.results,
                        ...action.payload.results,
                    ],
                };
            }
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = false;
        });
        builder.addCase(getNextScores.rejected, (state, action) => {
            state.errorScoreList = action.payload as IError;
            state.isLoadingScoreList = false;
        });
        //
        builder.addCase(getScoreboard.pending, (state) => {
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = true;
        });
        builder.addCase(getScoreboard.fulfilled, (state, action) => {
            state.scoreList = action.payload;
            state.errorScoreList = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreList = false;
        });
        builder.addCase(getScoreboard.rejected, (state, action) => {
            state.errorScoreList = action.payload as IError;
            state.isLoadingScoreList = false;
        });
        builder.addCase(getScoreDetail.pending, (state) => {
            state.errorScoreDetail = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreDetail = true;
        });
        builder.addCase(getScoreDetail.fulfilled, (state, action) => {
            state.scoreDetail = action.payload;
            state.errorScoreDetail = {
                code: undefined,
                message: undefined,
            };
            state.isLoadingScoreDetail = false;
        });
        builder.addCase(getScoreDetail.rejected, (state, action) => {
            state.errorScoreDetail = action.payload as IError;
            state.isLoadingScoreDetail = false;
        });
    },
});

export default scoreboardSlice.reducer;
