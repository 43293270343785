import styled, { keyframes, css } from "styled-components";
import { baseColors } from "../../../constants/main";

export const StyledLoader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
  position: relative;
  width: 32px;
  height: 20px;
`;

const dotFlashing = keyframes`
0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
`;

export const StyledDot = css<{ color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  animation: ${dotFlashing} 1s infinite linear alternate;
  opacity: 1;
`;

export const StyledDotOne = styled.div<{ color: string }>`
  ${StyledDot};
  animation-delay: 0s;
`;

export const StyledDotTwo = styled.div<{ color: string }>`
  ${StyledDot};
  animation-delay: 0.5s;
`;

export const StyledDotThree = styled.div<{ color: string }>`
  ${StyledDot};
  animation-delay: 1s;
`;

export const StyledPageOverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 10000;
  background: ${baseColors.bckg(0.8)};
`;
