import { TrackList, LabList } from './components';
import { StyledListContainer } from './styled';

export const Templates = () => {
    return (
        <StyledListContainer>
            <TrackList />
            <LabList />
        </StyledListContainer>
    );
};
