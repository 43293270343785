import { ILastCheck } from '@/types';
import { LoaderDots, OkIcon, FailedIcon, Tag } from '@/components';
import { baseColors } from '@/constants';
import { StyledRow, StyledCell, StyledValue } from '../../styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const CheckHistoryRow = ({
    created,
    in_progress,
    reason,
    result,
}: ILastCheck) => {
    const renderStatus = () => {
        if (in_progress) {
            return <LoaderDots />;
        }
        if (result) {
            return (
                <Tag
                    style={{
                        background: baseColors.ok(),
                    }}
                    title="SUCCESS"
                    icon={<OkIcon variant="S" />}
                />
            );
        }
        return (
            <Tag
                style={{
                    background: baseColors.error(),
                }}
                title="FAILED"
                icon={<FailedIcon variant="S" />}
            />
        );
    };
    const renderReason = () => {
        if (in_progress) {
            return <StyledValue>{reason || '–'}</StyledValue>;
        }
        if (result) {
            return <StyledValue>{reason || '–'}</StyledValue>;
        }
        return (
            <StyledValue style={{ color: baseColors.error() }}>
                {reason || '–'}
            </StyledValue>
        );
    };
    return (
        <StyledRow>
            <StyledCell>
                <StyledValue>
                    {dayjs.utc(created).format('MM.DD.YYYY, HH:mm').toString()}
                </StyledValue>
            </StyledCell>
            <StyledCell>{renderStatus()}</StyledCell>
            <StyledCell>{renderReason()}</StyledCell>
        </StyledRow>
    );
};
