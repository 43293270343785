import { IOffer } from './types';

export const offerList: IOffer[] = [
    {
        title: 'Free',
        rows: [
            {
                title: 'Polygons',
                value: '8',
                enabled: true,
            },
            {
                title: 'SSH access to the polygon',
                enabled: false,
            },
            {
                title: 'Teacher\'s personal page',
                enabled: false,
            },
            {
                title: 'Custom polygons and scenarios',
                enabled: false,
            },
            {
                title: 'Connecting polygons to your SIEM',
                enabled: false,
            },
            {
                title: 'Prioritization of tasks in our roadmap',
                enabled: false,
            },
        ],
    },
    {
        title: 'Supporters',
        rows: [
            {
                title: 'Polygons',
                value: 'All',
                enabled: true,
            },
            {
                title: 'SSH access to the polygon',
                enabled: true,
            },
            {
                title: 'Teacher\'s personal page',
                enabled: false,
            },
            {
                title: 'Custom polygons and scenarios',
                enabled: false,
            },
            {
                title: 'Connecting polygons to your SIEM',
                enabled: false,
            },
            {
                title: 'Prioritization of tasks in our roadmap',
                enabled: false,
            },
        ],
    },
    {
        title: 'Enterprise/Universities',
        rows: [
            {
                title: 'Polygons',
                value: 'All',
                enabled: true,
            },
            {
                title: 'SSH access to the polygon',
                enabled: true,
            },
            {
                title: 'Teacher\'s personal page',
                enabled: true,
            },
            {
                title: 'Custom polygons and scenarios',
                enabled: true,
            },
            {
                title: 'Connecting polygons to your SIEM',
                enabled: true,
            },
            {
                title: 'Prioritization of tasks in our roadmap',
                enabled: true,
            },
        ],
    },
];
