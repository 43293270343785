import { IState } from './types';

export const initialState: IState = {
    auth: false,
    refresh: undefined,
    access: undefined,
    isLoading: false,
    error: {
        code: undefined,
        message: undefined,
    },
};
