import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { baseColors } from '@/constants';
import { CheckIcon, MinusIcon } from '@/components';
import { IProps } from './types';
import {
    StyledCheckbox,
    StyledContainer,
    StyledCheckboxWrapper,
} from './styled';

export const Checkbox = (props: IProps) => {
    const {
        id: inputId,
        label,
        checked: inputChecked,
        onBlur,
        onChange,
        onClick,
        onFocus,
        size = 'M',
        disabled,
        type = 'default',
    } = props;
    const [checked, setChecked] = useState<boolean>(inputChecked ?? false);
    const [isHover, setIsHover] = useState<boolean>(false);
    const id = inputId || nanoid();
    useEffect(() => {
        if (typeof inputChecked !== 'undefined') {
            setChecked(inputChecked);
        }
    }, [inputChecked, setChecked]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked);
        }
        setChecked(event.target.checked);
    };
    return (
        <StyledContainer
            htmlFor={id}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            disabled={disabled}
        >
            <input
                style={{ display: 'none' }}
                id={id}
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={handleChange}
            />
            <StyledCheckboxWrapper size={size}>
                <StyledCheckbox
                    disabled={disabled}
                    hover={isHover}
                    checked={checked}
                >
                    {checked && (
                        <>
                            {type === 'default' ? (
                                <CheckIcon
                                    variant="S"
                                    size={size === 'M' ? 12 : 8}
                                    fill={baseColors.white()}
                                />
                            ) : (
                                <MinusIcon
                                    variant="S"
                                    size={size === 'M' ? 12 : 8}
                                    fill={baseColors.white()}
                                />
                            )}
                        </>
                    )}
                </StyledCheckbox>
            </StyledCheckboxWrapper>
            {label}
        </StyledContainer>
    );
};
