import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledContainer = styled.div`
    box-sizing: border-box;
    padding: 8px 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;

export const StyledWalkthroughs = styled.div`
    box-sizing: border-box;
    padding: 32px 56px 48px 56px;
    overflow: hidden;
    width: 100%;
    border: 1px solid ${baseColors.white(0.1)};
    background: ${baseColors.levelTwo()};
    border-radius: 8px;
    > img,
    p > img,
    span > img,
    a > img,
    h1 > img,
    h2 > img,
    h3 > img {
        width: 100%;
    }
`;
