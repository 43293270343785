import styled, { css } from 'styled-components';
import { baseColors, typography, link } from '../../constants';

const gap = {
    row: {
        S: '8px',
        M: '12px',
    },
    column: {
        S: '2px',
        M: '4px',
    },
};

export const StyledTextItem = styled.div<{
    direction: 'row' | 'column';
    size: 'S' | 'M';
}>`
    display: flex;
    flex-direction: ${({ direction }) => direction};
    gap: ${({ direction, size }) => gap[direction][size]};
    overflow: hidden;
`;

const cutText = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledKey = styled.p<{
    width: number;
    cut?: boolean;
    size: 'S' | 'M';
    keySize?: 'S' | 'M';
}>`
    ${({ size, keySize }) => typography.text[keySize ? keySize : size]};
    color: ${baseColors.white(0.5)};
    min-width: ${({ width }) => width}px;
    ${({ cut }) => (cut ? cutText : null)};
`;

export const StyledContainerValue = styled.div`
    display: flex;
    gap: 8px;
    overflow: hidden;
`;

export const StyledValue = styled.p<{
    cut?: boolean;
    size: 'S' | 'M';
    valueSize?: 'S' | 'M';
}>`
    ${({ size, valueSize }) => typography.text[valueSize ? valueSize : size]};
    ${({ cut }) => (cut ? cutText : null)};
`;

export const StyledLink = styled.a<{
    cut?: boolean;
    size: 'S' | 'M';
    valueSize?: 'S' | 'M';
}>`
    ${({ size, valueSize }) => link.text[valueSize ? valueSize : size]};
    ${({ cut }) => (cut ? cutText : null)};
`;

const iconSize = {
    S: '16px',
    M: '20px',
};

export const StyledContainerIcon = styled.div<{
    size: 'S' | 'M';
    valueSize?: 'S' | 'M';
}>`
    width: ${({ size, valueSize }) => iconSize[valueSize ? valueSize : size]};
    height: ${({ size, valueSize }) => iconSize[valueSize ? valueSize : size]};
    display: flex;
    align-items: center;
    justify-content: center;
`;
