import { TemplateCard, UserIcon } from '@/components';
import { IInfluencerLab } from '@/types';
import {
    StyledContainer,
    StyledImageContainer,
    StyledContent,
    StyledNameContainer,
    StyledName,
    StyledJobTitle,
    StyledText,
    StyledLabList,
} from './styled';
import { nanoid } from 'nanoid';

export const InfluencerLabCard = ({
    influencer_picture,
    influencer_name,
    description,
    influencer_title,
    labs,
}: IInfluencerLab) => {
    return (
        <StyledContainer>
            <StyledImageContainer>
                {influencer_picture ? (
                    <img
                        src={influencer_picture}
                        className="userpic_inlncr"
                        alt="userpic"
                    />
                ) : (
                    <UserIcon />
                )}
            </StyledImageContainer>
            <StyledContent>
                <StyledNameContainer>
                    <StyledName>{influencer_name}</StyledName>
                    <StyledJobTitle>{influencer_title}</StyledJobTitle>
                </StyledNameContainer>
                <StyledText>{description}</StyledText>
                <StyledLabList>
                    {labs.map((l) => {
                        return (
                            <TemplateCard
                                {...l}
                                size="S"
                                key={nanoid()}
                                isGeneralStats
                            />
                        );
                    })}
                </StyledLabList>
            </StyledContent>
        </StyledContainer>
    );
};
