import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const BookIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 11.8V3.8C2 2.2536 3.2536 1 4.8 1H13C13.2652 1 13.5196 1.10536 13.7071 1.29289C13.8946 1.48043 14 1.73478 14 2V10C14 10.1939 13.9448 10.3749 13.8493 10.5281C13.6574 10.8526 13.4223 11.4104 13.3556 11.979C13.289 12.5469 13.3996 12.9844 13.7057 13.2915C13.8875 13.4726 14 13.7232 14 14C14 14.5523 13.5523 15 13 15H4.8C3.2536 15 2 13.7464 2 12.2C2 12.1315 2.00689 12.0647 2.02001 12C2.00689 11.9354 2 11.8685 2 11.8ZM4.8 3C4.35817 3 4 3.35817 4 3.8V9.11595C4.25348 9.04051 4.52201 9 4.8 9H12L12 3H4.8ZM11.4028 13H4.8C4.35817 13 4 12.6419 4 12.2C4 12.1315 3.99311 12.0647 3.97999 12C3.99311 11.9354 4 11.8685 4 11.8C4 11.3582 4.35817 11 4.8 11H11.5124C11.4494 11.2387 11.3994 11.4892 11.3693 11.746C11.3235 12.1362 11.3219 12.5662 11.4028 13Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2 5V19C2 21.2091 3.79086 23 6 23H21C21.4285 23 21.7941 22.7304 21.9364 22.3516C22.0911 21.9414 21.9601 21.4669 21.5969 21.1976C21.5847 21.1885 21.5722 21.1796 21.5596 21.1711C21.5559 21.1684 21.5498 21.164 21.5416 21.1577C21.5187 21.1403 21.4798 21.1094 21.43 21.0651C21.3296 20.9759 21.1902 20.8369 21.05 20.65C20.7719 20.2792 20.5 19.7345 20.5 19C20.5 18.2655 20.7719 17.7208 21.05 17.35C21.1902 17.1631 21.3296 17.0241 21.43 16.9349C21.4798 16.8906 21.5187 16.8597 21.5416 16.8423C21.5497 16.8361 21.5557 16.8317 21.5594 16.829C21.5798 16.8152 21.5996 16.8007 21.6189 16.7855C21.8446 16.6081 21.9753 16.3491 21.9969 16.0791C21.9994 16.0475 22.0005 16.0157 22 15.984V2C22 1.44772 21.5523 1 21 1H6C3.79086 1 2 2.79086 2 5ZM4 5C4 3.89543 4.89543 3 6 3H6.99996V15H6C5.27143 15 4.58835 15.1948 4 15.5351V5ZM8.99996 15V3H20V15H8.99996ZM6 21H18.9395C18.6855 20.4563 18.5 19.7883 18.5 19C18.5 18.2117 18.6855 17.5437 18.9395 17H6C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
