import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IStatisticFromLab, IError } from '@/types';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';

export const getStatisticWsLabThunk = createAsyncThunk<
    IStatisticFromLab,
    string,
    { rejectValue: IError }
>(
    'labWithSchemeSlice/getStatisticWsLabThunk',
    async (uuid, { rejectWithValue }) => {
        try {
            const response = await apiWorkshopLabClient.get(
                `api/v1/workshoplabs/${uuid}/get_stats/`
            );
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
