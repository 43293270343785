import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './constants';
import {
    authFetchingAccessAction,
    exitAuthAction,
    authFetchingErrorAction,
} from './Actions';
import {
    fetchAuthBuilder,
    fetchAuthFromInviteBuilder,
    refreshAuthBuilder,
} from './AsyncThunks';

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        authFetchingAccessAction,
        exitAuthAction,
        authFetchingErrorAction,
    },
    extraReducers(builder) {
        fetchAuthBuilder(builder);
        fetchAuthFromInviteBuilder(builder);
        refreshAuthBuilder(builder);
    },
});

export default authSlice.reducer;
