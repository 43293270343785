import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const CheckIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.6764 3.26348C14.0832 3.63704 14.1101 4.26964 13.7365 4.67641L7.30796 11.6764C7.11858 11.8826 6.85142 12 6.57143 12C6.29145 12 6.02429 11.8826 5.8349 11.6764L2.26348 7.78752C1.88991 7.38075 1.91683 6.74816 2.3236 6.37459C2.73037 6.00102 3.36297 6.02794 3.73654 6.43471L6.57143 9.5216L12.2635 3.3236C12.637 2.91683 13.2696 2.88991 13.6764 3.26348Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.683 5.26958C21.0864 5.64679 21.1076 6.2796 20.7304 6.683L10.4447 17.683C10.2556 17.8852 9.99115 18 9.71429 18C9.43743 18 9.17296 17.8852 8.98387 17.683L3.26958 11.5719C2.89237 11.1685 2.91361 10.5357 3.31701 10.1585C3.72041 9.78126 4.35322 9.8025 4.73043 10.2059L9.71429 15.5359L19.2696 5.31701C19.6468 4.91361 20.2796 4.89237 20.683 5.26958Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
