import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    MoreIcon,
    LinkIcon,
    CheckIcon,
    TextItem,
    Tooltip,
    CrownIcon,
} from '@/components';
import {
    StyledCard,
    StyledTitleContainer,
    StyledStatsItem,
    StyledTitle,
} from './styled';
import { baseColors } from '@/constants';
import { IProps } from '../../types';

export const SmallCard = (props: IProps) => {
    const {
        lab: { name, id, is_premium },
        total,
        completed,
        flags_total,
        tasks_total,
        isGeneralStats,
    } = props;
    const navigate = useNavigate();
    const inputPercent = (completed / total) * 100;
    const percent = Number.isNaN(inputPercent) ? 0 : inputPercent;
    const [title, setTitle] = useState(name);
    const nameRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
        if (nameRef?.current && nameRef.current.offsetHeight > 20) {
            setTitle(title.slice(0, title.length - 4) + '...');
        }
    }, [nameRef?.current?.offsetHeight, title]);

    const isTooltip = title.indexOf('...') >= 0;

    return (
        <StyledCard
            onClick={(e) => {
                e.stopPropagation();
                navigate(`/labs/${id}`);
            }}
        >
            <StyledTitleContainer>
                {percent >= 100 ? (
                    <CheckIcon variant="S" fill={baseColors.ok()} />
                ) : (
                    <svg
                        transform={`rotate(-${
                            (100 - percent) * ((Math.PI * 360) / 100)
                        })`}
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="10"
                            cy="10"
                            r="6"
                            stroke="white"
                            stroke-opacity="0.1"
                            stroke-width="3"
                        />
                        <circle
                            cx="10"
                            cy="10"
                            r="6"
                            stroke="#145ADB"
                            stroke-width="3"
                            stroke-dasharray={`${
                                percent * ((Math.PI * 12) / 100)
                            } ${Math.PI * 12}`}
                        />
                    </svg>
                )}
                {isTooltip ? (
                    <Tooltip text={name}>
                        <StyledTitle ref={nameRef}>{title}</StyledTitle>
                    </Tooltip>
                ) : (
                    <StyledTitle ref={nameRef}>{title}</StyledTitle>
                )}
            </StyledTitleContainer>
            <div className="iconsContainer">
                {is_premium && (
                    <CrownIcon variant="S" fill={baseColors.warning()} />
                )}
                {isGeneralStats ? (
                    <StyledStatsItem>
                        <TextItem
                            size="S"
                            keyText="Flags"
                            widthKey={32}
                            valueText={String(flags_total)}
                        />
                        <TextItem
                            size="S"
                            keyText="Tasks"
                            widthKey={32}
                            valueText={String(tasks_total)}
                        />
                    </StyledStatsItem>
                ) : null}
                <div className="moreIcon">
                    <MoreIcon variant="S" />
                </div>
                <div className="linkIcon">
                    <LinkIcon variant="S" />
                </div>
            </div>
        </StyledCard>
    );
};
