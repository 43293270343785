import { IState } from './types';

export const initialState: IState = {
    walkthroughs: undefined,
    walkthroughsState: {
        fetching: false,
        error: {
            code: undefined,
            message: undefined,
        },
    },
};
