import { nanoid } from 'nanoid';
import { FC, useMemo, useState } from 'react';
import { PagesTabsProps, ITab } from './types';
import { StyledTabList, StyledTabItem, StyledTabItemLink } from './styled';
import { baseColors } from '@/constants';

export const PagesTabs: FC<PagesTabsProps> = (props) => {
    const {
        data,
        activeKey: inputActiveKey,
        navLink,
        onChange,
        style,
        tabStyle,
        titleStyle,
        stylesConditions,
        size = 'M',
        height = 48,
        color = baseColors.primary(),
        renderTab,
    } = props;
    const [activeKey, setActiveKey] = useState<ITab['id'] | undefined>(
        inputActiveKey
    );

    const tabs: ITab[] = useMemo(() => {
        let tmpTabs: ITab[] = [];
        if (activeKey) {
            const keys: (string | number)[] = data.map((tab) => {
                return tab.id;
            });
            if (keys.includes(activeKey)) {
                tmpTabs = data.map((tab, idx) => {
                    if (keys.indexOf(activeKey) === idx) {
                        return { ...tab, active: true };
                    }
                    return { ...tab, active: false };
                });
            } else {
                tmpTabs = data.map((tab, idx) => {
                    if (idx === 0) {
                        return { ...tab, active: true };
                    }
                    return { ...tab, active: false };
                });
            }
        } else {
            tmpTabs = data.map((tab, idx) => {
                if (idx === 0) {
                    return { ...tab, active: true };
                }
                return { ...tab, active: false };
            });
        }
        return tmpTabs;
    }, [data, activeKey]);

    const clickHandler = (key: ITab['id']) => {
        setActiveKey(key);
        if (onChange) {
            onChange(key);
        }
    };

    return (
        <StyledTabList style={style} height={height}>
            {tabs.map((tab) => {
                if (navLink) {
                    return (
                        <div
                            onClick={() => clickHandler(tab.id)}
                            style={{ display: 'flex', cursor: 'pointer' }}
                            key={nanoid()}
                        >
                            {!!renderTab ? (
                                renderTab(tab)
                            ) : (
                                <StyledTabItemLink
                                    $color={color}
                                    size={size}
                                    to={String(tab.id)}
                                    style={tabStyle}
                                    $stylesConditions={stylesConditions}
                                >
                                    <span style={titleStyle}>{tab.value}</span>
                                </StyledTabItemLink>
                            )}
                        </div>
                    );
                }
                return (
                    <div
                        onClick={() => clickHandler(tab.id)}
                        style={{ display: 'flex', cursor: 'pointer' }}
                        key={nanoid()}
                    >
                        {!!renderTab ? (
                            renderTab(tab)
                        ) : (
                            <StyledTabItem
                                $color={color}
                                size={size}
                                style={tabStyle}
                                isActive={tab.active}
                                $stylesConditions={stylesConditions}
                            >
                                <span style={titleStyle}>{tab.value}</span>
                            </StyledTabItem>
                        )}
                    </div>
                );
            })}
        </StyledTabList>
    );
};
