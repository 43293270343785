import { IState } from './types';

export const initialState: IState = {
    profile: undefined,
    isLoadingProfile: false,
    errorProfile: {
        code: undefined,
        message: undefined,
    },
    isLoadingTeam: false,
    errorTeam: {
        code: undefined,
        message: undefined,
    },
    unsubscribeState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
    changeUserNameState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
    changePasswordState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
};
