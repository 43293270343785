import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './components';
import { useAppDispatch } from '@/hooks/redux';
import {
    getProgressLabsThunk,
    getTracksThunk,
    getInfluencerLabsThunk,
} from '@/store/reducers/LabListsSlice/AsyncThunks';
import { StyledContainer, StyledContainerLabs } from './styled';

export const Home = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getProgressLabsThunk());
        dispatch(getTracksThunk());
        dispatch(getInfluencerLabsThunk());
    }, [dispatch]);
    return (
        <StyledContainer>
            <Sidebar />
            <StyledContainerLabs>
                <Outlet />
            </StyledContainerLabs>
        </StyledContainer>
    );
};
