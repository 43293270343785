import { IState } from './types';

export const initialState: IState = {
    newsListState: {
        data: undefined,
        count: undefined,
        next: undefined,
        previous: undefined,
        errors: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
};
