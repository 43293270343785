import { useState } from 'react';
import { joinTeam } from '@/store/reducers/ProfileSlice';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { Input, Button, Alert } from '@/components';
import { VARIANT } from '@/components/Alert/types';
import { StyledForm } from '../styled';

export const JoinTem = () => {
    const [teamString, setTeamStaring] = useState<string | undefined>();
    const { isLoadingTeam, errorTeam } = useAppSelector(
        (state) => state.ProfileSlice
    );
    const dispatch = useAppDispatch();

    const hendleJoinTeam = () => {
        if (teamString && teamString !== '') dispatch(joinTeam(teamString));
    };
    const isDisabled = isLoadingTeam || !teamString || teamString === '';
    return (
        <StyledForm>
            {!!errorTeam.code ? (
                <Alert
                    variant={VARIANT.ERROR}
                    code={errorTeam.code}
                    message={errorTeam.message}
                />
            ) : null}
            <Input onChange={(t) => setTeamStaring(t)} label="Team ID" />
            <Button onClick={hendleJoinTeam} disabled={isDisabled}>
                Join
            </Button>
        </StyledForm>
    );
};
