import styled, { css } from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledPricesTable = styled.div``;

export const StyledRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.65fr 1fr 0.65fr;
    gap: 8px;
`;

export const StyledHeaderCell = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
    padding: 8px 0 16px 0;
    border-radius: 8px 8px 0 0;
    border: 1px solid ${baseColors.white(0.1)};
    background: ${baseColors.levelTwo()};
    &:nth-child(2n - 1) {
        background: ${baseColors.primary()};
    }
`;

export const StyledTitle = styled.span`
    ${typography.text.S};
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
`;

export const StyledPrice = styled.div`
    display: flex;
`;

export const StyledAmount = styled.span`
    font-size: 44px;
    line-height: 44px;
`;

const unitStyle = {
    left: css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        margin-right: 4px;
        > span {
            ${typography.text.M};
        }
    `,
    right: css`
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        > span {
            ${typography.text.S};
            font-size: 10px;
        }
    `,
};

export const StyledUnit = styled.div<{ $direction: 'left' | 'right' }>`
    ${({ $direction }) => unitStyle[$direction]};
`;

const cellStyle = css`
    box-sizing: border-box;
    padding: 10px 12px;
    border: 1px solid ${baseColors.white(0.1)};
    border-top: none;
    background: ${baseColors.levelTwo()};
`;

export const StyledTitleCell = styled.div`
    ${cellStyle};
    > p {
        ${typography.text.M};
        opacity: 0.64;
    }
`;

export const StyledValueCell = styled.div`
    ${cellStyle};
    display: flex;
    justify-content: center;
    &:nth-child(2n - 1) {
        background: ${baseColors.primary()};
    }
`;

export const StyledFooterCell = styled.div`
    box-sizing: border-box;
    padding: 10px 12px;
    border: 1px solid ${baseColors.white(0.1)};
    border-top: none;
    background: ${baseColors.levelTwo()};
    display: flex;
    justify-content: center;
    border-radius: 0 0 8px 8px;
    &:nth-child(2n - 1) {
        background: ${baseColors.primary()};
    }
`;
