import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledText = styled.span`
    ${typography.title.M};
    text-transform: uppercase;
`;

export const StyledContainer = styled.div`
    display: flex;
    gap: 8px;
`;
