import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const StarIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8.95116 1.69098C8.81729 1.27896 8.43333 1 8.00011 1C7.56688 1 7.18293 1.27896 7.04905 1.69098L5.92648 5.1459H2.29377C1.86054 5.1459 1.47659 5.42486 1.34271 5.83688C1.20884 6.2489 1.3555 6.70027 1.70598 6.95492L4.64491 9.09017L3.52234 12.5451C3.38847 12.9571 3.53512 13.4085 3.88561 13.6631C4.2361 13.9178 4.71069 13.9178 5.06118 13.6631L8.00011 11.5279L10.939 13.6631C11.2895 13.9178 11.7641 13.9178 12.1146 13.6631C12.4651 13.4085 12.6117 12.9571 12.4779 12.5451L11.3553 9.09017L14.2942 6.95492C14.6447 6.70027 14.7914 6.2489 14.6575 5.83688C14.5236 5.42486 14.1397 5.1459 13.7064 5.1459H10.0737L8.95116 1.69098Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 2C12.4332 2 12.8172 2.27896 12.9511 2.69098L14.7472 8.21885H20.5595C20.9927 8.21885 21.3767 8.49781 21.5106 8.90983C21.6444 9.32185 21.4978 9.77322 21.1473 10.0279L16.445 13.4443L18.2411 18.9721C18.375 19.3842 18.2283 19.8355 17.8779 20.0902C17.5274 20.3448 17.0528 20.3448 16.7023 20.0902L12 16.6738L7.29772 20.0902C6.94724 20.3448 6.47264 20.3448 6.12215 20.0902C5.77167 19.8355 5.62501 19.3842 5.75888 18.9721L7.55499 13.4443L2.85271 10.0279C2.50223 9.77322 2.35557 9.32185 2.48944 8.90983C2.62332 8.49781 3.00727 8.21885 3.4405 8.21885H9.25284L11.049 2.69098C11.1828 2.27896 11.5668 2 12 2ZM12 6.23607L10.9304 9.52786C10.7966 9.93989 10.4126 10.2188 9.97938 10.2188H6.51818L9.31835 12.2533C9.66884 12.5079 9.8155 12.9593 9.68162 13.3713L8.61205 16.6631L11.4122 14.6287C11.7627 14.374 12.2373 14.374 12.5878 14.6287L15.388 16.6631L14.3184 13.3713C14.1845 12.9593 14.3312 12.5079 14.6817 12.2533L17.4818 10.2188H14.0206C13.5874 10.2188 13.2035 9.93989 13.0696 9.52786L12 6.23607Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
