import { memo } from 'react';
import { IScoreboardItemDetail } from '@/types/scoreboard';
import { Formula } from './components/Formula/Formula';
import {
    Achivements,
    ScoreboardIcon,
    StarIcon,
    PlayIcon,
    LoaderDots,
} from '@/components';
import { baseColors } from '@/constants';
import {
    StyledScoreDetail,
    StyledScoreDetailSection,
    StyledScoreDetailGrid,
    StyledScoreDetailTitle,
    StyledStatisticItem,
    StyledStatisticItemIcon,
    StyledScoreDescription,
    StyledScoreSectionTitle,
    StyledSeparator,
    StyledLoaderContainer,
} from './styled';

export const ScoreDetail = memo(function ScoreDetail(
    props: IScoreboardItemDetail
) {
    const { user, points, achievements, rating_place, labs_ran, isLoader } =
        props;
    return (
        <>
            {isLoader ? (
                <StyledLoaderContainer>
                    <LoaderDots />
                </StyledLoaderContainer>
            ) : (
                <StyledScoreDetail>
                    <StyledScoreDetailSection>
                        <StyledScoreDetailTitle>{user}</StyledScoreDetailTitle>
                    </StyledScoreDetailSection>
                    {achievements.length ? (
                        <>
                            <StyledSeparator />
                            <StyledScoreDetailSection>
                                <StyledScoreDescription>
                                    Achivements
                                </StyledScoreDescription>
                                <StyledScoreDetailGrid>
                                    <Achivements
                                        maxItems={5}
                                        achievements={achievements}
                                        imageHeight={64}
                                        styleContainer={{ gap: '12px' }}
                                    />
                                </StyledScoreDetailGrid>
                            </StyledScoreDetailSection>
                        </>
                    ) : null}
                    <StyledSeparator />
                    <StyledScoreDetailSection>
                        <StyledScoreDescription>
                            Statistics
                        </StyledScoreDescription>
                        <StyledScoreDetailGrid>
                            <StyledStatisticItem>
                                <StyledStatisticItemIcon>
                                    <StarIcon fill={baseColors.warning()} />
                                </StyledStatisticItemIcon>
                                <div>
                                    <StyledScoreDescription>
                                        Points *
                                    </StyledScoreDescription>
                                    <StyledScoreSectionTitle>
                                        {points}
                                    </StyledScoreSectionTitle>
                                </div>
                            </StyledStatisticItem>
                            <StyledStatisticItem>
                                <StyledStatisticItemIcon>
                                    <ScoreboardIcon fill={baseColors.ok()} />
                                </StyledStatisticItemIcon>
                                <div>
                                    <StyledScoreDescription>
                                        % Best
                                    </StyledScoreDescription>
                                    <StyledScoreSectionTitle>
                                        {rating_place}
                                    </StyledScoreSectionTitle>
                                </div>
                            </StyledStatisticItem>
                            <StyledStatisticItem>
                                <StyledStatisticItemIcon>
                                    <PlayIcon fill={baseColors.primary()} />
                                </StyledStatisticItemIcon>
                                <div>
                                    <StyledScoreDescription>
                                        Labs run
                                    </StyledScoreDescription>
                                    <StyledScoreSectionTitle>
                                        {labs_ran}
                                    </StyledScoreSectionTitle>
                                </div>
                            </StyledStatisticItem>
                        </StyledScoreDetailGrid>
                    </StyledScoreDetailSection>
                    <StyledSeparator />
                    <StyledScoreDetailSection style={{ paddingTop: '24px' }}>
                        <StyledScoreDescription>
                            * We calculate points using this formula
                        </StyledScoreDescription>
                        <Formula />
                    </StyledScoreDetailSection>
                </StyledScoreDetail>
            )}
        </>
    );
});
