import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { ITrackLab } from '@/types';
import { TemplateCard, Button } from '@/components';
import { SHAPE } from '@/components/Button/types';
import {
    StyledCard,
    StyledHeader,
    StyledTitle,
    StyledDescription,
    StyledLabContainer,
    StyledText,
    StyledLabList,
} from './styled';
import { SyntheticEvent, useMemo } from 'react';

interface IProps extends ITrackLab {
    isPrimary?: boolean;
}

export const Track = ({ name, description, labs, isPrimary }: IProps) => {
    const navigate = useNavigate();
    const currentLabs = labs.filter((l) => l.completed < l.total);
    const titleButton = useMemo(() => {
        for (let i = 0; i < labs?.length; i++) {
            if (labs[i].completed > 0) {
                return 'Continue';
            }
        }
        return 'Start';
    }, [labs]);
    const handleClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (currentLabs.length === 0) {
            navigate(`/labs/${labs[0].lab.id}`);
            return;
        }
        navigate(`/labs/${currentLabs[0].lab.id}`);
    };
    return (
        <StyledCard>
            <StyledHeader>
                <StyledTitle>{name}</StyledTitle>
                <StyledText>{description}</StyledText>
            </StyledHeader>
            <StyledLabContainer>
                <StyledDescription style={{ opacity: 0.5 }}>
                    Labs
                </StyledDescription>
                <StyledLabList>
                    {labs.map((t) => {
                        return <TemplateCard {...t} size="S" key={nanoid()} />;
                    })}
                </StyledLabList>
            </StyledLabContainer>
            <Button
                view={isPrimary ? SHAPE.PRIMARY : SHAPE.GHOST}
                onClick={handleClick}
            >
                {titleButton}
            </Button>
        </StyledCard>
    );
};
