import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const WebIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM10.9738 7C10.9069 5.7345 10.7141 4.56971 10.4218 3.62461C11.6797 4.32234 12.605 5.54672 12.9 7H10.9738ZM8.9707 7C8.90168 5.83767 8.7157 4.8241 8.46553 4.07359C8.30662 3.59686 8.13922 3.27937 8 3.09935C7.86078 3.27937 7.69338 3.59686 7.53447 4.07359C7.2843 4.8241 7.09832 5.83767 7.0293 7H8.9707ZM7.0293 9H8.9707C8.90168 10.1623 8.7157 11.1759 8.46553 11.9264C8.30662 12.4031 8.13922 12.7206 8 12.9006C7.86078 12.7206 7.69338 12.4031 7.53447 11.9264C7.2843 11.1759 7.09832 10.1623 7.0293 9ZM5.02618 7C5.09309 5.7345 5.28591 4.56971 5.57819 3.62462C4.32033 4.32234 3.39502 5.54673 3.10002 7H5.02618ZM3.10002 9H5.02618C5.09309 10.2655 5.28592 11.4303 5.57819 12.3754C4.32033 11.6777 3.39502 10.4533 3.10002 9ZM10.9738 9H12.9C12.605 10.4533 11.6797 11.6777 10.4218 12.3754C10.7141 11.4303 10.9069 10.2655 10.9738 9Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.06189 11C4.46259 7.78666 6.76903 5.16515 9.81448 4.30219C9.46809 4.77983 9.17939 5.35993 8.94235 5.99202C8.43487 7.34531 8.10742 9.09021 8.02218 11H4.06189ZM4.06189 13C4.46259 16.2133 6.76903 18.8349 9.81448 19.6978C9.46809 19.2202 9.17939 18.6401 8.94235 18.008C8.43487 16.6547 8.10742 14.9098 8.02218 13H4.06189ZM10.0243 13C10.1082 14.7079 10.4028 16.2065 10.815 17.3057C11.0619 17.964 11.3317 18.4272 11.5805 18.7079C11.8304 18.9898 11.9745 19 12 19C12.0255 19 12.1696 18.9898 12.4195 18.7079C12.6683 18.4272 12.9381 17.964 13.185 17.3057C13.5972 16.2065 13.8918 14.7079 13.9757 13H10.0243ZM15.9778 13C15.8926 14.9098 15.5651 16.6547 15.0576 18.008C14.8206 18.6401 14.5319 19.2202 14.1855 19.6978C17.231 18.8349 19.5374 16.2133 19.9381 13H15.9778ZM19.9381 11H15.9778C15.8926 9.09021 15.5651 7.34531 15.0576 5.99202C14.8206 5.35993 14.5319 4.77983 14.1855 4.30219C17.231 5.16515 19.5374 7.78666 19.9381 11ZM13.9757 11H10.0243C10.1082 9.2921 10.4028 7.79349 10.815 6.69427C11.0619 6.03602 11.3317 5.57279 11.5805 5.29209C11.8304 5.01022 11.9745 5 12 5C12.0255 5 12.1696 5.01022 12.4195 5.29209C12.6683 5.57279 12.9381 6.03602 13.185 6.69427C13.5972 7.79349 13.8918 9.2921 13.9757 11ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
