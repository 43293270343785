import styled from 'styled-components';
import { typography } from '@/constants';
import { CopyItem } from '@/components';
import { useAppSelector } from '@/hooks/redux';

const StyledDescription = styled.div`
    ${typography.text.S};
    opacity: 0.5;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SendInvite = () => {
    const { team } = useAppSelector((state) => state.ProfileSlice);
    return (
        <StyledContainer>
            <CopyItem
                direction="column"
                size="M"
                keyText="Your team id"
                valueText={team ?? undefined}
            />
            <StyledDescription>
                Copy that and share with your friends
                <br />
                to invite them to the leaderboard.
            </StyledDescription>
        </StyledContainer>
    );
};
