import styled from 'styled-components'
import { baseColors, typography } from '../../constants/main'

export const StyledHint = styled.div<{}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 20px;
    position: absolute;
    background-color: ${baseColors.hint()};
    border-radius: 8px;
    z-index: 10000;
    box-shadow: 0px 4px 25px rgba(18, 13, 33, 0.62);
`

export const StyledHintText = styled.span`
    ${typography.text.M};
    color: ${baseColors.bckg()};
    font-weight: 500;
`

export const StyledStaticHint = styled.div<{
    top: number
    left: number
    opacity: 0 | 1
}>`
    display: flex;
    gap: 12px;
    opacity: ${({ opacity }) => opacity};
    align-items: flex-start;
    padding: 12px;
    position: fixed;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    background-color: ${baseColors.hint()};
    border-radius: 8px;
    z-index: 10000;
    box-shadow: 0px 4px 25px rgba(18, 13, 33, 0.62);
    box-sizing: border-box;
`

export const StyledStaticHintContentContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
`
