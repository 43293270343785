import { memo } from 'react';
import { StyledHelpBar, StyledIsland } from './styled';
import { Mentor, Statistics } from './components';

interface IProps {
    messageFetching: boolean;
    message?: string;

    firstBlood?: { user: string; user_id: number };
    lastSolved?: { user: string; user_id: number };
}

export const HelpBar = memo((props: IProps) => {
    return (
        <StyledHelpBar>
            <StyledIsland>
                <Mentor {...props} speedOfMesage={10} />
            </StyledIsland>
            <StyledIsland>
                <Statistics {...props} />
            </StyledIsland>
            <StyledIsland
                style={{ height: '400px', minHeight: '400px' }}
            ></StyledIsland>
        </StyledHelpBar>
    );
});
