import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { ILab, IError } from '@/types';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';

export const checkStatusWsLabThunk = createAsyncThunk<
    ILab | undefined,
    string,
    { rejectValue: IError }
>(
    'labWithSchemeSlice/checkStatusWsLabThunk',
    async (uuid, { rejectWithValue }) => {
        try {
            const response = await apiWorkshopLabClient.get(
                `/api/v1/workshoplabs/${uuid}`
            );
            const data = response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
