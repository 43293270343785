import { createSlice } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { IWorkshop } from '@/types/workshop';
import {
    getWorkshopByIdThunk,
    getWorkshopListThunk,
    createWorkshopThunk,
} from '@/store/reducers/WorkshopSlice/AsyncThunks';

interface IInitialState {
    workshop?: IWorkshop | null;
    workshopState: {
        fetching: boolean;
        errors: IError;
    };
    workshopList?: IWorkshop[];
    workshopListState: {
        fetching: boolean;
        errors: IError;
    };
    workshopCreateState: {
        fetching: boolean;
        errors: IError;
    };
}

const initialState: IInitialState = {
    workshop: undefined,
    workshopList: undefined,
    workshopState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
    workshopListState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
    workshopCreateState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
};

export const workshopWithSchemeSlice = createSlice({
    name: 'workshopWithSchemeSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(createWorkshopThunk.pending, (state) => {
            state.workshopCreateState.errors = {
                code: undefined,
                message: undefined,
            };
            state.workshopCreateState.fetching = true;
        });
        builder.addCase(createWorkshopThunk.fulfilled, (state) => {
            state.workshopCreateState.errors = {
                code: undefined,
                message: undefined,
            };
            state.workshopCreateState.fetching = false;
        });
        builder.addCase(createWorkshopThunk.rejected, (state, action) => {
            state.workshopCreateState.errors = action.payload as IError;
            state.workshopCreateState.fetching = true;
        });
        //
        builder.addCase(getWorkshopListThunk.pending, (state) => {
            state.workshopListState.fetching = true;
            state.workshopListState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopListThunk.fulfilled, (state, action) => {
            state.workshopList = action.payload;
            state.workshopListState.fetching = false;
            state.workshopListState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopListThunk.rejected, (state, action) => {
            state.workshopListState.fetching = false;
            state.workshopListState.errors = action.payload as IError;
        });
        //
        builder.addCase(getWorkshopByIdThunk.pending, (state) => {
            state.workshopState.fetching = true;
            state.workshopState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopByIdThunk.fulfilled, (state, action) => {
            state.workshop = action.payload;
            state.workshopState.fetching = false;
            state.workshopState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopByIdThunk.rejected, (state, action) => {
            state.workshopState.fetching = false;
            state.workshopState.errors = action.payload as IError;
        });
    },
});

export default workshopWithSchemeSlice.reducer;
