import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const SchevronDownIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.23177 5.35981C1.58534 4.93553 2.2159 4.87821 2.64018 5.23177L7.99999 9.69829L13.3598 5.23177C13.7841 4.87821 14.4147 4.93553 14.7682 5.35981C15.1218 5.78409 15.0645 6.41465 14.6402 6.76822L8.64018 11.7682C8.26933 12.0773 7.73066 12.0773 7.35981 11.7682L1.35981 6.76822C0.935533 6.41465 0.878209 5.78409 1.23177 5.35981Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.29289 7.29289C2.68342 6.90237 3.31658 6.90237 3.70711 7.29289L12 15.5858L20.2929 7.29289C20.6834 6.90237 21.3166 6.90237 21.7071 7.29289C22.0976 7.68342 22.0976 8.31658 21.7071 8.70711L12.7071 17.7071C12.3166 18.0976 11.6834 18.0976 11.2929 17.7071L2.29289 8.70711C1.90237 8.31658 1.90237 7.68342 2.29289 7.29289Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
