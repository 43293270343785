import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError, FetchAuthFromInviteArgs, FetchAuthResponse } from '@/types';
import { IState } from '../types';

export const fetchAuthFromInviteThunk = createAsyncThunk<
    FetchAuthResponse,
    FetchAuthFromInviteArgs,
    { rejectValue: IError }
>('auth/fetchAuthFromInvite', async function (args, { rejectWithValue }) {
    const { name, pass, goToMain } = args;
    try {
        const response = await apiClient.post(
            '/api/token/',
            {
                username: name,
                password: pass,
            },
            {
                headers: {
                    Authorization: 'Basic xxxxxxxxxxxxxxxxxxx',
                    'Content-Type': 'application/json',
                },
            }
        );
        const data = await response.data;
        goToMain();
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const fetchAuthFromInviteBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(fetchAuthFromInviteThunk.pending, (state) => {
        state.isLoading = true;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(fetchAuthFromInviteThunk.fulfilled, (state, action) => {
        state.auth = true;
        state.isLoading = false;
        state.refresh = action.payload.refresh;
        state.access = action.payload.access;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(fetchAuthFromInviteThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};
