import styled, { css } from "styled-components";
import { typography, baseColors } from "../../constants/main";
import { VARIANT } from "./types";

const generateVariant = (variant: VARIANT) => {
  switch (variant) {
    case VARIANT.ERROR:
      return css`
        background-color: ${baseColors.error(0.7)};
      `;
    case VARIANT.WARNING:
      return css`
        background-color: ${baseColors.warning(0.7)};
      `;
    case VARIANT.SUCCESS:
      return css`
        background-color: ${baseColors.primary(0.7)};
      `;
  }
};

export const StyledAlert = styled.div<{ variant: VARIANT }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  ${({ variant }) => generateVariant(variant)};
`;

export const StyledErrCode = styled.span`
  font-weight: 600;
`;

export const StyledErrMessage = styled.p`
  ${typography.text.M};
`;
