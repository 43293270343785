import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { getWalkthroughsThunk } from '@/store/reducers/Walkthroughs/AsyncThunks';
import { useEffect } from 'react';
import Markdown from 'react-markdown';
import { NoData, LoaderDots } from '@/components';
import { StyledPageLoaderContainer } from '@/pages/styled';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkGemoji from 'remark-gemoji';
import emoji from 'remark-emoji';
import { StyledWalkthroughs, StyledContainer } from './styled';

export const Walkthroughs = () => {
    const dispatch = useAppDispatch();
    const {
        walkthroughs,
        walkthroughsState: { fetching, error },
    } = useAppSelector((state) => state.Walkthroughs);
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);
    useEffect(() => {
        if (lab) dispatch(getWalkthroughsThunk(lab.uuid));
    }, [dispatch, lab]);

    const renderWalkthroughs = () => {
        if (fetching) {
            return (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (walkthroughs && walkthroughs.results.length) {
            return (
                <StyledContainer>
                    <StyledWalkthroughs>
                        <Markdown
                            remarkPlugins={[
                                remarkGfm,
                                remarkBreaks,
                                emoji,
                                remarkGemoji,
                            ]}
                        >
                            {walkthroughs.results[0].text}
                        </Markdown>
                    </StyledWalkthroughs>
                </StyledContainer>
            );
        }
        return (
            <NoData
                title="No Walkthroughs"
                text={error?.code === 402 ? error?.message : undefined}
                buttons={
                    error?.code === 402
                        ? [
                              {
                                  title: 'Subscribe',
                                  action() {
                                      window.open(
                                          process.env.REACT_APP_PAYMENT_URL,
                                          '_blank'
                                      );
                                  },
                              },
                          ]
                        : undefined
                }
            />
        );
    };
    return <>{renderWalkthroughs()}</>;
};
