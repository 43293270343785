export enum HIGHLIGHT_TYPE {
    URL = 'URL',
    FILE = 'File',
    ENVIRONMENTAL_VAR = 'Environmental Variable',
    USER_NAME = 'Username',
}

export const highLightType = {
    [HIGHLIGHT_TYPE.URL]: 'URL',
    [HIGHLIGHT_TYPE.FILE]: 'File',
    [HIGHLIGHT_TYPE.ENVIRONMENTAL_VAR]: 'Environmental Variable',
    [HIGHLIGHT_TYPE.USER_NAME]: 'Username',
};
