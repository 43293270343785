import { nanoid } from 'nanoid';
import { useAppSelector } from '@/hooks/redux';
import {
    LoaderDots,
    SchevronLeftIcon,
    SchevronRightIcon,
    IconButton,
    Tag,
} from '@/components';
import { useSlider } from '../../hooks';
import { Track } from '../';
import { StyledPageLoaderContainer } from '@/pages/styled';
import {
    StyledTracks,
    StyledContainer,
    StyledTitle,
    StyledLabList,
    StyledTitleContainer,
    StyledActionContainer,
} from './styled';

export const TrackList = () => {
    const {
        tracksState: { data: tracks, fetching: fetchingTracks },
    } = useAppSelector((state) => state.LabListsSlice);
    const { next, prev, list, isNexDisabled, isPrevDisabled } = useSlider({
        list: tracks,
    });
    return (
        <StyledContainer>
            <StyledLabList>
                <StyledTitleContainer>
                    <StyledTitle>
                        Tracks
                        <Tag title={String(tracks?.length || 0)} />
                    </StyledTitle>
                    <StyledActionContainer>
                        <IconButton
                            icon={<SchevronLeftIcon />}
                            onClick={() => prev()}
                            disabled={isPrevDisabled}
                        />
                        <IconButton
                            icon={<SchevronRightIcon />}
                            onClick={() => next()}
                            disabled={isNexDisabled}
                        />
                    </StyledActionContainer>
                </StyledTitleContainer>
                {fetchingTracks ? (
                    <StyledPageLoaderContainer>
                        <LoaderDots />
                    </StyledPageLoaderContainer>
                ) : (
                    <StyledTracks>
                        {list?.map((t, idx) => {
                            return (
                                <Track
                                    {...t}
                                    key={nanoid()}
                                    isPrimary={idx === 0 ? true : false}
                                />
                            );
                        })}
                    </StyledTracks>
                )}
            </StyledLabList>
        </StyledContainer>
    );
};
