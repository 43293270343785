import { NavigateFunction } from 'react-router-dom';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { IError } from '@/types';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';

interface IStartWsLabResponse {
    ok: boolean;
    workshop_lab_id: string | null;
}

interface IStartWsLabRequest {
    code: string;
    name: string;
    gotToLab?: NavigateFunction;
}

export const runWsTemplateThunk = createAsyncThunk<
    void,
    IStartWsLabRequest,
    { rejectValue: IError }
>(
    'templateSlice/runWsTemplateThunk',
    async ({ code, name, gotToLab }, { rejectWithValue }) => {
        try {
            const response = await apiWorkshopLabClient.post(
                '/api/v1/workshoplabs/start_lab/',
                { code, name }
            );
            const { ok, workshop_lab_id } =
                (await response.data) as IStartWsLabResponse;
            if (ok && gotToLab) {
                gotToLab(`/workshoplab/${workshop_lab_id}/`);
            }
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
