export const ERROR_MESSAGES = {
    common: 'Поле заполнено некорректно',
    password:
        'Не все требования выполнены. Проверьте правильность введенных данных',
    required: 'Поле обязательно для заполнения',
    confirm: 'Пароли не совпадают. Проверьте правильность введенных данных',
    intervalDate: 'Диапазон дат заполнен некорректно',
    intervalTime: 'Диапазон времени заполнен некорректно',
    integer: 'Введено не целое число',
    float: 'Введено некорректное число',
    pin: 'Длина должна быть от 1 до 6 цифр',
    percent: 'Значение должно быть в диапазоне от 0 до 100',
    tag: 'Длина набора должна быть не менее 3 символов',
    sltuPorts: 'Значение должно быть в диапазоне от 0 до 99',
    hyperlink: 'Некорректный формат гиперссылки',
};

export const paginationNumber = (value?: string) =>
    value && !/^[1-9]\d{0,4}$/.test(value) ? ERROR_MESSAGES.integer : undefined;

export const tag = (value?: string | null) =>
    value && value?.length > 2 ? undefined : ERROR_MESSAGES.tag;

export const required = (
    value?: string | number | null | string[] | number[]
) => (value || value === 0 ? undefined : ERROR_MESSAGES.required);

type RequiredGroupFieldsType = (args: {
    name: string;
    vals: { [key: string]: string | undefined };
    messages: { [key: string]: string };
}) => string[] | undefined;

export const requiredGroupFields: RequiredGroupFieldsType = ({
    name,
    vals,
    messages,
}) => {
    const isFieldError = typeof vals[name] === 'undefined';
    if (isFieldError) {
        for (let i = 0; i < Object.values(vals).length; i++) {
            if (typeof Object.values(vals)[i] === 'undefined') {
                return [messages[name]];
            }
        }
    }
    return undefined;
};

export const confirmation = (value?: string, confirmValue?: string) =>
    value && confirmValue && confirmValue === value
        ? undefined
        : ERROR_MESSAGES.confirm;
