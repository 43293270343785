import { useEffect, useContext } from 'react';
import { LabContext } from '../context';
import { LAB_TYPE, STATUS_LAB } from '@/constants';
import { ILab, ILabTeamplate, ITask } from '@/types';
import {
    checkStatusLabThank,
    checkStatusWsLabThunk,
} from '@/store/reducers/LabSlice/AsyncThanks';
import {
    checkChangeSchemeThank,
    checkChangeSchemeWsThunk,
} from '@/store/reducers/SchemeSlice/AsyncThanks';
import {
    // getTasksThunk,
    // getTasksWsThunk,
    getTasksWithoutLoaderThunk,
    getTasksWithoutLoadersWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import { useAppDispatch } from '@/hooks/redux';

interface IProps {
    lab?: ILab;
    template?: ILabTeamplate;
    tasks?: ITask[];
}

export const useStatusPolingRequest = ({ lab, template, tasks }: IProps) => {
    const dispatch = useAppDispatch();
    const { type } = useContext(LabContext);
    useEffect(() => {
        const taskInterval = setInterval(() => {
            const isCheckTaskStatus = () => {
                if (lab?.status === STATUS_LAB.OPERATIONAL)
                    if (!!tasks && tasks.length)
                        for (let i = 0; i < tasks.length; i++)
                            if (tasks[i].in_progress === true) return true;
                return false;
            };
            if (isCheckTaskStatus()) {
                switch (type) {
                    case LAB_TYPE.DEFAULT:
                        dispatch(getTasksWithoutLoaderThunk(lab!.uuid));
                        return;
                    case LAB_TYPE.WORKSHOP:
                        dispatch(getTasksWithoutLoadersWsThunk(lab!.uuid));
                }
            } else {
                clearInterval(taskInterval);
            }
        }, 5000);
        const interval = setInterval(() => {
            if (lab?.status === STATUS_LAB.DEACTIVATED) {
                clearInterval(interval);
            }

            switch (type) {
                case LAB_TYPE.DEFAULT:
                    if (lab?.status === STATUS_LAB.OPERATIONAL) {
                        dispatch(checkChangeSchemeThank({ uuid: lab.uuid }));
                    }
                    if (template && lab?.status !== STATUS_LAB.DEACTIVATED) {
                        dispatch(
                            checkStatusLabThank({
                                templateId: Number(template.id),
                            })
                        );
                    }
                    return;
                case LAB_TYPE.WORKSHOP:
                    if (lab?.status === STATUS_LAB.OPERATIONAL) {
                        dispatch(checkChangeSchemeWsThunk(lab.uuid));
                    }
                    if (lab && lab?.status !== STATUS_LAB.DEACTIVATED) {
                        dispatch(checkStatusWsLabThunk(lab.uuid));
                    }
            }
        }, 10000);
        return () => {
            clearInterval(interval);
            clearInterval(taskInterval);
        };
    }, [lab, template, tasks, dispatch, type]);
};
