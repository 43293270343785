import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const VarIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M2 1C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H5C5.55229 15 6 14.5523 6 14C6 13.4477 5.55229 13 5 13H3V3H5C5.55229 3 6 2.55228 6 2C6 1.44772 5.55229 1 5 1H2Z"
                            fill={fill}
                        />
                        <path
                            d="M14 1C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H11C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H13V3H11C10.4477 3 10 2.55228 10 2C10 1.44772 10.4477 1 11 1H14Z"
                            fill={fill}
                        />
                        <path
                            d="M9.41421 8L10.7071 9.29289C11.0976 9.68342 11.0976 10.3166 10.7071 10.7071C10.3166 11.0976 9.68342 11.0976 9.29289 10.7071L8 9.41421L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L6.58579 8L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L8 6.58579L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L9.41421 8Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M3 4C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H6C6.55229 20 7 19.5523 7 19C7 18.4477 6.55229 18 6 18H4V6H6C6.55229 6 7 5.55228 7 5C7 4.44772 6.55229 4 6 4H3Z"
                            fill={fill}
                        />
                        <path
                            d="M21 4C21.5523 4 22 4.44772 22 5V19C22 19.5523 21.5523 20 21 20H18C17.4477 20 17 19.5523 17 19C17 18.4477 17.4477 18 18 18H20V6H18C17.4477 6 17 5.55228 17 5C17 4.44772 17.4477 4 18 4H21Z"
                            fill={fill}
                        />
                        <path
                            d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
