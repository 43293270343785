import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const SchevronLeftIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.6402 14.7682C12.0645 14.4147 12.1218 13.7841 11.7682 13.3598L7.30171 7.99999L11.7682 2.64018C12.1218 2.2159 12.0645 1.58534 11.6402 1.23177C11.2159 0.878209 10.5853 0.935533 10.2318 1.35981L5.23178 7.35981C4.92274 7.73066 4.92274 8.26933 5.23178 8.64018L10.2318 14.6402C10.5853 15.0645 11.2159 15.1218 11.6402 14.7682Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.7433 21.669C17.1127 21.2584 17.0795 20.6262 16.669 20.2567L7.49484 12L16.669 3.74328C17.0795 3.37383 17.1127 2.74154 16.7433 2.33103C16.3738 1.92052 15.7415 1.88724 15.331 2.2567L5.33103 11.2567C5.12031 11.4463 4.99999 11.7165 4.99999 12C4.99999 12.2835 5.12031 12.5536 5.33103 12.7433L15.331 21.7433C15.7415 22.1127 16.3738 22.0795 16.7433 21.669Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
