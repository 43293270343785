import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const ExitIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M5 2C5 1.44772 5.44772 1 6 1H13C13.5523 1 14 1.44772 14 2V14C14 14.5523 13.5523 15 13 15H6C5.44772 15 5 14.5523 5 14C5 13.4477 5.44772 13 6 13H12V3H6C5.44772 3 5 2.55228 5 2Z"
                            fill={fill}
                        />
                        <path
                            d="M6.29289 5.29289C6.68342 4.90237 7.31658 4.90237 7.70711 5.29289L9.70711 7.29289C10.0976 7.68342 10.0976 8.31658 9.70711 8.70711L7.70711 10.7071C7.31658 11.0976 6.68342 11.0976 6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289L6.58579 9H2C1.44772 9 1 8.55228 1 8C1 7.44772 1.44772 7 2 7H6.58579L6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M8 2C7.44772 2 7 2.44772 7 3C7 3.55228 7.44772 4 8 4H18V20H8C7.44772 20 7 20.4477 7 21C7 21.5523 7.44772 22 8 22H19C19.5523 22 20 21.5523 20 21V3C20 2.44772 19.5523 2 19 2H8Z"
                            fill={fill}
                        />
                        <path
                            d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L9.58579 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H9.58579L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
