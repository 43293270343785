import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledContainer = styled.div`
    width: 100%;
    background: ${baseColors.levelOne()};
    display: grid;
    grid-template-columns: 320px 1fr;
`;

export const StyledContainerLabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    padding: 8px 8px 8px 2px;
    overflow-y: scroll;
    position: relative;
`;
