import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledIsland = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
    box-sizing: border-box;
    padding-bottom: 8px;
    background: ${baseColors.levelTwo()};
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 16px;
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
`;

export const StyledUserContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
`;

export const StyledFetchingUserPic = styled.div`
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledUserPicContainer = styled.div`
    width: 96px;
    height: 96px;
    position: relative;
`;

export const StyledUserPic = styled.img`
    width: 96px;
`;

export const StyledUserPicEdit = styled.div`
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -2px;
    right: -2px;
    background: ${baseColors.levelOne()};
    border-radius: 200px;
`;

export const StyledUserNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
`;

export const StyledUserName = styled.p`
    ${typography.title.XL};
`;

export const StyledUserPlaneContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
`;

export const StyledStatisticContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledStatisticLab = styled.div`
    display: flex;
    jastify-content: space-between;
    box-sizing: border-box;
    padding: 12px 16px;
    > p.key {
        min-width: 114px;
        ${typography.text.M};
        color: ${baseColors.white(0.5)};
        display: flex;
        gap: 6px;
        align-items: center;
        width: 100%;
    }
    > p.value {
        width: fit-content;
        ${typography.text.M};
        text-align: right;
    }
`;
