interface IProps {
    direction: 'right' | 'left';
    fill: string;
    size: number;
}

export const Angle = ({ direction, fill, size }: IProps) => {
    const renderDirection = () => {
        if (direction === 'left') {
            return 'M0.5 32L32.5 0V32H0.5Z';
        }
        return 'M32 0L0 32V0H32Z';
    };
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                minWidth: `${size}px`,
                maxWidth: `${size}px`,
                minHeight: `${size}px`,
                maxHeight: `${size}px`,
            }}
        >
            <path d={renderDirection()} fill={fill} />
        </svg>
    );
};
