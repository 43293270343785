import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const TaskIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M10.1 7.2C10.5418 7.53137 10.6314 8.15817 10.3 8.6L8.8 10.6C8.62607 10.8319 8.36005 10.9769 8.07089 10.9975C7.78173 11.018 7.49788 10.9121 7.29289 10.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L7.89181 8.47759L8.7 7.4C9.03137 6.95817 9.65817 6.86863 10.1 7.2Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6 1C5.44772 1 5 1.44772 5 2H3C2.44772 2 2 2.44772 2 3V14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V3C14 2.44772 13.5523 2 13 2H11C11 1.44772 10.5523 1 10 1H6ZM11 4C11 4.55228 10.5523 5 10 5H6C5.44772 5 5 4.55228 5 4H4V13H12V4H11Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M15.8116 11.5843C16.1343 11.1361 16.0325 10.5112 15.5843 10.1885C15.1361 9.86576 14.5112 9.9675 14.1885 10.4157L11.2214 14.5367L9.64712 13.2006C9.22606 12.8432 8.595 12.8948 8.23762 13.3159C7.88023 13.7369 7.93185 14.368 8.35292 14.7254L10.7529 16.7624C10.9657 16.943 11.2442 17.0264 11.5212 16.9926C11.7982 16.9588 12.0485 16.8108 12.2116 16.5843L15.8116 11.5843Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 3C8 2.44772 8.44772 2 9 2H15C15.5523 2 16 2.44772 16 3V4H19C19.5523 4 20 4.44772 20 5V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V5C4 4.44772 4.44772 4 5 4H8V3ZM16 7V6H18V20H6V6H8V7C8 7.55228 8.44772 8 9 8H15C15.5523 8 16 7.55228 16 7ZM10 6H14V4H10V6Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
