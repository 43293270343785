import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const InProgressIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M8 3C5.23858 3 3 5.23858 3 8C3 10.7603 5.23683 12.9983 7.99677 13L8 13C8.11044 13 8.21991 12.9964 8.32831 12.9894C8.87944 12.9538 9.3551 13.3717 9.39071 13.9228C9.42633 14.474 9.00842 14.9496 8.45728 14.9853C8.30757 14.9949 8.15671 14.9999 8.00485 15L8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C9.95797 1 11.7297 1.80519 12.999 3.09996C13.3856 3.49434 13.3793 4.12748 12.9849 4.5141C12.5905 4.90072 11.9574 4.89443 11.5708 4.50004C10.6621 3.57318 9.39891 3 8 3Z"
                            fill={fill}
                        />
                        <path
                            d="M13.9241 6.61443C14.4752 6.57928 14.9505 6.99761 14.9857 7.54877C14.9952 7.69808 15 7.84854 15 7.99999C15 8.15349 14.995 8.30597 14.9853 8.45727C14.9497 9.0084 14.474 9.42632 13.9229 9.3907C13.3717 9.35508 12.9538 8.87943 12.9894 8.32829C12.9964 8.2199 13 8.11043 13 7.99999C13 7.89102 12.9965 7.783 12.9897 7.67603C12.9546 7.12486 13.3729 6.64957 13.9241 6.61443Z"
                            fill={fill}
                        />
                        <path
                            d="M13.1708 11.2045C13.5858 11.5688 13.627 12.2006 13.2627 12.6157C13.0613 12.8452 12.8452 13.0613 12.6157 13.2627C12.2007 13.627 11.5688 13.5858 11.2045 13.1707C10.8402 12.7557 10.8814 12.1238 11.2965 11.7595C11.4607 11.6154 11.6154 11.4607 11.7595 11.2965C12.1238 10.8814 12.7557 10.8402 13.1708 11.2045Z"
                            fill={fill}
                        />
                        <path
                            d="M10.7071 7.70711C11.0976 7.31658 11.0976 6.68342 10.7071 6.29289C10.3166 5.90237 9.68342 5.90237 9.2929 6.29289L7.52571 8.06008L6.7319 7.20747C6.35556 6.80326 5.7228 6.78066 5.31859 7.15699C4.91437 7.53333 4.89177 8.16609 5.26811 8.57031L6.76811 10.1814C6.95305 10.3801 7.21079 10.495 7.48216 10.4998C7.75352 10.5047 8.0152 10.399 8.20711 10.2071L10.7071 7.70711Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C12.3416 21 12.6784 20.981 13.0094 20.9441C13.5583 20.8829 14.0529 21.2783 14.1141 21.8272C14.1753 22.3761 13.7799 22.8706 13.231 22.9318C12.8265 22.9769 12.4158 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C15.0769 1 17.8603 2.26464 19.8555 4.29996L19.8578 4.30227C20.141 4.59135 20.4084 4.89602 20.6586 5.21489C20.9995 5.64939 20.9237 6.278 20.4892 6.61892C20.0547 6.95984 19.4261 6.88397 19.0852 6.44947C18.8797 6.18764 18.6601 5.93744 18.4273 5.70004C16.7927 4.03263 14.5179 3 12 3Z"
                            fill={fill}
                        />
                        <path
                            d="M16.717 9.30292C17.102 9.69891 17.0931 10.332 16.6971 10.717L11.5542 15.717C11.1661 16.0943 10.5482 16.0943 10.1601 15.717L7.30292 12.9392C6.90694 12.5542 6.89802 11.9211 7.28301 11.5251C7.66799 11.1292 8.30109 11.1202 8.69708 11.5052L10.8571 13.6053L15.3029 9.28301C15.6989 8.89802 16.332 8.90694 16.717 9.30292Z"
                            fill={fill}
                        />
                        <path
                            d="M22.9336 10.7849C22.8732 10.2359 22.3792 9.83981 21.8302 9.9002C21.2812 9.96059 20.8852 10.4546 20.9455 11.0035C20.9815 11.3304 21 11.6629 21 12C21 12.3416 20.981 12.6784 20.9441 13.0094C20.8829 13.5583 21.2783 14.0529 21.8272 14.1141C22.3761 14.1753 22.8706 13.7799 22.9318 13.231C22.9769 12.8265 23 12.4158 23 12C23 11.5897 22.9775 11.1842 22.9336 10.7849Z"
                            fill={fill}
                        />
                        <path
                            d="M20.6001 18.8591C20.9447 18.4275 20.8742 17.7982 20.4426 17.4536C20.0111 17.109 19.3818 17.1795 19.0372 17.6111C18.6165 18.1379 18.1379 18.6165 17.6111 19.0372C17.1795 19.3818 17.109 20.0111 17.4536 20.4426C17.7983 20.8742 18.4275 20.9447 18.8591 20.6001C19.5023 20.0865 20.0865 19.5023 20.6001 18.8591Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
