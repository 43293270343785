import { useState, FC, CSSProperties } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '@/hooks/redux';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { DropdownMenuItem } from '@/components/Dropdown/DropdownMenu/DropdownMenuItem';
import {
    IconButton,
    DropdownMenu,
    InfoIcon,
    SchevronDownIcon,
    SchevronUpIcon,
    BookIcon,
    MapIcon,
    DiscordIcon,
    TelegramIcon,
} from '@/components';
import { StyledDropdownMenuItem, StyledLink, StyledTitle } from './styled';

interface IUsefulLinksProps {
    links: string[];
}

const UsefulLinks: FC<IUsefulLinksProps> = ({ links }) => {
    const [isOpened, setIsOpened] = useState(false);
    return (
        <>
            <StyledDropdownMenuItem
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpened(!isOpened);
                }}
            >
                <BookIcon />
                <StyledTitle>Useful links</StyledTitle>
                {isOpened ? (
                    <SchevronUpIcon variant="S" />
                ) : (
                    <SchevronDownIcon variant="S" />
                )}
            </StyledDropdownMenuItem>
            {isOpened
                ? links.map((l) => (
                      <StyledDropdownMenuItem
                          key={nanoid()}
                          style={{ paddingLeft: '40px' }}
                      >
                          <StyledLink href={l} target="_blank">
                              {l}
                          </StyledLink>
                      </StyledDropdownMenuItem>
                  ))
                : null}
        </>
    );
};

interface IProps {
    offsetLeft?: boolean;
}

export const HelpButton = ({ offsetLeft = true }: IProps) => {
    const { currentTemplate: lab } = useAppSelector(
        (state) => state.TemplatesSlice
    );

    const helpOptions: IDropdownMenuItem[] = [
        { id: 0, title: 'Walkthrough guide', icon: <MapIcon /> },
        { id: 1, title: 'Useful links', icon: <BookIcon /> },
        { id: 2, title: 'Ask Community (Telegram)', icon: <TelegramIcon /> },
        { id: 3, title: 'Ask Community (Discord)', icon: <DiscordIcon /> },
    ];

    const getOptions = () => {
        return helpOptions.filter((o) => {
            if (o.id === 0 && !!lab?.write_up_link) {
                return true;
            } else if (o.id === 0 && !lab?.write_up_link) {
                return false;
            }
            if (o.id === 1 && !!lab?.useful_links) {
                return true;
            } else if (o.id === 1 && !lab?.write_up_link) {
                return false;
            }
            return true;
        });
    };

    const hendleClick = (option_id: number) => {
        switch (option_id) {
            case 0:
                window.open(lab!.write_up_link!, '_blank');
                return;
            case 1:
                window.open(lab!.useful_links!, '_blank');
                return;
            case 2:
                window.open('https://t.me/defbox_community', '_blank');
                return;
            case 3:
                window.open('https://discord.com/invite/PFN2hsjfKC', '_blank');
                return;
            default:
                return;
        }
    };

    const offsetLeftStyle: CSSProperties = {
        paddingLeft: '12px',
        borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
    };

    return (
        <div
            style={
                offsetLeft
                    ? {
                          boxSizing: 'border-box',
                          ...offsetLeftStyle,
                      }
                    : { boxSizing: 'border-box' }
            }
        >
            <DropdownMenu
                styleContainer={{ width: '240px', zIndex: 100000 }}
                list={getOptions()}
                renderItem={(item) => {
                    if (item.id === 1) {
                        return (
                            <UsefulLinks
                                links={lab!.useful_links!.split('\r\n')}
                            />
                        );
                    }
                    return (
                        <DropdownMenuItem
                            {...item}
                            onClickHandler={(id) => hendleClick(id as number)}
                        />
                    );
                }}
            >
                <IconButton size="M" label="Help" iconLeft={<InfoIcon />} />
            </DropdownMenu>
        </div>
    );
};
