import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { IState } from '../types';

export const authFetchingErrorAction: CaseReducer<
    IState,
    PayloadAction<IError>
> = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};
