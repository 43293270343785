import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { Form, Field } from 'react-final-form';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { ILabTeamplate, IWorkshopCreateRequest } from '@/types';
import { Select, Input, Button, IconButton, CloseIcon } from '@/components';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { getTemplateListThank } from '@/store/reducers/TemplatesSlice/AsyncThanks';
import { createWorkshopThunk } from '@/store/reducers/WorkshopSlice/AsyncThunks';
import { StyledForm, StyledHeader } from '../styled';
import { required } from '@/utils/validate';

interface IProps {
    onExpandModal: Dispatch<SetStateAction<boolean>>;
}
export const AddWorkshop = ({ onExpandModal }: IProps) => {
    const dispatch = useAppDispatch();
    const {
        templates,
        templatesState: { fetching: fetchingTemplateList },
    } = useAppSelector((state) => state.TemplatesSlice);
    const {
        workshopCreateState: { fetching },
    } = useAppSelector((state) => state.WorkshopWithShemeSlice);

    useEffect(() => {
        dispatch(getTemplateListThank());
    }, [dispatch]);

    const labOptions = useMemo<IDropdownMenuItem[]>(() => {
        if (templates) {
            return templates.map(({ id, name }) => ({ id: id, title: name }));
        }
        return [];
    }, [templates]);

    interface IValues {
        name?: string;
        lab_template?: ILabTeamplate['id'];
        students_count?: number;
        finish_date?: string;
    }

    const submitHandler = (args: IValues) => {
        const { lab_template, students_count, finish_date, name } = args;
        if (lab_template && students_count && finish_date && name) {
            dispatch(
                createWorkshopThunk({
                    dataRequest: args as IWorkshopCreateRequest['dataRequest'],
                    closeModalHandler: onExpandModal,
                })
            );
        }
    };

    return (
        <>
            <StyledHeader>
                <span>Create workshop</span>
                <IconButton
                    icon={<CloseIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        onExpandModal(false);
                    }}
                />
            </StyledHeader>
            <Form
                onSubmit={submitHandler}
                validate={(checkValues) => {
                    const { name, lab_template, students_count, finish_date } =
                        checkValues;
                    return {
                        name: required(name),
                        lab_template: required(lab_template),
                        students_count: required(students_count),
                        finish_date: required(finish_date),
                    };
                }}
                initialValues={
                    {
                        lab_template: undefined,
                        students_count: undefined,
                        finish_date: undefined,
                    } as IValues
                }
                render={({ handleSubmit, pristine, errors }) => {
                    console.log(errors);
                    const isDisabled =
                        pristine ||
                        fetching ||
                        fetchingTemplateList ||
                        (!!errors && Object.values(errors).length > 0);
                    return (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field name="name" id="name">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            value={value}
                                            label="Workshop name"
                                            onChange={onChange}
                                        />
                                    );
                                }}
                            </Field>
                            <Field name="lab_template" id="lab_template">
                                {({ input: { value, onChange } }) => {
                                    return (
                                        <Select
                                            // defaultValue={value}
                                            options={labOptions}
                                            onChange={(opt) => onChange(opt.id)}
                                            label="Select lab"
                                        />
                                    );
                                }}
                            </Field>
                            <Field name="students_count" id="students_count">
                                {({ input: { onChange, value } }) => {
                                    return (
                                        <Input
                                            value={value}
                                            label="Student number"
                                            onChange={onChange}
                                            type="number"
                                        />
                                    );
                                }}
                            </Field>
                            <Field name="finish_date" id="finish_date">
                                {({ input: { value, onChange } }) => {
                                    return (
                                        <Input
                                            label="End date"
                                            onChange={onChange}
                                            type="datetime-local"
                                            value={value}
                                        />
                                    );
                                }}
                            </Field>

                            <Button type="submit" disabled={isDisabled}>
                                Create
                            </Button>
                        </StyledForm>
                    );
                }}
            />
        </>
    );
};
