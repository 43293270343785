import { nanoid } from '@reduxjs/toolkit';
import { OfferCard } from './components/OfferCard/OfferCard';
import { offerList } from './constants';
import { StyledList } from './styled';

export const OfferList = () => {
    return (
        <StyledList>
            {offerList.map((o) => {
                return <OfferCard key={nanoid()} {...o} />;
            })}
        </StyledList>
    );
};
