import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const getDateUTC = (date?: string, format?: string) => {
    const resultFormat = format ?? 'MM.DD.YYYY, HH:mm:ss';
    return `${dayjs.utc(date).format(resultFormat).toString()} (UTC)`;
};

export const getTimeRemaining = (
    time: string,
    today: string,
    countdown?: boolean
) => {
    const t =
        countdown === true
            ? Date.parse(time) - Date.parse(today)
            : Date.parse(today) - Date.parse(time);

    const seconds = Math.floor((t / 1000) % 60);
    const minutes = Math.floor((t / 1000 / 60) % 60);
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};
