import styled from 'styled-components';
import { StyledContentWrapper } from '../../styled';

export const StyledPageContent = styled.div`
    ${StyledContentWrapper};
    @media (max-width: 768px) {
        min-height: auto;
        padding-bottom: 88px;
    }
`;

export const StyledMainTitleContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 160px;
    align-items: center;
    text-transform: uppercase;
    @media (max-width: 560px) {
        gap: 12px;
        padding-top: 88px;
    }
    @media (max-width: 360px) {
        gap: 8px;
    }
`;

export const StyledMainTitle = styled.img`
    width: 100%;
`;

export const StyledMainSubtitle = styled.p`
    font-size: 26px;
    font-weight: 800;
    @media (max-width: 768px) {
        font-size: 20px;
    }
    @media (max-width: 560px) {
        font-size: 16px;
    }
    @media (max-width: 360px) {
        font-size: 14px;
    }
`;

export const StyledSteps = styled.div`
    display: flex;
    gap: 40px;
    padding: 96px;
    padding-top: 64px;
    padding-bottom: 40px;
    @media (max-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 960px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (max-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media (max-width: 560px) {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
    @media (max-width: 460px) {
    }
`;

export const StyledMainButton = styled.div`
    display: flex;
    justify-content: center;
`;
