import { nanoid } from 'nanoid';
import { useState } from 'react';
import { PagesTabs, Tag } from '@/components';
import { ISchemeGroup, IHealthChecks } from '@/types';
import { baseColors } from '@/constants';
import { IData, PagesTabsProps } from '@/components/Tabs/PagesTabs/types';
import { StyledTab, StyledTabTitle, StyledTabCounters } from './styled';

interface IDataTab extends IData {
    counts?: { count: number; color: string }[];
}

interface IProps extends Omit<PagesTabsProps, 'data'> {
    group: ISchemeGroup;
}

const getChecks = (checks: IHealthChecks[] | null) => {
    if (checks && checks.length) {
        let ok = 0;
        let failed = 0;
        checks.forEach((c) => {
            if (c.result === 'OK') ok += 1;
            if (c.result === 'FAILED') failed += 1;
        });
        return [
            {
                count: ok,
                color: baseColors.ok(),
            },
            {
                count: failed,
                color: baseColors.error(),
            },
        ];
    }
    return undefined;
};

export const Tabs = (props: IProps) => {
    const {
        onChange,
        group: { information, vms },
    } = props;
    const [tabs] = useState<IDataTab[]>([
        {
            id: 0,
            value: 'VMs',
            counts: [{ count: vms.length, color: baseColors.primary() }],
        },
        {
            id: 1,
            value: 'Info',
        },
        {
            id: 2,
            value: 'Checks',
            counts: getChecks(information.healthchecks),
        },
    ]);
    const [activeTab, setActiveTab] = useState(0);
    return (
        <PagesTabs
            {...props}
            style={{ height: 'auto' }}
            data={tabs}
            renderTab={(t) => {
                const tab = t as IDataTab;
                const isActive = activeTab === t.id;
                return (
                    <StyledTab
                        $active={isActive}
                        onClick={(e) => {
                            e.stopPropagation();
                            setActiveTab(t.id as number);
                            if (onChange) onChange(t.id);
                        }}
                    >
                        <StyledTabTitle>{t.value}</StyledTabTitle>
                        {tab.counts && (
                            <StyledTabCounters>
                                {tab.counts.map((c) => (
                                    <Tag
                                        key={nanoid()}
                                        title={String(c.count)}
                                        style={{
                                            background: c.color,
                                            height: '16px',
                                            maxHeight: '16px',
                                        }}
                                    />
                                ))}
                            </StyledTabCounters>
                        )}
                    </StyledTab>
                );
            }}
        />
    );
};
