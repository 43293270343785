import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError, ITokensData } from '@/types';
import { IState } from '../types';

export const refreshAuthThunk = createAsyncThunk<
    string,
    { refresh: string },
    { rejectValue: IError }
>('auth/refreshAuth', async function (args, { rejectWithValue }) {
    const { refresh } = args;
    try {
        const response = await apiClient.post('/api/token/refresh/', {
            refresh: refresh,
        });
        const data = await response.data.access;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const refreshAuthBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(refreshAuthThunk.pending, (state) => {
        state.isLoading = true;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(refreshAuthThunk.fulfilled, (state, action) => {
        state.access = action.payload;
        if (typeof localStorage.getItem('token') === 'string') {
            const tokensData: ITokensData = JSON.parse(
                localStorage.getItem('token') as string
            );
            localStorage.setItem(
                'token',
                JSON.stringify({
                    ...tokensData,
                    access: action.payload,
                    start_time: Date.now(),
                } as ITokensData)
            );
        }
    });
    builder.addCase(refreshAuthThunk.rejected, (state, action) => {
        localStorage.clear();
        state.auth = false;
        state.access = undefined;
        state.refresh = undefined;
        state.isLoading = false;
        state.error = action.payload as IError;
    });
};
