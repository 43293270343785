import { BaseStatItem } from '../';
import { baseColors } from '@/constants';

interface IProps {
    total: number;
    completed: number;
}

export const ProgressStatItem = ({ completed, total }: IProps) => {
    const percent = (completed / total) * 100;
    const renderChart = () => {
        return (
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform={`rotate(${
                    (100 - percent) * ((Math.PI * 360) / 100)
                })`}
            >
                <rect
                    x="4"
                    y="4"
                    width="32"
                    height="32"
                    rx="16"
                    stroke="white"
                    stroke-opacity="0.1"
                    stroke-width="8"
                />
                <circle
                    cx="20"
                    cy="20"
                    r="16"
                    stroke="white"
                    stroke-opacity="0.1"
                    stroke-width="8"
                    stroke-linecap="square"
                />
                <circle
                    cx="20"
                    cy="20"
                    r="16"
                    stroke={baseColors.primary()}
                    stroke-width="8"
                    stroke-linecap="square"
                    stroke-dasharray={`${percent * ((Math.PI * 32) / 100)} ${
                        Math.PI * 32
                    }`}
                />
            </svg>
        );
    };
    return (
        <BaseStatItem
            icon={renderChart()}
            iconColor={baseColors.white(0)}
            label="Your result"
            value={`${Math.round(percent)}%`}
        />
    );
};
