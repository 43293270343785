import { Fragment } from 'react';
import { ISchemeInfo, ISchemeInfoItem } from '@/types';
import { CopyItem, PasswordItem } from '@/components';
import { INFO_ITEM_TYPE } from '@/constants';
import {
    StyledInfo,
    StyledTitle,
    StyledContent,
    StyledDescription,
} from './styled';

interface IProps {
    content: ISchemeInfo;
}

export const Info = ({ content }: IProps) => {
    const { name, info } = content;
    const renderContent = (item: ISchemeInfoItem) => {
        switch (item.type) {
            case INFO_ITEM_TYPE.URL:
                return (
                    <CopyItem
                        link={item.value}
                        valueText={item.value}
                        keyText={item.name}
                        widthKey={88}
                    />
                );
            case INFO_ITEM_TYPE.STRING:
                return (
                    <CopyItem
                        valueText={item.value}
                        keyText={item.name}
                        widthKey={88}
                    />
                );
            case INFO_ITEM_TYPE.PASSWORD:
                return <PasswordItem valueText={item.value} widthKey={88} />;
        }
    };
    return (
        <StyledInfo>
            <StyledTitle>{name}</StyledTitle>
            <StyledContent>
                {info ? (
                    info.map((i, idx) => (
                        <Fragment key={idx}>{renderContent(i)}</Fragment>
                    ))
                ) : (
                    <StyledDescription>No data</StyledDescription>
                )}
            </StyledContent>
        </StyledInfo>
    );
};
