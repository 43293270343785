import { useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { quitTeam, getProfile } from '@/store/reducers/ProfileSlice';
import { PageHeader, IconButton, TrashIcon } from '@/components';
import { SideBar, SideBarContext } from '@/components/SideBar';
import { ISideCell } from '@/components/SideBar/types';
import { Outlet } from 'react-router-dom';
import { StyledPageContainer, StyledContainer } from '../styled';
import { SendInvite } from './forms/SendInvite/SendInvite';
import { JoinTem } from './forms/JoinTeam/JoinTem';

export const Scoreboard = () => {
    const { team } = useAppSelector((state) => state.ProfileSlice);
    const [openedCellId, setOpenCellId] = useState<ISideCell['id'] | undefined>(
        undefined
    );
    const dispath = useAppDispatch();
    const styleContainer = css`
        padding: 0;
        gap: 0;
        padding-right: 4px;
    `;
    useEffect(() => {
        dispath(getProfile());
    }, [dispath]);
    return (
        <SideBarContext.Provider
            value={{
                cells: [
                    {
                        id: 'join_team',
                        title: team ? 'Send invite' : 'Join team',
                        children: team ? <SendInvite /> : <JoinTem />,
                    },
                ],
                openedCellId,
                setOpenCellId(cellId) {
                    setOpenCellId(cellId);
                },
            }}
        >
            <StyledPageContainer cssProp={styleContainer}>
                <PageHeader
                    title="Scoreboard"
                    tabs={[
                        {
                            value: 'All users',
                            id: 'all_users',
                        },
                        {
                            value: 'My team',
                            id: 'private',
                        },
                    ]}
                    actions={
                        <IconButton
                            size="M"
                            label="Leave team"
                            iconLeft={<TrashIcon />}
                            onClick={() => dispath(quitTeam())}
                            disabled={!team}
                        />
                    }
                />
                <StyledContainer>
                    <Outlet />
                    <SideBar />
                </StyledContainer>
            </StyledPageContainer>
        </SideBarContext.Provider>
    );
};
