import styled from 'styled-components';
import { typography } from '@/constants';
import { StyledContentWrapper } from '../../styled';

export const StyledOffer = styled.div`
    ${StyledContentWrapper};
    display: flex;
    flex-direction: column;
    gap: 88px;
    box-sizing: border-box;
    padding: 88px 0;
    @media (max-width: 1280px) {
        gap: 40px;
    }
    @media (max-width: 768px) {
        max-height: auto;
    }
`;

export const StyledTitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledSubtitle = styled.h2`
    margin: 0;
    padding: 0;
    ${typography.title.XL};
    text-align: center;
`;

export const StyledTitleImage = styled.img`
    width: 100%;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    > span {
        ${typography.text.S};
        opacity: 0.5;
    }
`;
