import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IError, IScheme } from '@/types';

export const checkChangeSchemeWsThunk = createAsyncThunk<
    IScheme,
    string,
    { rejectValue: IError }
>('schemeSlice/checkChangeSchemeWsThunk', async (uuid, { rejectWithValue }) => {
    try {
        const response = await apiWorkshopLabClient.get(
            `/api/v1/workshoplabs/${uuid}/schema/`
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});
