import { Fragment } from 'react';
import { nanoid } from 'nanoid';
import { ISchemeConnection } from '@/types';
import { IconButton, InfoIcon, TextItem, Tooltip } from '@/components';
import { baseColors } from '@/constants';
import { StyledContainer, StyledContent } from './styled';

interface IProps {
    rules: ISchemeConnection['rules'];
}

export const ArrowInfoButton = ({ rules }: IProps) => {
    const renderContent = () => {
        return (
            <StyledContent>
                {rules.map((r) => (
                    <Fragment key={nanoid()}>
                        <div className="section">
                            <TextItem
                                size="S"
                                keyText="Proto"
                                valueText={r.proto}
                                direction="column"
                            />
                            <TextItem
                                size="S"
                                keyText="Port"
                                valueText={r.ports}
                                direction="column"
                            />
                        </div>
                    </Fragment>
                ))}
            </StyledContent>
        );
    };
    return (
        <StyledContainer>
            <Tooltip
                content={renderContent()}
                styleHint={{ background: baseColors.levelOne() }}
            >
                <IconButton size="S" icon={<InfoIcon variant="S" />} />
            </Tooltip>
        </StyledContainer>
    );
};
