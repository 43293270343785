import styled from 'styled-components';
import { typography } from '@/constants';

export const StyledContainer = styled.div`
    box-sizing: border-box;
    padding: 32px 72px 48px 72px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    > p {
        ${typography.text.M};
    }
`;

export const StyledFooter = styled.div`
    box-sizing: border-box;
    padding: 16px 24px;
`;
