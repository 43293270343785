import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';

export const getUserChoiceLabsThunk = createAsyncThunk<
    string,
    void,
    { rejectValue: IError }
>('labListsSlice/getUserChoiceLabsThunk', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/lab_lists/usrchc/');
        const data = await response.data;
        console.log(data);
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});
