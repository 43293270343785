import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/hooks/redux';
import banner from '@/pages/Home/assets/banner.png';
import {
    // StyledContainer,
    // StyledTitle,
    // StyledSubTitle,
    StyledImageContainer,
} from './styled';

export const Banner = () => {
    const navigate = useNavigate();
    const {
        newsListState: { data, fetching },
    } = useAppSelector((state) => state.NewsSlice);
    const getTitle = () => {
        if (fetching) {
            return 'Loading news...';
        }
        if (data && data.length) {
            return data[0].title;
        }
        return 'Not news';
    };
    // const getSubtitle = () => {
    //     if (data && data.length) {
    //         return data[0].subtitle;
    //     }
    //     return '';
    // };
    return (
        <StyledImageContainer>
            <img
                src={banner}
                alt={getTitle()}
                onClick={() => navigate('/home/news')}
            />
        </StyledImageContainer>
        // <StyledContainer onClick={() => navigate('news')}>
        //     <StyledTitle>{getTitle()}</StyledTitle>
        //     <StyledSubTitle>{getSubtitle()}</StyledSubTitle>
        // </StyledContainer>
    );
};
