import { Icon } from "../Icon";

interface IconLogoProps {
  color?: string;
  figureFill?: string;
  size?: number;
  cursor?: "auto" | "pointer";
}
export const IconLogo = (props: IconLogoProps) => {
  const { color, size, figureFill, cursor } = props;
  return (
    <Icon
      size={size}
      cursor={cursor}
      content={
        <>
          <path
            d="M9.81285 1.59558C11.1628 0.801475 12.8372 0.801473 14.1871 1.59558L19.8734 4.94044C21.191 5.71549 22 7.12996 22 8.65859V15.4889C22 17.0175 21.191 18.432 19.8734 19.207L14.1871 22.5519C12.8372 23.346 11.1628 23.346 9.81285 22.5519L4.12658 19.207C2.809 18.432 2 17.0175 2 15.4889V8.65859C2 7.12996 2.809 5.71549 4.12658 4.94044L9.81285 1.59558Z"
            fill={color || "#145ADB"}
          />
          <path
            opacity="0.6"
            d="M3.95996 8.04232C3.95996 7.62453 4.18154 7.23807 4.5421 7.02701L10.2284 3.69846C11.0127 3.23936 11.9992 3.80499 11.9992 4.71377V11.3991C11.9992 11.8169 11.7776 12.2034 11.417 12.4144L5.73076 15.743C4.94647 16.2021 3.95996 15.6365 3.95996 14.7277V8.04232Z"
            fill={figureFill || "white"}
          />
          <path
            opacity="0.6"
            d="M7.8833 10.395C7.8833 9.97719 8.10488 9.59073 8.46544 9.37967L14.1517 6.05112C14.936 5.59202 15.9225 6.15765 15.9225 7.06643V13.7518C15.9225 14.1696 15.7009 14.556 15.3404 14.7671L9.6541 18.0956C8.86981 18.5547 7.8833 17.9891 7.8833 17.0803V10.395Z"
            fill={figureFill || "white"}
          />
          <path
            opacity="0.6"
            d="M12.196 12.7481C12.196 12.3303 12.4176 11.9439 12.7782 11.7328L18.4645 8.40427C19.2488 7.94517 20.2353 8.5108 20.2353 9.41958V16.1049C20.2353 16.5227 20.0137 16.9092 19.6531 17.1202L13.9668 20.4488C13.1825 20.9079 12.196 20.3423 12.196 19.4335V12.7481Z"
            fill={figureFill || "white"}
          />
        </>
      }
    />
  );
};
