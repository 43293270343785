import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const CopyIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7 1C6.44772 1 6 1.44772 6 2V5H2C1.44772 5 1 5.44772 1 6V14C1 14.5523 1.44772 15 2 15H9C9.55228 15 10 14.5523 10 14V11H14C14.5523 11 15 10.5523 15 10V2C15 1.44772 14.5523 1 14 1H7ZM8 11H7C6.44772 11 6 10.5523 6 10V7H3V13H8V11ZM13 9H8V3H13V9Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9 2C8.44772 2 8 2.44772 8 3V7H3C2.44772 7 2 7.44772 2 8V21C2 21.5523 2.44772 22 3 22H15C15.5523 22 16 21.5523 16 21V17H21C21.5523 17 22 16.5523 22 16V3C22 2.44772 21.5523 2 21 2H9ZM14 17H9C8.44772 17 8 16.5523 8 16V9H4V20H14V17ZM10 15V4H20V15H10Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
