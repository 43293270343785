import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledCard = styled.div<{ $isBorder: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    box-sizing: border-box;
    padding-bottom: 40px;
    ${({ $isBorder }) =>
        $isBorder ? `border-bottom: 1px solid ${baseColors.white(0.1)};` : null}
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledText = styled.p`
    ${typography.text.L};
`;

export const StyledConfigList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledConfigItem = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const generateStyleWrap = ({
    typeOfWrap,
}: {
    typeOfWrap: 'list' | 'value';
}) => {
    if (typeOfWrap === 'value')
        return css`
            display: flex;
            gap: 24px;
            box-sizing: border-box;
        `;
    return css`
        display: flex;
        flex-direction: column;
        gap: 4px;
        box-sizing: border-box;
        > span {
            ${typography.title.M};
        }
    `;
};

export const StyledConfigWrapper = styled.div<{
    typeOfWrap: 'list' | 'value';
}>`
    ${generateStyleWrap};
`;

export const StyledConfigKey = styled.p<{ typeOfWrap: 'list' | 'value' }>`
    width: 108px;
    ${({ typeOfWrap }) =>
        typeOfWrap === 'list' ? typography.text.M : typography.title.M};
`;

export const StyledConfigValue = styled.p`
    ${typography.text.M};
`;
