import { nanoid } from 'nanoid';
import { NoData, LoaderDots } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { InfluencerLabCard } from '../';

export const InfluencerLabList = () => {
    const {
        influencerLabState: { data, fetching },
    } = useAppSelector((state) => state.LabListsSlice);
    console.log('d: ', data);
    if (fetching) {
        return (
            <StyledPageLoaderContainer>
                <LoaderDots />
            </StyledPageLoaderContainer>
        );
    }
    if (data && data.length) {
        return (
            <>
                {data?.map((l) => {
                    return <InfluencerLabCard {...l} key={nanoid()} />;
                })}
            </>
        );
    }
    return <NoData title="No labs" style={{ paddingBottom: '128px' }} />;
};
