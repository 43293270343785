import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px 24px 0 24px;
    border-bottom: 1px solid ${baseColors.white(0.1)};
    align-items: center;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
    padding: 72px;
    padding-top: 56px;
    > form {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;
