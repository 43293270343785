import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { ITokensData } from '@/types';
import { IState } from '../types';

export const authFetchingAccessAction: CaseReducer<
    IState,
    PayloadAction<ITokensData>
> = (state, action) => {
    state.auth = true;
    state.isLoading = false;
    state.refresh = action.payload.refresh;
    state.access = action.payload.access;
    state.error = {
        code: undefined,
        message: undefined,
    };
};
