import styled, { CSSProp } from 'styled-components';
import { baseColors } from '@/constants';

export const StyledLogo = styled.div<{ CSSProp?: CSSProp }>`
    ${({ CSSProp }) => CSSProp && CSSProp};
    display: flex;
    width: fit-content;
    column-gap: 12px;
    align-items: center;
`;

export const StyledLogoFont = styled.span<{
    opacity: number;
    CSSProp?: CSSProp;
}>`
    ${({ CSSProp }) => CSSProp && CSSProp};
    letter-spacing: 2px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    opacity: ${({ opacity }) => opacity};
`;
