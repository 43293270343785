import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const ScoreboardIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M15 2C15 1.44772 14.5523 1 14 1C13.4477 1 13 1.44772 13 2L13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14L15 2Z"
                            fill={fill}
                        />
                        <path
                            d="M10 4C10.5523 4 11 4.44772 11 5V14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14V5C9 4.44772 9.44771 4 10 4Z"
                            fill={fill}
                        />
                        <path
                            d="M7 9C7 8.44772 6.55228 8 6 8C5.44772 8 5 8.44772 5 9L5 14C5 14.5523 5.44772 15 6 15C6.55229 15 7 14.5523 7 14L7 9Z"
                            fill={fill}
                        />
                        <path
                            d="M3 11C3 10.4477 2.55228 10 2 10C1.44772 10 1 10.4477 1 11V14C1 14.5523 1.44772 15 2 15C2.55229 15 3 14.5523 3 14L3 11Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M22 3C22 2.44772 21.5523 2 21 2C20.4477 2 20 2.44772 20 3V21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21V3Z"
                            fill={fill}
                        />
                        <path
                            d="M15 7C15.5523 7 16 7.44772 16 8L16 21C16 21.5523 15.5523 22 15 22C14.4477 22 14 21.5523 14 21L14 8C14 7.44772 14.4477 7 15 7Z"
                            fill={fill}
                        />
                        <path
                            d="M10 13C10 12.4477 9.55228 12 9 12C8.44772 12 8 12.4477 8 13L8 21C8 21.5523 8.44772 22 9 22C9.55229 22 10 21.5523 10 21V13Z"
                            fill={fill}
                        />
                        <path
                            d="M4 17C4 16.4477 3.55228 16 3 16C2.44772 16 2 16.4477 2 17V21C2 21.5523 2.44772 22 3 22C3.55229 22 4 21.5523 4 21L4 17Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
