import styled from 'styled-components';
import { typography } from '@/constants';
import { StyledContentWrapper } from '../../styled';

export const StyledWhom = styled.div`
    ${StyledContentWrapper};
    display: flex;
    flex-direction: column;
    gap: 64px;
    align-items: center;
    box-sizing: border-box;
    padding-top: 88px;
`;

export const StyledTitle = styled.img`
    width: 100%;
`;

export const StyledColumnsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    @media (max-width: 980px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 560px) {
        display: flex;
        flex-direction: column;
    }
`;

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const StyledSubtitleLarge = styled.h2`
    ${typography.title.XL};
    margin: 0;
    padding: 0;
`;

export const StyledTitleMiddle = styled.h3`
    ${typography.title.L};
    margin: 0;
    padding: 0;
`;

export const StyledBody = styled.p`
    ${typography.text.L};
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    > span {
        ${typography.text.S};
        opacity: 0.5;
        text-align: center;
    }
    @media (max-width: 768px) {
        > span > br {
            display: none;
        }
    }
`;
