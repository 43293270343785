import { memo, useContext } from 'react';
import { useAppSelector } from '@/hooks/redux';
import { STATUS_LAB } from '@/constants';
import { TaskCard } from './components/TaskCard/TaskCard';
import { NoData, Alert, LoaderDots } from '@/components';
import { StyledPage } from './styled';
import { TerminalContext } from '../../components/Sidebar/context/terminalContext';
import { SideBarContext } from '@/components/SideBar/context';
import { StyledPageLoaderContainer } from '@/pages/styled';

export const Tasks = memo(() => {
    const { setVmOption } = useContext(TerminalContext);
    const { setOpenCellId } = useContext(SideBarContext);
    const { tasks, fetching, checkTaskState } = useAppSelector(
        (state) => state.TaskSlice
    );

    const { error: checkError } = checkTaskState;
    const { lab } = useAppSelector((state) => state.LabWithSchemeSlice);

    const isDisabled = lab?.status !== STATUS_LAB.OPERATIONAL;
    const isNoData = (!tasks || !tasks.length) && !fetching;

    return (
        <StyledPage>
            {checkError.code && (
                <Alert code={checkError.code} message={checkError.message} />
            )}
            {fetching ? (
                <StyledPageLoaderContainer>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            ) : (
                tasks?.map((t) => {
                    return (
                        <TaskCard
                            {...t}
                            disabled={isDisabled}
                            key={t.id}
                            openTerminal={() => {
                                const {
                                    name,
                                    id,
                                    text,
                                    target_machine,
                                    is_flag,
                                } = t;
                                setOpenCellId('terminal');
                                if (setVmOption)
                                    setVmOption({
                                        title: name,
                                        id: `${id}_task`,
                                        description: text,
                                        vmId: target_machine,
                                        is_flag,
                                    });
                            }}
                        />
                    );
                })
            )}
            {isNoData && (
                <NoData title="No tasks" text="But we will add them soon." />
            )}
        </StyledPage>
    );
});
