import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWsLabByUuidThunk } from '@/store/reducers/LabSlice/AsyncThanks/WorkshopThunks';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IError } from '@/types/error';

export const destroyWsLabThunk = createAsyncThunk<
    void,
    string,
    { rejectValue: IError }
>(
    'labWithSchemeSlice/destroyWsLabThunk',
    async (uuid, { rejectWithValue, dispatch }) => {
        try {
            await apiWorkshopLabClient.get(
                `/api/v1/workshoplabs/${uuid}/destroy_lab/`
            );
            await dispatch(getWsLabByUuidThunk(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.error,
                });
            }
        }
    }
);
