import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

interface IAPIClient extends AxiosInstance {
    isRestoringSession: boolean;
}

interface CustomRequestConfig extends AxiosRequestConfig {
    requestName?: string;
}

const AXIOS = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
}) as IAPIClient;

export const apiWorkshopLabClient = {
    get: (url: string, config?: CustomRequestConfig) => AXIOS.get(url, config),
    post: (url: string, data: unknown, headers?: CustomRequestConfig) => {
        if (headers) {
            return AXIOS.post(url, data, headers);
        }
        return AXIOS.post(url, data);
    },
};
