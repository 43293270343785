import styled from "styled-components";
import { baseColors, typography } from "../../../../constants/main";

export const StyledViel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${baseColors.bckg(0.96)};
  padding-top: 96px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

export const StyledContainer = styled.div`
  padding: 0 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
`;

export const StyledHeader = styled.p`
  ${typography.text.L};
  text-align: center;
  font-weight: 700;
`;

export const StyledDedicatedHeader = styled.span`
  ${typography.text.L};
  text-align: center;
  font-weight: 700;
  border-bottom: 2px solid ${baseColors.error()};
  box-sizing: border-box;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;
