import { ReactNode } from 'react';
import { LoaderDots, PagesTabs } from '../../';
import { IData } from '../../Tabs/PagesTabs/types';
import {
    StyledPageHeader,
    StyledTitle,
    StyledNavigation,
    StyledTitleContainer,
    StyledLoaderContainer,
} from './styled';

interface IProps {
    title: ReactNode;
    tabs?: IData[];
    actions?: ReactNode;
    fetching?: boolean;
}

export const PageHeader = ({ title, tabs, actions, fetching }: IProps) => {
    const renderTitle = () => {
        if (typeof title === 'string') {
            return (
                <StyledTitleContainer>
                    <StyledTitle>{title}</StyledTitle>
                </StyledTitleContainer>
            );
        }
        return title;
    };

    return (
        <StyledPageHeader>
            {fetching ? (
                <StyledLoaderContainer>
                    <LoaderDots />
                </StyledLoaderContainer>
            ) : (
                <>
                    <StyledNavigation>
                        {renderTitle()}
                        {tabs && <PagesTabs navLink data={tabs} size="L" />}
                    </StyledNavigation>
                    {actions}
                </>
            )}
        </StyledPageHeader>
    );
};
