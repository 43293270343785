import { CSSProperties } from 'react';
import { nanoid } from 'nanoid';
import { LoaderDots } from '@/components';
import { useAppSelector } from '@/hooks/redux';
import { UserItem } from '../';
import { StyledUserList } from './styled';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { baseColors } from '@/constants';

export const UserList = () => {
    const { profile, isLoadingProfile } = useAppSelector(
        (state) => state.ProfileSlice
    );
    return (
        <StyledUserList>
            {isLoadingProfile ? (
                <StyledPageLoaderContainer style={{ paddingBottom: '16px' }}>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            ) : (
                profile?.close_users.map((u, idx, arr) => {
                    const firstItemStyled: CSSProperties = {
                        borderTop: `1px solid ${baseColors.white(0.1)}`,
                    };
                    const lastItemStyled: CSSProperties = {
                        borderBottom: `none`,
                    };
                    const getStyle = () => {
                        if (idx === 0) {
                            return firstItemStyled;
                        }
                        if (idx === arr.length - 1) {
                            return lastItemStyled;
                        }
                        return undefined;
                    };
                    return (
                        <UserItem {...u} key={nanoid()} style={getStyle()} />
                    );
                })
            )}
        </StyledUserList>
    );
};
