import { memo } from 'react';
import { ISideCell } from '../../types';
import {
    StyledSideCell,
    StyledHeader,
    StyledTitleContainer,
    StyledTitle,
    StyledFormContainer,
} from '../../styled';

interface IProps extends ISideCell {
    order: number;
    isOpened: boolean;
    isOpenedBar: boolean;
    onClick: (cellId: ISideCell['id']) => void;
}

export const SideCell = memo(
    ({
        id,
        width,
        order,
        title,
        icon,
        isOpened,
        isOpenedBar,
        children,
        onClick,
    }: IProps) => {
        return (
            <StyledSideCell
                width={width}
                isOpened={isOpened}
                isOpenedBar={isOpenedBar}
                order={order}
            >
                <StyledHeader
                    isOpened={isOpened}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (!isOpened) onClick(id);
                    }}
                >
                    <StyledTitleContainer isOpened={isOpened}>
                        {icon}
                        <StyledTitle>{title}</StyledTitle>
                    </StyledTitleContainer>
                </StyledHeader>
                {isOpened && (
                    <StyledFormContainer>{children}</StyledFormContainer>
                )}
            </StyledSideCell>
        );
    }
);
