import { FC, ReactNode, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { StyledHint, StyledHintText } from './styled';
import { TooltipProps } from './types';
import { usePopper } from '../../hooks/usePopper/usePopper';
import { PLACEMENTS } from '../../hooks/usePopper/constants';

export const Tooltip = ({
    children,
    text = 'Hint',
    content,
    style,
    styleHint,
    placement,
    enabled,
}: TooltipProps) => {
    const [isShow, setIsShow] = useState(false);

    const showTooltip = () => {
        setIsShow(true);
    };

    const hideTooltip = () => {
        setIsShow(false);
    };

    const { referenceRef, popperRef } = usePopper({
        enabled: isShow,
        placement: placement || PLACEMENTS.top,
        flip: true,
        offset: 4,
        arrowPadding: 0,
        matchWidth: false,
    });

    const renderContent = useMemo<ReactNode | undefined>(() => {
        if ((content && text) || (!text && content)) {
            return content;
        }
        if (!content && text) {
            return <StyledHintText>{text}</StyledHintText>;
        }
        return undefined;
    }, [content, text]);

    const Hint: FC = () => {
        if (isShow && (enabled === true || enabled === undefined)) {
            return createPortal(
                <StyledHint ref={popperRef} style={{ ...styleHint }}>
                    {renderContent}
                </StyledHint>,
                window.document.body
            );
        } else {
            return null;
        }
    };

    return (
        <div
            ref={referenceRef}
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            style={{
                ...style,
                width: 'fit-content',
            }}
        >
            {children}
            <Hint />
        </div>
    );
};
