import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IGetHighlightResponse } from '@/types/highlights';

export const getFlagsWsThunk = createAsyncThunk<
    IGetHighlightResponse,
    string,
    { rejectValue: IError }
>('flagsSlice/getFlagsWsThunk', async (uuid, { rejectWithValue }) => {
    try {
        const response = await apiWorkshopLabClient.get(
            `/api/v1/workshoplabs/${uuid}/highlights/`
        );
        const data = await response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.response?.status,
                message: err.response?.data.detail,
            });
        }
    }
});
