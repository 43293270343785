import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getTimeRemaining, setFormatTimer } from './utils';
import { StyledTimer, StyledSectionTime } from './styled';
dayjs.extend(utc);

interface CardTimerProps {
    endTime: string | null;
    actionEnd?: () => void;
}

export const Timer: FC<CardTimerProps> = ({ endTime, actionEnd }) => {
    const formattedDate = dayjs
        .utc(endTime)
        .format('MMMM DD YYYY HH:mm:ss')
        .toString();
    const [today, setToday] = useState(
        dayjs.utc().format('MMMM DD YYYY HH:mm:ss').toString()
    );
    const [days, setDays] = useState<number>(99);
    const [hours, setHours] = useState<number>(99);
    const [minutes, setMinutes] = useState<number>(99);
    const [seconds, setSeconds] = useState<number>(99);

    useEffect(() => {
        const secondsInterval = setInterval(() => {
            const t = getTimeRemaining(formattedDate, today);
            setDays(t.days);
            setHours(t.hours);
            setMinutes(t.minutes);
            setSeconds(t.seconds);
            if (t.days + t.hours + t.minutes + t.seconds <= 0) {
                if (actionEnd) {
                    actionEnd();
                }
                clearInterval(secondsInterval);
            } else {
                setToday(
                    dayjs.utc().format('MMMM DD YYYY HH:mm:ss').toString()
                );
            }
        }, 1000);
        return () => {
            return clearInterval(secondsInterval);
        };
    }, [
        formattedDate,
        today,
        setToday,
        setDays,
        setHours,
        setMinutes,
        setSeconds,
        actionEnd,
    ]);

    // useEffect(() => {
    //     if (days + hours + minutes + seconds <= 0 && actionEnd) {
    //         actionEnd();
    //     }
    // }, [days, hours, minutes, seconds, actionEnd]);

    return (
        <StyledTimer>
            <StyledSectionTime>
                <span>{setFormatTimer(hours)}</span>
            </StyledSectionTime>
            <StyledSectionTime>
                <span>{setFormatTimer(minutes)}</span>
            </StyledSectionTime>
            <StyledSectionTime>
                <span>{setFormatTimer(seconds)}</span>
            </StyledSectionTime>
        </StyledTimer>
    );
};
