import styled from 'styled-components';
import { typography, baseColors } from '../../../constants/main';

export const StyledPageHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${baseColors.levelTwo()};
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: 1px solid ${baseColors.white(0.1)};
`;

export const StyledNavigation = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
