import { css } from 'styled-components';
import { Logo } from '@/components';
import { StyledHeader, StyledNav, StyledLink } from './styled';

export const Header = () => {
    const textSyle = css`
        @media (max-width: 560px) {
            font-size: 14px;
            line-height: 20px;
        }
    `;
    const logoContainerStyle = css`
        @media (max-width: 560px) {
            column-gap: 8px;
        }
    `;
    return (
        <StyledHeader>
            <Logo textCSSProp={textSyle} CSSProp={logoContainerStyle} />
            <StyledNav>
                <StyledLink to="/auth/signin">Sign in</StyledLink>
                <StyledLink to="/auth/signup">Sign up</StyledLink>
                <StyledLink to="/auth/write_to_us">Write To Us</StyledLink>
            </StyledNav>
        </StyledHeader>
    );
};
