import { useContext } from 'react';
import { StyledFooter, StyledNav, StyledLink, StyledTagline } from './styled';
import { DiscordIcon, TelegramIcon, IconMail } from '@/components';
import { ToastContext } from '@/components/Toast/ToastContext';

export const Footer = () => {
    const { setIsShow, setTitle } = useContext(ToastContext);
    const email = 'm@defbox.io';
    const clickOnCopyHandler = () => {
        navigator.clipboard.writeText(email);
        setIsShow(true);
        setTitle('Email successfully copied to clipboard');
    };
    return (
        <StyledFooter>
            <StyledTagline>Defbox – enhance cybersec skills</StyledTagline>
            <StyledNav>
                <StyledLink as={'div'} onClick={clickOnCopyHandler}>
                    <IconMail />
                    <span>m@defbox.io</span>
                </StyledLink>
                <StyledLink
                    href="https://discord.com/invite/PFN2hsjfKC"
                    target="_blank"
                >
                    <DiscordIcon /> <span>Discord</span>
                </StyledLink>
                <StyledLink
                    href="https://t.me/defbox_community"
                    target="_blank"
                >
                    <TelegramIcon /> <span>Telegram</span>
                </StyledLink>
            </StyledNav>
        </StyledFooter>
    );
};
