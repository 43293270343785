import { baseColors } from '@/constants';
import styled from 'styled-components';

export const StyledPrice = styled.div`
    box-sizing: border-box;
    padding: 96px 128px 0 128px;
`;

export const StyledTeamHint = styled.div`
    width: 570px;
    margin-bottom: 48px;
`

export const StyledTeamHintTitle = styled.h4`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px 0;
    color: ${baseColors.white()}
`

export const StyledTeamHintText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 12px 0;
    color: ${baseColors.white(0.5)}
`