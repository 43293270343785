import { ReactNode } from 'react';
import {
    StyledContainer,
    StyledIconContainer,
    StyledValueContainer,
    StyledKey,
    StyledValue,
} from './styled';

interface IProps {
    icon: ReactNode;
    iconColor?: string;
    label: string;
    value: string;
}

export const BaseStatItem = ({ icon, iconColor, label, value }: IProps) => {
    return (
        <StyledContainer>
            <StyledIconContainer style={{ background: iconColor }}>
                {icon}
            </StyledIconContainer>
            <StyledValueContainer>
                <StyledKey>{label}</StyledKey>
                <StyledValue>{value}</StyledValue>
            </StyledValueContainer>
        </StyledContainer>
    );
};
