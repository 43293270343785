import styled, { css } from 'styled-components';
import { typography, baseColors } from '@/constants';

const iconContainerStyle = css`
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
`;

export const StyledCard = styled.div`
    box-sizing: border-box;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${baseColors.white(0.04)};
    border-radius: 8px;
    border: 1px solid ${baseColors.white(0.1)};
    cursor: pointer;
    &:hover {
        background: ${baseColors.white(0.06)};
    }
    > div.iconsContainer {
        display: flex;
        gap: 12px;
    }
    > div.iconsContainer > div.moreIcon {
        ${iconContainerStyle};
        display: block;
    }
    > div.iconsContainer > div.linkIcon {
        ${iconContainerStyle};
        display: none;
    }
    &:hover > div.iconsContainer > div.moreIcon {
        display: none;
    }
    &:hover > div.iconsContainer > div.linkIcon {
        ${iconContainerStyle};
        display: block;
    }
`;

export const StyledStatsItem = styled.div`
    width: fit-content;
    display: flex;
    gap: 16px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

export const StyledTitle = styled.p`
    ${typography.title.M};
    width: 100%;
`;
