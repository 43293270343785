import styled from 'styled-components';
import { baseColors } from '../../constants/main';

export const StyledMainLayout = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: ${baseColors.black()};
    max-width: 1920px;
    min-width: 1280px;
    margin: 0 auto;
`;
