import { CSSProperties, useRef, useState, useEffect } from 'react';
import { Tag } from '@/components';
import { ICloseUser } from '@/types/profile';
import { useAppSelector } from '@/hooks/redux';
import user_pic from '../../../../assets/userpic.svg';
import {
    StyledContainer,
    StyledIconContainer,
    StyledTextContainer,
    StyledTitle,
    StyledPoints,
    StyledTitleContainer,
} from './styled';

interface IProps extends ICloseUser {
    style?: CSSProperties;
}

export const UserItem = ({ user, points, style, id }: IProps) => {
    const { profile } = useAppSelector((state) => state.ProfileSlice);
    const [title, setTitle] = useState(user);
    const userRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
        if (userRef?.current && userRef.current.offsetHeight > 20) {
            setTitle(title.slice(0, title.length - 4) + '...');
        }
    }, [userRef?.current?.offsetHeight, title]);

    const isMy = profile?.id === id;

    return (
        <StyledContainer style={style}>
            <StyledIconContainer>
                <img src={user_pic} alt="" width={40} height={40} />
            </StyledIconContainer>
            <StyledTextContainer>
                <StyledTitleContainer>
                    <StyledTitle ref={userRef}>{title}</StyledTitle>
                    {isMy && (
                        <Tag
                            title="YOU"
                            titleStyle={{
                                fontSize: '10px',
                                lineHeight: '12px',
                                textAlign: 'center',
                            }}
                        />
                    )}
                </StyledTitleContainer>
                <StyledPoints>{points}</StyledPoints>
            </StyledTextContainer>
        </StyledContainer>
    );
};
