import styled from 'styled-components';
import { typography, baseColors } from '@/constants';
import { IconMail } from '@/components';

export const StyledFooter = styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 0 24px;
    box-sizing: border-box;
    background: ${baseColors.black(0.5)};
    @media (max-width: 768px) {
        height: 40px;
    }
`;

export const StyledNav = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledLink = styled.a`
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    > span {
        ${typography.text.M};
    }
    @media (max-width: 768px) {
        > span {
            ${typography.text.S};
        }
    }
    @media (max-width: 560px) {
        > span {
            display: none;
        }
    }
`;

export const StyledTagline = styled.p`
    ${typography.text.M};
    opacity: 0.25;
    @media (max-width: 768px) {
        ${typography.text.S};
    }
    @media (max-width: 560px) {
        display: none;
    }
`;
