import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const SchevronUpIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.23177 11.6402C1.58534 12.0645 2.2159 12.1218 2.64018 11.7682L7.99999 7.30171L13.3598 11.7682C13.7841 12.1218 14.4147 12.0645 14.7682 11.6402C15.1218 11.2159 15.0645 10.5853 14.6402 10.2318L8.64018 5.23178C8.26933 4.92274 7.73066 4.92274 7.35981 5.23178L1.35981 10.2318C0.935533 10.5853 0.878209 11.2159 1.23177 11.6402Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.29289 17.7071C2.68342 18.0976 3.31658 18.0976 3.70711 17.7071L12 9.41421L20.2929 17.7071C20.6834 18.0976 21.3166 18.0976 21.7071 17.7071C22.0976 17.3166 22.0976 16.6834 21.7071 16.2929L12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L2.29289 16.2929C1.90237 16.6834 1.90237 17.3166 2.29289 17.7071Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
