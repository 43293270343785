import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const SendIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.7071 1.29289C15.0038 1.58958 15.0837 2.03993 14.9073 2.42059L9.34418 14.4206C9.17724 14.7807 8.81309 15.008 8.41624 14.9998C8.01938 14.9916 7.66496 14.7494 7.51306 14.3827L6.57039 12.1069C6.57033 12.1067 6.57044 12.107 6.57039 12.1069L5.78623 10.2138L1.61732 8.48694C1.25059 8.33504 1.00843 7.98062 1.00022 7.58376C0.992 7.18691 1.21929 6.82276 1.57941 6.65581L13.5794 1.09275C13.9601 0.916277 14.4104 0.996204 14.7071 1.29289ZM8.41815 11.3415C8.4182 11.3416 8.41809 11.3414 8.41815 11.3415L8.48846 11.5113L11.9452 4.05482L4.48874 7.51154L6.93428 8.52452C7.17931 8.62601 7.37399 8.82069 7.47548 9.06572L8.41815 11.3415Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.7071 2.29289C22.0038 2.58958 22.0837 3.03993 21.9073 3.42059L13.0991 22.4206C12.9321 22.7807 12.568 23.008 12.1711 22.9998C11.7743 22.9916 11.4198 22.7494 11.2679 22.3827L9.77538 18.7793C9.56403 18.2691 9.80633 17.6841 10.3166 17.4728C10.8268 17.2614 11.4118 17.5037 11.6231 18.014L12.2433 19.5113L18.9452 5.05482L4.48874 11.7567L9.00098 13.6257L11.5164 11.3219C11.9237 10.9489 12.5563 10.9767 12.9293 11.384C13.3023 11.7913 13.2745 12.4238 12.8672 12.7968L9.8821 15.5308C9.59557 15.7932 9.18298 15.8659 8.82402 15.7172L1.61732 12.7321C1.25059 12.5802 1.00843 12.2257 1.00022 11.8289C0.992 11.432 1.21929 11.0679 1.57941 10.9009L20.5794 2.09275C20.9601 1.91628 21.4104 1.9962 21.7071 2.29289Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
