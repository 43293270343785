import { FC, useMemo, ReactNode } from 'react';
import { IIconProps } from './types';

export const Icon: FC<IIconProps> = ({
    content,
    size: inputSize,
    variant = 'M',
}) => {
    const { S, M } = content;
    const renderContent = useMemo<ReactNode>(() => {
        if (variant === 'S') {
            return S;
        }
        return M;
    }, [variant, M, S]);

    const size = useMemo<number>(() => {
        if (inputSize) {
            return inputSize;
        }
        if (variant === 'S') {
            return 16;
        }
        return 24;
    }, [variant, inputSize]);
    return (
        <svg
            width={size}
            height={size}
            viewBox={variant === 'S' ? '0 0 16 16' : '0 0 24 24'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            {renderContent}
        </svg>
    );
};
