import styled, { css } from 'styled-components';
import { baseColors, typography } from '../../constants/main';
import { SHAPE } from './types';

const generateView = ({ view }: { view?: SHAPE }) => {
    switch (view) {
        case SHAPE.PRIMARY:
            return css`
                background-color: ${baseColors.primary()};
                &:hover {
                    background-color: ${baseColors.primary(0.8)};
                    color: rgba(255, 255, 255, 0.8);
                }
                &:disabled {
                    background-color: ${baseColors.primary(0.25)};
                    color: rgba(255, 255, 255, 0.25);
                    cursor: auto;
                }
            `;
        case SHAPE.SECONDARY:
            return css`
                background-color: ${baseColors.primary(0)};
                border: 3px solid rgba(255, 255, 255, 1);
                padding: 5px 12px;
                &:hover {
                    border: 3px solid rgba(255, 255, 255, 0.8);
                    color: rgba(255, 255, 255, 0.8);
                }
                &:disabled {
                    border: 3px solid rgba(255, 255, 255, 0.25);
                    color: rgba(255, 255, 255, 0.25);
                    cursor: auto;
                }
            `;
        case SHAPE.GHOST:
            return css`
                background-color: ${baseColors.primary(0)};
                border: 0;
                &:hover {
                    color: rgba(255, 255, 255, 0.8);
                }
                &:disabled {
                    color: rgba(255, 255, 255, 0.25);
                    cursor: auto;
                }
            `;
        default:
            return css`
                background-color: ${baseColors.primary()};
            `;
    }
};

export const StyledButton = styled.button.attrs(
    ({
        disabled,
        type,
    }: {
        view?: SHAPE;
        disabled?: boolean;
        type?: string;
    }) => ({
        disabled,
        type,
    })
)`
    ${typography.text.M};
    width: fit-content;
    align-items: center;
    display: flex;
    gap: 8px;
    font-weight: 700;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    & > .icon {
        width: 24px;
        height: 24px;
        opacity: 1;
    }
    ${generateView};
    &:hover > .icon {
        opacity: 0.8;
    }
    &:disabled > .icon {
        opacity: 0.25;
    }
`;
