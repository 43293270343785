import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledTitle = styled.p`
    ${typography.text.M};
    width: 100%;
`;

export const StyledMenuItem = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: ${baseColors.levelThree()};
    box-sizing: border-box;
    padding: 8px 16px;
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 6px;
    &:hover {
        background: ${baseColors.levelOne(0.25)};
        border: 1px solid ${baseColors.white(0)};
    }
`;
