import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledContainer = styled.div`
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    background: ${baseColors.levelTwo()};
    border-bottom: 1px solid ${baseColors.white(0.1)};
`;

export const StyledIconContainer = styled.div`
    position: relative;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 8px;
`;

export const StyledTitle = styled.span`
    ${typography.text.M};
    word-break: break-all;
`;

export const StyledPoints = styled.span`
    ${typography.text.M};
    color: ${baseColors.warning()};
    text-align: right;
`;
