import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background: ${baseColors.levelThree()};
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid ${baseColors.white(0.1)};
`;

const generateBorder = ({ $isOpened }: { $isOpened: boolean }) => {
    if ($isOpened)
        return css`
            border-bottom: 1px solid ${baseColors.white(0.1)};
        `;
    return null;
};

export const StyledHeader = styled.div<{ $isOpened: boolean }>`
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    ${generateBorder};
    cursor: pointer;
    > span.tableWsHeader {
        ${typography.title.L};
        width: 100%;
    }
    &:hover {
        background: ${baseColors.levelTwo()};
    }
`;

const generateRowBackgroun = ({ index }: { index: number }) => {
    if (index % 2 === 0) {
        return css`
            background: ${baseColors.levelTwo()};
        `;
    }
    return null;
};

export const StyledRow = styled.div<{ index: number }>`
    display: flex;
    justify-content: space-between;
    ${generateRowBackgroun}
`;

export const StyledContainer = styled.div`
    display: flex;
`;

export const StyledAction = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledCell = styled.div`
    box-sizing: border-box;
    padding: 8px 16px;
    > span {
        ${typography.text.M};
    }
    > span.cellHeader {
        ${typography.text.M};
        color: ${baseColors.white(0.5)};
    }
`;
