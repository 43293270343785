import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { IState } from '../types';
import { getProfile } from '../';

export const changeUserNameThunk = createAsyncThunk<
    void,
    string,
    { rejectValue: IError }
>(
    'profileSlice/chngeUserNameThunk',
    async (username, { rejectWithValue, dispatch }) => {
        try {
            const response = await apiClient.post(
                '/api/v1/userinfo/change_username/',
                {
                    username: username,
                }
            );
            if (response.status === 200) {
                dispatch(getProfile());
            }
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const changeUserNameBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(changeUserNameThunk.pending, (state) => {
        state.changeUserNameState.fetching = true;
        state.changeUserNameState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(changeUserNameThunk.fulfilled, (state) => {
        state.changeUserNameState.fetching = false;
        state.changeUserNameState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(changeUserNameThunk.rejected, (state, action) => {
        state.changeUserNameState.fetching = false;
        state.changeUserNameState.errors = action.payload as IError;
    });
};
