import { Templates, Banner } from '../../components';

export const LabList = () => {
    return (
        <>
            <Banner />
            <Templates />
        </>
    );
};
