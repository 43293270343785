import { createSlice } from '@reduxjs/toolkit';
import { ILabTeamplate } from '@/types/labs';
import { IError } from '@/types/error';
import {
    getTemplateByIdThank,
    getTemplateListThank,
    recreateTemplateThank,
    runTemplateThank,
    recreateWsTemplateThunk,
    runWsTemplateThunk,
} from './AsyncThanks';

export interface IInitialState {
    templates?: ILabTeamplate[];
    templatesState: {
        error: IError;
        fetching: boolean;
    };
    currentTemplate?: ILabTeamplate;
    currentTemplateState: {
        error: IError;
        fetching: boolean;
    };
    runTemplateState: {
        error: IError;
        fetching: boolean;
    };
    recreateTemplateState: {
        error: IError;
        fetching: boolean;
    };
}

export interface IGetLabsListArgs {
    access: string;
}

const initialState: IInitialState = {
    templates: undefined,
    templatesState: {
        error: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    currentTemplate: undefined,
    currentTemplateState: {
        error: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    runTemplateState: {
        error: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
    recreateTemplateState: {
        error: {
            code: undefined,
            message: undefined,
        },
        fetching: false,
    },
};

export const templateSlice = createSlice({
    name: 'templateSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getTemplateListThank.pending, (state) => {
            state.templatesState.fetching = true;
            state.templatesState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTemplateListThank.fulfilled, (state, action) => {
            state.templates = action.payload;
            state.templatesState.fetching = false;
            state.templatesState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTemplateListThank.rejected, (state, action) => {
            state.templatesState.fetching = false;
            state.templatesState.error = action.payload as IError;
        });
        //
        builder.addCase(getTemplateByIdThank.pending, (state) => {
            state.currentTemplateState.fetching = true;
            state.currentTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTemplateByIdThank.fulfilled, (state, action) => {
            state.currentTemplate = action.payload;
            state.currentTemplateState.fetching = false;
            state.currentTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getTemplateByIdThank.rejected, (state, action) => {
            state.currentTemplateState.fetching = false;
            state.currentTemplateState.error = action.payload as IError;
        });
        // RUN TEMPLATE
        // // lab
        builder.addCase(runTemplateThank.pending, (state) => {
            state.runTemplateState.fetching = true;
            state.runTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runTemplateThank.fulfilled, (state) => {
            state.runTemplateState.fetching = false;
            state.runTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runTemplateThank.rejected, (state, action) => {
            state.runTemplateState.fetching = false;
            state.runTemplateState.error = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(runWsTemplateThunk.pending, (state) => {
            state.runTemplateState.fetching = true;
            state.runTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runWsTemplateThunk.fulfilled, (state) => {
            state.runTemplateState.fetching = false;
            state.runTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runWsTemplateThunk.rejected, (state, action) => {
            state.runTemplateState.fetching = false;
            state.runTemplateState.error = action.payload as IError;
        });

        // RECREATE TEMPLATE
        // // lab
        builder.addCase(recreateTemplateThank.pending, (state) => {
            state.recreateTemplateState.fetching = true;
            state.recreateTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateTemplateThank.fulfilled, (state) => {
            state.recreateTemplateState.fetching = false;
            state.recreateTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateTemplateThank.rejected, (state, action) => {
            state.recreateTemplateState.fetching = false;
            state.recreateTemplateState.error = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(recreateWsTemplateThunk.pending, (state) => {
            state.recreateTemplateState.fetching = true;
            state.recreateTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateWsTemplateThunk.fulfilled, (state) => {
            state.recreateTemplateState.fetching = false;
            state.recreateTemplateState.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(recreateWsTemplateThunk.rejected, (state, action) => {
            state.recreateTemplateState.fetching = false;
            state.recreateTemplateState.error = action.payload as IError;
        });
    },
});

export default templateSlice.reducer;
