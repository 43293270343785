import { useEffect, useContext } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { SideBarContext } from '@/context/sideBarContext';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import {
    getScoreboard,
    getScoreDetail,
    getNextScores,
} from '@/store/reducers/ScoreboardSlice';
import { createTeam } from '@/store/reducers/ProfileSlice';
import { NoData } from '@/components';
import { SHAPE } from '@/components/Button/types';
import { ScoreboardTable } from '@/components';
import { StyledScorePage } from '../styled';

export const ScoreboardPrivate = () => {
    const navigate = useNavigate();
    const { privateId } = useParams();
    const { setIsOpened } = useContext(SideBarContext);
    const { team, profile } = useAppSelector((state) => state.ProfileSlice);
    const { scoreList, isLoadingScoreList } = useAppSelector(
        (state) => state.scoreboardSlice
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (team) dispatch(getScoreboard({ team, limit: 20 }));
    }, [team, dispatch]);

    useEffect(() => {
        if (profile && !privateId) navigate(String(profile.id));
    }, [profile, navigate, privateId]);

    useEffect(() => {
        if (privateId) {
            dispatch(getScoreDetail(Number(privateId)));
        }
    }, [privateId, dispatch]);

    return (
        <>
            {team ? (
                <StyledScorePage>
                    <Outlet />
                    <ScoreboardTable
                        isLoader={isLoadingScoreList}
                        getNextScores={() => {
                            if (scoreList?.next)
                                dispatch(getNextScores(scoreList?.next));
                        }}
                        scores={scoreList}
                        clickOnRow={(id) => navigate(String(id))}
                    />
                </StyledScorePage>
            ) : (
                <NoData
                    title="You don't belong to a team yet"
                    text={
                        <>
                            You can join an&nbsp;existing team using their
                            unique team ID&nbsp;or create a&nbsp;new team and
                            share the&nbsp;ID with your friends. Get ready for
                            exciting competitions with colleagues
                            or&nbsp;friends. Join or&nbsp;create a&nbsp;team
                            now!
                        </>
                    }
                    buttons={[
                        {
                            title: 'Create',
                            variants: SHAPE.SECONDARY,
                            action: () => dispatch(createTeam()),
                        },
                        {
                            title: 'Join',
                            variants: SHAPE.GHOST,
                            action: () => {
                                if (setIsOpened) setIsOpened(true);
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};
