import styled, { css } from 'styled-components';
import { typography, baseColors } from '@/constants';

const countStyle = css`
    ${typography.title.XXXL};
    line-height: 32px;
`;

export const StyledPointContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    width: fit-content;
    gap: 4px;
`;

export const StyledTotalCountContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    height: fit-content;
`;

export const StyledDescription = styled.span`
    ${typography.text.S};
`;

export const StyledDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledCount = styled.span`
    ${countStyle};
`;

export const StyledIconContainer = styled.div`
    border-radius: 100px;
    box-sizing: border-box;
    padding: 4px;
    overflow: hidden;
    background: ${baseColors.white(0.1)};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledStatisticCard = styled.div`
    display: flex;
    gap: 12px;
    box-sizing: border-box;
    padding: 12px 16px;
    background: ${baseColors.levelThree()};
    border: 1px solid ${baseColors.white(0.1)};
    border-radius: 8px;
`;

export const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledStatistics = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-sizing: border-box;
    padding: 12px 0;
`;

export const StyledChampionBloc = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
`;
