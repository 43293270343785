import { Icon } from "../Icon";

export const ShevronUp = () => {
  return (
    <Icon
      content={
        <path
          d="M13.0508 8.46532L17.5618 13.2879C18.4979 14.2887 17.8349 16 16.511 16L7.48903 16C6.1651 16 5.50207 14.2887 6.43824 13.2879L10.9492 8.46532C11.5295 7.84489 12.4705 7.84489 13.0508 8.46532Z"
          fill="white"
          fillOpacity="0.72"
        />
      }
    />
  );
};
