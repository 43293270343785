import { IconButton, CloseIcon } from '@/components';
import { PagesTabs } from '@/components/Tabs/PagesTabs/PagesTabs';
import { useState } from 'react';
import { ChangePasswordForm } from './Forms/ChangePasswordForm';
import { ChangeUsernameForm } from './Forms/ChangeUsernameForm';
import { StyledHeader, StyledContainer } from './styled';

interface IProps {
    onClose?: () => void;
}

export const ProfileSettings = ({ onClose }: IProps) => {
    const tabs = [
        {
            value: 'CHANGE USERNAME',
            id: 'username',
        },
        {
            value: 'CHANGE PASSWORD',
            id: 'password',
        },
    ];
    const [current, setCurrent] = useState('username');
    return (
        <>
            <StyledHeader>
                <PagesTabs
                    data={tabs}
                    style={{ height: '48px' }}
                    tabStyle={{
                        height: '48px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onChange={setCurrent as (key: string | number) => void}
                />
                <IconButton
                    icon={<CloseIcon />}
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose && onClose();
                    }}
                />
            </StyledHeader>
            <StyledContainer>
                {current === 'password' ? (
                    <ChangePasswordForm />
                ) : (
                    <ChangeUsernameForm />
                )}
            </StyledContainer>
        </>
    );
};
