import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModals {
  opened: boolean;
  content:
    | "create_lab"
    | "create_lab_wizard"
    | "auth"
    | "reg"
    | "attack_button_pressed"
    | "join_team"
    | "deploy_elk"
    | "create_workshop"
    | undefined;
}

const initialState: IModals = {
  opened: false,
  content: undefined,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    open(state) {
      state.opened = true;
    },
    close(state) {
      state.opened = false;
    },
    addContent(
      state,
      action: PayloadAction<
        | "create_lab"
        | "create_lab_wizard"
        | "auth"
        | "reg"
        | "attack_button_pressed"
        | "join_team"
        | "deploy_elk"
        | 'create_workshop'
        | undefined
      >
    ) {
      state.content = action.payload;
    },
  },
});

export default modalSlice.reducer;
