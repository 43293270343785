import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledVmCardList = styled.div`
    display: flex;
    flex-direction: column;
    background: ${baseColors.levelThree()};
    width: 100%;
`;

export const StyledVmCard = styled.div`
    display: flex;
`;

export const StyledImageContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    height: 72px;
`;

export const StyledContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    padding: 12px 16px;
    width: 100%;
`;

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
`;

export const StyledNotIp = styled.p`
    ${typography.text.M};
    opacity: 0.25;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledTitle = styled.span`
    ${typography.title.L};
`;

export const StyledActionContainer = styled.div`
    display: flex;
    gap: 24px;
`;
