import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { ILab, IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { LAB_TYPE } from '@/constants';
import qs from 'qs';

export const checkStatusLabThank = createAsyncThunk<
    ILab | undefined,
    { templateId: number; basePath?: LAB_TYPE },
    { rejectValue: IError }
>(
    'labWithSchemeSlice/checkStatusLabThank',
    async (
        { templateId, basePath = LAB_TYPE.DEFAULT },
        { rejectWithValue }
    ) => {
        try {
            const response = await apiClient.get(
                `/api/v1/${basePath}/?${qs.stringify({
                    limit: 1,
                    lab_template: templateId,
                })}`
            );
            const data = await response.data;
            return data.results[0];
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);
