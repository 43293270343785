import { CSSProperties, ReactNode } from 'react';
import { StyledTag } from './styled';

interface IProps {
    icon?: ReactNode;
    title: string;
    style?: CSSProperties;
    titleStyle?: CSSProperties;
}

export const Tag = ({ title, icon, style, titleStyle }: IProps) => {
    return (
        <StyledTag style={style}>
            {icon && icon}
            <span style={titleStyle}>{title}</span>
        </StyledTag>
    );
};
