import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
    StyledStreak,
    StyledPointContainer,
    StyledPoint,
    StyledStreakContainer,
    StyledStreakText,
    StyledContainer,
    StyledDescription,
} from './styled';

dayjs.extend(utc);

interface IProps {
    streakStarted?: string | null;
}

export const Streak = ({ streakStarted }: IProps) => {
    const getActivePoints = () => {
        if (!!streakStarted && dayjs(streakStarted).isValid()) {
            if (
                Math.abs(
                    dayjs
                        .utc(streakStarted)
                        .diff(dayjs.utc(new Date()), 'hours')
                ) <= 24
            ) {
                return 1;
            }
            return Math.abs(
                dayjs.utc(streakStarted).diff(dayjs.utc(new Date()), 'days')
            );
        }
        return 0;
    };
    const activePoints = getActivePoints();
    return (
        <StyledContainer>
            <StyledStreak>
                {[...Array(7)].map((_, idx, arr) => {
                    const getPos = () => {
                        if (idx === 0) {
                            return 'first';
                        }
                        if (idx === arr.length - 1) {
                            return 'last';
                        }
                        if (idx === activePoints) {
                            return 'lastMiddle';
                        }
                        return 'middle';
                    };
                    const active = idx <= activePoints - 1;
                    return (
                        <StyledStreakContainer key={nanoid()}>
                            <StyledPointContainer
                                $position={getPos()}
                                $active={active}
                            >
                                <StyledPoint $active={active} />
                            </StyledPointContainer>
                            <StyledStreakText $active={active}>
                                {idx + 1}
                            </StyledStreakText>
                        </StyledStreakContainer>
                    );
                })}
            </StyledStreak>
            <StyledDescription>
                Complete labs and tasks to fill the streak and improve
            </StyledDescription>
        </StyledContainer>
    );
};
