import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { RootState, AppDispatch } from '@/store/store';
import {
    getLaunchCards,
    getLaunchCardById,
    getWorkshopLaunchById,
} from '@/store/reducers/LaunchCardsSlice';
import { labsSlice } from '@/store/reducers/LabsSlice';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { UuidWithBasePath } from './types/general';

let store: ToolkitStore<RootState>;

export const injectStore = (_store: ToolkitStore<RootState>) => {
    store = _store;
};

interface IInitialState {
    isLoading: boolean;
    error: IError;
}

const initialState: IInitialState = {
    isLoading: false,
    error: {
        code: undefined,
        message: undefined,
    },
};

export const runAttack = createAsyncThunk<
    void,
    { labName: string; uuid: string; playbook_id: string },
    { rejectValue: IError }
>(
    'attackSlice/runAttack',
    async ({ labName, uuid, playbook_id }, { rejectWithValue, dispatch }) => {
        try {
            await apiClient.post(
                `/api/v1/labs/${uuid}/run_attack/`,
                { playbook_id },
                { requestName: 'runAttack' }
            );
            await dispatch(getLaunchCardById(uuid));
            await dispatch(getLaunchCards(labName));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const runWorkshopAttack = createAsyncThunk<
    void,
    { uuid: string; playbook_id: string },
    { rejectValue: IError }
>(
    'attackSlice/runAttack',
    async ({ uuid, playbook_id }, { rejectWithValue, dispatch }) => {
        try {
            await apiClient.post(
                `/api/v1/workshoplabs/${uuid}/run_attack/`,
                { playbook_id },
                { requestName: 'runAttack' }
            );
            await dispatch(getWorkshopLaunchById(uuid));
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.request.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const attackSlice = createSlice({
    name: 'attackSlice',
    initialState,
    reducers: {
        runAttackFetching(state) {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        runAttackSaccess(state) {
            const labName = labsSlice.getInitialState().lab?.name;
            const dispatch = store.dispatch as AppDispatch;
            if (labName) {
                dispatch(getLaunchCards(labName));
            }
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        runAttackFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(runAttack.pending, (state) => {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runAttack.fulfilled, (state) => {
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(runAttack.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as IError;
        });
    },
});

export default attackSlice.reducer;
