import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { ILabLayout, IError } from '@/types';
import { apiClient } from '@/api/apiClient';

interface IInitialState {
    layout: ILabLayout[];
    isLoading: boolean;
    error: IError;
}

const initialState: IInitialState = {
    layout: [],
    isLoading: false,
    error: {
        code: undefined,
        message: undefined,
    },
};

export const getLayout = createAsyncThunk<
    ILabLayout[],
    void,
    { rejectValue: IError }
>('layoutSlice/getLayout', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get(`/api/v1/labtemplates/layout/`, {
            requestName: 'getLayout',
        });
        const data = response.data;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        getLayoutFetching(state) {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        getLayoutSaccess(state, action: PayloadAction<ILabLayout[]>) {
            state.layout = action.payload;
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        },
        getLayoutFailure(state, action: PayloadAction<IError>) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getLayout.pending, (state) => {
            state.isLoading = true;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLayout.fulfilled, (state, action) => {
            state.layout = action.payload;
            state.isLoading = false;
            state.error = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getLayout.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload as IError;
        });
    },
});

export default layoutSlice.reducer;
