import { isAxiosError } from 'axios';
import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ITask, IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { apiWorkshopLabClient } from '@/api/workshopLabApi';
import { IInitialState } from '../types';

export const getTasksThunk = createAsyncThunk<
    ITask[],
    string,
    { rejectValue: IError }
>('taskSlice/getTasks', async function (uuid, { rejectWithValue }) {
    try {
        const response = await apiClient.get(`/api/v1/labs/${uuid}/tasks/`);
        const data = await response.data.results;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getTasksBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(getTasksThunk.pending, (state) => {
        state.fetching = true;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTasksThunk.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.fetching = false;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTasksThunk.rejected, (state, action) => {
        state.fetching = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};

export const getTasksWsThunk = createAsyncThunk<
    ITask[],
    string,
    { rejectValue: IError }
>('taskSlice/getTasksWs', async function (uuid, { rejectWithValue }) {
    try {
        const response = await apiWorkshopLabClient.get(
            `/api/v1/labs/${uuid}/tasks/`
        );
        const data = await response.data.results;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});

export const getTasksWsBuilder = (
    builder: ActionReducerMapBuilder<IInitialState>
) => {
    builder.addCase(getTasksWsThunk.pending, (state) => {
        state.fetching = true;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTasksWsThunk.fulfilled, (state, action) => {
        state.tasks = action.payload;
        state.fetching = false;
        state.error = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getTasksWsThunk.rejected, (state, action) => {
        state.fetching = false;
        state.error.code = action.payload?.code;
        state.error.message = action.payload?.message;
    });
};
