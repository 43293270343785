import { SmallCard, FullCard } from './components';
import { IProps } from './types';

export const TemplateCard = (props: IProps) => {
    const { size = 'L' } = props;
    if (size === 'L') {
        return <FullCard {...props} isGeneralStats isUserStats />;
    }
    return <SmallCard {...props} />;
};
