import styled from 'styled-components';
import { baseColors, typography } from '@/constants';

export const StyledHeader = styled.div`
    display: flex;
    gap: 8px;
`;

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.span`
    ${typography.text.L};
`;

export const StyledSubtitle = styled.span`
    ${typography.text.S};
`;

export const StyledBlockContainer = styled.div`
    box-sizing: border-box;
    padding: 0 48px 48px 56px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
