import { PagesTabs } from '@/components';
import { UnfinishedLabList, InfluencerLabList } from './components';
import { StyledContainer } from './styled';
import { useState } from 'react';

export const LabList = () => {
    const [tabId, setTabId] = useState(0);
    return (
        <StyledContainer>
            <PagesTabs
                style={{ paddingLeft: '16px' }}
                onChange={(id) => setTabId(id as number)}
                data={[
                    {
                        value: 'Unfinished labs',
                        id: 0,
                    },
                    {
                        value: 'Influencers choice',
                        id: 1,
                    },
                ]}
            />
            {tabId === 0 ? <UnfinishedLabList /> : <InfluencerLabList />}
        </StyledContainer>
    );
};
