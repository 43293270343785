import { baseColors } from '../../../constants/main';
import {
    StyledLoader,
    StyledDotOne,
    StyledDotTwo,
    StyledDotThree,
    StyledPageOverlayContainer,
} from './styled';

interface LoaderDotsProps {
    color?: string;
    pageOverlay?: boolean;
}

export const LoaderDots = (props: LoaderDotsProps) => {
    const { color: inputColor, pageOverlay = false } = props;
    const color = inputColor ?? baseColors.primary();
    return (
        <>
            {pageOverlay === false ? (
                <StyledLoader>
                    <StyledDotOne color={color} />
                    <StyledDotTwo color={color} />
                    <StyledDotThree color={color} />
                </StyledLoader>
            ) : (
                <StyledPageOverlayContainer>
                    <StyledLoader>
                        <StyledDotOne color={color} />
                        <StyledDotTwo color={color} />
                        <StyledDotThree color={color} />
                    </StyledLoader>
                </StyledPageOverlayContainer>
            )}
        </>
    );
};
