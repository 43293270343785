import styled from 'styled-components';
import { typography, baseColors } from '@/constants';

export const StyledActionContainer = styled.div`
    display: flex;
    gap: 24px;
`;

export const StyledActionDescription = styled.span`
    ${typography.text.M};
    color: ${baseColors.white(0.25)};
`;
