import { createSlice } from '@reduxjs/toolkit';
import { IError } from '@/types/error';
import { IGetHighlightResponse } from '@/types/highlights';
import { getFlagsThunk, addFlagThunk } from './AsyncThunks';
import { getFlagsWsThunk, addFlagWsThunk } from './AsyncThunks/WorkshopThunks';

interface IInitialState {
    highlights?: IGetHighlightResponse;
    highlightState: {
        fetching: boolean;
        errors: IError;
    };
    addHighlightState: {
        fetching: boolean;
        errors: IError;
    };
}

const initialState: IInitialState = {
    highlights: undefined,
    highlightState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
    addHighlightState: {
        fetching: false,
        errors: {
            code: undefined,
            message: undefined,
        },
    },
};

export const flagsSlice = createSlice({
    name: 'flagsSlice',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // GET FLAGS
        // // labs
        builder.addCase(getFlagsThunk.pending, (state) => {
            state.highlightState.fetching = true;
            state.highlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getFlagsThunk.fulfilled, (state, action) => {
            state.highlightState.fetching = false;
            state.highlights = action.payload;
            state.highlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getFlagsThunk.rejected, (state, action) => {
            state.highlightState.fetching = false;
            state.highlightState.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(getFlagsWsThunk.pending, (state) => {
            state.highlightState.fetching = true;
            state.highlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getFlagsWsThunk.fulfilled, (state, action) => {
            state.highlightState.fetching = false;
            state.highlights = action.payload;
            state.highlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getFlagsWsThunk.rejected, (state, action) => {
            state.highlightState.fetching = false;
            state.highlightState.errors = action.payload as IError;
        });

        // ADD FLAG
        // // labs
        builder.addCase(addFlagThunk.pending, (state) => {
            state.addHighlightState.fetching = true;
            state.addHighlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addFlagThunk.fulfilled, (state) => {
            state.addHighlightState.fetching = false;
            state.addHighlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addFlagThunk.rejected, (state, action) => {
            state.addHighlightState.fetching = false;
            state.addHighlightState.errors = action.payload as IError;
        });
        // // workshoplabs
        builder.addCase(addFlagWsThunk.pending, (state) => {
            state.addHighlightState.fetching = true;
            state.addHighlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addFlagWsThunk.fulfilled, (state) => {
            state.addHighlightState.fetching = false;
            state.addHighlightState.errors = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(addFlagWsThunk.rejected, (state, action) => {
            state.addHighlightState.fetching = false;
            state.addHighlightState.errors = action.payload as IError;
        });
    },
});

export default flagsSlice.reducer;
