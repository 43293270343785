import styled from 'styled-components';
import { baseColors, typography } from '../../constants/main';

export const StyledBlock = styled.div`
    background-color: ${baseColors.board()};
    border: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
`;

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px 24px;
`;

export const StyledTitle = styled.span`
    ${typography.text.L};
`;

export const StyledActionBar = styled.div`
    display: flex;
    gap: 12px;
`;

export const StyledSearchArea = styled.div`
    min-width: 420px;
`;

export const StyledContent = styled.div`
    min-height: 96px;
    box-sizing: border-box;
`;

export const StyledLoaderContainer = styled.div`
    min-height: 96px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`;
