import { CaseReducer } from '@reduxjs/toolkit';
import { IState } from '../types';

export const exitAuthAction: CaseReducer<IState> = (state) => {
    localStorage.clear();
    state.auth = false;
    state.access = undefined;
    state.refresh = undefined;
    state.isLoading = false;
};
