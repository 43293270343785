import { isAxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IError } from '@/types';
import { apiClient } from '@/api/apiClient';
import { IWorkshop } from '@/types/workshop';

interface IInitialState {
    workshops: IWorkshop[];
    workshop: IWorkshop | null;
    isLoadingWorkshop: boolean;
    errorWorkshop: IError;
}

const initialState: IInitialState = {
    workshops: [],
    workshop: null,
    isLoadingWorkshop: false,
    errorWorkshop: {
        code: undefined,
        message: undefined,
    },
};

export const getWorkshops = createAsyncThunk<IWorkshop[], void, { rejectValue: IError }>(
    'workshopSlice/getWorkshops',
    async (_, {rejectWithValue }) => {
        try {
            const response = await apiClient.get(`/api/v1/workshops/`, {
                requestName: 'getWorkshops',
            });
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    },
);

export const getWorkshopById = createAsyncThunk<IWorkshop, string, { rejectValue: IError }>(
    'workshopSlice/getWorkshopById',
    async (id, {rejectWithValue }) => {
        try {
            const response = await apiClient.get(`/api/v1/workshops/${id}/`, {
                requestName: 'getWorkshopById',
            });
            const data = await response.data;
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    },
);

export const vmSlice = createSlice({
    name: 'vmSlice',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers(builder) {
        builder.addCase(getWorkshops.pending, (state) => {
            state.isLoadingWorkshop = true;
            state.errorWorkshop = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshops.fulfilled, (state, action) => {
            state.workshops = action.payload;
            state.isLoadingWorkshop = false;
            state.errorWorkshop = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshops.rejected, (state, action) => {
            state.isLoadingWorkshop = false;
            state.errorWorkshop = action.payload as IError;
        });
        builder.addCase(getWorkshopById.pending, (state) => {
            state.isLoadingWorkshop = true;
            state.errorWorkshop = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopById.fulfilled, (state, action) => {
            state.workshop = action.payload;
            state.isLoadingWorkshop = false;
            state.errorWorkshop = {
                code: undefined,
                message: undefined,
            };
        });
        builder.addCase(getWorkshopById.rejected, (state, action) => {
            state.isLoadingWorkshop = false;
            state.errorWorkshop = action.payload as IError;
        });
    },
});

export default vmSlice.reducer;
