import { SyntheticEvent, useMemo, useState } from 'react';
import { ISchemeTask } from '@/types';
import { IconButton, TextItem, DropdownMenu, TaskIcon } from '@/components';
import { IDropdownMenuItem } from '@/components/Dropdown/types';
import { StyledTitle, StyledMenuItem } from './styled';
import { baseColors } from '@/constants';

interface IProps {
    tasks: ISchemeTask[];
    disabled?: boolean;
    onClick?: (ev: SyntheticEvent, item: ISelectTaskItem) => void;
}

interface ISelectTaskItem extends IDropdownMenuItem {
    points: number;
    is_flag?: boolean;
}

export const SelectTask = ({ tasks, disabled, onClick }: IProps) => {
    const [isOpened, setIsOpened] = useState(false);
    const options = useMemo<ISelectTaskItem[]>(() => {
        return tasks.map((t) => ({
            ...t,
            title: t.name,
            description: t.text,
        }));
    }, [tasks]);

    const isDisabled = disabled || tasks.length === 0;

    return (
        <DropdownMenu
            flip
            isOpen={isOpened}
            styleContainer={{
                width: '282px',
            }}
            styleItemsContainer={{
                maxHeight: '320px',
                overflowY: 'auto',
                background: baseColors.levelTwo(),
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                boxSizing: 'border-box',
                padding: '4px',
            }}
            list={options}
            renderItem={(i) => {
                const item = i as ISelectTaskItem;
                return (
                    <StyledMenuItem
                        onClick={(e) => {
                            if (onClick) onClick(e, item);
                            setIsOpened(false);
                        }}
                    >
                        <StyledTitle>{item.title}</StyledTitle>
                        <TextItem
                            valueText={item.description}
                            keyText="Description"
                            direction="column"
                            size="S"
                        />
                        <TextItem
                            valueText={String(item.points)}
                            keyText="Points"
                            direction="column"
                            size="S"
                        />
                    </StyledMenuItem>
                );
            }}
        >
            <IconButton
                tooltip={
                    isDisabled
                        ? { text: 'First you need to run the deploy' }
                        : undefined
                }
                size="M"
                label={`Tasks ${tasks.length}`}
                iconLeft={<TaskIcon />}
                disabled={isDisabled}
            />
        </DropdownMenu>
    );
};
