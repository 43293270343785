import styled from 'styled-components';
import { baseColors } from '@/constants';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${baseColors.white(0.1)};
    padding: 0 8px;
    padding-bottom: 8px;
    background: ${baseColors.levelTwo()};
`;
