import styled from 'styled-components';
import { baseColors, link, typography, scrollStyles } from '@/constants';

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    padding: 8px;
    background: ${baseColors.levelThree()};
    border-radius: 8px;
    border: 1px solid ${baseColors.white(0.1)};
`;

export const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 72px;
    min-height: 72px;
`;

export const StyledTitle = styled.p`
    ${typography.title.L};
`;

export const StyledText = styled.p`
    ${typography.text.M};
`;

export const StyledDescription = styled.p`
    ${typography.text.S};
`;

export const StyledLabContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
`;

export const StyledLabList = styled.div`
    height: 180px;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-sizing: border-box;
    overflow-y: scroll;
    width: calc(100% + 6px);
    box-sizing: border-box;
    padding-right: 2px;
    ${scrollStyles};
`;

export const StyledShowMore = styled.span`
    ${link.text.S};
`;
