import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { apiClient } from '@/api/apiClient';
import { IError } from '@/types';
import { IState, IInfluencerLAbResponseDate } from '../types';

export const getInfluencerLabsThunk = createAsyncThunk<
    IInfluencerLAbResponseDate,
    void,
    { rejectValue: IError }
>(
    'labListsSlice/getInfluencerChoiceLabsThunk',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiClient.get('/api/v1/lab_lists/');
            const data = await response.data;
            console.log(data);
            return data;
        } catch (err) {
            if (isAxiosError(err)) {
                return rejectWithValue({
                    code: err.response?.status,
                    message: err.response?.data.detail,
                });
            }
        }
    }
);

export const getInfluencerLabsBuilder = (
    builder: ActionReducerMapBuilder<IState>
) => {
    builder.addCase(getInfluencerLabsThunk.pending, (state) => {
        state.influencerLabState.fetching = true;
        state.influencerLabState.errors = {
            code: undefined,
            message: undefined,
        };
    });
    builder.addCase(getInfluencerLabsThunk.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.influencerLabState = {
            ...action.payload,
            data: results,
            fetching: false,
            errors: {
                code: undefined,
                message: undefined,
            },
        };
    });
    builder.addCase(getInfluencerLabsThunk.rejected, (state, action) => {
        state.influencerLabState = {
            ...state.influencerLabState,
            fetching: false,
            errors: action.payload as IError,
        };
    });
};
