import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const ArrowLeftIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M1.00791 8.12638C0.99728 8.04247 0.997285 7.95748 1.00792 7.87358C1.01583 7.81092 1.02953 7.75006 1.04848 7.69157C1.09561 7.54579 1.17706 7.4087 1.29283 7.29292L4.29283 4.29292C4.68336 3.9024 5.31652 3.9024 5.70705 4.29292C6.09757 4.68345 6.09757 5.31661 5.70705 5.70714L4.41415 7.00003L14 7.00003C14.5523 7.00003 15 7.44775 15 8.00003C15 8.55232 14.5523 9.00003 14 9.00003L4.41415 9.00003L5.70705 10.2929C6.09757 10.6834 6.09757 11.3166 5.70705 11.7071C5.31652 12.0977 4.68336 12.0977 4.29283 11.7071L1.29283 8.70714C1.17706 8.59136 1.09561 8.45427 1.04848 8.30849C1.02952 8.24996 1.01581 8.18907 1.00791 8.12638Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M5.41415 13L8.70705 16.2929C9.09757 16.6834 9.09757 17.3166 8.70705 17.7071C8.31652 18.0977 7.68336 18.0977 7.29283 17.7071L2.29283 12.7071C1.90231 12.3166 1.90231 11.6834 2.29283 11.2929L7.29283 6.29292C7.68336 5.9024 8.31652 5.9024 8.70705 6.29292C9.09757 6.68345 9.09757 7.31661 8.70705 7.70714L5.41415 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L5.41415 13Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
