import styled from 'styled-components';
import { typography, baseColors, baseColorHover } from '@/constants/main';

export const StyledDropdownMenuItem = styled.div`
    display: flex;
    gap: 8px;
    padding: 8px 12px;
    background: ${baseColors.board()};
    align-items: center;
    box-sizing: border-box;
    :hover {
        background: ${baseColorHover.board()};
    }
`;

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const StyledTitle = styled.p`
    ${typography.text.M};
    width: 100%;
`;

export const StyledLink = styled.a`
    ${typography.text.M};
    color: ${baseColors.link()};
    text-decoration: none;
    max-width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    &:hover {
        color: ${baseColorHover.link()};
    }
`;
