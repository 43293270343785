import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const DocIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.77778 1C5.5143 1 5.26147 1.10398 5.07423 1.28935L2.29646 4.03935C2.10674 4.22717 2 4.48304 2 4.75V14C2 14.5523 2.44771 15 3 15H13C13.5523 15 14 14.5523 14 14V2C14 1.44772 13.5523 1 13 1H5.77778ZM7 3H12V13H4V6H6C6.55229 6 7 5.55229 7 5V3Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 1C7.73478 1 7.48043 1.10536 7.29289 1.29289L2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V22C2 22.5523 2.44772 23 3 23H21C21.5523 23 22 22.5523 22 22V2C22 1.44772 21.5523 1 21 1H8ZM8 3.41421V7H4.41421L8 3.41421ZM4 9H9C9.55228 9 10 8.55228 10 8V3H20V21H4V9Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
