import { nanoid } from 'nanoid';
import { useAppSelector } from '@/hooks/redux';
import { TemplateCard, LoaderDots, NoData } from '@/components';
import { StyledPageLoaderContainer } from '@/pages/styled';

export const UnfinishedLabList = () => {
    const {
        progressLabState: { data, fetching },
    } = useAppSelector((state) => state.LabListsSlice);
    if (fetching) {
        return (
            <StyledPageLoaderContainer>
                <LoaderDots />
            </StyledPageLoaderContainer>
        );
    }
    if (data && data.length) {
        return (
            <>
                {data?.map((l) => {
                    return <TemplateCard {...l} key={nanoid()} size="L" />;
                })}
            </>
        );
    }
    return (
        <NoData
            title="You haven't started any lab yet"
            text="Labs that you started will appear here"
            style={{ paddingBottom: '128px' }}
        />
    );
};
