import styled, { css } from 'styled-components';
import { baseColors, typography } from '@/constants';

export interface IStyledCheckboxProps {
    checked: boolean;
    disabled?: boolean;
    hover: boolean;
}

export const StyledContainer = styled.label<{ disabled?: boolean }>`
    width: fit-content;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    gap: 8px;
    ${typography.text.M};
    color: ${baseColors.white()};
    &:hover {
        color: ${baseColors.white(0.7)};
    }
`;

const sizes = {
    M: '20px',
    S: '16px',
};

export const StyledCheckboxWrapper = styled.div<{ size: 'M' | 'S' }>`
    width: ${({ size }) => sizes[size]};
    height: ${({ size }) => sizes[size]};
    box-sizing: border-box;
    padding: 2px;
`;

const generateColor = (args: IStyledCheckboxProps) => {
    const { checked, disabled, hover } = args;
    if (!disabled) {
        if (checked) {
            return css`
                background: ${hover
                    ? baseColors.primary(0.7)
                    : baseColors.primary()};
            `;
        }
        return css`
            background: ${hover
                ? baseColors.levelOne()
                : baseColors.levelTwo()};
            box-shadow: inset 0 0 0 1px
                ${hover ? baseColors.white(0.05) : baseColors.white(0.1)};
        `;
    }
    return css`
        background: ${baseColors.black(0.25)};
    `;
};

export const StyledCheckbox = styled.div<IStyledCheckboxProps>`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${generateColor};
`;
