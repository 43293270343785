import styled, { css } from 'styled-components';
import { typography, baseColors, baseColorHover } from '@/constants';

export const StyledButton = styled.button<{
    $color: 'white' | 'primary' | 'error';
}>`
    background: none;
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
`;

const generateVariant = ({
    $color,
    $variant,
    $hover,
}: {
    $color: 'white' | 'primary' | 'error';
    $variant: 'primary' | 'secondary';
    $hover: boolean;
}) => {
    switch ($variant) {
        case 'primary':
            return css`
                background: ${$hover
                    ? baseColorHover[$color]()
                    : baseColors[$color]()};
            `;
        case 'secondary':
            return css`
                border-top: 2px solid
                    ${$hover ? baseColorHover[$color]() : baseColors[$color]()};
                border-bottom: 2px solid
                    ${$hover ? baseColorHover[$color]() : baseColors[$color]()};
            `;
    }
};

export const StyledTextContainer = styled.div<{
    $color: 'white' | 'primary' | 'error';
    $variant: 'primary' | 'secondary';
    $hover: boolean;
}>`
    display: flex;
    align-items: center;
    height: 36px;
    box-sizing: border-box;
    padding: 0 4px;
    ${generateVariant};
`;

const textColor = {
    primary: baseColors.white(),
    error: baseColors.white(),
    white: baseColors.black(),
};

export const StyledTitle = styled.span<{
    $color: 'white' | 'primary' | 'error';
    $hover: boolean;
}>`
    ${typography.text.L};
    text-transform: uppercase;
    color: ${({ $color }) => textColor[$color]};
    opacity: ${({ $hover }) => ($hover ? 0.7 : 1)};
`;
