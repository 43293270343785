import { Icon } from '../Icon';
import { IContentOfIconProps } from '../types';
import { baseColors } from '../../../constants/main';

export const PayIcon = (props: IContentOfIconProps) => {
    const { fill = baseColors.white(), variant, size } = props;

    return (
        <Icon
            variant={variant}
            size={size}
            content={{
                S: (
                    <>
                        <path
                            d="M7.99984 9.8941C8.73622 9.8941 9.33317 9.27226 9.33317 8.50517C9.33317 7.73809 8.73622 7.11624 7.99984 7.11624C7.26346 7.11624 6.6665 7.73809 6.6665 8.50517C6.6665 9.27226 7.26346 9.8941 7.99984 9.8941Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.32668 3.52502C10.0577 3.12183 12.0621 2.65493 14.2898 3.38106C14.3571 3.40139 14.4213 3.42862 14.4818 3.46191C14.6152 3.53505 14.7271 3.63615 14.8124 3.75515C14.9373 3.92943 15.0051 4.14209 15 4.3615V12.3159C15 12.6367 14.8461 12.9381 14.5861 13.1261C14.3262 13.3142 13.9918 13.3661 13.6871 13.2657C12.002 12.7106 10.5138 13.0527 8.69825 13.4701L8.69584 13.4707C8.57985 13.4973 8.46252 13.5243 8.34373 13.5514C6.43789 13.9855 4.24761 14.3999 1.71069 13.2823C1.3057 13.1601 1.0091 12.7879 1.00021 12.3452C0.999686 12.328 0.999615 12.3106 1 12.2933V4.3384C1 3.99777 1.17339 3.68057 1.46011 3.49667C1.74683 3.31276 2.10744 3.28746 2.41704 3.42951C4.36636 4.32395 6.02645 4.05047 7.89952 3.6238C8.03998 3.5918 8.18238 3.55863 8.32668 3.52502ZM3 5.75596C4.99897 6.27801 6.76851 5.93267 8.34373 5.57385C8.46267 5.54675 8.58014 5.51975 8.69627 5.49305L8.69751 5.49276C10.2628 5.13292 11.5855 4.82885 13 5.1076V11.0588C11.2832 10.8139 9.71676 11.1788 8.32668 11.5025C8.18238 11.5362 8.03998 11.5693 7.89952 11.6013C6.21584 11.9849 4.70426 12.2446 3 11.6436V5.75596Z"
                            fill={fill}
                        />
                    </>
                ),
                M: (
                    <>
                        <path
                            d="M12 14.4873C13.1046 14.4873 14 13.5918 14 12.4873C14 11.3827 13.1046 10.4873 12 10.4873C10.8954 10.4873 10 11.3827 10 12.4873C10 13.5918 10.8954 14.4873 12 14.4873Z"
                            fill={fill}
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.5987 5.74578C15.2122 5.16283 18.0811 4.52292 21.2782 5.52648C21.4909 5.58796 21.6744 5.71814 21.8032 5.89144C21.9337 6.0677 22.0052 6.28503 22 6.50975V17.9746C22 18.2933 21.8481 18.5929 21.591 18.7813C21.3339 18.9696 21.0024 19.0241 20.6985 18.928C18.024 18.0822 15.6508 18.6071 12.9576 19.2027L12.9545 19.2034C12.7699 19.2443 12.5838 19.2854 12.3961 19.3265C9.54643 19.9496 6.4003 20.5112 2.74847 18.9554C2.32151 18.8448 2.00528 18.4592 2.00007 17.9988C1.99968 17.9833 1.99965 17.9676 2 17.952V6.48729C2 6.14937 2.17066 5.83431 2.4537 5.6497C2.73673 5.46509 3.09386 5.43592 3.4031 5.57213C6.49745 6.93512 9.14157 6.50361 11.9688 5.88537C12.1771 5.83981 12.3871 5.79298 12.5987 5.74578ZM4 7.92752C7.12667 8.89275 9.87719 8.39001 12.3961 7.8392C12.5849 7.7979 12.772 7.75652 12.9576 7.71547C15.4141 7.17217 17.6044 6.68774 20 7.24865V16.6928C17.3105 16.1821 14.8591 16.7289 12.5987 17.233C12.3871 17.2803 12.1771 17.3271 11.9688 17.3726C9.32505 17.9508 6.8414 18.3656 4 17.3025V7.92752Z"
                            fill={fill}
                        />
                    </>
                ),
            }}
        />
    );
};
