import React, { ReactNode, useEffect, useState } from 'react';
import { TOAST_TYPE, ToastContext } from './ToastContext';

type ToastProviderProps = {
    children: ReactNode;
};
type ToastFNArgs = {
    title: string,
    text?: string,
    type?: TOAST_TYPE,
}
const DEFAULT_TYPE = TOAST_TYPE.SUCCESS;
export const ToastProvider = (props: ToastProviderProps) => {
    const { children } = props;
    const [isShow, setIsShow] = useState(false);
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState(TOAST_TYPE.SUCCESS);
    const toast = ({title, text = "", type = DEFAULT_TYPE}:ToastFNArgs) => {
        setTitle(title);
        setText(text);
        setIsShow(true);
        setType(type)
    }
    useEffect(() => {
        if (isShow === true) {
            const tOut = setTimeout(() => {
                setIsShow(false);
                setTitle('');
                setType(DEFAULT_TYPE);
                clearTimeout(tOut);
            }, 30000);
        }
    }, [isShow]);

    return (
        <ToastContext.Provider
            value={{
                text,
                title,
                isShow,
                setIsShow,
                setText,
                setTitle,
                type,
                setType,
                toast
            }}
        >
            {children}
        </ToastContext.Provider>
    );
};
