import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILabTeamplate } from '@/types/labs';
import { IError } from '@/types/error';
import { apiClient } from '@/api/apiClient';

export const getTemplateListThank = createAsyncThunk<
    ILabTeamplate[],
    void,
    { rejectValue: IError }
>('templateSlice/getTemplateListThank', async (_, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/api/v1/labtemplates/');

        const data = await response.data.results;
        return data;
    } catch (err) {
        if (isAxiosError(err)) {
            return rejectWithValue({
                code: err.request.status,
                message: err.response?.data.detail,
            });
        }
    }
});
