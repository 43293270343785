import { useAppSelector } from '@/hooks/redux';
import {
    StarIcon,
    LoaderDots,
    TextItem,
    RefreshIcon,
    CloseIcon,
    LabsIcon,
    AttackIcon,
    // IconButton,
    // EditIcon,
    Tooltip,
} from '@/components';
import { baseColors } from '@/constants';
import user_pic from '@/pages/Home/assets/userpic.svg';
import { Streak, PersonMenu } from './components';
import {
    StyledIsland,
    StyledHeader,
    StyledContent,
    StyledUserContainer,
    StyledUserPic,
    StyledUserNameContainer,
    StyledUserName,
    StyledUserPlaneContainer,
    StyledStatisticContainer,
    StyledStatisticLab,
    StyledFetchingUserPic,
    // StyledUserPicEdit,
    StyledUserPicContainer,
} from './styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const Person = () => {
    const { profile, isLoadingProfile } = useAppSelector(
        (state) => state.ProfileSlice
    );
    return (
        <StyledIsland>
            <StyledHeader>
                <PersonMenu />
            </StyledHeader>

            <StyledContent>
                <StyledUserContainer>
                    {isLoadingProfile ? (
                        <StyledFetchingUserPic>
                            <LoaderDots />
                        </StyledFetchingUserPic>
                    ) : (
                        <StyledUserPicContainer>
                            <StyledUserPic src={user_pic} />
                            {/* <StyledUserPicEdit>
                                <IconButton icon={<EditIcon variant="S" />} />
                            </StyledUserPicEdit> */}
                        </StyledUserPicContainer>
                    )}
                    <StyledUserNameContainer>
                        <StyledUserName>{profile?.user || '–'}</StyledUserName>
                        <StyledUserPlaneContainer>
                            <TextItem
                                valueText={
                                    profile?.is_premium
                                        ? 'Supporter'
                                        : 'Basic plan'
                                }
                            />
                            {profile?.renewal_date && (
                                <Tooltip
                                    text={
                                        profile?.will_renew
                                            ? 'Next payment at'
                                            : 'your support will end at'
                                    }
                                >
                                    <TextItem
                                        size="S"
                                        valueText={`${dayjs
                                            .utc(profile.renewal_date)
                                            .format('DD.MM.YYYY')}`}
                                        icon={
                                            profile?.will_renew ? (
                                                <RefreshIcon variant="S" />
                                            ) : (
                                                <CloseIcon variant="S" />
                                            )
                                        }
                                        styleContainerValue={{ gap: '4px' }}
                                        style={{ opacity: 0.5 }}
                                    />
                                </Tooltip>
                            )}
                        </StyledUserPlaneContainer>
                    </StyledUserNameContainer>
                </StyledUserContainer>
                <Streak streakStarted={profile?.streak_started} />

                <StyledStatisticContainer>
                    <StyledStatisticLab
                        style={{
                            borderTop: `1px solid ${baseColors.white(0.1)}`,
                            borderBottom: `1px solid ${baseColors.white(0.1)}`,
                        }}
                    >
                        <p
                            className="key"
                            style={{ color: baseColors.warning(0.5) }}
                        >
                            <StarIcon variant="S" fill={baseColors.warning()} />
                            Points
                        </p>
                        <p
                            className="value"
                            style={{
                                color: baseColors.warning(),
                            }}
                        >
                            {isLoadingProfile ? '...' : profile?.points || 0}
                        </p>
                    </StyledStatisticLab>
                    <StyledStatisticLab
                        style={{
                            borderBottom: `1px solid ${baseColors.white(0.1)}`,
                        }}
                    >
                        <p className="key">
                            <AttackIcon variant="S" />
                            First bloods
                        </p>
                        <p className="value">
                            {isLoadingProfile
                                ? '...'
                                : profile?.first_blood || 0}
                        </p>
                    </StyledStatisticLab>
                    <StyledStatisticLab>
                        <p className="key">
                            <LabsIcon variant="S" />
                            Solved / Total labs
                        </p>
                        <p className="value">
                            {isLoadingProfile
                                ? '...'
                                : `${profile?.labs_stats.solved_labs || 0}/${
                                      profile?.labs_stats.all_labs || 0
                                  }`}
                        </p>
                    </StyledStatisticLab>
                </StyledStatisticContainer>
            </StyledContent>
        </StyledIsland>
    );
};
