import { baseColors, typography } from '@/constants';
import styled from 'styled-components';

export const StyledImageContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    box-sizing: border-box;
    padding: 8px;
    padding-right: 8px;
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 0;
    > img {
        width: 100%;
    }
`;

export const StyledContainer = styled.div`
    height: 220px;
    min-height: 220px;
    box-sizing: border-box;
    background: ${baseColors.primary()};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
`;

export const StyledTitle = styled.p`
    ${typography.title.XXXXL};
    align-text: center;
`;
export const StyledSubTitle = styled.p`
    ${typography.title.L};
    align-text: center;
`;
